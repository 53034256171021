import { Spin } from "antd";
import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import DocumentTemplatesPage from "src/pages/docTemplates/DocTemplatesPage";
import CreateModelConfigPage from "src/pages/modelConfigs/CreateModelConfigPage";
import EditModelConfigPage from "src/pages/modelConfigs/EditModelConfigPage";
import ModelConfigsListPage from "src/pages/modelConfigs/ModelConfigsListPage";
import CampaignLabels from "../components/campaignLabels/CampaignLabels/CampaignLabels";
import GlobalLabels from "../components/globalLabels/GlobalLabels/GlobalLabels";
import Footer from "../components/navbars/Footer";
import Header from "../components/navbars/Header";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { CampaignEditorPage } from "../pages/campaign";
import CampaignsPage from "../pages/campaigns/CampaignsPage";
import CompaniesPage from "../pages/companies/CompaniesPage";
import CompanyDetails from "../pages/companyDetails/CompanyDetails";
import CompanyStoragePage from "../pages/storage/companyStoragePage/CompanyStoragePage";
import GenerateTemplatePage from "../pages/generateTemplate/GenerateTemplatePage";
import GraphPage from "../pages/graph/GraphPage";
import TemplateDataPage from "../pages/templateData/TemplateDataPage";
import UsersPage from "../pages/users/UsersPage";
import { getIsFetching } from "../store/slices/appSlice";
import CompanyPrivateStorage from "../components/storage/companyStorage/CompanyPrivateStorage/CompanyPrivateStorage";
import CompanyPublicStorage from "../components/storage/companyStorage/CompanyPublicStorage/CompanyPublicStorage";
import CampaignStoragePage from "../pages/storage/campaignStoragePage/CampaignStoragePage";
import MicrositeStorage from "../components/storage/micrositeStorage/MicrositeStorage";
import GlobalPublicStorage from "../components/storage/globalStorage/globalPublicStorage/GlobalPublicStorage";
import GlobalStoragePage from "../pages/storage/GlobalStoragePage";
import GlobalPrivateStorage from "../components/storage/globalStorage/globalPrivateStorage/GlobalPrivateStorage";
import CampaignViewStorage from "../components/storage/campaignViewStorage/CampaignViewStorage";

const MainRoutes: React.FC = () => {
  const isFetching = getIsFetching();
  const location = useLocation();
  const { isGlobalAdmin, isRegularUser } = useCurrentUser();

  return (
    <div className="h-[calc(100vh-4px)] flex flex-col justify-between">
      <div>
        <Header />
        <div className="w-full py-[24px] px-[48px]">
          <Spin spinning={isFetching}>
            <Routes location={location}>
              {!isRegularUser && (
                <>
                  <Route path="companies" element={<CompaniesPage />} />
                  <Route
                    path="companies/:companyId/details"
                    element={<CompanyDetails />}
                  />
                </>
              )}

              <Route path="users" element={<UsersPage />} />
              <Route
                path="graph/:companyId/:campaignId/:phaseId/:stepId/:key"
                element={
                  <ReactFlowProvider>
                    <GraphPage />
                  </ReactFlowProvider>
                }
              />

              <Route path="activities" element={<div>Coming soon</div>} />

              <Route path="campaigns" element={<CampaignsPage />} />

              {/* GLOBAL STORAGE */}
              <Route path="campaigns/storage" element={<GlobalStoragePage />}>
                <Route path="public" element={<GlobalPublicStorage />} />
                <Route path="private" element={<GlobalPrivateStorage />} />
              </Route>

              {/* COMPANY STORAGE */}
              <Route
                path="campaigns/company/:companyId/storage"
                element={<CompanyStoragePage />}
              >
                <Route path="public" element={<CompanyPublicStorage />} />
                <Route path="private" element={<CompanyPrivateStorage />} />
                <Route path="microsite" element={<MicrositeStorage />} />
              </Route>

              {/* CAMPAIGN STORAGE */}
              <Route
                path="campaigns/company/:companyId/campaign/:campaignId/storage"
                element={<CampaignStoragePage />}
              >
                <Route path="public" element={<CompanyPublicStorage />} />
                <Route path="private" element={<CompanyPrivateStorage />} />
                <Route path="assets" element={<CampaignViewStorage />} />
                <Route path="microsite" element={<MicrositeStorage />} />
              </Route>

              <Route
                path="campaigns/company/:companyId/campaign/:campaignId/phase/:phaseId/step/:stepId"
                element={<CampaignEditorPage />}
              />
              <Route
                path="campaigns/company/:companyId/campaign/:campaignId/phase/:phaseId"
                element={<CampaignEditorPage />}
              />
              <Route
                path="campaigns/company/:companyId/campaign/:campaignId"
                element={<CampaignEditorPage />}
              />

              <Route
                path="campaigns/notFound"
                element={<div>Not found: 404</div>}
              />

              <Route
                path="campaigns/company/:companyId/campaign/:campaignId/labels"
                element={<CampaignLabels />}
              />
              <Route path="campaigns/globalLabels" element={<GlobalLabels />} />

              {isGlobalAdmin && (
                <>
                  {/* Document Templates */}
                  <Route
                    path="document-templates"
                    element={<DocumentTemplatesPage />}
                  />
                  <Route
                    path="document-templates/generate-figma"
                    element={<GenerateTemplatePage />}
                  />
                  <Route
                    path="document-templates/create"
                    element={<TemplateDataPage />}
                  />
                  <Route
                    path="document-templates/create/:templateId"
                    element={<TemplateDataPage />}
                  />

                  {/* Model Configs */}
                  <Route
                    path="model-configs"
                    element={<ModelConfigsListPage />}
                  />
                  <Route
                    path="model-configs/create"
                    element={<CreateModelConfigPage />}
                  />
                  <Route
                    path="model-configs/edit/:modelConfigId"
                    element={<EditModelConfigPage />}
                  />
                </>
              )}

              <Route path="*" element={<Navigate to="campaigns" />} />
              {/*<Route path="*" element={<div>Page not found: 404</div>} />*/}
            </Routes>
            {/*<Loader isFetching={isFetching} waitBeforeShow={0}/>*/}
          </Spin>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MainRoutes;
