import { AxiosResponse } from "axios";
import { PROXY_PREFIX } from "src/constants";
import { CopyFileParams } from "src/types";
import { replaceBrackets } from "src/utils/cm.utils";
import { TStorageFileItem } from "../globalTypes";
import axiosInstance from "../utils/axios/axiosInstance";

const cmManagerApiKey = process.env.REACT_APP_CM_SUPER_USER_API_KEY;
const cmApiUrl = process.env.REACT_APP_CM_API_URL;
//TODO: move variable to project constants
const CM_API_PREFIX = "/api/secure/campaignManager";

// PUBLIC
export const getGlobalPublicFilesListApi = ({
  companyId,
  path,
}: {
  companyId: number | undefined;
  path: string;
}): Promise<AxiosResponse<Array<TStorageFileItem>>> => {
  return axiosInstance.get(`${CM_API_PREFIX}/fs/public/list`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      companyId,
      path: replaceBrackets(path),
    },
  });
};

export const uploadGlobalPublicFileApi = ({
  formData,
  companyId,
}: {
  formData: FormData;
  companyId: number | undefined;
}): Promise<AxiosResponse<TStorageFileItem>> => {
  return axiosInstance.post(`${CM_API_PREFIX}/fs/public/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    params: {
      companyId,
    },
  });
};

export const getGlobalPublicFileDataApi = ({
  path,
  companyId,
}: {
  path: string;
  companyId: number | undefined;
}): Promise<AxiosResponse<{ data: any; url: string }>> => {
  return axiosInstance.get(`${CM_API_PREFIX}/fs/public/file`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      companyId,
      path: replaceBrackets(path),
    },
  });
};

export const deleteGlobalPublicFileApi = ({
  path,
}: {
  path: string;
}): Promise<AxiosResponse<void>> => {
  const form = new FormData();
  form.append("path", path);

  return axiosInstance.delete(`${PROXY_PREFIX}/api/secured/fs/public/file`, {
    data: form,
  });
};

export const copyGlobalPublicFileApi = ({
  sourcePath,
  targetPath,
}: CopyFileParams) => {
  return axiosInstance.post(
    `${PROXY_PREFIX}/api/secured/fs/public/copy`,
    {},
    {
      params: {
        targetPath: replaceBrackets(targetPath),
        sourcePath: replaceBrackets(sourcePath),
      },
    }
  );
};

export const downloadGlobalPublicFileApi = ({
  path,
}: {
  path: string;
}): Promise<AxiosResponse<ArrayBuffer>> => {
  return axiosInstance.get(`${cmApiUrl}/api/secured/fs/public/download`, {
    responseType: "arraybuffer",
    params: {
      path,
      "api-key": cmManagerApiKey,
    },
  });
  // return axiosInstance.get(`${PROXY_PREFIX}/api/secured/fs/public/download`, {
  //   responseType: "arraybuffer",
  //   params: {
  //     path,
  //   },
  // });
};

// PRIVATE
export const getGlobalPrivateFilesListApi = ({
  companyId,
  path,
}: {
  companyId: number | undefined;
  path: string;
}): Promise<AxiosResponse<Array<TStorageFileItem>>> => {
  return axiosInstance.get(`${CM_API_PREFIX}/fs/private/list`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      companyId,
      path: replaceBrackets(path),
    },
  });
};

export const uploadGlobalPrivateFileApi = ({
  formData,
  companyId,
}: {
  formData: FormData;
  companyId: number | undefined;
}): Promise<AxiosResponse<TStorageFileItem>> => {
  return axiosInstance.post(`${CM_API_PREFIX}/fs/private/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    params: {
      companyId,
    },
  });
};

export const getGlobalPrivateFileDataApi = ({
  path,
  companyId,
}: {
  path: string;
  companyId: number | undefined;
}): Promise<AxiosResponse<string | Object>> => {
  return axiosInstance.get(`${CM_API_PREFIX}/fs/private/file`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      companyId,
      path: replaceBrackets(path),
    },
  });
};

export const deleteGlobalPrivateFileApi = ({
  path,
}: {
  path: string;
}): Promise<AxiosResponse<void>> => {
  const form = new FormData();
  form.append("path", path);

  return axiosInstance.delete(`${PROXY_PREFIX}/api/secured/fs/private/file`, {
    data: form,
  });
};

export const copyGlobalPrivateFileApi = ({
  sourcePath,
  targetPath,
}: CopyFileParams) => {
  return axiosInstance.post(
    `${PROXY_PREFIX}/api/secured/fs/private/copy`,
    {},
    {
      params: {
        targetPath: replaceBrackets(targetPath),
        sourcePath: replaceBrackets(sourcePath),
      },
    }
  );
};

export const downloadGlobalPrivateFileApi = ({
  path,
}: {
  path: string;
}): Promise<AxiosResponse<ArrayBuffer>> => {
  return axiosInstance.get(`${cmApiUrl}/api/secured/fs/private/download`, {
    responseType: "arraybuffer",
    params: {
      path,
      "api-key": cmManagerApiKey,
    },
  });
  // return axiosInstance.get(`${PROXY_PREFIX}/api/secured/fs/private/download`, {
  //   params: {
  //     path,
  //   },
  // });
};
