import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { TNavigationItem } from "../../globalTypes";

type Props = {
  items: TNavigationItem[];
  replace?: boolean
};

const Navigation: FC<Props> = ({ items, replace = false }) => {
  return (
    <nav className="flex gap-[24px]">
      {items.map(({ path, title }) => {
        return (
          <NavLink
            replace={replace}
            to={path}
            key={path}
            className={({ isActive }) => {
              return clsx(
                "text-[14px] cursor-pointer capitalize inline-flex items-center font-sans font-semibold transition-colors h-[44px] border-y-[3px] border-y-transparent",
                {
                  "text-primary border-b-primary hover:text-[#4F46E5]":
                    isActive,
                  "text-[#334155] hover:text-[#4F46E5]": !isActive,
                }
              );
            }}
          >
            {title}
          </NavLink>
        );
      })}
    </nav>
  );
};

export default Navigation;
