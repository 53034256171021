import { Select, Space } from "antd";
import { FC, MutableRefObject, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import handleRequestError from "src/utils/handleRequestError";
import { SelectOption } from "../../globalTypes";
import { selectMessageApi } from "../../store/slices/appSlice";
import { EmptySelectDropdown } from "./SearchableSelect";

type Props = {
  onSelect: (values: string[]) => void;
  fetchOptions: (wordMatch: string) => Promise<SelectOption[]>;
  value?: string[];
  className?: string;
  disabled?: boolean;
};

const SearchableMultiSelect: FC<Props> = ({
  onSelect,
  fetchOptions,
  value,
  className,
  disabled,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const timeout: MutableRefObject<any> = useRef(null);
  const messageApi = useSelector(selectMessageApi);

  const setAsyncOptions = async (word?: string) => {
    try {
      setOptions([]);
      setIsFetching(true);
      const fetchedOptions = await fetchOptions(word || "");
      setOptions(fetchedOptions);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    const startFetchOptions = async () => {
      await setAsyncOptions();
    };

    startFetchOptions();
  }, []);

  const handleSelect = (values: string[]) => {
    onSelect(values);
  };

  const handleSearch = (name: string) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }

    const fetchSearchItems = async () => {
      try {
        await setAsyncOptions(name);
      } catch (e: any) {
        const customError = handleRequestError(e);
        messageApi.error(customError.message);
        console.error(customError);
      } finally {
        setIsFetching(false);
      }
    };

    if (name) {
      timeout.current = setTimeout(fetchSearchItems, 400);
    } else {
      setOptions([]);
      setIsFetching(false);
    }
  };

  return (
    <Select
      placeholder="Start typing to search"
      showSearch
      mode="multiple"
      filterOption={false}
      loading={isFetching}
      onSearch={handleSearch}
      disabled={disabled}
      notFoundContent={<EmptySelectDropdown isFetching={isFetching} />}
      options={options}
      onChange={handleSelect}
      className={className}
      value={value}
      optionRender={(option) => <Space>{option.label}</Space>}
    />
  );
};

export default SearchableMultiSelect;
