import {
  MicrositeCompanyAssets,
  TStorageFileItem,
} from "../../../../globalTypes";

type Props = {
  assetsData: MicrositeCompanyAssets | null;
  loadedFiles: TStorageFileItem[];
};

//check if the loaded file/folder does not exist in assetsData
// return true to update assetsData
const shouldUpdateCompanyAssets = ({ assetsData, loadedFiles }: Props) => {
  if (!assetsData) return true;

  const filteredExistingAssetKeys = Object.keys(assetsData.assets).filter(
    (item) => !!item
  );

  for (let i = 0; i < loadedFiles.length; i++) {
    const { folder, fileName } = loadedFiles[i];

    //check if there is an asset in "assetsData.assets" for a file
    if (!folder) {
      const isFileExistInAssetData = filteredExistingAssetKeys.some(
        (assetKey) => assetKey === fileName
      );
      if (!isFileExistInAssetData) {
        return true;
      }
    } else {
      //check if there is an asset in "assets Data.assets" that starts with the folder name
      const isFolderExistInAssetData = filteredExistingAssetKeys.some(
        (assetKey) => assetKey.startsWith(fileName)
      );

      if (!isFolderExistInAssetData) {
        return true;
      }
    }
  }

  return false;
};

export default shouldUpdateCompanyAssets;
