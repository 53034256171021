import {
  FileSearchOutlined,
  ImportOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Empty, Flex } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getMessageApi } from "../../../store/slices/appSlice";
import { AppDispatch } from "../../../store/store";
import EditModal from "../EditModal/EditModal";
import ImportModal from "../ImportModal/ImportModal";
import LabelsHeader from "../LabelsHeader/LabelsHeader";
import LabelsList from "../LabelsList/LabelsList";
import { getCampaignLabels, getCampaignLabelsThunk } from "../../../store/slices/campaignLabelsSlice";
import { convertLabelsToArray } from "../../../utils/cm.utils";

export type TCampaignLabelsListData = {
  key: React.Key;
  labelKey: string;
  value: string;
};

const CampaignLabels = () => {
  const dispatch: AppDispatch = useDispatch();
  const labels = getCampaignLabels();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [editModeProps, setEditModeProps] =
    useState<TCampaignLabelsListData | null>(null);
  const { companyId, campaignId } = useParams();
  const messageApi = getMessageApi();
  const labelsList = convertLabelsToArray(labels);

  useEffect(() => {
    const getLabels = async (
      campaignId: number,
    ) => {
      try {
        const res = await dispatch(getCampaignLabelsThunk(campaignId));

        if ("error" in res) {
          messageApi.error(res.payload?.message);
        }
      } catch (e: any) {
        messageApi.error(e?.message);
        console.error(e);
      }
    };

    if (campaignId) {
      getLabels(+campaignId);
    }

    /* eslint-disable*/
  }, []);

  const onLabelCreate = () => {
    setEditModeProps(null);
    setIsEditModalOpen(true);
  };

  const onLabelsImport = () => {
    setIsImportModalOpen(true);
  };

  return (
    <Flex
      vertical
      gap="middle"
      style={{
        width: "100%",
        padding: "32px",
      }}
    >
      <LabelsHeader companyId={companyId} campaignId={campaignId} />
      <Flex vertical gap="middle" style={{ width: "100%", height: "70vh" }}>
        <Flex gap="middle">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={onLabelCreate}
          >
            Add
          </Button>
          <Button icon={<ImportOutlined />} onClick={onLabelsImport}>
            Import
          </Button>
          <Button icon={<FileSearchOutlined />} disabled>
            Find Duplicates
          </Button>
        </Flex>

        {!!labelsList.length ? (
          <LabelsList
            labelsList={labelsList}
            setEditModeProps={setEditModeProps}
            setIsEditModalOpen={setIsEditModalOpen}
          />
        ) : (
          <Flex
            vertical
            style={{ height: "100%", marginBottom: "15vh" }}
            align="center"
            justify="center"
          >
            <Empty
              imageStyle={{ height: "30vh" }}
              description="There are no Campaign Labels."
            />
          </Flex>
        )}

        {isEditModalOpen && (
          <EditModal
            isOpened={isEditModalOpen}
            setIsOpened={setIsEditModalOpen}
            labelData={editModeProps}
            labelsKeysLowerCase={labelsList.map((label) =>
              label.labelKey.toLowerCase()
            )}
          />
        )}
        {isImportModalOpen && (
          <ImportModal
            isOpened={isImportModalOpen}
            setIsOpened={setIsImportModalOpen}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default CampaignLabels;
