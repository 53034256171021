import { Input, Spin, Tooltip } from "antd";
import classNames from "classnames/bind";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  editEnvVarKeyThunk,
  getEnvVariables,
} from "../../../store/slices/envVariablesSlice";
import { AppDispatch } from "../../../store/store";
import styles from "./VariableItemKey.module.scss";

type TPropsType = {
  itemKey: string;
};

const VariableItemKey = (props: TPropsType) => {
  const { itemKey } = props;
  const dispatch: AppDispatch = useDispatch();
  const envVars = getEnvVariables();
  const [value, setValue] = useState(itemKey);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState("");
  const ref = useRef<HTMLDivElement>(null);
  const cn = classNames.bind(styles);

  const onBlur = async () => {
    if (isOpened) {
      setValue(itemKey);
      setIsOpened(false);

      return;
    }

    if (value === itemKey) return;

    try {
      setIsLoading(true);

      const res = await dispatch(
        editEnvVarKeyThunk({ currentKey: itemKey, newKey: value })
      );

      if ("error" in res) {
        console.error("Error in updating env var key:", res.payload);
        setValue(itemKey);
      }
    } catch (e) {
      console.error("Error in updating env var key:", e);
      setValue(itemKey);
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    const lowerCaseValue = value.toLowerCase();

    if (!value?.length) {
      setValue(value);
      setTooltipTitle(
        "The value should not be empty. The changes will not be saved!"
      );
      setIsOpened(true);

      return;
    }

    if (!/^[a-zA-Z_$][a-zA-Z0-9_$-]*$/.test(value)) {
      setValue(value);
      setTooltipTitle(
        "Invalid Javascript variable name. The changes will not be saved!"
      );
      setIsOpened(true);

      return;
    }

    if (
      itemKey.toLowerCase() !== lowerCaseValue &&
      envVars.map((item) => item.id.key.toLowerCase()).includes(lowerCaseValue)
    ) {
      setValue(value);
      setTooltipTitle(
        "The key already exists in this grid. The changes will not be saved!"
      );
      setIsOpened(true);

      return;
    }

    setValue(value);
    setTooltipTitle("");
    setIsOpened(false);
  };

  return (
    <div ref={ref} className={styles.wrap}>
      <Tooltip title={tooltipTitle} color={"red"} open={isOpened}>
        <Input.TextArea
          autoSize={true}
          bordered={false}
          className={cn("textArea", { disabled: isLoading })}
          placeholder="not defined"
          value={value.length ? value : undefined}
          onChange={onChange}
          onBlur={onBlur}
        />
      </Tooltip>
      <Spin spinning={isLoading} className={styles.spinner} />
    </div>
  );
};

export default VariableItemKey;
