import { FC } from "react";
import { Link } from "react-router-dom";
import { Icons } from "src/components/common/Icons";
import { Button } from "src/components/common/ui/button";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import SettingsMenu from "./SettingsMenu/SettingsMenu";

type Props = {};

const DocTemplatesHeader: FC<Props> = () => {
  const { isGlobalAdmin } = useCurrentUser();

  return (
    <>
      <div className="flex justify-between">
        <h1 className=" text-slate-900 text-2xl font-semibold leading-normal font-sans">
          Templates
        </h1>
        <div className="flex gap-3">
          {isGlobalAdmin && <SettingsMenu />}
          <Link to="/admin/document-templates/create">
            <Button icon={Icons.Plus} className="rounded-full">
              Add XSLT Template
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default DocTemplatesHeader;
