import { Empty, Spin } from "antd";
import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { useModelConfigsList } from "src/hooks/useModelConfigsList";
import { Table, TableBody, TableRow } from "../common/ui/table";
import { TableHeader } from "../table/Header";
import ModelConfigListItem from "./ListItem";

type Props = {};

const tableHeaders = [
  {
    title: "Title",
    name: "title",
    className: "font-sans w-1/8 text-black",
    isSortable: true,
  },
  {
    title: "Service Provider",
    name: "serviceProvider",
    className: "font-sans w-1/8 text-black",
    isSortable: false,
  },
  {
    title: "Model Name",
    name: "modelName",
    className: "font-sans w-1/8 text-black",
    isSortable: false,
  },
  {
    title: "Read Timeout",
    className: "font-sans w-1/8 text-black",
    name: "readTimoutSec",
    isSortable: false,
  },
  {
    title: "Max Tokens",
    className: "font-sans w-1/8 text-black",
    name: "maxTokens",
    isSortable: false,
  },
  {
    title: "Top P",
    className: "font-sans w-1/8 text-black",
    name: "topP",
    isSortable: false,
  },
  {
    title: "Temperature",
    className: "font-sans w-1/8 text-black",
    name: "temperature",
    isSortable: false,
  },
  {
    title: "System Default",
    className: "font-sans w-1/8 text-black",
    name: "systemDefault",
    isSortable: false,
  },
  null,
];

const ModelConfigsList: FC<Props> = () => {
  const { isGlobalAdmin } = useCurrentUser();
  const { list, loading, load, pageNumber, pageSize } = useModelConfigsList();

  useEffect(() => {
    load();
  }, [pageNumber, pageSize]);

  return (
    <Spin spinning={loading}>
      {!list?.length && (
        <div className="w-full h-full flex justify-center items-center">
          <Empty
            imageStyle={{ height: "30vh" }}
            description={
              <div>
                <span>There are no model configs created.</span>
                {isGlobalAdmin && (
                  <span>
                    <Link to="/admin/model-configs/create"> Create</Link> a new
                    model config.
                  </span>
                )}
              </div>
            }
          />
        </div>
      )}
      {!!list?.length && (
        <div className="rounded-md border w-full mx-auto">
          <Table>
            <TableHeader items={tableHeaders} />
            <TableBody className="font-sans">
              {list.length ? (
                list.map((item) => (
                  <ModelConfigListItem key={item.id} item={item} />
                ))
              ) : (
                <TableRow>No results.</TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      )}
    </Spin>
  );
};

export default ModelConfigsList;
