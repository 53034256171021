import clsx from "clsx";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import packageJson from "../../../package.json";
import logo from "../../assets/images/logo_horizontal.svg";
import UserAvatar from "../common/Avatar";
import VersionButton from "../common/VersionButton";
import ReleasesPopup from "./ReleasesPopup";

type HeaderNavItem = {
  label: string;
  active: boolean;
  path: string;
};

const Header = () => {
  const { pathname } = useLocation();
  const { data: user } = useCurrentUser();
  const { isGlobalAdmin, isRegularUser } = useCurrentUser();
  const [isModalOpened, setIsModalOpened] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpened(true);
  };

  const navDataList = [
    !isRegularUser && {
      label: "Companies",
      active: pathname.includes("companies"),
      path: "/admin/companies",
    },
    {
      label: "Users",
      active: pathname.includes("users"),
      path: "/admin/users",
    },
    {
      label: "Campaigns",
      active: pathname.includes("campaigns"),
      path: "/admin/campaigns",
    },
    isGlobalAdmin && {
      label: "Templates",
      active: pathname.includes("document-templates"),
      path: "/admin/document-templates",
    },
    isGlobalAdmin && {
      label: "Model Configs",
      active: pathname.includes("model-configs"),
      path: "/admin/model-configs",
    },
    {
      label: "Activity",
      active: pathname.includes("activities"),
      path: "/admin/activities",
    },
  ].filter(Boolean) as HeaderNavItem[];

  const navList = navDataList.map(({ path, label, active }) => {
    return (
      <Link to={path} key={path}>
        <div
          className={clsx(
            "inline-flex items-center font-sans font-semibold transition-colors text-[#334155] text-[14px] h-[64px] mx-[24px] border-y-transparent hover:text-[#4F46E5] border-y-[3px]",
            {
              "border-b-primary": active,
            }
          )}
        >
          <span className={active ? "text-primary" : ""}>{label}</span>
        </div>
      </Link>
    );
  });

  return (
    <>
      <div className="w-full h-[64px] flex items-center border-b border-[#E2E8F0] font-sans justify-between px-[48px]">
        <div className="flex gap-[8px]">
          <img src={logo} alt="App logo" />
          <div>{navList}</div>
        </div>
        <div>
          <div className="flex gap-[24px] items-center">
            <UserAvatar user={user} />
            {isGlobalAdmin && (
              <VersionButton
                label={`v${packageJson.version}`}
                onClick={handleModalOpen}
              />
            )}
          </div>
        </div>
      </div>
      {isModalOpened && isGlobalAdmin && (
        <ReleasesPopup
          setIsModalOpened={setIsModalOpened}
          isModalOpened={isModalOpened}
        />
      )}
    </>
  );
};

export default Header;
