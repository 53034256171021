import React from "react";
import { TUserData } from "../../../store/slices/usersSlice";
import { roleSettings } from "../../../constants";
import { TableCell, TableRow } from "../../common/ui/table";
import { Badge } from "../../common/ui/badge";

type PropsType = {
  user: Omit<TUserData, "global_admin_api_key">;
};
const CompanyUsersListItem: React.FC<PropsType> = (props) => {
  const { user } = props;

  const {
    label: badgeLabel = "",
    className: badgeClassName = "",
    icon: Icon,
  } = roleSettings[user.role];

  return (
    <>
      <TableRow
        key={user.id}
        className="cursor-pointer relative text-[#475569] font-sans h-10"
      >
        <TableCell className="font-medium text-[#0F172A]">
          {user.first_name} {user.last_name}
        </TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell>
          <Badge className={badgeClassName} variant={"outline"}>
            {Icon && <Icon className="size-4 mr-[4px] text-[#64748B]" />}
            <span className="text-[#64748B] text-[11px]">
              {badgeLabel}
            </span>
          </Badge>
        </TableCell>
        <TableCell>{user.position}</TableCell>
      </TableRow>
    </>
  );
};

export default CompanyUsersListItem;
