import { FC } from "react";
import { useParams } from "react-router-dom";
import { getMicroSiteStorageFilesApi } from "../../../api/microsite-storage.api";
import { micrositeApi } from "../../../api/microsite.api";
import { MICRO_SITE_POSTFIX } from "../../../constants";
import { TFileTreeItem } from "../../../globalTypes";
import useCampaignData from "../../../pages/storage/campaignStoragePage/hooks/useCampaignData.hook";
import Storage from "../dynamicStorage/Storage";
import useDynamicStorage from "../dynamicStorage/hooks/useDynamicStorage.hook";
import {
  LoadCompanyAssets,
  LoadFolderFiles,
  LoadRootFiles,
} from "../dynamicStorage/types";
import MicrositeStorageItemMenu from "./MicrositeStorageItemMenu";

const MicrositeStorage: FC = () => {
  const campaignData = useCampaignData();
  const { companyId } = useParams();
  const ROOT_PATH = "/";

  const loadRootFiles: LoadRootFiles = async () => {
    if (!companyId) {
      return null;
    }

    const { data: microsite } = await micrositeApi(companyId).get();
    const { subDomain, siteContext } = microsite;
    const micrositePath = siteContext ? `/${siteContext}` : "";
    const rootStorageName = `[${subDomain}${MICRO_SITE_POSTFIX}${micrositePath}]`;

    const { data } = await getMicroSiteStorageFilesApi({
      companyId,
      path: ROOT_PATH,
    });

    return {
      rootFolderFiles: data,
      rootStoragePath: ROOT_PATH,
      rootStorageName,
    };
  };

  const loadFolderFiles: LoadFolderFiles = async ({ path }) => {
    if (!companyId) {
      console.error("Unable to get [companyId] from URL");
      return [];
    }

    const { data } = await getMicroSiteStorageFilesApi({
      companyId,
      path,
    });

    return data;
  };

  const loadCompanyAssets: LoadCompanyAssets = async () => {
    if (!companyId) {
      console.error("Unable to get [companyId] from URL");
      return null;
    }
    const { data } = await micrositeApi(companyId).getCompanyAssets();

    return data;
  };

  const {
    setSelectedItem,
    selectedItem,
    treeData,
    setTreeData,
    getFolderFiles,
    isLoading,
    error,
    rootPath,
  } = useDynamicStorage({
    loadRootFiles,
    loadFolderFiles,
    loadCompanyAssets,
    contextFolderName:
      campaignData && campaignData.microSiteContextFolder
        ? campaignData.microSiteContextFolder.replace(/\//g, "")
        : undefined,
  });

  const renderMenu = ({
    isHovering,
    isSelected,
    item,
  }: {
    isHovering: boolean;
    isSelected: boolean;
    item: TFileTreeItem;
  }) => {
    if (!companyId) {
      return <span className={"text-red-500"}>Error</span>;
    }
    return (
      <MicrositeStorageItemMenu
        isHovering={isHovering}
        isSelected={isSelected}
        item={item}
        setTreeData={setTreeData}
        treeData={treeData}
        contextPath={rootPath}
        companyId={companyId}
      />
    );
  };

  return (
    <Storage
      setSelectedItem={setSelectedItem}
      getFolderFiles={getFolderFiles}
      isLoading={isLoading}
      error={error}
      treeData={treeData}
      selectedItem={selectedItem}
      renderMenu={renderMenu}
    />
  );
};

export default MicrositeStorage;
