import { Skeleton } from "antd";
import { FC, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import CampaignDocView from "src/components/CampaignDocView";
import { selectCurrentCampaignView } from "src/store/slices/campaignsSlice";
import { CampaignViewTypes } from "src/types";
import CampaignGrid from "../../../../components/CampaignGrid/CampaignGrid";
import CampaignAssets from "../../../../components/campaignAssets/CampaignAssets/CampaignAssets";
import useLoadDataByStep from "../../hooks/useLoadDataByStep";

type Props = {
  stepId: number;
  companyId: number | undefined;
  campaignId: number;
};

const StepData: FC<Props> = ({ stepId, companyId, campaignId }) => {
  const {
    campaignLabels,
    globalLabels,
    ccVars,
    assets,
    setAssets,
    isLoading,
    loadStepData,
  } = useLoadDataByStep({ stepId, companyId, campaignId });
  const currentCampaignView = useSelector(selectCurrentCampaignView);
  const memoizedCcVars = useMemo(() => ccVars, [ccVars]);
  const memoizedGlobalLabels = useMemo(() => globalLabels, [globalLabels]);
  const memoizedCampaignLabels = useMemo(
    () => campaignLabels,
    [campaignLabels]
  );

  useEffect(() => {
    if (currentCampaignView === CampaignViewTypes.GRID) {
      loadStepData();
    }
  }, [currentCampaignView]);

  if (isLoading) {
    return <Skeleton active />;
  }

  return (
    <div className="flex flex-col gap-[24px]">
      <div
        style={{
          display:
            currentCampaignView === CampaignViewTypes.GRID ? "block" : "none",
        }}
      >
        {!isLoading && (
          <CampaignGrid
            ccVariables={memoizedCcVars}
            globalLabels={memoizedGlobalLabels}
            campaignLabels={memoizedCampaignLabels}
            companyId={companyId}
          />
        )}
      </div>
      <div
        style={{
          display:
            currentCampaignView === CampaignViewTypes.DOC ? "block" : "none",
        }}
      >
        <CampaignDocView
          companyId={companyId}
          ccVariables={ccVars}
          currentCampaignViewType={currentCampaignView}
        />
      </div>
      <CampaignAssets
        companyId={companyId}
        assets={assets}
        stepId={stepId}
        campaignId={campaignId}
        setAssets={setAssets}
      />
    </div>
  );
};

export default StepData;
