import { AxiosResponse } from "axios";
import { PROXY_PREFIX } from "src/constants";
import { ModelConfig, ModelConfigFormValues } from "src/types/modelConfigs";
import axiosInstance from "src/utils/axios/axiosInstance";

export const getModelConfigsApi = (): Promise<
  AxiosResponse<{ items: ModelConfig[]; totalItems: number }>
> => {
  return axiosInstance.get(`${PROXY_PREFIX}/api/secured/model-configs`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getModelConfigsListApi = ({
  pageNumber,
  pageSize,
  sortBy,
  orderBy = "DESC",
}: {
  pageNumber: number;
  pageSize: number;
  sortBy?: string;
  orderBy?: "DESC" | "ASC";
}): Promise<AxiosResponse<{ items: ModelConfig[]; totalItems: number }>> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/ai/model-configuration/`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        pageNumber,
        pageSize,
        sortBy,
        orderBy,
      },
    }
  );
};

export const createModelConfigApi = (body: ModelConfigFormValues) => {
  return axiosInstance.post(
    `${PROXY_PREFIX}/api/secured/ai/model-configuration/`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const updateModelConfigApi = (body: ModelConfig) => {
  return axiosInstance.put(
    `${PROXY_PREFIX}/api/secured/ai/model-configuration/`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const getModelConfigApi = (id: number) => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/ai/model-configuration/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const getSystemDefaultConfigApi = async (): Promise<ModelConfig> => {
  const configs = await getModelConfigsListApi({
    pageNumber: 1,
    pageSize: 1,
    sortBy: "systemDefault",
    orderBy: "DESC",
  });

  return configs.data.items[0];
};

export const deleteModelConfigApi = (id: number) => {
  return axiosInstance.delete(
    `${PROXY_PREFIX}/api/secured/ai/model-configuration/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const setDefaultSystemModelConfigApi = (id: number) => {
  return axiosInstance.post(
    `${PROXY_PREFIX}/api/secured/ai/model-configuration/${id}/default`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const changeToDefaultSystemModelConfigApi = (id: number) => {
  return axiosInstance.put(
    `${PROXY_PREFIX}/api/secured/campaign/${id}/default-ai-model`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
