import { LoadingOutlined } from "@ant-design/icons";
import { TComputationState } from "src/store/slices/ccVariablesSlice";
import { ComputedIcon, CreatedIcon, SolidErrorIcon } from "../common/Icons";

export const iconStateMapper: Record<
  string,
  { icon: React.ReactNode; tooltip: string }
> = {
  computed: {
    icon: <ComputedIcon className="text-[#16A34A] hover:text-[#16A34A]" />,
    tooltip: "",
  },
  created: {
    icon: <CreatedIcon className="text-[#D97706] hover:text-[#D97706]" />,
    tooltip: "",
  },
  error: {
    icon: <SolidErrorIcon className="text-red-600" />,
    tooltip: "",
  },
  processing: {
    icon: <LoadingOutlined style={{ color: "brown", fontSize: "20px" }} />,
    tooltip: "",
  },
};

export const campaignStatePriority: TComputationState[] = [
  "processing",
  "error",
  "created",
  "computed",
];
