import { AxiosResponse } from "axios";
import { PROXY_PREFIX } from "../constants";
import axiosInstance from "../utils/axios/axiosInstance";

export const getAiModelConfigOptions = (): Promise<AxiosResponse<string[]>> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/ai/model-configuration/campaign-ui-list`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
