import { Empty, Flex } from "antd";

export const EmptyCampaignPage = () => {
  return (
    <Flex
      vertical
      style={{ height: "100%", width: "100%" }}
      align="center"
      justify="center"
    >
      <Empty
        imageStyle={{ height: "30vh" }}
        description={
          <div>
            <span>There are no companies in your profile.</span>
          </div>
        }
      />
    </Flex>
  );
};
