import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import {
  bulkDeleteEnvVarApi,
  createEnvVarApi,
  editEnvVarKeyApi,
  exportEnvVarsToCSVApi,
  getEnvVarTypesApi,
  getEnvVarsApi,
  getImportVarTypesApi,
  importVarsApi,
} from "../../api/env-variables.api";
import {
  TCustomError,
  handleRequestError,
} from "../../utils/handleRequestError";
import { RootState } from "../store";
import { toggleIsFetching } from "./appSlice";
import { TDynamicItem, TDynamicSelectProps, TDynamicTextareaProps } from "../../globalTypes";

export type TVariables = {
  isEditorOneped: boolean;
  list: Array<TEnvVariable>;
  dynamicFormItems: TDynamicFormItems;
  dynamicFormItemsImport: TDynamicFormItemsImportVars;
  varTypesList: Array<TEnvVariableType>;
  varTypesListImport: Array<TEnvVariableType>;
};

export type TEnvVarData = {
  key: React.Key;
  type: TEnvVariableType;
  varKey: string;
  value: string;
  action: TEnvVarsFormOptionsValues;
  variable: TEnvVariable;
};
//TODO revise types along with cc vars
export type TEnvVariableType =
  | "string"
  | "function"
  | "prompt"

export type TEnvVariable = {
  id: {
    campaignId: number;
    key: string;
  };
  type: TEnvVariableType;
  options: TEnvVarsFormOptionsValues;
  value: string;
};

export type TPromptEnvVarServicePovider = ["OPENAI", "CLAUDE"];
export type TDynamicFormItemsFunction = [
  TDynamicItem,
  TDynamicTextareaProps,
];
export type TDynamicFormItemsPrompt = [
  TDynamicItem,
  TDynamicSelectProps,
];
export type TDynamicFormItemsString = Array<any>;

export type TDynamicFormItems = {
  function: TDynamicFormItemsFunction;
  prompt: TDynamicFormItemsPrompt;
  string: TDynamicFormItemsString;
};

export type TDynamicFormItem =
  | TDynamicFormItemsFunction
  | TDynamicFormItemsPrompt
  | TDynamicFormItemsString

export type TEnvVarsFormValues = TEnvVarsFormRequiredValues &
  TEnvVarsFormOptionsValues;

export type TEnvVarsFormRequiredValues = {
  type: string;
  key: string;
  value: string;
};

export type TEnvVarsFormOptionsValues = {
  modelName?: string;
  serviceProvider?: TPromptEnvVarServicePovider;
  cacheResult?: boolean;
  scriptContent?: string;
};

//IMPORT VARS DYNAMIC ITEMS
export type TDynamicElementImportVar = Array<
  | TDynamicSelectProps
  | TDynamicItem
  | TDynamicTextareaProps
>;
export type TDynamicFormItemsImportVars = {
  [key in TEnvVariableType]: TDynamicElementImportVar;
};

export type TImportEnvVarsFormValues = TImportEnvVarsFormRequiredValues &
  TImportEnvVarsFormOptionsValues;

export type TImportEnvVarsFormRequiredValues = Omit<
  TEnvVarsFormRequiredValues,
  "key"
>;

export type TImportEnvVarsFormOptionsValues = {
  columnDelimeter?: string;
  defaultModelName?: string;
  defaultServiceProvider?: TPromptEnvVarServicePovider;
};
type InitialStateType = TVariables;

const initialState: InitialStateType = {
  isEditorOneped: false,
  list: [],
  dynamicFormItems: {} as TDynamicFormItems,
  dynamicFormItemsImport: {} as TDynamicFormItemsImportVars,
  varTypesList: [],
  varTypesListImport: [],
};

const envVariablesSlice = createSlice({
  name: "envVariables",
  initialState,
  reducers: {
    toggleIsEnvVarsEditorOpened: (
      state: InitialStateType,
      action: PayloadAction<boolean>
    ) => {
      state.isEditorOneped = action.payload;
    },
    setEnvVariables: (
      state: InitialStateType,
      action: PayloadAction<Array<TEnvVariable>>
    ) => {
      state.list = action.payload;
    },
    bulkDeleteEnvVariables: (
      state: InitialStateType,
      action: PayloadAction<Array<string>>
    ) => {
      const keysForDelete = action.payload;
      const filteredItems = state.list.filter(
        (item) => !keysForDelete.includes(item.id.key)
      );

      state.list = filteredItems;
    },
    setDynamicFormItems: (
      state: InitialStateType,
      action: PayloadAction<TDynamicFormItems>
    ) => {
      state.dynamicFormItems = action.payload;
    },
    setDynamicFormItemsImport: (
      state: InitialStateType,
      action: PayloadAction<TDynamicFormItemsImportVars>
    ) => {
      state.dynamicFormItemsImport = action.payload;
    },
    setEnvVarsTypes: (
      state: InitialStateType,
      action: PayloadAction<Array<TEnvVariableType>>
    ) => {
      state.varTypesList = action.payload;
    },
    setEnvVarsTypesImport: (
      state: InitialStateType,
      action: PayloadAction<Array<TEnvVariableType>>
    ) => {
      state.varTypesListImport = action.payload;
    },
    updateEnvVarKey: (
      state: InitialStateType,
      action: PayloadAction<{ currentKey: string; newKey: string }>
    ) => {
      const { currentKey, newKey } = action.payload;
      const index = state.list.findIndex(
        (envVar) => envVar.id.key === currentKey
      );

      if (index !== -1) {
        state.list[index].id.key = newKey;
      } else {
        console.error("Can't find env var by key to update");
      }
    },
  },
});

export const {
  toggleIsEnvVarsEditorOpened,
  setEnvVariables,
  bulkDeleteEnvVariables,
  setDynamicFormItems,
  setDynamicFormItemsImport,
  setEnvVarsTypes,
  setEnvVarsTypesImport,
  updateEnvVarKey,
} = envVariablesSlice.actions;

export default envVariablesSlice.reducer;

/* eslint-disable*/
export const getIsVarEditorOpened = (): boolean =>
  useSelector((state: RootState) => state.envVariables.isEditorOneped);
export const getEnvVariables = (): Array<TEnvVariable> =>
  useSelector((state: RootState) => state.envVariables.list);
export const getDynamicFormItems = (): TDynamicFormItems =>
  useSelector((state: RootState) => state.envVariables.dynamicFormItems);
export const getDynamicFormItemsImportVars = (): TDynamicFormItemsImportVars =>
  useSelector((state: RootState) => state.envVariables.dynamicFormItemsImport);
export const getEnvVarTypes = (): Array<TEnvVariableType> =>
  useSelector((state: RootState) => state.envVariables.varTypesList);
export const getImportEnvVarTypes = (): Array<TEnvVariableType> =>
  useSelector((state: RootState) => state.envVariables.varTypesListImport);

export const getEnvVariablesWithTypesThunk = createAsyncThunk<
  undefined,
  undefined,
  { state: RootState; rejectValue: TCustomError }
>(
  "envVariables/getVarsWithTypes",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      const { campaigns, companies } = getState();
      const { data: envVars } = await getEnvVarsApi(
        campaigns.current!.id,
        companies.current?.id
      );
      const { data } = await getEnvVarTypesApi(companies.current?.id);
      const envVarTypes = Object.keys(data) as Array<TEnvVariableType>;
      const dynamicFormItems = {} as TDynamicFormItems;

      for (const type in data) {
        // @ts-ignore
        dynamicFormItems[type] = data[type].items;
      }

      dispatch(setDynamicFormItems(dynamicFormItems));
      dispatch(setEnvVarsTypes(envVarTypes));
      dispatch(setEnvVariables(envVars));
      dispatch(toggleIsEnvVarsEditorOpened(true));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to get env variables:`, e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

export const getImportVarsDynItemsThunk = createAsyncThunk<
  undefined,
  undefined,
  { state: RootState; rejectValue: TCustomError }
>(
  "envVariables/getImportVarsDynItems",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      const { companies } = getState();
      const { data } = await getImportVarTypesApi(companies.current?.id);
      const envVarTypes = Object.keys(data) as Array<TEnvVariableType>;
      const dynamicFormItems = {} as TDynamicFormItemsImportVars;

      for (const type in data) {
        // @ts-ignore
        dynamicFormItems[type] = data[type].items;
      }

      dispatch(setDynamicFormItemsImport(dynamicFormItems));
      dispatch(setEnvVarsTypesImport(envVarTypes));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to get import env types:`,
        e
      );

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

export const importVariablesThunk = createAsyncThunk<
  undefined,
  TImportEnvVarsFormValues,
  { state: RootState; rejectValue: TCustomError }
>(
  "envVariables/importVariables",
  async (values, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      const { campaigns, companies } = getState();
      const campaignId = campaigns.current!.id;

      await importVarsApi(campaignId, values, companies.current?.id);

      const { data } = await getEnvVarsApi(campaignId, companies.current?.id);

      dispatch(setEnvVariables(data));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to import env variables:`,
        e
      );

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);
type TcreateEnvVarThunkProps = {
  values: TEnvVarsFormValues;
  action: "create" | "update";
};
export const createEnvVarThunk = createAsyncThunk<
  undefined,
  TcreateEnvVarThunkProps,
  { state: RootState; rejectValue: TCustomError }
>(
  "envVariables/createEnvVar",
  async ({ values, action }, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      const { campaigns, companies } = getState();
      await createEnvVarApi(
        campaigns.current!.id,
        values,
        action,
        companies.current?.id
      );
      const { data } = await getEnvVarsApi(
        campaigns.current!.id,
        companies.current?.id
      );

      dispatch(setEnvVariables(data));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to create the env variable:`,
        e
      );

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

export const bulkDeleteEnvVarThunk = createAsyncThunk<
  undefined,
  Array<string>,
  { state: RootState; rejectValue: TCustomError }
>(
  "envVariables/bulkDeleteEnvVar",
  async (keys, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      const { campaigns, companies } = getState();

      await bulkDeleteEnvVarApi(
        campaigns.current!.id,
        keys,
        companies.current?.id
      );

      dispatch(bulkDeleteEnvVariables(keys));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to delete env variables:`,
        e
      );

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

export const editEnvVarKeyThunk = createAsyncThunk<
  undefined,
  { currentKey: string; newKey: string },
  { state: RootState; rejectValue: TCustomError }
>(
  "envVariables/editEnvVarKeyThunk",
  async ({ currentKey, newKey }, { getState, rejectWithValue, dispatch }) => {
    try {
      const { campaigns, companies } = getState();
      const campaignId = campaigns.current!.id;

      await editEnvVarKeyApi(
        campaignId,
        currentKey,
        newKey,
        companies.current?.id
      );

      dispatch(updateEnvVarKey({ currentKey, newKey }));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to edit env variable key:`,
        e
      );

      return rejectWithValue(customError);
    }
  }
);

type TExportEnvVarsToCSVRes = {
  data: Blob;
  fileName: string;
};
export const exportEnvVarsToCSVThunk = createAsyncThunk<
  TExportEnvVarsToCSVRes,
  undefined,
  { state: RootState; rejectValue: TCustomError }
>(
  "envVariables/exportEnvVarsToCSVThunk",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const { campaigns, companies } = getState();
      const campaignId = campaigns.current!.id;
      //a hard-coded type. Need to add a selector with types when we need it.
      const type = "prompt";

      const { data } = await exportEnvVarsToCSVApi(
        campaignId,
        type,
        companies.current?.id
      );
      const fileName = `${campaigns.current?.name || "envVariables"}.csv`;

      return { data, fileName };
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to edit env variable key:`,
        e
      );

      return rejectWithValue(customError);
    }
  }
);
