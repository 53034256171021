import React from "react";
import { useSelector } from "react-redux";
import { TFileTreeItem, TStorageFileItem } from "../../../../globalTypes";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import handleRequestError from "../../../../utils/handleRequestError";

type Props = {
  selectedItem: TFileTreeItem;
  setTreeData: React.Dispatch<React.SetStateAction<TFileTreeItem[]>>;
  saveFile: (formData: FormData) => Promise<TStorageFileItem>;
  closeModal: () => void;
};

const useSaveFile = ({
  saveFile,
  selectedItem,
  setTreeData,
  closeModal,
}: Props) => {
  const messageApi = useSelector(selectMessageApi);
  const { fileName, mimeType } = selectedItem;

  return async (content: string) => {
    try {
      const formData = new FormData();
      const fileNameSplit = fileName.split("/");
      const fileNameToUpdate = fileNameSplit[fileNameSplit.length - 1];
      const file = new File([content], fileNameToUpdate, {
        type: mimeType,
      });
      const dirPathSplit = fileNameSplit.slice(0, -1);
      const dirPath = dirPathSplit.join("/") + "/";

      formData.append("path", dirPath);
      formData.append("file", file);

      const newFileData = await saveFile(formData);

      setTreeData((prevState) => {
        return prevState.map((item) => {
          if (item.fileName === newFileData.fileName) {
            return { ...item, ...newFileData };
          } else {
            return item;
          }
        });
      });

      closeModal();

      messageApi.success("The file was saved successfully");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };
};

export default useSaveFile;
