import { Drawer, Spin } from "antd";
import React from "react";
import { TUserCompaniesCreateUser } from "../../../api/user.api";
import { UserDrawerProps } from "../../../pages/users/hooks/useGetUsers.hook";
import {
  createUserThunk,
  updateUserThunk,
} from "../../../store/slices/usersSlice";
import handleRequestError from "../../../utils/handleRequestError";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import { Button } from "../../common/ui/button";
import UserForm, { TUserForm } from "../UserForm/UserForm";
import useGetUserData from "./useGetUserData";

type Props = {
  userDrawerProps: UserDrawerProps;
  setUserDrawerProps: React.Dispatch<React.SetStateAction<UserDrawerProps>>;
};

const UserDrawer = (props: Props) => {
  const { userDrawerProps, setUserDrawerProps } = props;
  const { userId, visible, fullName } = userDrawerProps;
  const {
    form,
    messageApi,
    dispatch,
    companiesList,
    companiesOptions,
    isFetching,
    setIsFetching,
  } = useGetUserData({
    userId: userId,
    visibleDrawer: visible,
  });

  const onCloseDrawer = () => {
    setUserDrawerProps((prev: UserDrawerProps) => ({
      ...prev,
      visible: false,
    }));
  };

  const submitForm = (extraAction?: boolean) => {
    return submitFormWithTrim({
      form,
      onSuccessValidationCb: async (values: TUserForm) => {
        if (
          values.role !== "global_admin" &&
          !values.editor?.length &&
          !values.viewer?.length
        ) {
          messageApi.error("The user must be linked to at least one company!");
          return;
        }

        await onFormSubmit(values, extraAction);
      },
    });
  };

  const onFormSubmit = async (values: TUserForm, extraAction?: boolean) => {
    try {
      setIsFetching(true);

      const { editor, viewer, ...userData } = values;
      const userCompanies: Array<TUserCompaniesCreateUser> = [];

      editor?.forEach((companyName) => {
        const targetCompany = companiesList.find(
          (company) => company.name === companyName
        );
        if (targetCompany)
          userCompanies.push({
            company_id: targetCompany.id,
            permission: "editor",
          });
      });

      viewer?.forEach((companyName) => {
        const targetCompany = companiesList.find(
          (company) => company.name === companyName
        );
        if (targetCompany)
          userCompanies.push({
            company_id: targetCompany.id,
            permission: "viewer",
          });
      });

      const userProps = {
        ...userData,
        companies: userCompanies,
      };

      let res;

      if (userId) {
        res = await dispatch(updateUserThunk({ userProps, userId: +userId }));
      } else {
        res = await dispatch(createUserThunk(userProps));
      }

      if ("error" in res) {
        messageApi.error(res.payload?.message);
      } else {
        extraAction ? form.resetFields() : onCloseDrawer();

        messageApi.success(
          userId
            ? "The user has been successfully updated"
            : "The user has been successfully created"
        );
      }
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Drawer
      title={
        <div className="font-sans text-white text-base">
          {userId ? `${fullName}` : "Create User"}
        </div>
      }
      placement="right"
      onClose={onCloseDrawer}
      open={visible}
      styles={{
        header: { background: "#0D0A37", borderBottom: "0" },
        footer: { borderTop: "1px solid #CBD5E1", paddingLeft: "40px" },
        body: { padding: "40px" },
      }}
      width={533}
      footer={
        <div className="flex justify-start gap-[8px]">
          <Button
            className="rounded-full"
            onClick={submitForm()}
            disabled={isFetching}
          >
            {userId ? "Save" : "Create"}
          </Button>
          {!userId && (
            <Button
              variant="primaryOutline"
              onClick={submitForm(true)}
              disabled={isFetching}
            >
              Create and add another
            </Button>
          )}
          <Button
            className="border border-gray-600 text-gray-600 w-[94px]"
            variant="primaryOutline"
            onClick={onCloseDrawer}
          >
            Cancel
          </Button>
        </div>
      }
    >
      <Spin spinning={isFetching}>
        <UserForm form={form} companies={companiesOptions} />
      </Spin>
    </Drawer>
  );
};

export default UserDrawer;
