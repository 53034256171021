import axiosInstance from "src/utils/axios/axiosInstance";
import { SftpUser } from "../globalTypes";
import { PROXY_PREFIX } from "src/constants";

export const sftpUsersApi = (
  micrositeId: number,
  companyId: number | undefined
) => ({
  getAll: () =>
    axiosInstance.get<SftpUser[] | undefined>(
      `${PROXY_PREFIX}/api/secured/microsite/${micrositeId}/user/`,
      {
        params: {
          companyId,
        },
      }
    ),
  create: (data: { username: string }) =>
    axiosInstance.post(
      `${PROXY_PREFIX}/api/secured/microsite/${micrositeId}/user/`,
      data,
      {
        params: {
          companyId,
        },
      }
    ),
  resetPassword: (username: string) =>
    axiosInstance.put(
      `${PROXY_PREFIX}/api/secured/microsite/${micrositeId}/user/${username}/reset-password/`,
      {
        params: {
          companyId,
        },
      }
    ),
  delete: (username: string) =>
    axiosInstance.delete(
      `${PROXY_PREFIX}/api/secured/microsite/${micrositeId}/user/${username}/`,
      {
        params: {
          companyId,
        },
      }
    ),
});
