import { Tooltip } from "antd";
import { FC } from "react";

type TableItemProps = {
  data?: string[];
  tooltipTitle?: React.ReactNode;
};

export const TableItem: FC<TableItemProps> = ({ data, tooltipTitle }) => {
  if (!data || !data.length) return null;

  return (
    <Tooltip
      overlayStyle={{ maxWidth: "400px" }}
      mouseEnterDelay={0.5}
      title={tooltipTitle}
      placement="topLeft"
    >
      <div className="w-1/2 whitespace-nowrap overflow-hidden text-ellipsis">
        {data.join(", ")}
      </div>
    </Tooltip>
  );
};
