import { Spin } from "antd";
import { createCompanyApi } from "../../api/companies.api";
import { Icons } from "../../components/common/Icons";
import Pagination from "../../components/common/Pagination";
import { Button } from "../../components/common/ui/button";
import CompaniesList from "../../components/companies/CompaniesList";
import CompanyDrawer from "../../components/companies/CompanyDrawer/CompanyDrawer";
import useCompaniesListHook from "./useCompaniesList.hook";

const CompaniesPage = () => {
  const {
    pageNumber,
    pageSize,
    total,
    updatePage,
    updatePageSize,
    loading,
    list,
    companyDrawerProps,
    setCompanyDrawerProps,
    isRegularUser,
    updateList,
    messageApi,
  } = useCompaniesListHook();

  const onCreateCompany = async (formData: FormData) => {
    // try catch is not needed, error handling is in the component CompanyDrawer
    await createCompanyApi(formData);
    await updateList();

    messageApi.success("The company has been successfully created");
  };

  return (
    <div className="w-full flex flex-col">
      <div className="flex justify-between">
        <h1 className=" text-slate-900 text-2xl font-semibold font-sans leading-normal">
          Companies
        </h1>
        {!isRegularUser && (
          <Button
            icon={Icons.Plus}
            className="rounded-full"
            onClick={() =>
              setCompanyDrawerProps({
                visible: true,
                onSubmitCb: onCreateCompany,
              })
            }
          >
            Add New Company
          </Button>
        )}
      </div>
      <div className="py-[24px]">
        <Pagination
          updatePageSize={updatePageSize}
          updatePage={updatePage}
          total={total}
          pageSize={pageSize}
          pageNumber={pageNumber}
        />
      </div>
      <Spin spinning={loading}>
        <CompaniesList
          list={list}
          updateList={updateList}
          setCompanyDrawerProps={setCompanyDrawerProps}
        />
      </Spin>
      <div className="pt-3">
        <Pagination
          updatePageSize={updatePageSize}
          updatePage={updatePage}
          total={total}
          pageSize={pageSize}
          pageNumber={pageNumber}
        />
      </div>
      <CompanyDrawer
        companyDrawerProps={companyDrawerProps}
        setCompanyDrawerProps={setCompanyDrawerProps}
      />
    </div>
  );
};

export default CompaniesPage;
