import CampaignHeader from "src/components/campaignHeader/CampaignHeader/CampaignHeader";
import CampaignVariables from "../../../../components/campaignEnvVariables/CampaignVariables/CampaignVariables";
import CampaignPhases from "../../../../components/campaignPhases/CampaignPhases/CampaignPhases";
import CampaignSteps from "../../../../components/campaignSteps/CampaignSteps/CampaignSteps";
import useInitCampaignHook from "../../hooks/useInitCampaignHook";
import CampaignViewSelector from "../StepData/CampaignView";
import StepData from "../StepData/StepData";

const CampaignEditorPage = () => {
  const {
    currentCampaign,
    currentStep,
    phases,
    currentPhase,
    companyId,
    setPhases,
    view,
    handleViewChange,
  } = useInitCampaignHook();

  if (!currentCampaign) return <>Loading...</>;

  return (
    <div className="flex flex-col justify-center">
      <CampaignHeader currentCampaign={currentCampaign} companyId={companyId} />
      <div className="flex items-center mb-[24px] mt-[12px] w-full">
        <CampaignViewSelector
          currentViewType={view}
          onViewTypeChange={handleViewChange}
        />
        <div style={{ width: "calc(100% - 49px)" }}>
          <CampaignPhases
            currentCampaign={currentCampaign}
            phases={phases}
            currentPhase={currentPhase}
            companyId={companyId}
            setPhases={setPhases}
          />
          <CampaignSteps
            campaignId={currentCampaign.id}
            companyId={companyId}
            currentPhase={currentPhase}
          />
        </div>
      </div>
      {currentPhase && currentStep && (
        <StepData
          companyId={companyId}
          stepId={currentStep.id}
          campaignId={currentCampaign.id}
        />
      )}
      <CampaignVariables />
    </div>
  );
};

export default CampaignEditorPage;
