import { LockOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Modal, Tooltip, Typography } from "antd";
import { FC, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TabsCarousel from "src/components/common/TabsCarousel";
import { Button } from "src/components/common/ui/button";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { calcNewSeqDnd } from "src/utils";
import { TPhase, TPhaseFormValues } from "../../../globalTypes";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { TCampaign } from "../../../store/slices/campaignsSlice";
import {
  savePhaseThunk,
  updatePhaseThunk,
} from "../../../store/slices/phasesSlice";
import { AppDispatch } from "../../../store/store";
import { PHASE_SEQUENCE_STEP } from "../../../utils/campaigns.constant";
import { getLastSequence } from "../../../utils/cm.utils";
import { getConfirmationMessage } from "../../../utils/getConfirmationMessage";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import { getPhaseNameValidationRule } from "../../../utils/validators";
import PhaseModal from "../../common/modals/PhaseModal/PhaseModal";
import Phase from "../Phase/Phase";

type Props = {
  currentCampaign: TCampaign;
  phases: TPhase[];
  currentPhase: TPhase | null;
  companyId?: number;
  setPhases: (phases: TPhase[]) => void;
};

const CampaignPhases: FC<Props> = ({
  currentCampaign,
  phases,
  currentPhase,
  companyId,
  setPhases,
}) => {
  const { id: campaignId, name: campaignName } = currentCampaign;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editPhase, setEditPhase] = useState<TPhase | null>(null);
  const [form] = Form.useForm<TPhaseFormValues>();
  const [sequence, setSequence] = useState<number | null>(null);
  const { hasPhaseCreateRole, hasPhaseListRole, hasPhaseEditRole } =
    useCurrentUser();
  const dispatch: AppDispatch = useDispatch();
  const messageApi = useSelector(selectMessageApi);
  const navigate = useNavigate();

  const phaseNameValidationRule = getPhaseNameValidationRule({
    editPhaseName: editPhase?.name,
    phases,
  });

  const onAddPhaseClick = () => {
    const sequence = getLastSequence(phases, PHASE_SEQUENCE_STEP);

    setIsModalOpen(true);
    setSequence(sequence);
  };

  const onDragEnd: OnDragEndResponder = async (result) => {
    if (!result.destination) {
      return;
    }

    const {
      source: { index: sourceIndex },
      destination: { index: destinationIndex },
    } = result;

    if (sourceIndex === destinationIndex) {
      return;
    }

    const newDraggedItemSeq = calcNewSeqDnd(
      result,
      phases,
      PHASE_SEQUENCE_STEP
    )!;

    const draggedItem = {
      phase: { ...phases[sourceIndex], seq: newDraggedItemSeq },
    };

    try {
      await dispatch(updatePhaseThunk(draggedItem));

      const phasesCopy = phases.slice();
      const [removed] = phasesCopy.splice(result.source.index, 1);

      phasesCopy.splice(result.destination.index, 0, removed);

      setPhases(phasesCopy);
    } catch (e) {
      console.error("An error occurred while trying to drag the phase:", e);
    }
  };

  async function onCreatePhase(values: TPhaseFormValues) {
    try {
      if (sequence === null) {
        messageApi.error("Sequence is null");
        return;
      }

      const res = await dispatch(
        savePhaseThunk({
          phaseName: values.name,
          sequence: sequence,
          hidden: values.hidden,
          microSiteContextFolder: values.microSiteContextFolder,
        })
      );

      if ("error" in res) {
        messageApi.error(res.payload?.message);
      } else {
        //if companyId === undefined it implies the company is global
        const companyIdForUrl = companyId === undefined ? "global" : companyId;
        const path = `/admin/campaigns/company/${companyIdForUrl}/campaign/${campaignId}/phase/${res.payload.id}`;

        navigate(path, { replace: true });
        onCancel();
        messageApi.success("The phase has been successfully created");
      }
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error("An error occurred while trying to save the phase:", e);
    }
  }

  const updatePhase = async (values: TPhaseFormValues, republish: boolean) => {
    try {
      await dispatch(
        updatePhaseThunk({
          phase: {
            ...editPhase!,
            name: values.name,
            hidden: values.hidden,
            microSiteContextFolder: values.microSiteContextFolder,
          },
          republish,
        })
      ).unwrap();

      onCancel();
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error("An error occurred while trying to update the phase:", e);
    }
  };

  const onEditPhase = async (values: TPhaseFormValues) => {
    const isMicroSiteContextFolderChanged =
      values.microSiteContextFolder !== editPhase?.microSiteContextFolder;

    const isPhaseNameChanged = values.name !== editPhase?.name;

    const confirmationMessage = getConfirmationMessage(
      isMicroSiteContextFolderChanged,
      isPhaseNameChanged,
      "Phase Name"
    );

    if (confirmationMessage) {
      Modal.confirm({
        title: "Confirm phase change",
        content: confirmationMessage,
        okText: "Confirm",
        cancelText: "Cancel",
        onOk: () => updatePhase(values, true),
      });
    } else {
      await updatePhase(values, false);
    }
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TPhaseFormValues) => {
      editPhase ? await onEditPhase(values) : await onCreatePhase(values);
    },
  });

  const onCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
    setEditPhase(null);
    setSequence(null);
  };

  return (
    <div>
      <div className="flex items-center">
        {hasPhaseListRole && (
          <TabsCarousel
            className="flex rounded-tr-[8px] items-center overflow-x-scroll relative bg-slate-50"
            rightButtonClassName={"rounded-tr-[8px]"}
            leftButtonClassName={"rounded-tl-[8px]"}
          >
            {!!phases.length ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided, _snap) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="flex"
                    >
                      {phases.map((phase, index) => {
                        return (
                          <Draggable
                            isDragDisabled={!hasPhaseEditRole}
                            key={phase.id}
                            draggableId={String(phase.id)}
                            index={index}
                          >
                            {(provided, snap) => (
                              <div
                                style={{
                                  position: "relative",
                                  backgroundColor: snap.isDragging
                                    ? "lightblue"
                                    : "white",
                                }}
                                className="relative"
                                key={phase.id}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <Phase
                                  campaignId={campaignId}
                                  campaignName={campaignName}
                                  prevPhaseSeq={phases[index - 1]?.seq}
                                  nextPhaseSeq={phases[index + 1]?.seq}
                                  phase={phase}
                                  companyId={companyId}
                                  currentPhase={currentPhase}
                                  form={form}
                                  setIsModalOpen={setIsModalOpen}
                                  phaseNameValidationRule={
                                    phaseNameValidationRule
                                  }
                                  setEditPhase={setEditPhase}
                                  setSequence={setSequence}
                                  dragHandleProps={provided.dragHandleProps}
                                />
                                {phase.hidden && (
                                  <Tooltip
                                    title={
                                      <span>
                                        This Phase is hidden from users.
                                        <br />
                                        To switch the state, go to the Edit
                                        Paste modal.
                                      </span>
                                    }
                                  >
                                    <LockOutlined className="absolute top-[5px] left-[5px]" />
                                  </Tooltip>
                                )}
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <Typography.Text>
                There are no phases in this campaign yet
              </Typography.Text>
            )}
            {hasPhaseCreateRole && (
              <Button
                className="border-transparent bg-transparent border-b-2 hover:bg-primary/10 rounded-none h-[48px]"
                onClick={onAddPhaseClick}
                variant="primaryOutline"
                icon={PlusOutlined}
              >
                Add Phase
              </Button>
            )}
          </TabsCarousel>
        )}
      </div>
      {isModalOpen && (
        <PhaseModal
          onCancel={onCancel}
          onSubmitForm={submitForm}
          form={form}
          isModalOpen={isModalOpen}
          editPhase={editPhase}
          phaseNameValidationRule={phaseNameValidationRule}
        />
      )}
    </div>
  );
};

export default CampaignPhases;
