import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPromptTemplateApi } from "../../../api/env-variables.api";
import { selectMessageApi } from "../../../store/slices/appSlice";
import handleRequestError from "../../../utils/handleRequestError";
import splitTextByTags from "./splitTextByTags.util";

type Props = {
  campaignId: number;
  stepId: number;
  key: string;
};
const useGetPrompt = ({ campaignId, stepId, key }: Props) => {
  const messageApi = useSelector(selectMessageApi);
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState("");
  const [splitPrompt, setSplitPrompt] = useState<React.ReactElement[]>([]);

  useEffect(() => {
    getPrompt();
  }, []);

  const getPrompt = async () => {
    try {
      const { data } = await getPromptTemplateApi({
        campaignId,
        stepId,
        key,
        companyId: undefined,
      });

      setSplitPrompt(splitTextByTags(data));
      setError("");
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);

      setError(customError.message);
    } finally {
      setInitialized(true);
    }
  };

  return {
    initialized,
    splitPrompt,
    error,
  };
};

export default useGetPrompt;
