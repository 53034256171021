import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { CampaignViewTypes } from "src/types";
import { computationApi } from "../../api/computation.api";
import {
  clonePhaseApi,
  createPhaseApi,
  deletePhaseApi,
  redeployPhaseToMicroSiteApi,
  swapPhaseSequenceApi,
  updatePhaseApi,
} from "../../api/phases.api";
import { getStepsApi } from "../../api/steps.api";
import { TCloneEntityFormValues } from "../../components/common/modals/CloneEntityModal/CloneEntityModal";
import { TPhase } from "../../globalTypes";
import { sortBySequence } from "../../utils/cm.utils";
import {
  handleRequestError,
  TCustomError,
} from "../../utils/handleRequestError";
import { RootState } from "../store";
import { toggleIsFetching } from "./appSlice";
import { setCurrentCampaignView } from "./campaignsSlice";
import { setCcVariables } from "./ccVariablesSlice";
import { setCurrentStep, setSteps, TStep } from "./stepsSlice";

const initialState = {
  current: null as TPhase | null,
  list: [] as Array<TPhase>,
};

type InitialStateType = typeof initialState;

const slice = createSlice({
  name: "phases",
  initialState,
  reducers: {
    cleanUpPhases: (state: InitialStateType) => {
      state.current = null;
      state.list = [];
    },
    setPhases: (
      state: InitialStateType,
      action: PayloadAction<Array<TPhase>>
    ) => {
      state.list = action.payload;
    },
    updatePhase: (state: InitialStateType, action: PayloadAction<TPhase>) => {
      const editedPhase = action.payload;
      const index = state.list.findIndex(
        (phase) => phase.id === editedPhase.id
      );

      state.list[index] = editedPhase;
    },
    setCurrentPhase: (
      state: InitialStateType,
      action: PayloadAction<TPhase | null>
    ) => {
      state.current = action.payload;
    },
  },
});

export const { cleanUpPhases, setPhases, updatePhase, setCurrentPhase } =
  slice.actions;

export default slice.reducer;

export const selectPhasesList = (state: RootState) => state.phases.list;
export const selectCurrentPhase = (state: RootState) => state.phases.current;

// eslint-disable-next-line react-hooks/rules-of-hooks
export const getPhases = (): TPhase[] => useSelector(selectPhasesList);
export const getCurrentPhase = (): TPhase | null =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useSelector(selectCurrentPhase);

type TDeletePhaseThunkProps = {
  campaignId: number;
  phaseId: number;
  removeFiles: boolean;
  companyId: number | undefined;
};

export const deletePhaseThunk = createAsyncThunk<
  {
    phaseId: number | undefined;
    stepId: number | undefined;
  },
  TDeletePhaseThunkProps,
  { state: RootState; rejectValue: TCustomError }
>(
  "phases/deletePhase",
  async (
    { campaignId, phaseId, removeFiles, companyId },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const { phases } = getState();
      const allPhases = phases.list;
      const currentPhase = phases.current!;

      await deletePhaseApi({
        campaignId,
        phaseId,
        removeFiles,
        companyId,
      });

      const updatedPhases = allPhases.filter((phase) => phase.id !== phaseId);
      let currentPhaseAfterDelete: TPhase | null = null;
      let currentStep: TStep | null = null;
      let steps: Array<TStep> = [];

      if (updatedPhases.length) {
        if (currentPhase.id === phaseId) {
          //if deleted selected phase
          const index = allPhases.findIndex((phase) => phase.id === phaseId);
          const newCurrentPhase =
            index === 0 ? updatedPhases[0] : updatedPhases[index - 1];
          const { data: stepsData } = await getStepsApi({
            phaseId: newCurrentPhase.id,
            companyId,
          });

          if (stepsData.length) {
            const sortedSteps = sortBySequence<TStep>(stepsData);

            currentStep = sortedSteps[0];
            steps = sortedSteps;
          }
          currentPhaseAfterDelete = newCurrentPhase;
        }
      }

      dispatch(setCurrentPhase(currentPhaseAfterDelete));
      dispatch(setPhases(updatedPhases));

      dispatch(setCurrentStep(currentStep));
      dispatch(setSteps(steps));

      return {
        phaseId: currentPhaseAfterDelete?.id,
        stepId: currentStep?.id,
      };
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to delete the phase:`, e);

      return rejectWithValue(customError);
    }
  }
);

type TSavePhaseThunkProps = {
  phaseName: string;
  sequence: number;
  hidden: boolean;
  microSiteContextFolder: string | null;
};

export const savePhaseThunk = createAsyncThunk<
  TPhase,
  TSavePhaseThunkProps,
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "phases/savePhase",
  async (
    { phaseName, sequence, hidden, microSiteContextFolder },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(toggleIsFetching(true));

      const { campaigns, phases, companies } = getState();
      const { data } = await createPhaseApi(
        campaigns.current!.id,
        phaseName,
        sequence,
        hidden,
        microSiteContextFolder,
        companies.current?.id
      );
      const allPhases = [...phases.list];

      allPhases.push(data);

      const sortedPhases = sortBySequence(allPhases);

      dispatch(setPhases(sortedPhases));
      dispatch(setCurrentPhase(data));
      dispatch(setCurrentStep(null));
      dispatch(setSteps([]));
      dispatch(setCcVariables([]));

      return data;
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to save the phase:`, e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

export const updatePhaseThunk = createAsyncThunk<
  string,
  { phase: TPhase; republish?: boolean },
  { state: RootState; rejectValue: TCustomError }
>(
  "phases/updatePhase",
  async ({ phase, republish }, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      const { companies, campaigns } = getState();

      if (republish) {
        const { data: isExecuting } = await computationApi(
          campaigns.current!.id,
          companies.current?.id
        ).validatePhase({ phaseId: phase.id });

        if (isExecuting) {
          throw new Error(
            "The phase couldn't be saved because the computation is in progress"
          );
        } else {
          await updatePhaseApi(phase, companies.current?.id);

          dispatch(updatePhase(phase));
          dispatch(setCurrentPhase(phase));

          await redeployPhaseToMicroSiteApi({
            phaseId: phase.id,
            campaignId: phase.campaignId,
            companyId: companies.current?.id,
          });
        }
      } else {
        await updatePhaseApi(phase, companies.current?.id);
        dispatch(updatePhase(phase));
        dispatch(setCurrentPhase(phase));
      }

      return "success";
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to update the phase:`, e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

type TSelectPhaseThunkProps = {
  phase: TPhase;
  companyId: number | undefined;
};
export const selectPhaseThunk = createAsyncThunk<
  { phaseId: number; stepId: number | undefined },
  TSelectPhaseThunkProps,
  {
    rejectValue: TCustomError;
    state: RootState;
  }
>(
  "phases/selectPhase",
  async ({ phase, companyId }, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      const {
        users,
        campaigns: { currentView },
      } = getState();
      const isGlobalAdmin = users.userData.role === "global_admin";

      const { data: stepsData } = await getStepsApi({
        phaseId: phase.id,
        companyId,
      });

      let currentStep = null;
      let steps: TStep[] = [];

      if (stepsData.length) {
        currentStep = stepsData[0];
        steps = stepsData;
      }

      dispatch(setSteps(steps));

      if (!isGlobalAdmin) {
        const firstStepNotHiddenDocView = steps.find(
          (step) => !step.hiddenDocView
        );

        if (
          firstStepNotHiddenDocView &&
          currentView === CampaignViewTypes.DOC
        ) {
          dispatch(setCurrentStep(firstStepNotHiddenDocView));
        } else {
          dispatch(setCurrentCampaignView(CampaignViewTypes.GRID));
          dispatch(setCurrentStep(steps[0]));
        }
      } else {
        dispatch(setCurrentStep(steps[0]));
      }

      dispatch(setCurrentPhase(phase));

      return {
        phaseId: phase.id,
        stepId: currentStep?.id,
      };
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to select the phase:`, e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

export const swapPhaseSequenceThunk = createAsyncThunk<
  undefined,
  { currentPhase: TPhase; seqToSwap: number },
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "phases/swapPhaseSequenceThunk",
  async (
    { currentPhase, seqToSwap },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(toggleIsFetching(true));

      const { campaigns, companies } = getState();
      const { data: updatedPhasesList } = await swapPhaseSequenceApi(
        campaigns.current!.id,
        currentPhase.seq,
        seqToSwap,
        companies.current?.id
      );
      const sortedPhases = sortBySequence(updatedPhasesList);
      const updatedCurrentPhase: TPhase = {
        ...currentPhase,
        seq: seqToSwap,
      };

      dispatch(setCurrentPhase(updatedCurrentPhase));
      dispatch(setPhases(sortedPhases));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to move the phase:`, e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

export const clonePhaseThunk = createAsyncThunk<
  undefined,
  { phase: TPhase; params: TCloneEntityFormValues },
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "phases/clonePhaseThunk",
  async ({ phase, params }, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      const { campaigns, phases, companies } = getState();
      const { data: clonedPhase } = await clonePhaseApi({
        campaignId: campaigns.current!.id,
        phaseId: phase.id,
        companyId: companies.current?.id,
        ...params,
      });

      const index = phases.list.findIndex(
        (phaseFormList) => phaseFormList.id === phase.id
      );
      let updatedPhasesList: Array<TPhase> = [];

      if (index !== -1) {
        if (phases.list.length - 1 === index) {
          updatedPhasesList = [...phases.list, { ...clonedPhase }];
        } else {
          updatedPhasesList = [
            ...phases.list.slice(0, index + 1),
            { ...clonedPhase },
            ...phases.list.slice(index + 1),
          ];
        }
      }

      dispatch(setPhases(sortBySequence(updatedPhasesList)));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to clone the phase:`, e);

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);
