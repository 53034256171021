import { Form, FormInstance, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { TUserRole } from "../../../store/slices/usersSlice";
import {
  allowOnlyCharsDashAndQuotes,
  email,
  minMax,
  required,
} from "../../../utils/validations";

export type TUserForm = {
  first_name: string;
  last_name: string;
  position: string;
  email: string;
  role: TUserRole;
  editor?: Array<string>;
  viewer?: Array<string>;
};

type Props = {
  form: FormInstance<TUserForm>;
  companies: Array<{ id: number; name: string }>;
};

const UserForm: React.FC<Props> = ({ form, companies }) => {
  const [companiesOptions, setCompaniesOptions] = useState<string[]>([]);
  const { isGlobalAdmin } = useCurrentUser();
  const newUserRole = Form.useWatch<string | undefined>("role", form);

  useEffect(() => {
    setCompaniesOptions(companies.map((company) => company.name));
  }, [companies]);

  const onCompanySelect = (values: Array<string>) => {
    const filteredOptions = companiesOptions.filter(
      (company) => !values.includes(company)
    );
    setCompaniesOptions(filteredOptions);
  };

  const onCompanyDeselect = (value: string) => {
    setCompaniesOptions([...companiesOptions, value]);
  };

  const roleOptions = [
    { value: "user", label: "User" },
    { value: "admin", label: "Admin" },
    isGlobalAdmin && { value: "global_admin", label: "Global Admin" },
  ].filter(Boolean) as any;

  return (
    <Form name="userForm" form={form} layout="vertical">
      <div className="flex flex-col">
        <Form.Item
          name="first_name"
          label={
            <span className="font-sans font-bold text-[#475569] text-[14px]">
              First Name
            </span>
          }
          rules={[
            required(),
            minMax({ text: "First name", min: 2, max: 40 }),
            allowOnlyCharsDashAndQuotes,
          ]}
        >
          <Input size="large" placeholder="Enter user first name" />
        </Form.Item>

        <Form.Item
          name="last_name"
          label={
            <span className="font-sans font-bold text-[#475569] text-[14px]">
              Last Name
            </span>
          }
          rules={[
            required(),
            minMax({ text: "User last name", min: 2, max: 80 }),
            allowOnlyCharsDashAndQuotes,
          ]}
        >
          <Input size="large" placeholder="Enter user last name" />
        </Form.Item>
        <Form.Item
          name="position"
          rules={[
            required(),
            minMax({ text: "User position", min: 2, max: 255 }),
          ]}
          label={
            <span className="font-sans font-bold text-[#475569] text-[14px]">
              Position/Title
            </span>
          }
        >
          <Input size="large" placeholder="Enter user position" />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[required(), email, minMax({ text: "User email", max: 255 })]}
          label={
            <span className="font-sans font-bold text-[#475569] text-[14px]">
              Email
            </span>
          }
        >
          <Input size="large" placeholder="Enter user email" />
        </Form.Item>
        <Form.Item
          name="role"
          rules={[required()]}
          label={
            <span className="font-sans font-bold text-[#475569] text-[14px]">
              Role
            </span>
          }
        >
          <Select
            size="large"
            placeholder="Select user role"
            options={roleOptions}
          />
        </Form.Item>

        {newUserRole && newUserRole !== "global_admin" && (
          <>
            <Form.Item
              name="editor"
              label={
                <span className="font-sans font-bold text-[#475569] text-[14px]">
                  Edit Access
                </span>
              }
            >
              <Select
                size="large"
                mode="multiple"
                onDeselect={onCompanyDeselect}
                onChange={onCompanySelect}
                placeholder="Select company"
                options={companiesOptions.map((item) => ({
                  value: item,
                  label: item,
                }))}
              />
            </Form.Item>
            <Form.Item
              name="viewer"
              label={
                <span className="font-sans font-bold text-[#475569] text-[14px]">
                  View Access
                </span>
              }
            >
              <Select
                size="large"
                mode="multiple"
                onDeselect={onCompanyDeselect}
                onChange={onCompanySelect}
                placeholder="Select company"
                options={companiesOptions.map((item) => ({
                  value: item,
                  label: item,
                }))}
              />
            </Form.Item>
          </>
        )}
      </div>
    </Form>
  );
};

export default UserForm;
