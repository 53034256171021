import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { deleteCompanyApi } from "../../api/companies.api";
import handleRequestError, {
  TCustomError,
} from "../../utils/handleRequestError";
import { RootState } from "../store";

export type TCompany = {
  id: number;
  name: string;
  website: string;
  founding_at: string;
};

const initialState = {
  current: null as TCompany | null,
  list: [] as TCompany[],
};

type InitialStateType = typeof initialState;

type deleteCompanyProps = {
  companyId: number;
  removeFiles: boolean;
};

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setCompanies: (
      state: InitialStateType,
      action: PayloadAction<Array<TCompany>>
    ) => {
      state.list = action.payload;
    },
    setCurrentCompany: (
      state: InitialStateType,
      action: PayloadAction<TCompany | null>
    ) => {
      state.current = action.payload;
    },
    setCurrentCompanyId: (
      state: InitialStateType,
      action: PayloadAction<number | null>
    ) => {
      const company = state.list.find(
        (company) => company.id === action.payload
      );

      if (company) {
        state.current = company;
      }
    },
    deleteCompany: (state: InitialStateType, action: PayloadAction<number>) => {
      state.list = state.list.filter(
        (company) => company.id !== action.payload
      );
    },
  },
});

export const {
  setCompanies,
  setCurrentCompany,
  deleteCompany,
  setCurrentCompanyId,
} = companiesSlice.actions;

export default companiesSlice.reducer;

export const selectCompanies = (state: RootState) => state.companies.list;

/* eslint-disable*/
export const getCurrentCompany = (): TCompany | null =>
  useSelector((state: RootState) => state.companies.current);

export const useCompaniesList = () => useSelector(selectCompanies);

export const deleteCompanyThunk = createAsyncThunk<
  undefined,
  deleteCompanyProps,
  { state: RootState; rejectValue: TCustomError }
>(
  "companies/deleteCompanyThunk",
  async (
    { companyId, removeFiles },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const { companies } = getState();
      await deleteCompanyApi(companyId, removeFiles);

      if (companies.current?.id === companyId) {
        dispatch(setCurrentCompany(null));
      }
      dispatch(deleteCompany(companyId));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to delete the company by ID (${companyId}):`,
        e
      );

      return rejectWithValue(customError);
    }
  }
);
