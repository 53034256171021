import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { getCompaniesApi } from "src/api/companies.api";
import { CampaignsListRequestParams } from "src/pages/campaigns/hooks/useCampaignsList";
import { getCampaignsApi } from "../../api/campaigns.api";
import {
  TCustomError,
  handleRequestError,
} from "../../utils/handleRequestError";
import { RootState } from "../store";
import { toggleIsFetching } from "./appSlice";
import { TCampaignType, setCampaigns } from "./campaignsSlice";
import { setCompanies } from "./companiesSlice";

export type TCampaignsList = {
  totalCampaigns: number;
};

export type TCampaignListOptionType = TCampaignType | "ALL";

type InitialStateType = TCampaignsList;

const initialState: InitialStateType = {
  totalCampaigns: 0,
};

const campaignsListSlice = createSlice({
  name: "campaignsList",
  initialState,
  reducers: {
    setCampaignListTotalCampaigns: (
      state: InitialStateType,
      action: PayloadAction<number>
    ) => {
      state.totalCampaigns = action.payload;
    },
  },
});

export const { setCampaignListTotalCampaigns } = campaignsListSlice.actions;

export default campaignsListSlice.reducer;

export const useCampaignListTotalCampaigns = (): number =>
  useSelector((state: RootState) => state.campaignsList.totalCampaigns);

export const initCampaignsListFilters = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "campaignsList/initCampaignsListFilters",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(toggleIsFetching(true));

      const { data } = await getCompaniesApi();
      dispatch(setCompanies(data.companies));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to init campaigns page:`,
        e
      );

      return rejectWithValue(customError);
    }
  }
);

export const loadCampaignsList = createAsyncThunk<
  undefined,
  CampaignsListRequestParams,
  {
    state: RootState;
    rejectValue: TCustomError;
  }
>(
  "campaignsList/loadCampaignsList",
  async (
    { companyId, page, pageSize, campaignType },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(toggleIsFetching(true));

      const { users } = getState();
      const isGlobalAdmin = users.userData.role === "global_admin";

      if ((companyId && !isGlobalAdmin) || isGlobalAdmin) {
        const { data } = await getCampaignsApi({
          companyId: companyId || null,
          currentPage: page,
          pageSize,
          campaignType,
        });
        const { items: campaigns, totalItems } = data;

        dispatch(setCampaignListTotalCampaigns(totalItems));
        dispatch(setCampaigns(campaigns));
      }
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to init campaigns page:`,
        e
      );

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);
