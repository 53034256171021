import React from "react";
import { useSelector } from "react-redux";
import { TFileTreeItem } from "../../../../globalTypes";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import handleRequestError from "../../../../utils/handleRequestError";

type Props = {
  setTreeData: React.Dispatch<React.SetStateAction<TFileTreeItem[]>>;
  deleteFile: (path: string) => Promise<void>;
};

const useDelete = ({ setTreeData, deleteFile }: Props) => {
  const messageApi = useSelector(selectMessageApi);

  return async (path: string) => {
    try {
      await deleteFile(path);

      setTreeData((prevState) =>
        prevState.filter((item) => item.fileName !== path)
      );

      messageApi.success("The file was removed successfully");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };
};

export default useDelete;
