import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import handleRequestError from "../../../../utils/handleRequestError";

type Props = {
  getFileData: (path: string) => Promise<string>;
};

const useTabPreview = ({ getFileData }: Props) => {
  const messageApi = useSelector(selectMessageApi);
  const MESSAGE_KEY = "getting_file_link_tab_preview";

  return async (path: string) => {
    try {
      messageApi.open({
        key: MESSAGE_KEY,
        type: "loading",
        content: "Preparing the preview...",
      });

      const fileUrl = await getFileData(path);

      window.open(fileUrl, "_blank");

      messageApi.open({
        key: MESSAGE_KEY,
        type: "success",
        content: "Ready!",
      });
    } catch (e: any) {
      const customError = handleRequestError(e);

      console.error(customError);
      messageApi.open({
        key: MESSAGE_KEY,
        type: "error",
        content: customError.message,
      });
    }
  };
};

export default useTabPreview;
