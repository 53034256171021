import { LoadingOutlined } from "@ant-design/icons";
import { TComputationState } from "../../store/slices/ccVariablesSlice";
import {
  ComputedIcon,
  CreatedIcon,
  SolidErrorIcon,
  WaitingComputationIcon,
} from "../common/Icons";

const StateIcon = ({ state }: { state: TComputationState | undefined }) => {
  switch (state) {
    case "computed":
      return <ComputedIcon className="text-[#16A34A]" />;
    case "waiting_computation":
      return (
        <WaitingComputationIcon className="text-[#0000ff] mx-auto size-5 m-3" />
      );
    case "created":
      return <CreatedIcon className="text-[#D97706]" />;
    case "error":
      return <SolidErrorIcon className="mx-auto text-red-600" />;
    case "processing":
      return <LoadingOutlined style={{ color: "brown", fontSize: "20px" }} />;
    default:
      return null;
  }
};

export default StateIcon;
