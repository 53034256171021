import { Checkbox, Form, Input, InputRef, Modal } from "antd";
import { Rule } from "antd/es/form";
import React, { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createCampaignFromTemplateApi } from "../../../api/campaigns.api";
import useSetFocus from "../../../hooks/useSetFocus";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import { getMessageApi } from "../../../store/slices/appSlice";
import { getCurrentCompany } from "../../../store/slices/companiesSlice";
import { fetchCompaniesOptions } from "../../../utils/apiUtils";
import handleRequestError from "../../../utils/handleRequestError";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import SelectWithHighlightSearch from "../../common/SelectWithHighlightSearch";
import { Button } from "../../common/ui/button";

type TStartCampaignFormValues = {
  campaignName: string;
  companyId: number;
  resetOvr: boolean;
  resetResult: boolean;
};

type Props = {
  isModalOpen: boolean;
  campaignId: number;
  campaignTemplateName: string;
  campaignNameValidationRule: Rule;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const StartCampaignModal: FC<Props> = ({
  isModalOpen,
  campaignId,
  campaignNameValidationRule,
  campaignTemplateName,
  setIsModalOpen,
}) => {
  const navigate = useNavigate();
  const messageApi = getMessageApi();
  const currentCompany = getCurrentCompany();
  const [form] = Form.useForm<TStartCampaignFormValues>();
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef<InputRef>(null);

  useSubmitFormOnEnter(() => submitForm(), { condition: !isLoading });
  useSetFocus(inputRef);

  const onCreateCampaign = async (values: TStartCampaignFormValues) => {
    try {
      setIsLoading(true);

      const { companyId, campaignName, resetOvr, resetResult } = values;
      const companyIdForCM = currentCompany ? currentCompany.id : undefined;

      const { data } = await createCampaignFromTemplateApi({
        campaignId,
        companyId: companyIdForCM,
        campaignName,
        companyIdToCreate: companyId,
        resetOvr,
        resetResult,
      });

      setIsModalOpen(false);
      navigate(`/admin/campaigns/company/${companyId}/campaign/${data.id}`);
      messageApi.success("The campaign has been successfully created");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TStartCampaignFormValues) => {
      await onCreateCampaign(values);
    },
  });

  //TODO move to util and reuse
  const customizeRequiredMark = (
    label: React.ReactNode,
    { required }: { required: boolean }
  ) => (
    <span className="font-sans font-bold text-[#475569] text-[14px] flex">
      {label}
      {required && <span className="text-red-500 ml-1">*</span>}
    </span>
  );

  return (
    <Modal
      title="Create a campaign based on a template"
      open={isModalOpen}
      destroyOnClose
      width={500}
      footer={() => {
        return (
          <div className="flex gap-3 justify-end">
            <Button variant="outline" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button
              loading={isLoading}
              disabled={isLoading}
              onClick={submitForm}
            >
              Create Campaign
            </Button>
          </div>
        );
      }}
      onCancel={() => setIsModalOpen(false)}
    >
      <Form
        form={form}
        layout="vertical"
        name="campaign_import_form"
        requiredMark={customizeRequiredMark}
        initialValues={{
          campaignName: `Campaign from ${campaignTemplateName}`,
          resetOvr: true,
          resetResult: false,
        }}
      >
        <Form.Item
          name="campaignName"
          label="Campaign name"
          tooltip="Please enter a string between 4 and 255 characters."
          rules={[campaignNameValidationRule]}
        >
          <Input placeholder="Enter Campaign name" ref={inputRef} />
        </Form.Item>
        <Form.Item
          name="companyId"
          label="Choose Company"
          rules={[{ required: true, message: "Required field!" }]}
        >
          <SelectWithHighlightSearch
            onSelect={(companyId: number | undefined, _) =>
              form.setFieldValue("companyId", companyId)
            }
            fetchOptions={fetchCompaniesOptions}
            allowClear={true}
          />
        </Form.Item>
        <div className="flex flex-col gap-[6px]">
          <Form.Item name="resetOvr" valuePropName="checked" noStyle>
            <Checkbox>Reset override</Checkbox>
          </Form.Item>
          <Form.Item name="resetResult" valuePropName="checked" noStyle>
            <Checkbox>Reset result</Checkbox>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default StartCampaignModal;
