import React, { FC, useState } from "react";
import { Button, Modal, Upload, UploadFile, UploadProps } from "antd";
import { UploadOutlined } from "@ant-design/icons";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedPath: string;
  onUpload: (file: File) => Promise<void>;
};

const UploadFileModal: FC<Props> = ({ open, setOpen, onUpload }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [fileList, setFileList] = useState<Array<UploadFile<any>>>([]);

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    fileList,
    onChange(info) {
      setFileList(info.fileList);
    },
    onRemove() {
      setFileList([]);
    },
    async beforeUpload(file) {
      setIsFetching(true);

      await onUpload(file);

      setOpen(false);
      setIsFetching(false);

      return false;
    },
  };

  return (
    <Modal
      title="Upload File"
      open={open}
      footer={null}
      onCancel={() => setOpen(false)}
      className="w-[500px]"
    >
      <div className="flex flex-col gap-[12px] pb-[16px]">
        <span className="font-sans font-bold text-[#475569] text-[14px] flex">
          Choose files to upload
        </span>
        <Upload {...uploadProps}>
          <Button
            size="small"
            icon={<UploadOutlined />}
            loading={isFetching}
            disabled={isFetching}
          >
            Choose file
          </Button>
        </Upload>
      </div>
    </Modal>
  );
};

export default UploadFileModal;
