import { AxiosResponse } from "axios";
import { ComputationType, PROXY_PREFIX } from "src/constants";
import axiosInstance from "src/utils/axios/axiosInstance";

export const computationApi = (campaignId: number, companyId?: number) => ({
  executeCampaign(computationType: ComputationType, republish: boolean) {
    return axiosInstance.post(
      `${PROXY_PREFIX}/api/secured/${campaignId}/computation/campaign`,
      {},
      {
        params: {
          computationType,
          companyId,
          republish,
        },
      }
    );
  },
  executePhase(
    phaseId: number,
    computationType: ComputationType,
    republish: boolean
  ) {
    return axiosInstance.post(
      `${PROXY_PREFIX}/api/secured/${campaignId}/computation/phase/${phaseId}`,
      {},
      {
        params: {
          computationType,
          companyId,
          republish,
        },
      }
    );
  },
  executeStep(
    stepId: number,
    computationType: ComputationType,
    republish: boolean
  ) {
    return axiosInstance.post(
      `${PROXY_PREFIX}/api/secured/${campaignId}/computation/step/${stepId}`,
      {},
      {
        params: {
          computationType,
          companyId,
          republish,
        },
      }
    );
  },
  validateCampaign(): Promise<AxiosResponse<boolean>> {
    return axiosInstance.get(
      `${PROXY_PREFIX}/api/secured/campaign/execute/validate/${campaignId}/`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          companyId,
        },
      }
    );
  },
  validatePhase({
    phaseId,
  }: {
    phaseId: number;
  }): Promise<AxiosResponse<boolean>> {
    return axiosInstance.get(
      `${PROXY_PREFIX}/api/secured/${campaignId}/phase/execute/validate/${phaseId}/`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          companyId,
        },
      }
    );
  },
  validateStep({
    phaseId,
    stepId,
  }: {
    phaseId: number;
    stepId: number;
  }): Promise<AxiosResponse<boolean>> {
    return axiosInstance.get(
      `${PROXY_PREFIX}/api/secured/${phaseId}/step/execute/validate/${stepId}/`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          companyId,
        },
      }
    );
  },
  validateCCItem(): Promise<AxiosResponse<boolean>> {
    return axiosInstance.get(
      `${PROXY_PREFIX}/api/secured/${campaignId}/cc/execute/validate/`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          companyId,
        },
      }
    );
  },
});
