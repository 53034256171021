import { AxiosResponse } from "axios";
import { PROXY_PREFIX } from "src/constants";
import { CampaignDataAssetsStructure } from "src/store/slices/campaignsSlice";
import { TStorageFileItem } from "../globalTypes";
import { CopyFileParams } from "../types";
import axiosInstance from "../utils/axios/axiosInstance";
import { replaceBrackets } from "../utils/cm.utils";

const cmManagerApiKey = process.env.REACT_APP_CM_SUPER_USER_API_KEY;
const cmApiUrl = process.env.REACT_APP_CM_API_URL;
const CM_API_PREFIX = "/api/secure/campaignManager";

export const getCampaignStructureApi = ({
  campaignId,
  companyId,
}: {
  campaignId: string;
  companyId: number | string | undefined;
}): Promise<AxiosResponse<CampaignDataAssetsStructure>> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/campaign/${campaignId}/data`,
    {
      params: {
        initProps: "ASSETS",
        companyId,
      },
    }
  );
};

// PRIVATE
export const getCompanyPrivateFilesListApi = ({
  path,
}: {
  path: string;
}): Promise<AxiosResponse<Array<TStorageFileItem>>> => {
  return axiosInstance.get(
    `${cmApiUrl}/api/secured/fs/private-companies/list`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        path,
        "api-key": cmManagerApiKey,
      },
    }
  );
};

export const uploadCompanyPrivateFileApi = ({
  formData,
}: {
  formData: FormData;
}): Promise<AxiosResponse<TStorageFileItem>> => {
  return axiosInstance.post(
    `${cmApiUrl}/api/secured/fs/private-companies/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        "api-key": cmManagerApiKey,
      },
    }
  );
};

export const deleteCompanyPrivateFileApi = ({
  path,
}: {
  path: string;
}): Promise<AxiosResponse<void>> => {
  const form = new FormData();
  form.append("path", path);

  return axiosInstance.delete(
    `${cmApiUrl}/api/secured/fs/private-companies/file`,
    {
      data: form,
      params: {
        "api-key": cmManagerApiKey,
      },
    }
  );
};

export const createCompanyPrivateFolderApi = ({
  formData,
}: {
  formData: FormData;
}): Promise<AxiosResponse<TStorageFileItem>> => {
  return axiosInstance.post(
    `${cmApiUrl}/api/secured/fs/private-companies/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        "api-key": cmManagerApiKey,
      },
    }
  );
};

export const getCompanyPrivateFileDataApi = ({
  path,
}: {
  path: string;
}): Promise<AxiosResponse<string>> => {
  return axiosInstance.get(
    `${cmApiUrl}/api/secured/fs/private-companies/file`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        "api-key": cmManagerApiKey,
        path,
      },
    }
  );
};

export const downloadCompanyPrivateFileApi = ({
  path,
}: {
  path: string;
}): Promise<AxiosResponse<ArrayBuffer>> => {
  return axiosInstance.get(
    `${cmApiUrl}/api/secured/fs/private-companies/download`,
    {
      responseType: "arraybuffer",
      params: {
        path,
        "api-key": cmManagerApiKey,
      },
    }
  );
};

export const copyCompanyPrivateFileApi = ({
  sourcePath,
  targetPath,
}: CopyFileParams): Promise<AxiosResponse<TStorageFileItem>> => {
  return axiosInstance.post(
    `${cmApiUrl}/api/secured/fs/private-companies/copy`,
    {},
    {
      params: {
        "api-key": cmManagerApiKey,
        sourcePath,
        targetPath,
      },
    }
  );
};

// PUBLIC
export const getCompanyPublicFilesListApi = ({
  path,
}: {
  path: string;
}): Promise<AxiosResponse<Array<TStorageFileItem>>> => {
  return axiosInstance.get(`${cmApiUrl}/api/secured/fs/public-companies/list`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      path: replaceBrackets(path),
      "api-key": cmManagerApiKey,
    },
  });
};

export const uploadCompanyPublicFileApi = ({
  formData,
}: {
  formData: FormData;
}): Promise<AxiosResponse<TStorageFileItem>> => {
  return axiosInstance.post(
    `${cmApiUrl}/api/secured/fs/public-companies/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        "api-key": cmManagerApiKey,
      },
    }
  );
};

export const deleteCompanyPublicFileApi = ({
  path,
}: {
  path: string;
}): Promise<AxiosResponse<void>> => {
  const form = new FormData();
  form.append("path", path);

  return axiosInstance.delete(
    `${cmApiUrl}/api/secured/fs/public-companies/file`,
    {
      data: form,
      params: {
        "api-key": cmManagerApiKey,
      },
    }
  );
};

export const downloadCompanyPublicFileApi = ({
  path,
}: {
  path: string;
}): Promise<AxiosResponse<Blob>> => {
  return axiosInstance.get(
    `${cmApiUrl}/api/secured/fs/public-companies/download`,
    {
      responseType: "blob",
      params: {
        path,
        "api-key": cmManagerApiKey,
      },
    }
  );
};

export const getCompanyPublicFileDataApi = ({
  path, companyId
}: {
  path: string;
  companyId: number | undefined;
}): Promise<AxiosResponse<{ data: any; url: string }>> => {
  return axiosInstance.get(
    // `${cmApiUrl}/api/secured/fs/public-companies/file`,
    `${CM_API_PREFIX}/fs/public-companies/file`,
    {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      companyId,
      // "api-key": cmManagerApiKey,
      path,
    },
  });
};

export const copyCompanyPublicFileApi = ({
  sourcePath,
  targetPath,
}: {
  sourcePath: string;
  targetPath: string;
}): Promise<AxiosResponse<TStorageFileItem>> => {
  return axiosInstance.post(
    `${cmApiUrl}/api/secured/fs/public-companies/copy`,
    {},
    {
      params: {
        "api-key": cmManagerApiKey,
        sourcePath,
        targetPath,
      },
    }
  );
};
