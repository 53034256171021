import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { docTemplateApi } from "src/api/document-templates.api";
import { ArrowRightIcon, DeleteIcon, Icons } from "src/components/common/Icons";
import MenuDropdown, {
  MenuDropdownItem,
} from "src/components/common/MenuDropdown";
import { Button } from "src/components/common/ui/button";
import { TableCell, TableRow } from "src/components/common/ui/table";
import { docTemplateStateBadgeSettings } from "src/constants";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { useDocTemplatesList } from "src/hooks/useDocTemplatesList";
import { selectMessageApi } from "src/store/slices/appSlice";
import { DocumentTemplate } from "src/types/docTemplates";
import handleRequestError from "src/utils/handleRequestError";
import DeleteWithTitleConfirmationModal from "../common/modals/DeleteWithTitleConfirmationModal/DeleteWithTitleConfirmationModal";
import { EditTemplateClassesModal } from "../common/modals/EditTemplateClassesModal";
import { Badge } from "../common/ui/badge";
import { Spin } from "antd";
import { ViewKeysModal } from "../common/modals/ViewKeysModal";
import { TableCellsIcon } from "@heroicons/react/16/solid";

type Props = {
  item: DocumentTemplate;
};

const DocTemplateListItem: FC<Props> = ({ item }) => {
  const { id, name, state, mediaType, classes, keys, includeCampaignStructure } =
    item;
  const { isRegularUser } = useCurrentUser();
  const [isEditClassesModalOpen, setIsEditClassesModalOpen] = useState(false);
  const messageApi = useSelector(selectMessageApi);
  const [isDeleteTemplateModalOpen, setDeleteTemplateModalOpen] =
    useState(false);
  const { updateClasses, deleteTemplate, updateTemplate } =
    useDocTemplatesList();
  const [isLoading, setIsLoading] = useState(false);
  const [displayKeysModal, setDisplayKeysModal] = useState(false);
  const navigate = useNavigate();

  const navigateToEdit = () => {
    navigate(`/admin/document-templates/create/${id}`);
  };

  const handleToggleIncludeCampaignStructure = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const toggledIncludeCampaignStructure = !includeCampaignStructure;
      await docTemplateApi(id).updateIncludeCampaignStructure({
        includeCampaignStructure: toggledIncludeCampaignStructure,
      });
      updateTemplate(id, { includeCampaignStructure: toggledIncludeCampaignStructure });
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };

  const menuItems = [
    {
      key: "1",
      label: "Edit Classes",
      onClick: () => setIsEditClassesModalOpen(true),
      icon: ArrowRightIcon,
    },
    {
      key: 'keys',
      label: 'View Keys',
      onClick: () => setDisplayKeysModal(true),
      icon: Icons.docs,
    },
    !isRegularUser && {
      key: "2",
      label: "Edit",
      onClick: navigateToEdit,
      icon: Icons.edit,
    },
    {
      key: "3",
      label: item?.includeCampaignStructure
        ? "Campaign Structure Included"
        : "Campaign Structure Not Included",
      onClick: handleToggleIncludeCampaignStructure,
      icon: isLoading ? Spin : TableCellsIcon,
    },
    !isRegularUser && {
      key: "4",
      label: "Delete",
      onClick: () => setDeleteTemplateModalOpen(true),
      icon: DeleteIcon,
      className: "!text-red-600",
    },
  ].filter(Boolean) as MenuDropdownItem[];

  const handleDelete = async () => {
    try {
      await docTemplateApi(id).delete();
      deleteTemplate(id);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setDeleteTemplateModalOpen(false);
    }
  };

  const {
    label: badgeLabel = "",
    className: badgeClassName = "",
    icon: Icon,
  } = docTemplateStateBadgeSettings[state];

  return (
    <>
      <TableRow
        key={id}
        onClick={navigateToEdit}
        className="cursor-pointer relative"
      >
        <TableCell>
          <span className="text-slate-900 text-sm font-medium font-sans">
            {name}
          </span>
        </TableCell>
        <TableCell>
          <span className="text-slate-900 text-sm font-medium font-sans">
            {mediaType || "-"}
          </span>
        </TableCell>
        <TableCell>
          <Badge className={badgeClassName} variant={"state"}>
            {Icon && <Icon className="size-4 mr-[4px]" />}
            {badgeLabel}
          </Badge>
        </TableCell>
        <TableCell>
          <div className="flex flex-wrap gap-1">
            {classes?.length ? (
              classes.map((classItem) => (
                <Badge
                  key={classItem}
                  className={"bg-transparent !text-slate-500 font-sans"}
                  variant={"tableOutline"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsEditClassesModalOpen(true);
                  }}
                >
                  {classItem}
                </Badge>
              ))
            ) : (
              <Badge
                className="bg-slate-100 text-slate-500 font-sans"
                variant={"tableOutline"}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEditClassesModalOpen(true);
                }}
              >
                No assigned classes
              </Badge>
            )}
          </div>
        </TableCell>
        <TableCell
          className="flex items-center justify-end"
          onClick={(e) => e.stopPropagation()}
        >
          <MenuDropdown
            items={menuItems}
            dropdownClassName="w-[190px]"
            align="end"
          >
            <Button
              variant={"ghostPrimary"}
              className="size-[40px] p-2 flex justify-end"
            >
              <Icons.MoreDots className="size-[24px]" />
            </Button>
          </MenuDropdown>
        </TableCell>
      </TableRow>
      {isEditClassesModalOpen && (
        <EditTemplateClassesModal
          open={isEditClassesModalOpen}
          setOpen={setIsEditClassesModalOpen}
          templateId={id}
          defaultClasses={classes}
          onUpdated={(classes) => {
            updateClasses(id, classes);
          }}
        />
      )}
      {isDeleteTemplateModalOpen && (
        <DeleteWithTitleConfirmationModal
          entity="Document Template"
          title={name}
          onDelete={handleDelete}
          isModalOpen={isDeleteTemplateModalOpen}
          setIsModalOpen={setDeleteTemplateModalOpen}
        />
      )}
      {displayKeysModal && (
        <ViewKeysModal
          open={displayKeysModal}
          setOpen={setDisplayKeysModal}
          name={name}
          keys={keys}
        />
      )}
    </>
  );
};

export default DocTemplateListItem;
