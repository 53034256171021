import { Skeleton } from "antd";
import { Outlet } from "react-router-dom";
import { Icons } from "../../../components/common/Icons";
import Navigation from "../../../components/common/Navigation";
import { Button } from "../../../components/common/ui/button";
import useCampaignStorageData from "./hooks/useCampaignStorageData.hook";

const CampaignStoragePage = () => {
  const { isLoading, campaignData, error, onGoBack, navigationItems } =
    useCampaignStorageData();

  const renderContent = () => {
    if (isLoading) {
      return <Skeleton active />;
    }

    if (error) {
      return <span className="text-red-500">Error: {error}</span>;
    }

    if (campaignData) {
      return (
        <>
          <div>
            <div className="flex gap-[12px] items-center">
              <Button
                variant={"ghost"}
                icon={Icons.back}
                onClick={onGoBack}
                className="p-[12px] h-auto"
              />
              <div className="flex items-end gap-[8px]">
                <h1 className="text-slate-900 text-2xl font-semibold font-sans leading-normal capitalize">
                  Campaign Storage:{" "}
                  <span className="font-normal">{campaignData.name}</span>
                </h1>
              </div>
            </div>

            <Navigation items={navigationItems} replace={true}/>

            <div className="h-[1px] bg-[#E2E8F0]" />
            <Outlet context={campaignData} />
          </div>
        </>
      );
    }

    return <span className="text-red-500">Unable to load campaign data</span>;
  };

  return <div>{renderContent()}</div>;
};

export default CampaignStoragePage;
