import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TAsset } from "../../../globalTypes";
import { getMessageApi } from "../../../store/slices/appSlice";
import { getCampaignLabels } from "../../../store/slices/campaignLabelsSlice";
import { loadCampaignDataByStepThunk } from "../../../store/slices/campaignsSlice";
import { useCcVariables } from "../../../store/slices/ccVariablesSlice";
import { getGlobalLabels } from "../../../store/slices/globalLabelsSlice";
import { AppDispatch } from "../../../store/store";

type PropsType = {
  stepId: number;
  companyId: number | undefined;
  campaignId: number;
};
const useLoadDataByStep = (props: PropsType) => {
  const { companyId, stepId, campaignId } = props;
  const dispatch: AppDispatch = useDispatch();
  const messageApi = getMessageApi();
  const ccVars = useCcVariables();
  const campaignLabels = getCampaignLabels();
  const globalLabels = getGlobalLabels();
  const [assets, setAssets] = useState<TAsset[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadStepData = async () => {
    try {
      setIsLoading(true);

      const { assets } = await dispatch(
        loadCampaignDataByStepThunk({
          companyId,
          stepId,
          campaignId,
        })
      ).unwrap();

      setAssets(assets);
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error("get all step data ERR:", e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadStepData();
  }, [stepId]);

  return {
    ccVars,
    campaignLabels,
    globalLabels,
    assets,
    setAssets,
    isLoading,
    loadStepData
  };
};

export default useLoadDataByStep;
