import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCompaniesListState,
  setCompanies,
  setPageNumber,
  setPageSize,
} from "src/store/slices/companiesListSlice";
import { getCompaniesSearchApi } from "../../api/companies.api";
import { CompanyDrawerProps } from "../../components/companies/CompanyDrawer/CompanyDrawer";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { selectMessageApi } from "../../store/slices/appSlice";
import handleRequestError from "../../utils/handleRequestError";

const useCompaniesListHook = () => {
  const dispatch = useDispatch();
  const messageApi = useSelector(selectMessageApi);
  const { list, total, pageNumber, pageSize } = useSelector(
    selectCompaniesListState
  );
  const [loading, setLoading] = useState(false);
  const [companyDrawerProps, setCompanyDrawerProps] =
    useState<CompanyDrawerProps>({
      visible: false,
    });
  const { isRegularUser } = useCurrentUser();

  useEffect(() => {
    loadCompanies({ pageNumber, pageSize });
  }, [pageNumber, pageSize]);

  const loadCompanies = async ({
    pageNumber,
    pageSize,
  }: {
    pageNumber: number;
    pageSize: number;
  }) => {
    try {
      setLoading(true);
      const { data } = await getCompaniesSearchApi({
        pageSize,
        pageNumber,
      });

      dispatch(setCompanies(data));
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setLoading(false);
    }
  };
  const updateList = async () => {
    const { data } = await getCompaniesSearchApi({
      pageSize,
      pageNumber,
    });
    dispatch(setCompanies(data));
  };
  const updatePage = (pageNumber: number) => {
    dispatch(setPageNumber(pageNumber));
  };

  const updatePageSize = (pageSize: number) => {
    dispatch(setPageNumber(1));
    dispatch(setPageSize(pageSize));
  };

  return {
    list,
    total,
    pageSize,
    pageNumber,
    updatePage,
    updatePageSize,
    loading,
    companyDrawerProps,
    setCompanyDrawerProps,
    isRegularUser,
    messageApi,
    updateList,
  };
};

export default useCompaniesListHook;
