import { Rule } from "antd/es/form";
import { TPhase } from "../globalTypes";
import { TStep } from "../store/slices/stepsSlice";

export const getPhaseNameValidationRule = ({
  editPhaseName,
  phases,
}: {
  //could be undefined if no need to compare
  //ex - when rename do not take into account the name of the phase itself
  editPhaseName: string | undefined;
  phases: TPhase[];
}): Rule => {
  const phasesNamesLowercase = phases.map((phase) => phase.name.toLowerCase());

  return {
    validator: (_, value) => {
      const trimmed = value ? value.trim() : "";
      const lowerCaseValue = trimmed.toLowerCase();
      const editPhaseNameLowercase = editPhaseName?.toLowerCase();

      if (
        editPhaseNameLowercase !== lowerCaseValue &&
        phasesNamesLowercase.includes(lowerCaseValue)
      ) {
        return Promise.reject(
          new Error("A phase with that name already exists!")
        );
      }

      return Promise.resolve();
    },
  };
};

export const getStepNameValidationRule = ({
  editStepName,
  steps,
}: {
  //could be undefined if no need to compare
  //ex - when rename do not take into account the name of the step itself
  editStepName: string | undefined;
  steps: TStep[];
}): Rule => {
  const stepsNamesLowercase = steps.map((step) => step.name.toLowerCase());

  return {
    validator: (_, value) => {
      const trimmed = value ? value.trim() : "";
      const lowerCaseValue = trimmed.toLowerCase();

      if (
        editStepName?.toLowerCase() !== lowerCaseValue &&
        stepsNamesLowercase.includes(lowerCaseValue)
      ) {
        return Promise.reject(
          new Error("A step with that name already exists!")
        );
      }

      return Promise.resolve();
    },
  };
};

export const getCampaignNameValidationRule = (): Rule => {
  return {
    required: true,
    validator: (_, value) => {
      const trimmed = value ? value.trim() : "";

      if (!trimmed?.length) {
        return Promise.reject(new Error("Required field!"));
      }

      if (trimmed.length < 4) {
        return Promise.reject(
          new Error("The name must not be less than 4 characters!")
        );
      }

      if (trimmed.length > 255) {
        return Promise.reject(
          new Error("The name must contain no more than 255 characters!")
        );
      }

      if (/[\\/]/.test(trimmed)) {
        return Promise.reject(new Error("The name must not contain slashes!"));
      }

      return Promise.resolve();
    },
  };
};
