import { AxiosResponse } from "axios";
import { PROXY_PREFIX } from "src/constants";
import axiosInstance from "src/utils/axios/axiosInstance";

type FindDictionaryPayload = {
  wordMatch: string;
};

type AddDictionaryPayload = {
  item: string;
  companyId?: number;
};

export enum DictionaryType {
  CLASS = "CLASS",
}

export const dictionaryApi = (dictionaryType: DictionaryType) => ({
  find: ({
    wordMatch,
  }: FindDictionaryPayload): Promise<AxiosResponse<string[]>> => {
    return axiosInstance.get(
      `${PROXY_PREFIX}/api/secured/dictionary/find/${dictionaryType}`,
      {
        params: {
          wordMatch,
        },
      }
    );
  },
  add: ({ item, companyId }: AddDictionaryPayload): Promise<AxiosResponse<string[]>> => {
    return axiosInstance.post(
      `${PROXY_PREFIX}/api/secured/dictionary/${dictionaryType}/`,
      {
        id: {
          type: dictionaryType,
          word: item,
        },
      },
      {
        params: {
          companyId
        },
      }
    );
  },
  delete: ({
           wordMatch,
         }: FindDictionaryPayload): Promise<AxiosResponse<string[]>> => {
    return axiosInstance.delete(
      `${PROXY_PREFIX}/api/secured/dictionary/${dictionaryType}`,
      {
        params: {
          wordMatch,
        },
      }
    );
  },
});
