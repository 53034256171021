import { Checkbox, Form, Input, InputRef, Modal, Select, Spin } from "antd";
import { FC, useRef, useState } from "react";
import { getPhasesApi } from "../../../api/phases.api";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import { getMessageApi } from "../../../store/slices/appSlice";
import { TCampaignResponseData } from "../../../store/slices/campaignsSlice";
import { fetchCampaignsOptions } from "../../../utils/apiUtils";
import { STEP_SEQUENCE_STEP } from "../../../utils/campaigns.constant";
import { sortBySequence } from "../../../utils/cm.utils";
import handleRequestError from "../../../utils/handleRequestError";
import SelectWithHighlightSearch, {
  TSelectOptionWithData,
} from "../../common/SelectWithHighlightSearch";

export type TCloneToPhaseFormValues = {
  name: string;
  resetOvr: boolean;
  resetResult: boolean;
  campaignId: number;
  targetPhaseSeq: number;
  companyId: number | "global";
};

type TSelectOption = { label: string; value: number };

type Props = {
  entityTitle: string;
  onSubmitForm: (
    values: TCloneToPhaseFormValues & { companyId: number | "global" }
  ) => Promise<void>;
  onCancel: () => void;
};

const CloneToPhaseModal: FC<Props> = ({
  entityTitle,
  onSubmitForm,
  onCancel,
}) => {
  const messageApi = getMessageApi();
  const inputRef = useRef<InputRef>(null);
  const [form] = Form.useForm<TCloneToPhaseFormValues>();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isLoadingPhases, setIsLoadingPhases] = useState(false);
  const [companyId, setCompanyId] = useState<number | "global" | undefined>(
    undefined
  );
  const campaignId = Form.useWatch<number | undefined>("campaignId", form);
  const [phaseOptions, setPhaseOptions] = useState<TSelectOption[]>([]);

  useSubmitFormOnEnter(() => onValidateForm(), { condition: !isLoadingSubmit });

  const onValidateForm = async () => {
    if (companyId === undefined) {
      messageApi.error("Company ID not found");
      return;
    }

    try {
      setIsLoadingSubmit(true);

      const values = await form.validateFields();

      await onSubmitForm({ ...values, name: values.name.trim(), companyId });

      onCancel();
    } catch (e) {
      messageApi.error(
        "Please fill in all required fields of the form before sending"
      );
      console.log(e);
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  const getTargetSequence = (seq: number, nextSeq: number | undefined) => {
    return nextSeq !== undefined
      ? seq + Math.floor((nextSeq - seq) / 2)
      : seq + STEP_SEQUENCE_STEP;
  };

  const onSelectCampaign = async (
    campaignId: number | undefined,
    option: TSelectOptionWithData<TCampaignResponseData> | undefined
  ) => {
    setPhaseOptions([]);
    setCompanyId(
      option
        ? option.data.extCompanyId
          ? option.data.extCompanyId
          : "global"
        : undefined
    );

    form.setFieldsValue({
      campaignId,
      targetPhaseSeq: undefined,
    });

    //prevent loading options if the clear value selection action was called
    if (campaignId === undefined) return;

    try {
      setIsLoadingPhases(true);
      const { data } = await getPhasesApi(campaignId, undefined); // undefined since the feature only for global admins
      let options: TSelectOption[] = [];

      if (data.length) {
        const sortedPhases = sortBySequence(data);
        options = sortedPhases.map(({ seq, name }, index) => ({
          label: name,
          value: getTargetSequence(seq, sortedPhases[index + 1]?.seq),
        }));
      } else {
        //if no phases in campaign - set seq=0 because it'll be the first phase
        form.setFieldValue("targetPhaseSeq", 0);
      }

      setPhaseOptions(options);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoadingPhases(false);
    }
  };

  return (
    <Modal
      title="Clone Phase To"
      open={true}
      onOk={onValidateForm}
      okButtonProps={{ loading: isLoadingSubmit }}
      okText="Clone"
      onCancel={onCancel}
      className="w-[700px]"
    >
      <Form
        form={form}
        layout="vertical"
        className="h-[280px]"
        name="clone_to_phase_form"
        initialValues={{
          resetOvr: true,
          resetResult: false,
          name: `Clone of ${entityTitle}`,
        }}
      >
        <div className="flex justify-between items-center">
          <Form.Item
            name="name"
            className="w-[330px]"
            label="Phase Name"
            tooltip="Please enter a string up to 255 characters long"
            rules={[
              {
                type: "string",
                required: true,
                whitespace: true,
                message: "Required field!",
              },
              {
                max: 255,
                message: "Phase name must contain no more than 255 characters!",
              },
            ]}
          >
            <Input placeholder="Enter Phase name" ref={inputRef} />
          </Form.Item>
          <div className="flex flex-col gap-[6px]">
            <Form.Item name="resetOvr" valuePropName="checked" noStyle>
              <Checkbox>Reset override</Checkbox>
            </Form.Item>
            <Form.Item name="resetResult" valuePropName="checked" noStyle>
              <Checkbox>Reset result</Checkbox>
            </Form.Item>
          </div>
        </div>

        <Form.Item
          name="campaignId"
          label="Choose Campaign"
          rules={[{ required: true, message: "Required field!" }]}
        >
          <SelectWithHighlightSearch
            onSelect={onSelectCampaign}
            fetchOptions={fetchCampaignsOptions}
            allowClear={true}
            optionLabel="extCompanyName"
          />
        </Form.Item>
        <Spin spinning={isLoadingPhases}>
          <Form.Item
            name="targetPhaseSeq"
            hidden={!campaignId || !phaseOptions.length}
            label="Choose Phase"
            rules={[{ required: true, message: "Required field!" }]}
          >
            <Select
              placeholder="Select the Phase after which to insert"
              className="w-full font-normal"
              options={phaseOptions}
            />
          </Form.Item>
          {campaignId && !phaseOptions.length && (
            <div className="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400">
              <span>
                There are no phases in the campaign yet.
                <br />
                Therefore, the copied phase will be inserted first.
                <br />
                Please continue by clicking the Clone button.
              </span>
            </div>
          )}
        </Spin>
      </Form>
    </Modal>
  );
};

export default CloneToPhaseModal;
