import {
  TCcVariable,
  TComputationState,
} from "src/store/slices/ccVariablesSlice";
import { campaignStatePriority } from "./constants";

export const closestEditableElement = (event: MouseEvent) => {
  const target = event.target as Element;
  return target?.closest("[ve-content]");
};

export const postProcessContent = (doc: Document) => {
  const anchorTags = doc.getElementsByTagName("a");

  const css = document.createElement("style");
  css.innerHTML = `
      [ve-content]:hover {
        background-color: #f0f0f0!important;
        cursor: pointer!important;
      }
    `;
  doc.head.appendChild(css);

  for (let i = 0; i < anchorTags.length; i++) {
    anchorTags[i].addEventListener("click", (e) => {
      e.preventDefault();
    });
  }
};

export const getHighestPriorityState = (
  ccVariables: TCcVariable[]
): TComputationState | undefined => {
  const states = ccVariables.map(
    (variable) => variable.state
  ) as TComputationState[];

  for (const state of campaignStatePriority) {
    if (states.includes(state)) {
      return state;
    }
  }
};
