import { AxiosResponse } from "axios";
import { PROXY_PREFIX } from "../constants";
import { TPhase } from "../globalTypes";
import axiosInstance from "../utils/axios/axiosInstance";

//TODO: move variable to project constants
const CM_API_PREFIX = "/api/secure/campaignManager";

export const getPhasesApi = (
  campaignId: number,
  companyId: number | undefined
): Promise<AxiosResponse<Array<TPhase>>> => {
  return axiosInstance.get(`${CM_API_PREFIX}/phase/${campaignId}`, {
    headers: { "Content-Type": "application/json" },
    params: {
      companyId,
    },
  });
};

export const createPhaseApi = (
  campaignId: number,
  name: string,
  seq: number,
  hidden: boolean,
  microSiteContextFolder: string | null,
  companyId: number | undefined
): Promise<AxiosResponse<TPhase>> => {
  return axiosInstance.post(
    `${CM_API_PREFIX}/phase/${campaignId}`,
    { name, seq, microSiteContextFolder, hidden },
    {
      headers: { "Content-Type": "application/json" },
      params: {
        companyId,
      },
    }
  );
};

export const updatePhaseApi = (
  phase: TPhase,
  companyId: number | undefined,
): Promise<AxiosResponse<string>> => {
  return axiosInstance.put(
    `${CM_API_PREFIX}/phase/${phase.campaignId}`,
    phase,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const deletePhaseApi = ({
  campaignId,
  phaseId,
  removeFiles,
  companyId,
}: {
  campaignId: number;
  phaseId: number;
  removeFiles: boolean;
  companyId: number | undefined;
}): Promise<AxiosResponse<string>> => {
  return axiosInstance.delete(
    `${PROXY_PREFIX}/api/secured/${campaignId}/phase/${phaseId}`,
    {
      headers: { "Content-Type": "application/json" },
      params: {
        removeFiles,
        companyId,
      },
    }
  );
};

export const exportCSVPhaseApi = (
  campaignId: number,
  phaseId: number,
  companyId: number | undefined
): Promise<AxiosResponse<string>> => {
  return axiosInstance.get(
    `${CM_API_PREFIX}/phase/${campaignId}/${phaseId}/export-csv`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const swapPhaseSequenceApi = (
  campaignId: number,
  phase1Seq: number,
  phase2Seq: number,
  companyId: number | undefined
): Promise<AxiosResponse<Array<TPhase>>> => {
  return axiosInstance.put(
    `${CM_API_PREFIX}/phase/${campaignId}/swap`,
    { phase1Seq, phase2Seq },
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

type TClonePhaseProps = {
  campaignId: number;
  phaseId: number;
  name: string;
  resetResult: boolean;
  resetOvr: boolean;
  companyId: number | undefined;
};

export const clonePhaseApi = ({
  companyId,
  phaseId,
  resetOvr,
  resetResult,
  name,
  campaignId,
}: TClonePhaseProps): Promise<AxiosResponse<TPhase>> => {
  return axiosInstance.put(
    `${CM_API_PREFIX}/${campaignId}/phase/${phaseId}/clone`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        name,
        resetResult,
        resetOvr,
        companyId,
      },
    }
  );
};

export const publishPhaseToMicroSiteApi = ({
  phaseId,
  campaignId,
  companyId,
}: {
  phaseId: number;
  campaignId: number;
  companyId: number | undefined;
}): Promise<AxiosResponse<string>> => {
  return axiosInstance.put(
    `${CM_API_PREFIX}/${campaignId}/phase/${phaseId}/microsite-deploy`,
    {},
    {
      headers: { "Content-Type": "application/json" },
      params: { companyId },
    }
  );
};

export const redeployPhaseToMicroSiteApi = ({
  phaseId,
  campaignId,
  companyId,
}: {
  phaseId: number;
  campaignId: number;
  companyId: number | undefined;
}): Promise<AxiosResponse<string>> => {
  return axiosInstance.put(
    `${PROXY_PREFIX}/api/secured/${campaignId}/phase/${phaseId}/microsite-redeploy`,
    {},
    {
      headers: { "Content-Type": "application/json" },
      params: { companyId },
    }
  );
};

export const copyPhaseToApi = ({
  phaseId,
  campaignId,
  targetCampaignId,
  name,
  newSeq,
  resetResult,
  resetOvr,
  companyId,
}: {
  phaseId: number;
  campaignId: number;
  targetCampaignId: number;
  name: string;
  newSeq: number;
  resetResult: boolean;
  resetOvr: boolean;
  companyId: number | undefined;
}): Promise<AxiosResponse<TPhase>> => {
  return axiosInstance.put(
    `${PROXY_PREFIX}/api/secured/${campaignId}/phase/${phaseId}/copy`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        resetResult,
        resetOvr,
        name,
        newSeq,
        targetCampaignId,
        companyId,
      },
    }
  );
};
