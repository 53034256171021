import { Badge } from "antd";
import { usePostHog } from "posthog-js/react";
import { useDispatch } from "react-redux";
import { logoutThunk } from "src/store/slices/usersSlice";
import { AppDispatch } from "src/store/store";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";

const UserAvatar = ({ user }: any) => {
  const dispatchRedux: AppDispatch = useDispatch();
  const posthog = usePostHog();

  const handleLogout = () => {
    posthog?.capture("The user logged out");

    dispatchRedux(logoutThunk());
  };

  const getRoleDisplayName = (role: string) => {
    switch (role) {
      case "global_admin":
        return "Global Admin";
      case "admin":
        return "Admin";
      case "user":
        return "User";
      default:
        return role;
    }
  };

  return (
    <>
      <div className="font-sans">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="size-[32px] rounded-full flex items-center justify-center bg-[#F472B6] cursor-pointer">
              <span className="text-[#0D0A37] text-[12px] font-sans">
                {(user.first_name[0] + user.last_name[0]).toUpperCase()}
              </span>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            <DropdownMenuLabel className="font-sans text-lg font-bold">
              My Account
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <div className="px-2 py-2 text-sm font-semibold">
              <div className="break-words max-w-full">{`${user.first_name} ${user.last_name}`}</div>
              <div className="text-xs text-gray-600 break-words max-w-full">
                {user.email}
              </div>
              <div className="mt-1">
                <Badge className="px-2 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-800">
                  {getRoleDisplayName(user.role)}
                </Badge>
              </div>
            </div>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuItem
                className="font-sans cursor-pointer text-red-500"
                onClick={handleLogout}
              >
                Logout
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </>
  );
};

export default UserAvatar;
