import { logoutThunk } from "../../store/slices/usersSlice";
import instance from "./axiosInstance";

const axiosInterceptor = (store: any) => {
  instance.interceptors.response.use(
    (res) => res,
    async (err) => {
      const status = err?.response?.status || null;
      const errorCode = err?.response?.data?.errorCode || err?.response?.data?.code || null;

      if (status === 401 && errorCode === "AUTH_ERROR") {
        await store.dispatch(logoutThunk());
      }
      return Promise.reject(err);
    }
  );
};

export default axiosInterceptor;
