import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import localStorageProvider from "../utils/localStorageProvider";

const useTrackUrlChanges = () => {
  const { pathname, search, hash } = useLocation();

  useEffect(() => {
    const lastUrl = pathname + search + hash;
    localStorageProvider.setLastUrl(lastUrl);
  }, [pathname, search]);

  return null;
};

export default useTrackUrlChanges;
