import {
  Button,
  Flex,
  Input,
  Popover,
  Segmented,
  Select,
  Typography,
} from "antd";
import clsx from "clsx";
import React, {
  FC,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { TVarType, VarTypeEnum } from "src/types";
import { TCcVariable } from "../../../store/slices/ccVariablesSlice";
import TreeSelect from "../../common/SelectCCKey";

type Props = {
  varKey: string | null;
  varKeys: Array<string | null>;
  varType: TVarType;
  setVarKeys: (keys: Array<string | null>) => void;
  index: number;
  gridItemSequence: number;
  localKeys: Array<TCcVariable>;
  postProcessingIndex: number;
  companyId: number | undefined;
  campaignId: number;
  stepId: number;
};

const TemplatePromptVar: FC<Props> = ({
  varKey,
  varKeys,
  setVarKeys,
  index,
  gridItemSequence,
  varType,
  localKeys,
  postProcessingIndex,
  campaignId,
  companyId,
}) => {
  const [inputText, setInputText] = useState<string | null>(null);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const timeout: MutableRefObject<any> = useRef(null);
  const currentValue: MutableRefObject<string> = useRef("");
  const [open, setOpen] = useState(false);
  const inputTagRef = useRef<any>(null);
  const [argumentType, setArgumentType] = useState<TVarType>(varType);
  const [varKeyPlaceholder, setVarKeyPlaceholder] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (inputTagRef.current) {
      inputTagRef.current.focus();
    }
  }, [open]);

  useEffect(() => {
    const inputTextDefaultValue =
      varType === VarTypeEnum.RAW
        ? varKey
          ? varKey.replace(/^"|"$/g, "")
          : varKey
        : null;

    let keyPlaceholder = varKey;

    if (varKey && varType === VarTypeEnum.CAMPAIGN_VAR) {
      const splitKey = varKey.split("/");

      keyPlaceholder = splitKey[splitKey.length - 1];
    }

    setInputText(inputTextDefaultValue);
    setSelectedOption(varType === VarTypeEnum.CAMPAIGN_VAR ? varKey : null);
    setVarKeyPlaceholder(keyPlaceholder);
  }, [varType, varKey, varKeyPlaceholder]);

  const onSelectKey = (value: string) => {
    setSelectedOption(value);

    const newKeys = [...varKeys];

    newKeys[index] = value;

    setVarKeys(newKeys);
    setOpen(false);
    setInputText(null);
  };

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
  };

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;

    if (argumentType === VarTypeEnum.CAMPAIGN_VAR) {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }

      currentValue.current = text;
    } else {
      setInputText(text);
    }
  };

  const onSaveText = () => {
    const newKeys = [...varKeys];

    newKeys[index] = `"${inputText?.trim()}"`;

    setVarKeys(newKeys);
    setOpen(false);

    setSelectedOption(null);
  };

  const getPostProcessingOptions = () => {
    const options = [];

    for (let i = 0; i < postProcessingIndex; i++) {
      options.push({
        label: `Post Processing Key ${i + 1}`,
        value: "${" + (i + 1) + "}",
      });
    }

    return options;
  };

  const getInputSection = () => {
    switch (argumentType) {
      case VarTypeEnum.CAMPAIGN_VAR:
        return (
          <div>
            <TreeSelect
              gridItemSequence={gridItemSequence}
              companyId={companyId}
              campaignId={campaignId}
              onSelect={onSelectKey}
            />
            <div className="ml-2 mt-4 text-gray-700">{selectedOption}</div>
          </div>
        );

      case VarTypeEnum.LOCAL_VAR:
        return (
          <Select
            options={localKeys.map((variable) => ({
              label: variable.id.key,
              value: variable.id.key,
            }))}
            defaultOpen
            autoFocus
            showSearch
            ref={inputTagRef}
            onSelect={onSelectKey}
            placeholder="Choose local key"
            defaultValue={
              varType === VarTypeEnum.LOCAL_VAR ? varKey : undefined
            }
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
          />
        );
      case VarTypeEnum.RAW:
        return (
          <>
            <Input
              placeholder="Enter value"
              onChange={onTextChange}
              value={inputText || undefined}
              ref={inputTagRef}
            />
            <div>
              <Button
                type="primary"
                size="small"
                onClick={onSaveText}
                disabled={!inputText}
              >
                Choose
              </Button>
            </div>
          </>
        );
      case VarTypeEnum.PROMPT_RESULTS_VAR:
        return (
          <>
            <Select
              options={[
                {
                  label: "Source computation result",
                  // eslint-disable-next-line no-template-curly-in-string
                  value: "${0}",
                },
                ...getPostProcessingOptions(),
              ]}
              defaultOpen
              autoFocus
              showSearch
              ref={inputTagRef}
              onSelect={onSelectKey}
              placeholder="Choose previous prompt result"
              defaultValue={
                varType === VarTypeEnum.PROMPT_RESULTS_VAR ? varKey : undefined
              }
              filterOption={(input, option) =>
                (option?.label.toLowerCase() ?? "").includes(
                  input.toLowerCase()
                )
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
            <div>
              <Button
                type="primary"
                size="small"
                onClick={onSaveText}
                disabled={!inputText}
              >
                Choose
              </Button>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <Popover
      destroyTooltipOnHide
      content={
        <Flex
          vertical
          gap="small"
          justify="space-between"
          style={{ height: "76px" }}
        >
          {getInputSection()}
        </Flex>
      }
      title={
        <div className="flex flex-col w-[500px]">
          <span className="mb-2">Choose Argument</span>
          <Segmented
            size="small"
            value={argumentType}
            onChange={(value) => {
              setArgumentType(value as TVarType);
            }}
            options={[
              VarTypeEnum.LOCAL_VAR,
              VarTypeEnum.CAMPAIGN_VAR,
              VarTypeEnum.PROMPT_RESULTS_VAR,
              VarTypeEnum.RAW,
            ]}
          />
        </div>
      }
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Typography.Text
        code
        strong
        className={clsx(
          "font-bold text-[#ff761b] cursor-pointer hover:text-[#a54200] transition-colors",
          {
            "!hover:text-[#002c02] !text-[#007706]": varKey !== null,
          }
        )}
      >
        {varKeyPlaceholder !== null ? varKey : "var"}
      </Typography.Text>
    </Popover>
  );
};

export default TemplatePromptVar;
