import { Modal } from "antd";
import { FC, useState } from "react";
import { DictionaryType, dictionaryApi } from "src/api/dictionary.api";
import { docTemplateApi } from "src/api/document-templates.api";
import { Label } from "src/components/common/ui/label";
import useSubmitFormOnEnter from "src/hooks/useSubmitFormOnEnter";
import SearchableMultiSelect from "../SearchableMultiSelect";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  templateId: number;
  defaultClasses: string[];
  onUpdated?: (newClasses: string[]) => void;
};

const fetchClasses = async (word: string) => {
  const fetchedClasses = await dictionaryApi(DictionaryType.CLASS).find({
    wordMatch: `*${word}*`,
  });
  return fetchedClasses.data.map((item) => ({ label: item, value: item }));
};

export const EditTemplateClassesModal: FC<Props> = ({
  open,
  setOpen,
  templateId,
  defaultClasses = [],
  onUpdated,
}) => {
  const [classes, setClasses] = useState<string[]>(defaultClasses || []);

  const handleSelectClasses = (classes: string[]) => {
    setClasses(classes);
  };

  const handleUpdate = async () => {
    await docTemplateApi(templateId).updateClasses(classes);
    onUpdated?.(classes);
    setOpen(false);
  };

  useSubmitFormOnEnter(() => handleUpdate());

  return (
    <Modal
      title="Edit Classes"
      open={open}
      onOk={handleUpdate}
      okText="Change"
      onCancel={() => setOpen(false)}
      className="w-[700px]"
      maskClosable={false}
    >
      <div className="font-sans flex flex-col gap-3 mt-3">
        <Label>Select Classes</Label>
        <SearchableMultiSelect
          fetchOptions={fetchClasses}
          onSelect={handleSelectClasses}
          value={classes}
        />
      </div>
    </Modal>
  );
};
