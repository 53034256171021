import axios, { AxiosError } from "axios";

// Custom error type
export type TCustomError = {
  message: string;
  originalError: Error;
  errorCode?: string;
  status?: number;
};

type TVEServerError = {
  errorCode: string;
  errors: object;
  message: string;
  status: string;
  success: boolean;
};

export const handleRequestError = (error: Error | AxiosError): TCustomError => {
  if (axios.isAxiosError(error)) {
    const axiosError: AxiosError = error;

    if (axiosError.response) {
      const cmServerError = axiosError.response.data as TVEServerError;

      return {
        message: cmServerError.message || axiosError.message,
        errorCode: cmServerError.errorCode || undefined,
        status: axiosError.response.status,
        originalError: error,
      };
    } else if (axiosError.request) {
      return {
        message: `No response received from the server. Please try again later. Error: ${axiosError.message}`,
        originalError: error,
      };
    }
  }

  return {
    message: error.message,
    originalError: error,
  };
};

export default handleRequestError;
