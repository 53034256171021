import { Spin } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { sftpUsersApi } from "../../../api/sftp-users.api";
import { SftpUser } from "../../../globalTypes";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { getMessageApi } from "../../../store/slices/appSlice";
import handleRequestError from "../../../utils/handleRequestError";
import { DeleteIcon, EyeIcon, EyeSlashIcon, Icons } from "../../common/Icons";
import MenuDropdown, { MenuDropdownItem } from "../../common/MenuDropdown";
import { Button } from "../../common/ui/button";
import { TableCell, TableRow } from "../../common/ui/table";

type FilterFunction = (
  callbackFn: (value: SftpUser, index?: number, array?: SftpUser[]) => boolean,
  thisArg?: any
) => void;
type UpdateFunction = (index: number, value: SftpUser) => void;

type Props = {
  user: SftpUser;
  i: number;
  micrositeId: number;
  filter: FilterFunction;
  updateAt: UpdateFunction;
};

const SftpUserItem = ({ user, i, micrositeId, filter, updateAt }: Props) => {
  const { isRegularUser } = useCurrentUser();
  const [passwordVisibility, setPasswordVisibility] = useState<{
    [key: string]: boolean;
  }>({});
  const messageApi = getMessageApi();
  const [loading, setLoading] = useState(false);
  const { companyId } = useParams();
  const companyIdValidated =
    companyId && companyId !== "global" ? +companyId : undefined;

  const togglePasswordVisibility = (username: string) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [username]: !prevState[username],
    }));
  };

  const hidePassword = (password: string, username: string) => {
    return passwordVisibility[username]
      ? password
      : "*".repeat(password.length);
  };

  const menuItems = [
    !isRegularUser && {
      key: "1",
      label: "Reset",
      onClick: () => {
        handleResetPassword();
      },
      icon: Icons.edit,
      loading: loading,
    },
    !isRegularUser && {
      key: "2",
      label: "Delete",
      onClick: () => {
        handleDelete();
      },
      icon: DeleteIcon,
      className: "!text-red-600",
      loading: loading,
    },
  ].filter(Boolean) as MenuDropdownItem[];

  const handleResetPassword = async () => {
    try {
      setLoading(true);
      const resp = await sftpUsersApi(
        micrositeId,
        companyIdValidated
      ).resetPassword(user.id.username);
      updateAt(i, resp.data);
      messageApi.success("sFTP User password reset successfully");
    } catch (error: any) {
      const customError = handleRequestError(error);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await sftpUsersApi(micrositeId, companyIdValidated).delete(
        user.id.username
      );

      filter((u) => u.id.username !== user.id.username);
      messageApi.success("sFTP User deleted successfully");
    } catch (error: any) {
      const customError = handleRequestError(error);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TableRow className="h-12" key={user.id.username}>
      <TableCell className="font-sans text-[#0F172A]">
        {user.id.username}
      </TableCell>
      <TableCell>
        <div className="flex items-center">
          <span className="font-mono mr-2 text-[#475569]">
            {hidePassword(user.password, user.id.username)}
          </span>
          {passwordVisibility[user.id.username] ? (
            <EyeSlashIcon
              className="size-4 cursor-pointer text-[#475569]"
              onClick={() => togglePasswordVisibility(user.id.username)}
            />
          ) : (
            <EyeIcon
              className="size-4 cursor-pointer text-[#475569]"
              onClick={() => togglePasswordVisibility(user.id.username)}
            />
          )}
        </div>
      </TableCell>
      <TableCell className="text-[#475569]">{user.sftpEndpoint}</TableCell>
      <TableCell className="relative" onClick={(e) => e.stopPropagation()}>
        {loading ? (
          <Spin />
        ) : (
          <div className="flex justify-end items-center">
            <MenuDropdown
              items={menuItems}
              dropdownClassName="w-[190px]"
              align="end"
            >
              <Button variant={"ghostPrimary"} className="size-[40px] p-2">
                <Icons.MoreDots className="size-[24px]" />
              </Button>
            </MenuDropdown>
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};

export default SftpUserItem;
