import { clsx, type ClassValue } from "clsx";
import { DropResult } from "react-beautiful-dnd";
import { TPhase } from "src/globalTypes";
import { TStep } from "src/store/slices/stepsSlice";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const calcNewSeqDnd = (
  result: DropResult,
  items: Pick<TStep | TPhase, "seq">[],
  step: number
) => {
  if (!result.destination) return;

  const {
    source: { index: sourceIndex },
    destination: { index: destinationIndex },
  } = result;

  const isAfterPhase = destinationIndex > sourceIndex;

  const newDraggedItemSeq = isAfterPhase
    ? typeof items[destinationIndex + 1]?.seq === "number"
      ? (items[destinationIndex].seq + items[destinationIndex + 1]?.seq) / 2
      : items[destinationIndex].seq + step
    : typeof items[destinationIndex - 1]?.seq === "number"
      ? (items[destinationIndex - 1]?.seq + items[destinationIndex].seq) / 2
      : items[destinationIndex].seq - step;

  return newDraggedItemSeq;
};
