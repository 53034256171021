import { Link } from "react-router-dom";

type TTooltipTitleProps = {
  display: boolean;
  isGlobalAdmin: boolean;
};

export const tooltipTitle = ({
  display,
  isGlobalAdmin,
}: TTooltipTitleProps) => {
  return display ? (
    <div className="text-black text-sm">
      There are no templates for this class. Please ask an admin to add a class
      template from the template library{" "}
      {isGlobalAdmin ? (
        <Link to="/admin/document-templates">Velocity Engine</Link>
      ) : (
        <span>Velocity Engine</span>
      )}
    </div>
  ) : null;
};
