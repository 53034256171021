import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setDefaultSystemModelConfigApi } from "src/api/model-configs.api";
import { DeleteIcon, Icons } from "src/components/common/Icons";
import MenuDropdown, {
  MenuDropdownItem,
} from "src/components/common/MenuDropdown";
import { Button } from "src/components/common/ui/button";
import { TableCell, TableRow } from "src/components/common/ui/table";
import useConfirm from "src/hooks/useConfirm";
import { useModelConfigsList } from "src/hooks/useModelConfigsList";
import { getMessageApi } from "src/store/slices/appSlice";
import { ModelConfig } from "src/types/modelConfigs";
import DeleteModelConfigModal from "../common/modals/DeleteModelConfigModal/DeleteModelConfigModal";
import { Checkbox } from "../common/ui/checkbox";

type Props = {
  item: ModelConfig;
};

const ModelConfigListItem: FC<Props> = ({ item }) => {
  const {
    id,
    title,
    serviceProvider,
    modelName,
    readTimoutSec,
    systemDefault,
    maxTokens,
    topP,
    temperature,
  } = item;
  const navigate = useNavigate();
  const messageApi = getMessageApi();
  const confirm = useConfirm();
  const { load, updateSystemDefault } = useModelConfigsList();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const navigateToEdit = () => {
    navigate(`/admin/model-configs/edit/${id}`);
  };

  const menuItems = [
    {
      key: "1",
      label: "Edit",
      onClick: navigateToEdit,
      icon: Icons.edit,
    },
    !systemDefault && {
      key: "2",
      label: "Delete",
      onClick: () => {
        setIsDeleteModalOpen(true);
      },
      icon: DeleteIcon,
      className: "!text-red-600",
    },
  ].filter(Boolean) as MenuDropdownItem[];

  const handleSetSystemDefault = async () => {
    try {
      await setDefaultSystemModelConfigApi(id);
      updateSystemDefault(id);
      messageApi.success(`${title} set as system default`);
    } catch (error) {
      console.error("Error setting system default model config", error);
      messageApi.error("Error setting system default model config");
    }
  };

  return (
    <>
      <TableRow
        key={id}
        className="cursor-pointer relative"
        onClick={navigateToEdit}
      >
        <TableCell>
          <span className="text-slate-900 text-sm font-medium font-sans">
            {title}
          </span>
        </TableCell>
        <TableCell>
          <span className="text-slate-900 text-sm font-medium font-sans">
            {serviceProvider || "-"}
          </span>
        </TableCell>
        <TableCell>
          <span className="text-slate-900 text-sm font-medium font-sans">
            {modelName || "-"}
          </span>
        </TableCell>
        <TableCell>
          <span className="text-slate-900 text-sm font-medium font-sans">
            {readTimoutSec}
          </span>
        </TableCell>
        <TableCell>
          <span className="text-slate-900 text-sm font-medium font-sans">
            {maxTokens}
          </span>
        </TableCell>
        <TableCell>
          <span className="text-slate-900 text-sm font-medium font-sans">
            {topP}
          </span>
        </TableCell>
        <TableCell>
          <span className="text-slate-900 text-sm font-medium font-sans">
            {temperature}
          </span>
        </TableCell>
        <TableCell>
          <div className="text-slate-900 text-sm font-medium font-sans relative">
            <Checkbox
              onClick={(e) => {
                e.stopPropagation();
                if (systemDefault) {
                  confirm({
                    title: "Already System Default",
                    action: () => {},
                    content:
                      "You cannot deselect this model config, you need to select another model config as system default.",
                  });
                  return;
                }
                confirm({
                  title: "Set System Default",
                  action: handleSetSystemDefault,
                });
              }}
              checked={systemDefault}
            />
          </div>
        </TableCell>
        <TableCell
          className="flex items-center justify-end"
          onClick={(e) => e.stopPropagation()}
        >
          <MenuDropdown
            items={menuItems}
            dropdownClassName="w-[190px]"
            align="end"
          >
            <Button
              variant={"ghostPrimary"}
              className="size-[40px] p-2 flex justify-end"
            >
              <Icons.MoreDots className="size-[24px]" />
            </Button>
          </MenuDropdown>
        </TableCell>
      </TableRow>
      {isDeleteModalOpen && (
        <DeleteModelConfigModal
          setIsModalOpen={setIsDeleteModalOpen}
          isModalOpen={isDeleteModalOpen}
          aiModelId={item.id}
          onDeleted={() => {
            load();
          }}
        />
      )}
    </>
  );
};

export default ModelConfigListItem;
