import { Spin, Tooltip } from "antd";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getMessageApi } from "../../../../store/slices/appSlice";
import { deleteCampaignLabelsThunk, saveCampaignLabelThunk, } from "../../../../store/slices/campaignLabelsSlice";
import { AppDispatch } from "../../../../store/store";
import { TGridItemTitle } from "../../CampaignGrid";
import TextArea from "antd/es/input/TextArea";

type TPropsType = {
  itemKey: string;
  title: TGridItemTitle;
};

const GridItemTitle = (props: TPropsType) => {
  const { itemKey, title } = props;
  const { value: titleValue, scope } = title;
  const dispatch: AppDispatch = useDispatch();
  const messageApi = getMessageApi();
  const [value, setValue] = useState(titleValue);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const onBlur = async () => {
    setIsOpened(false);

    if (value === titleValue) return;

    try {
      setIsLoading(true);

      const lang = "en";
      let res: any;

      if (!value) {
        res = await dispatch(
          deleteCampaignLabelsThunk({ keys: [itemKey], lang })
        );
      } else {
        //if global label and !titleValue.length - need POST request, otherwise - PUT
        const action =
          scope === "global"
            ? "create"
            : titleValue.length
              ? "update"
              : "create";

        res = await dispatch(
          saveCampaignLabelThunk({
            label: value,
            key: itemKey,
            lang: lang,
            action,
          })
        );
      }

      if ("error" in res) {
        messageApi.error(res.payload?.message);
      } else {
        messageApi.success("Successfully saved");
      }
    } catch (e: any) {
      console.error(e?.message);
      setValue(titleValue);
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > 26) {
      setValue(e.target.value.substring(0, 26));
      setIsOpened(true);
    } else {
      setValue(e.target.value);
      setIsOpened(false);
    }
  };

  return (
    <div ref={ref} className={`p-[3px]`}>
      <Tooltip
        title="The maximum possible number of characters is 26!"
        color={"red"}
        open={isOpened}
      >
        <TextArea
          autoSize
          value={value.length ? value : undefined}
          placeholder="not defined"
          onBlur={onBlur}
          onChange={onChange}
          disabled={isLoading}
          className="hover:border-indigo-600 border-transparent"
        />
      </Tooltip>
      <Spin
        spinning={isLoading}
        className={
          "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        }
      />
    </div>
  );
};

export default GridItemTitle;
