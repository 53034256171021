import { Editor } from "@monaco-editor/react";
import { FormInstance, Upload, UploadProps } from "antd";
import clsx from "clsx";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { XSLTEditorEnum, XSLTEditorTabItem } from "../../../types/docTemplates";
import { downloadTextFile } from "../../../utils/cm.utils";
import { DownloadIcon, UploadIcon } from "../../common/Icons";
import { Button } from "../../common/ui/button";
import TemplatePreview from "../TemplatePreview/TemplatePreview";
import TemplateSettingsForm, {
  TemplateSettingForm,
} from "../TemplateSettingsForm";

type Props = {
  setXSLTCode: React.Dispatch<React.SetStateAction<string | undefined>>;
  XSLTCode: string | undefined;
  setTab: React.Dispatch<React.SetStateAction<XSLTEditorEnum>>;
  tab: XSLTEditorEnum;
  //insert 3rd tab with settings if form provided
  form?: FormInstance<TemplateSettingForm>;
  className?: string;
  mediaType?: string | undefined;
};

const XSLTCodeEditor: FC<Props> = ({
  XSLTCode,
  setXSLTCode,
  tab,
  setTab,
  className,
  form,
  mediaType,
}) => {
  const messageApi = useSelector(selectMessageApi);

  const props: UploadProps = {
    name: "file",
    multiple: false,
    accept: ".xml",
    showUploadList: false,
    beforeUpload(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const content = e.target?.result;

        if (typeof content === "string") {
          setXSLTCode(content);
        }
      };

      reader.readAsText(file);

      return false;
    },
  };
  const onDownload = () => {
    if (!XSLTCode) {
      messageApi.error("Error, no data found!");
      return;
    }

    downloadTextFile({
      data: XSLTCode,
      fileName: `XSLT_template_${new Date().getTime()}.xml`,
      type: "text/xml",
    });
  };

  const tabsItems = [
    {
      onSelect: setTab,
      title: XSLTEditorEnum.CODE,
      active: tab === XSLTEditorEnum.CODE,
    },
    form && {
      onSelect: setTab,
      title: XSLTEditorEnum.SETTINGS,
      active: tab === XSLTEditorEnum.SETTINGS,
    },
    {
      onSelect: setTab,
      title: XSLTEditorEnum.PREVIEW,
      active: tab === XSLTEditorEnum.PREVIEW,
    },
  ].filter(Boolean) as XSLTEditorTabItem[];

  return (
    <div className={className || "w-[49%]"}>
      <h2 className="text-slate-900 text-1xl font-semibold font-sans leading-normal mb-[6px]">
        XSLT editor
      </h2>
      <div className="flex justify-between overflow-x-scroll">
        <Tabs items={tabsItems} />

        {tab === XSLTEditorEnum.CODE ? (
          <div className="flex gap-[16px] items-center">
            <div className="h-[20px]">
              <Upload {...props}>
                <Button
                  variant="text"
                  size="text"
                  className="text-[#475569]"
                  iconClassName="h-4 w-4 mr-[2px]"
                  icon={UploadIcon}
                >
                  Upload
                </Button>
              </Upload>
            </div>

            <div className="h-[20px]">
              <Button
                variant="text"
                size="text"
                className="text-[#475569]"
                iconClassName="h-4 w-4 mr-[2px]"
                icon={DownloadIcon}
                onClick={onDownload}
                disabled={!XSLTCode}
              >
                Download
              </Button>
            </div>
          </div>
        ) : null}
      </div>

      <div className="h-[740px] relative">
        <Editor
          options={{
            minimap: {
              enabled: false,
            },
          }}
          theme="vs-dark"
          defaultLanguage="xml"
          value={XSLTCode}
          onChange={setXSLTCode}
          className={`absolute top-0 left-0 h-full ${tab === XSLTEditorEnum.CODE ? "visible" : "hidden"}`}
        />
        {form && (
          <TemplateSettingsForm
            className={`absolute top-0 left-0 h-full w-full p-[46px] border-2 rounded-tr-lg rounded-br-lg rounded-bl-lg ${tab === XSLTEditorEnum.SETTINGS ? "visible" : "hidden"}`}
            form={form}
          />
        )}
        <TemplatePreview
          content={XSLTCode}
          className={tab === XSLTEditorEnum.PREVIEW ? "visible" : "hidden"}
          mediaType={mediaType}
        />
      </div>
    </div>
  );
};

export default XSLTCodeEditor;

const Tabs: FC<{ items: XSLTEditorTabItem[] }> = ({ items }) => {
  if (!items.length) return null;

  return (
    <div className="flex">
      {items.map(({ active, onSelect, title }) => {
        return (
          <div
            key={title}
            className={clsx(
              "h-[45px] px-4 items-center flex cursor-pointer transition-colors text-[#334155] text-sm font-semibold font-sans leading-[14px] border-t-[3px] border-transparent",
              {
                "bg-slate-50 hover:bg-primary/10": !active,
                "bg-indigo-50 !border-primary": active,
              }
            )}
            onClick={() => onSelect(title)}
          >
            {title}
          </div>
        );
      })}
    </div>
  );
};
