import { Button, Form, Input, Spin } from "antd";
import React, { Dispatch, FC, SetStateAction } from "react";
import { useSelector } from "react-redux";
import {
  createCcVarApi,
  redeployCCItemApi,
} from "../../../api/cc-variables.api";
import { ChangeNodeProps } from "../../../pages/graph/hooks/useGetNodes.hook";
import { selectMessageApi } from "../../../store/slices/appSlice";
import {
  TCcVariable,
  TCcVarsFormValues,
} from "../../../store/slices/ccVariablesSlice";
import handleRequestError from "../../../utils/handleRequestError";
import saveCCItemWithRepublish from "../../../utils/saveCCItemWithRepublish";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import DynamicCCForm from "../../dynamicForms/DynamicCCForm";
import useGetDynamicData from "./useGetDynamicData.hook";

type Props = {
  ccItem: TCcVariable;
  nodeType: "TARGET" | "INPUT";
  ccItemPhaseId: number;
  changeNodeProps?: ChangeNodeProps;
  setItem: Dispatch<SetStateAction<TCcVariable | null>>;
};

const PaneDynamicForm: FC<Props> = ({
  ccItem,
  nodeType,
  ccItemPhaseId,
  changeNodeProps,
  setItem,
}) => {
  const { id, seq, type, result, options } = ccItem;
  const {
    ccVarData,
    form,
    dynamicItems,
    companyId,
    stepCCVars,
    isFetching,
    setIsFetching,
  } = useGetDynamicData(ccItem);
  const messageApi = useSelector(selectMessageApi);

  //TODO move to util and reuse
  const customizeRequiredMark = (
    label: React.ReactNode,
    { required }: { required: boolean }
  ) => (
    <span className="font-sans font-bold text-[#475569] text-[14px] flex">
      {label}
      {required && <span className="text-red-500 ml-1">*</span>}
    </span>
  );

  const onSaveModel = async ({
    values,
    republish = false,
  }: {
    republish?: boolean;
    values: TCcVarsFormValues;
  }) => {
    try {
      setIsFetching(true);

      const { data } = await createCcVarApi(
        id.campaignId,
        values,
        id.stepId,
        seq,
        "update",
        companyId
      );

      if (republish) {
        await redeployCCItemApi({
          campaignId: id.campaignId,
          stepId: id.stepId,
          key: values.key,
          companyId,
        });
      }

      setItem(data);
      changeNodeProps?.({
        nodeId: `${id.key}_${id.stepId}`,
        props: { touched: true, state: data.state },
      });
      messageApi.success("The model was successfully saved");
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TCcVarsFormValues) => {
      const postProcessingParams = values.postProcessingParams;
      if (postProcessingParams) {
        values.postProcessingParams = JSON.stringify(postProcessingParams);
      }

      await saveCCItemWithRepublish({
        values,
        saveCCItem: onSaveModel,
        ccItem: ccVarData.variableData,
      });
    },
  });

  return (
    <Spin spinning={isFetching}>
      <Form
        form={form}
        layout="vertical"
        name={`cc_variable_graph_${nodeType}_form`}
        size="small"
        requiredMark={customizeRequiredMark}
        initialValues={{
          //set initial form values for dynamic items
          ...options,
        }}
      >
        <Form.Item name="type" noStyle initialValue={type}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="key" noStyle initialValue={id.key}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="result" noStyle initialValue={result}>
          <Input type="hidden" />
        </Form.Item>

        <DynamicCCForm
          hidden={false}
          form={form}
          items={dynamicItems}
          ccVarData={ccVarData}
          gridItemSequence={seq}
          companyId={companyId}
          campaignId={id.campaignId}
          stepId={id.stepId}
          phaseId={ccItemPhaseId}
          stepCCVars={stepCCVars}
        />
      </Form>
      <Button
        size="small"
        type="primary"
        className="absolute bottom-[8px] right-[8px]"
        onClick={submitForm}
        loading={isFetching}
      >
        Save Model
      </Button>
    </Spin>
  );
};

export default PaneDynamicForm;
