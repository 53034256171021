import { FC } from "react";
import { useDispatch } from "react-redux";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { ALL_COMPANIES_ID } from "src/pages/campaigns/hooks/useCampaignsList";
import { TCompany, setCurrentCompanyId, useCompaniesList } from "../../store/slices/companiesSlice";
import { CustomSelect } from "../custom/Select";
import { AppDispatch } from "src/store/store";

export type TChooseCompanySelectOption = {
  value: number | string;
  label: string;
  company: TCompany | null;
};

type TCompanySelectorProps = {
  companyId: number | null;
  onChanged: (companyId: string) => void;
};

const CompanySelector: FC<TCompanySelectorProps> = ({
  companyId,
  onChanged,
}) => {
  const companies = useCompaniesList();
  const dispatch = useDispatch<AppDispatch>();
  const { isGlobalAdmin } = useCurrentUser();

  const handleChangeCompany = (companyId: string) => {
    if (companyId !== ALL_COMPANIES_ID) {
      dispatch(setCurrentCompanyId(Number(companyId)));
    }
    onChanged(companyId);
  };

  const options: TChooseCompanySelectOption[] = [
    ...(isGlobalAdmin
      ? [{ value: ALL_COMPANIES_ID, label: "All Companies", company: null }]
      : []),
    ...companies.map((company) => ({
      value: company.id,
      label: company.name,
      company,
    })),
  ];

  return (
    <CustomSelect
      options={options.map((option) => ({
        value: String(option.value),
        label: option.label,
      }))}
      value={companyId?.toString() || ALL_COMPANIES_ID}
      onChange={handleChangeCompany}
      label="Select Company"
      className="w-[180px]"
    />
  );
};

export default CompanySelector;
