import { AxiosResponse } from "axios";
import { PROXY_PREFIX } from "src/constants";
import { TCampaignListOptionType } from "../store/slices/campaignsListSlice";
import {
  TCampaign,
  TCampaignResponseData,
  TCampaignType,
} from "../store/slices/campaignsSlice";
import axiosInstance from "../utils/axios/axiosInstance";
import { TCampaignData } from "../utils/transformCampaignData";

//TODO: move variable to project constants
const CM_API_PREFIX = "/api/secure/campaignManager";
const cmManagerApiKey = process.env.REACT_APP_CM_SUPER_USER_API_KEY;
const cmApiUrl = process.env.REACT_APP_CM_API_URL;

type TGetCampaignsRes = {
  items: Array<TCampaignResponseData>;
  totalItems: number;
};

type TGetCampaignsProps = {
  companyId: number | null;
  currentPage: number;
  pageSize: number;
  campaignType: TCampaignListOptionType;
};

//TODO replace getCampaignsApi with getCampaignsApi_proxy
export const getCampaignsApi = (
  props: TGetCampaignsProps
): Promise<AxiosResponse<TGetCampaignsRes>> => {
  const { currentPage, pageSize, companyId, campaignType } = props;

  return axiosInstance.get(`${CM_API_PREFIX}/global/campaign`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      pageNum: currentPage - 1,
      type: campaignType !== "ALL" ? campaignType : undefined,
      companyId,
      pageSize,
    },
  });
};

export const getCampaignsApi_proxy = ({
  currentPage = 1,
  pageSize,
  companyId,
  extCompanyId,
  campaignType,
  name,
  aiModelId,
}: {
  currentPage?: number;
  pageSize?: number;
  extCompanyId?: number | undefined;
  campaignType?: TCampaignListOptionType;
  companyId?: number | undefined;
  name?: string;
  aiModelId?: number;
}): Promise<AxiosResponse<TGetCampaignsRes>> => {
  return axiosInstance.get(`${PROXY_PREFIX}/api/secured/global/campaign/`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      extCompanyId,
      pageNum: currentPage - 1,
      type: campaignType !== "ALL" ? campaignType : undefined,
      pageSize,
      name,
      companyId,
      aiModelId,
    },
  });
};

export const getCampaignsDataApi = (
  campaignId: number,
  companyId?: number
): Promise<AxiosResponse<TCampaignData>> => {
  return axiosInstance.get(`${CM_API_PREFIX}/campaign/${campaignId}/data`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      companyId,
    },
  });
};

export const createCampaignApi = (
  name: string,
  type: TCampaignType,
  extCompanyId?: number
): Promise<AxiosResponse<TCampaign>> => {
  return axiosInstance.post(
    `${CM_API_PREFIX}/campaign`,
    { type, name, extCompanyId },
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId: extCompanyId,
      },
    }
  );
};

export const deleteCampaignApi = (
  campaignId: number,
  companyId: number | undefined,
  removeFiles: boolean
): Promise<AxiosResponse<string>> => {
  return axiosInstance.delete(`${CM_API_PREFIX}/campaign/${campaignId}`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      companyId,
      removeFiles,
    },
  });
};

type TCloneCampaignProps = {
  campaignId: number;
  name: string;
  resetResult: boolean;
  resetOvr: boolean;
  companyId: number | undefined;
};

export const cloneCampaignApi = ({
  campaignId,
  name,
  resetResult,
  resetOvr,
  companyId,
}: TCloneCampaignProps): Promise<AxiosResponse<TCampaign>> => {
  return axiosInstance.put(
    `${CM_API_PREFIX}/campaign/${campaignId}/clone`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        name,
        resetResult,
        resetOvr,
        companyId,
      },
    }
  );
};

export const publishCampaignApi = (
  campaignId: number,
  companyId: number | undefined
): Promise<AxiosResponse<TCampaign>> => {
  return axiosInstance.put(
    `${CM_API_PREFIX}/campaign/${campaignId}/publish/`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const unPublishCampaignApi = (
  campaignId: number,
  companyId: number | undefined
): Promise<AxiosResponse<TCampaign>> => {
  return axiosInstance.put(
    `${CM_API_PREFIX}/campaign/${campaignId}/un-publish/`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const createCampaignFromTemplateApi = ({
  campaignId,
  campaignName,
  companyId,
  companyIdToCreate,
  resetOvr,
  resetResult,
}: {
  campaignId: number;
  companyIdToCreate: number;
  campaignName: string;
  resetOvr: boolean;
  resetResult: boolean;
  companyId: number | undefined;
}): Promise<AxiosResponse<TCampaign>> => {
  return axiosInstance.post(
    `${PROXY_PREFIX}/api/secured/campaign/${campaignId}/startCampaign/${companyIdToCreate}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        name: campaignName,
        resetOvr,
        resetResult,
        companyId,
      },
    }
  );
};

type TImportCampaignRes = Omit<TCampaign, "extCompanyName">;
export const importCampaignApi = (
  formData: FormData,
  companyIdToImport: number,
  companyId: number | undefined
): Promise<AxiosResponse<TImportCampaignRes>> => {
  return axiosInstance.post(
    `${CM_API_PREFIX}/campaign/${companyIdToImport}/import`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        companyId,
      },
    }
  );
};

export const stopExecuteCampaignApi = (
  campaignId: number,
  companyId: number | undefined
): Promise<AxiosResponse<string>> => {
  return axiosInstance.post(
    `${CM_API_PREFIX}/campaign/${campaignId}/computation/campaign/stop`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const editCampaignApi = (
  campaignData: TCampaign,
  companyId?: number | undefined
): Promise<AxiosResponse<string>> => {
  return axiosInstance.put(
    `${CM_API_PREFIX}/campaign/`,
    { ...campaignData },
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const exportCampaignApi = (
  campaignId: number,
  params: string,
  companyId: number | undefined
): Promise<AxiosResponse<Blob>> => {
  // return axiosInstance.get(`${CM_API_PREFIX}/campaign/${campaignId}/export`, {
  return axiosInstance.get(`${cmApiUrl}/api/secured/campaign/${campaignId}/export${params}`, {
    responseType: "blob",
    headers: {
      "Content-Type": "application/json",
      "api-key": cmManagerApiKey,
    },
    // params: {
    //   params,
    //   companyId,
    // },
  });
};

export const publishCampaignToMicroSiteApi = ({
  campaignId,
  companyId,
}: {
  campaignId: number;
  companyId: number | undefined;
}): Promise<AxiosResponse<string>> => {
  return axiosInstance.put(
    `${CM_API_PREFIX}/campaign/${campaignId}/microsite-deploy`,
    {},
    {
      headers: { "Content-Type": "application/json" },
      params: { companyId },
    }
  );
};

export const redeployCampaignToMicroSiteApi = ({
  campaignId,
  companyId,
}: {
  campaignId: number;
  companyId: number | undefined;
}): Promise<AxiosResponse<string>> => {
  return axiosInstance.put(
    `${PROXY_PREFIX}/api/secured/campaign/${campaignId}/microsite-redeploy/`,
    {},
    {
      headers: { "Content-Type": "application/json" },
      params: { companyId },
    }
  );
};

export const campaignApi = (campaignId: number, companyId?: number) => ({
  updateCompany: (newCompanyId: number) =>
    axiosInstance.put(
      `${PROXY_PREFIX}/api/secured/campaign/${campaignId}/company/`,
      {
        extCompanyId: newCompanyId,
      },
      {
        params: { companyId },
      }
    ),
});

export const getCampaignDataApi = ({
  campaignId,
  companyId,
}: {
  campaignId: number | string;
  companyId: number | undefined;
}): Promise<AxiosResponse<TCampaign>> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/campaign/${campaignId}`,
    {
      headers: { "Content-Type": "application/json" },
      params: { companyId },
    }
  );
};
