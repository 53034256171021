import {
  CheckIcon,
  EyeSlashIcon,
  ListBulletIcon,
} from "@heroicons/react/16/solid";
import { EyeIcon } from "@heroicons/react/20/solid";
import { FormInstance, Tooltip } from "antd";
import { Rule } from "antd/es/form";
import clsx from "clsx";
import React, { FC, useEffect, useState } from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { computationApi } from "src/api/computation.api";
import {
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  CloneIcon,
  DeleteIcon,
  DocumentIcon,
  ExecuteIcon,
  ExportDocIcon,
  GlobeIcon,
  GridIcon,
  Icons,
  InsertAfterIcon,
  InsertBeforeIcon,
} from "src/components/common/Icons";
import { MenuDropdownItem } from "src/components/common/MenuDropdown";
import StepMenuDropdown from "src/components/common/StepMenuDropdown";
import { ComputationType } from "src/constants";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { useUiTemplatesOptions } from "src/hooks/useTemplatesOptions";
import {
  selectCurrentCampaignView,
  setCurrentCampaignView,
  updateCampaignView,
} from "src/store/slices/campaignsSlice";
import { CampaignViewTypes } from "src/types";
import {
  copyStepToApi,
  exportCSVStepApi,
  publishStepToMicroSiteApi,
} from "../../../api/steps.api";
import useConfirm from "../../../hooks/useConfirm";
import useModals from "../../../hooks/useModals";
import { getMessageApi } from "../../../store/slices/appSlice";
import {
  TStep,
  cloneStepThunk,
  deleteStepThunk,
  selectCurrentStep,
  setCurrentStep,
  swapStepSequenceThunk,
  updateHiddenDocViewThunk,
} from "../../../store/slices/stepsSlice";
import { UserPermissions } from "../../../store/slices/usersSlice";
import { AppDispatch } from "../../../store/store";
import { DocumentTemplateState } from "../../../types/docTemplates";
import { STEP_SEQUENCE_STEP } from "../../../utils/campaigns.constant";
import { downloadTextFile } from "../../../utils/cm.utils";
import handleRequestError from "../../../utils/handleRequestError";
import CloneEntityModal, {
  TCloneEntityFormValues,
} from "../../common/modals/CloneEntityModal/CloneEntityModal";
import DeleteWithFilesModal from "../../common/modals/DeleteWithFilesModal/DeleteWithFilesModal";
import { TStepFormValues } from "../CampaignSteps/CampaignSteps";
import { TCloneToStepFormValues } from "../CloneToStepModal/CloneToStepModal";
import { resetComputationMessages } from "../../../store/slices/computationMessagesSlice";

type Props = {
  step: TStep;
  stepIndex: number;
  prevStepSeq: number | undefined;
  nextStepSeq: number | undefined;
  phaseId: number;
  companyId: number | undefined;
  campaignId: number;
  stepNameValidationRule: Rule;
  form: FormInstance<TStepFormValues>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEditStep: React.Dispatch<React.SetStateAction<TStep | null>>;
  setSequence: React.Dispatch<React.SetStateAction<number | null>>;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
};

const Step: FC<Props> = ({
  step,
  stepIndex,
  form,
  setIsModalOpen,
  setEditStep,
  setSequence,
  prevStepSeq,
  nextStepSeq,
  campaignId,
  phaseId,
  companyId,
  stepNameValidationRule,
  dragHandleProps,
}) => {
  const { execute, cloneToStep, stepTemplatePreview, modal } = useModals();
  const currentCampaignView = useSelector(selectCurrentCampaignView);
  const dispatch: AppDispatch = useDispatch();
  const currentStep = useSelector(selectCurrentStep);
  const confirm = useConfirm();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
  const messageApi = getMessageApi();
  const navigate = useNavigate();
  const {
    isGlobalAdmin,
    hasPermission,
    hasStepCreateRole,
    hasStepEditRole,
    hasStepCloneRole,
    hasStepDeleteRole,
    hasStepDeployRole,
    hasStepCopyRole,
  } = useCurrentUser();
  const isCurrentStep = currentStep?.id === step.id;
  const companyIdForUrl = companyId === undefined ? "global" : companyId;

  const hasCSVExportRole =
    isGlobalAdmin || hasPermission(UserPermissions.ROLE_CAMPAIGN_CSV_EXPORT);
  const hasExecuteStepRole =
    isGlobalAdmin ||
    hasPermission(UserPermissions.ROLE_CAMPAIGN_CC_COMPUTATION);

  const { options, load, selectedOption } = useUiTemplatesOptions({
    displayTitle: false,
  });
  useEffect(() => {
    if (currentStep?.id === step.id) {
      load({
        states: [DocumentTemplateState.PUBLISHED],
        classes: step.classes || [],
        stepId: step.id,
        companyId,
      });
    }
  }, [currentStep?.id, companyId]);

  const onDelete = async (removeFiles: boolean) => {
    try {
      const res = await dispatch(
        deleteStepThunk({
          stepId: step.id,
          phaseId,
          removeFiles,
          stepIndex,
          companyId,
        })
      );

      if ("error" in res) {
        messageApi.error(res.payload?.message);
      } else {
        let path = `/admin/campaigns/company/${companyIdForUrl}/campaign/${campaignId}/phase/${phaseId}`;

        const newStepId = res.payload.stepId;

        if (newStepId) {
          path += `/step/${newStepId}`;
        }

        navigate(path, { replace: true });
        messageApi.success("The step has been successfully removed");
      }
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(e);
    }
  };

  const handleSelectStep = async () => {
    dispatch(setCurrentStep(step));
    navigate(
      `/admin/campaigns/company/${companyIdForUrl}/campaign/${campaignId}/phase/${phaseId}/step/${step.id}`,
      { replace: true }
    );
  };

  const onEdit = () => {
    form.setFieldsValue({
      name: step.name,
      hidden: step.hidden,
      microSiteContextFolder: step.microSiteContextFolder,
    });

    setEditStep(step);
    setIsModalOpen(true);
  };

  const onAddBefore = () => {
    const sequence =
      prevStepSeq !== undefined
        ? prevStepSeq + Math.floor((step.seq - prevStepSeq) / 2)
        : step.seq - STEP_SEQUENCE_STEP;

    setSequence(sequence);
    setIsModalOpen(true);
  };

  const onAddAfter = () => {
    const sequence =
      nextStepSeq !== undefined
        ? step.seq + Math.floor((nextStepSeq - step.seq) / 2)
        : step.seq + STEP_SEQUENCE_STEP;

    setSequence(sequence);
    setIsModalOpen(true);
  };

  const onExportCSV = async () => {
    try {
      const { data } = await exportCSVStepApi(phaseId, step.id, companyId);
      const csvData = data.data;

      downloadTextFile({
        data: csvData,
        fileName: `${step.name}.csv`,
        type: "text/csv",
      });

      messageApi.success("The CSV file has been successfully downloaded");
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const publishToMicroSite = async () => {
    try {
      await publishStepToMicroSiteApi(phaseId, step.id, companyId);

      messageApi.success(
        "The step has been successfully published to micro site"
      );
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onMove = async (seqToSwap: number | undefined) => {
    if (seqToSwap === undefined) return;

    try {
      const res = await dispatch(
        swapStepSequenceThunk({
          currentStep: step,
          seqToSwap,
        })
      );

      if ("error" in res) {
        messageApi.error(res.payload?.message);
      } else {
        messageApi.success("The step has been successfully moved");
      }
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(e);
    }
  };

  const onClone = async (values: TCloneEntityFormValues) => {
    try {
      const res = await dispatch(cloneStepThunk({ step, params: values }));

      if ("error" in res) {
        messageApi.error(res.payload?.message);
      } else {
        setIsCloneModalOpen(false);
        messageApi.success("The step has been successfully cloned");
      }
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(e);
    }
  };

  const onCopyTo = async (values: TCloneToStepFormValues) => {
    try {
      const {
        companyId: targetCompanyId,
        campaignId: targetCampaignId,
        targetStepSeq,
        name,
        resetResult,
        resetOvr,
        phaseId: targetPhase,
      } = values;

      const { data } = await copyStepToApi({
        companyId,
        newSeq: targetStepSeq,
        name,
        phaseId,
        targetPhase,
        stepId: step.id,
        resetOvr,
        resetResult,
      });

      navigate(
        `/admin/campaigns/company/${targetCompanyId}/campaign/${targetCampaignId}/phase/${data.phaseId}/step/${data.id}`,
        { replace: true }
      );
    } catch (e: any) {
      let error = e;

      if (e?.response?.data instanceof Blob) {
        try {
          error = JSON.parse(await e.response.data.text());
        } catch (e) {
          console.error("Can't get and parse error response from Blob:", e);
        }
      }

      const customError = handleRequestError(error);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const onTemplatePreview = async () => {
    try {
      messageApi.open({
        key: "get_preview_templates",
        type: "loading",
        content: "Loading templates...",
      });

      if (!options) {
        messageApi.error("No templates found");
        return;
      }

      stepTemplatePreview({
        stepId: step.id,
        phaseId,
        options,
        companyId,
      });

      messageApi.open({
        key: "get_preview_templates",
        type: "success",
        content: "The templates have been loaded successfully",
      });
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      messageApi.open({
        key: "get_preview_templates",
        type: "error",
        content: customError.message,
      });
      console.error(customError);
    }
  };

  const itemsBeforeViews = [
    hasExecuteStepRole && {
      label: "Execute",
      key: "1",
      icon: ExecuteIcon,
      onClick: () =>
        execute({
          title: "Execute Step",
          requestExecute: async (
            computationType: ComputationType,
            republish: boolean
          ) => {
            try {
              const { data: isExecuting } = await computationApi(
                campaignId,
                companyId
              ).validateStep({
                phaseId,
                stepId: step.id,
              });

              if (!isExecuting) {
                await computationApi(campaignId, companyId).executeStep(
                  step.id,
                  computationType,
                  republish
                );

                dispatch(resetComputationMessages());
                messageApi.success("Execution started successfully");
              } else {
                messageApi.error(
                  "There are some active computation elements in progress, please wait before execute"
                );
              }
            } catch (e: any) {
              const customError = handleRequestError(e);

              messageApi.error(customError.message);
              console.error(customError);
            }
          },
        }),
      className: "!text-primary",
    },
    hasStepEditRole && {
      label: "Edit",
      key: "9",
      onClick: onEdit,
      icon: Icons.edit,
    },
  ].filter(Boolean) as MenuDropdownItem[];

  const itemsAfterViews = [
    hasStepDeleteRole && {
      label: "Delete",
      key: "13",
      icon: DeleteIcon,
      onClick: () => setIsDeleteModalOpen(true),
      className: "!text-red-600",
    },
  ].filter(Boolean) as MenuDropdownItem[];

  const isGridSelected = currentCampaignView === CampaignViewTypes.GRID;

  const currentStepHiddenDocView = currentStep?.hiddenDocView;

  const globalAdminOrStepNotHidden = isGlobalAdmin || !step.hiddenDocView;

  const viewItems = [
    {
      label: `Grid`,
      key: "14",
      icon: isGridSelected ? CheckIcon : GridIcon,
      onClick: () => {
        if (isGridSelected) return;
        dispatch(setCurrentCampaignView(CampaignViewTypes.GRID));
      },
      className: isGridSelected ? "!text-primary" : "",
    },
    globalAdminOrStepNotHidden && {
      label: `Doc: ${selectedOption ? selectedOption?.label : "No Doc Template Selected"}`,
      key: "15",
      icon: !isGridSelected ? CheckIcon : DocumentIcon,
      onClick: () => {
        if (!isGridSelected) return;
        dispatch(updateCampaignView({ viewType: CampaignViewTypes.DOC }));
      },
      className: !isGridSelected ? "!text-primary" : "",
    },
    globalAdminOrStepNotHidden && {
      label: "Select Doc Template",
      key: "16",
      icon: ListBulletIcon,
      onClick: () => onTemplatePreview(),
    },
    isGlobalAdmin && {
      label: currentStepHiddenDocView
        ? "Hidden in Doc View"
        : "Visible in Doc View",
      key: "17",
      icon: currentStepHiddenDocView ? EyeSlashIcon : EyeIcon,
      onClick: () => {
        dispatch(
          updateHiddenDocViewThunk({
            hiddenDocView: !currentStepHiddenDocView,
          })
        );
      },
    },
  ].filter(Boolean) as MenuDropdownItem[];

  const moreItems = [
    hasCSVExportRole && {
      label: "Export CSV",
      key: "2",
      icon: ExportDocIcon,
      onClick: () => confirm({ action: onExportCSV, title: "Export as CSV" }),
    },
    hasStepEditRole && {
      label: "Move Left",
      key: "3",
      icon: InsertBeforeIcon,
      disabled: prevStepSeq === undefined,
      onClick: () => onMove(prevStepSeq),
    },
    hasStepEditRole && {
      label: "Move Right",
      key: "4",
      icon: InsertAfterIcon,
      disabled: nextStepSeq === undefined,
      onClick: () => onMove(nextStepSeq),
    },
    hasStepCreateRole && {
      label: "Add Before",
      key: "5",
      icon: ArrowUturnLeftIcon,
      onClick: onAddBefore,
    },
    hasStepCreateRole && {
      label: "Add After",
      key: "6",
      icon: ArrowUturnRightIcon,
      onClick: onAddAfter,
    },
    hasStepCloneRole && {
      label: "Clone",
      key: "7",
      icon: CloneIcon,
      onClick: () => setIsCloneModalOpen(true),
    },
    hasStepCopyRole && {
      label: "Copy To",
      key: "8",
      icon: CloneIcon,
      onClick: () =>
        cloneToStep({
          entityTitle: step.name,
          onSubmitForm: onCopyTo,
          currentCompanyId: companyId,
        }),
    },
    hasStepDeployRole &&
      companyId !== undefined && {
        label: "Micro Site Publish",
        key: "10",
        onClick: () =>
          confirm({
            action: publishToMicroSite,
            title: "Publish to Micro Site",
          }),
        icon: GlobeIcon,
      },
  ].filter(Boolean) as MenuDropdownItem[];

  const isCampaignViewDoc = currentCampaignView === CampaignViewTypes.DOC;

  const disabledStepButton =
    isCampaignViewDoc && !isGlobalAdmin ? step.hiddenDocView : false;

  return (
    <>
      {modal}
      {isCurrentStep ? (
        <div className="relative bg-indigo-50">
          <StepMenuDropdown
            beforeViewsItems={itemsBeforeViews}
            afterViewsItems={itemsAfterViews}
            moreItems={moreItems}
            viewItems={viewItems as any}
          >
            {(ref, setOpen, DropdownMenuTrigger) => (
              <>
                <div
                  className={clsx(
                    "absolute right-[10px] flex items-center justify-center rounded-full cursor-pointer hover:bg-primary/10 transition-colors duration-200 size-6 top-3"
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                  }}
                >
                  <Icons.MoreDots className="size-5" />
                </div>
                <div
                  className={clsx(
                    "h-12 pr-10 bg-indigo-50 border-b-[3px] border-primary justify-between items-center gap-2 inline-flex cursor-pointer flex-col",
                    {
                      "hover:bg-indigo-100": isCurrentStep,
                    }
                  )}
                  {...dragHandleProps}
                >
                  <div className="text-slate-700 pl-4 h-full text-sm !font-semibold !font-sans leading-[14px] line-clamp-1 whitespace-nowrap inline-flex flex-col justify-center pt-[6px]">
                    {step.name}
                  </div>
                  <DropdownMenuTrigger asChild ref={ref}>
                    <div className="h-0 w-full" />
                  </DropdownMenuTrigger>
                </div>
              </>
            )}
          </StepMenuDropdown>

          {isCloneModalOpen && (
            <CloneEntityModal
              entity="Step"
              entityTitle={step.name}
              entityTitleTooltip="Please enter a string between 4 and 26 characters."
              nameValidationRule={stepNameValidationRule}
              onSubmitForm={onClone}
              isModalOpen={isCloneModalOpen}
              onCancel={() => setIsCloneModalOpen(false)}
            />
          )}
          {isDeleteModalOpen && (
            <DeleteWithFilesModal
              isModalOpen={isDeleteModalOpen}
              entity="Step"
              title={step.name}
              setIsModalOpen={setIsDeleteModalOpen}
              onDelete={onDelete}
            />
          )}
        </div>
      ) : (
        <Tooltip title={disabledStepButton ? "Hidden for Doc View" : undefined}>
          <div
            className={clsx(
              `h-12 bg-indigo-50 px-[16px] py-[19px] justify-start items-center gap-2 inline-flex cursor-pointer border-y-[3px] border-transparent hover:border-b-primary transition-colors hover:bg-primary/10 tab`,
              {
                "!cursor-not-allowed !text-slate-200 !bg-gray-100 !border-b-gray-100":
                  disabledStepButton,
              }
            )}
            onClick={disabledStepButton ? undefined : handleSelectStep}
            {...dragHandleProps}
          >
            <span
              className={clsx(
                "text-slate-700 text-sm font-semibold font-sans leading-[14px] line-clamp-1 whitespace-nowrap inline-flex items-center"
              )}
            >
              {step.name}
              <div>
                <Icons.MoreDots
                  className={clsx("size-5 ml-2 tab-icon", {
                    "!bg-gray-100": disabledStepButton,
                  })}
                />
              </div>
            </span>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default Step;
