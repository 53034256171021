export const getConfirmationMessage = (
  isContextChanged: boolean,
  isNameChanged: boolean,
  fieldName: string
): string | null => {
  let confirmationMessage = "";

  if (isContextChanged && isNameChanged) {
    confirmationMessage = `Micro Site Target Folder and ${fieldName} have changed, do you want to republish resources?`;
  } else if (isContextChanged) {
    confirmationMessage =
      "Micro Site Target Folder has changed, do you want to republish resources?";
  } else if (isNameChanged) {
    confirmationMessage = `${fieldName} has changed, do you want to republish resources?`;
  }

  return confirmationMessage || null;
};
