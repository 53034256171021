import { ClearOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Flex, Input, Table, Typography } from "antd";
import { TableRowSelection } from "antd/es/table/interface";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TLabelsListData } from "../../../globalTypes";
import useConfirm from "../../../hooks/useConfirm";
import { getMessageApi } from "../../../store/slices/appSlice";
import { deleteCampaignLabelsThunk } from "../../../store/slices/campaignLabelsSlice";
import { AppDispatch } from "../../../store/store";

const { Column } = Table;

type PropsType = {
  setIsEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEditModeProps: React.Dispatch<
    React.SetStateAction<TLabelsListData | null>
  >;
  labelsList: Array<TLabelsListData>;
};

const LabelsList = (props: PropsType) => {
  const { labelsList, setIsEditModalOpen, setEditModeProps } = props;
  const dispatch: AppDispatch = useDispatch();
  const confirm = useConfirm();
  const messageApi = getMessageApi();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [filteredData, setFilteredData] = useState<Array<TLabelsListData>>([]);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    let filtered = labelsList;

    if (searchText) {
      filtered = labelsList.filter((item) =>
        item.labelKey.toLowerCase().includes(searchText)
      );
    }

    setFilteredData(filtered);
  }, [labelsList]);

  const rowSelection: TableRowSelection<TLabelsListData> = {
    selectedRowKeys,
    columnWidth: 30,
    onChange: (keys) => {
      setSelectedRowKeys(keys);
    },
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const onBulkDelete = async () => {
    try {
      const keysToDelete = selectedRowKeys as Array<string>;

      //lang: 'en' - hardcoded! Need to pass from form select language
      const res = await dispatch(
        deleteCampaignLabelsThunk({ keys: keysToDelete, lang: "en" })
      );

      if ("error" in res) {
        messageApi.error(res.payload?.message);
      } else {
        setSelectedRowKeys([]);
      }
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(
        "An error occurred while trying to delete campaign labels:",
        e
      );
    }
  };

  const onClearFilter = () => {
    setFilteredData(labelsList);
    setSearchText("");
  };

  const onSearchKey = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();

    setSearchText(value);

    if (value.length === 0) {
      setFilteredData(labelsList);
    } else {
      const filtered = labelsList.filter((item) =>
        item.labelKey.toLowerCase().includes(value)
      );

      setFilteredData(filtered);
      setSelectedRowKeys([]);
    }
  };

  const onEditLabel = (labelData: TLabelsListData) => {
    setEditModeProps(labelData);
    setIsEditModalOpen(true);
  };

  return (
    <Flex vertical gap="small" style={{ height: "100%" }}>
      <Flex align="center" justify="space-between" wrap="wrap">
        <Flex gap="small">
          <div>
            <Input.Search
              size="small"
              placeholder="Enter label key"
              onChange={onSearchKey}
              value={searchText}
            />
          </div>
          <Button
            disabled={!searchText}
            size="small"
            type="default"
            icon={<ClearOutlined />}
            onClick={onClearFilter}
          >
            Clear filter
          </Button>
          <Typography.Text type="secondary">{`Items: ${filteredData.length}`}</Typography.Text>
        </Flex>
        <Button
          icon={<DeleteOutlined />}
          size="small"
          disabled={!selectedRowKeys.length}
          onClick={() => confirm({
            action: onBulkDelete, title: "Delete Labels"})}
          danger
        >
          Delete Selected
        </Button>
      </Flex>
      <Table
        size="small"
        dataSource={filteredData}
        bordered={true}
        rowSelection={rowSelection}
        pagination={false}
        scroll={{ x: 500, y: 400 }}
        virtual
      >
        <Column title="Key" dataIndex="labelKey" key="labelKey" width={150} />
        <Column title="Value" dataIndex="value" key="value" />
        <Column
          title=""
          key="action"
          align="center"
          width={70}
          render={(_: any, record: TLabelsListData) => {
            return (
              <Button
                size="small"
                type="link"
                onClick={() => onEditLabel(record)}
              >
                Edit
              </Button>
            );
          }}
        />
      </Table>
    </Flex>
  );
};

export default LabelsList;
