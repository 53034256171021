import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Skeleton, Switch } from "antd";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getCompanyOptionsApi,
  saveCompanyOptionApi,
} from "../../api/companies.api";
import {
  CompanyGlobalAdminOptionsEnum,
  companyGlobalAdminOptions,
} from "../../constants";
import { selectMessageApi } from "../../store/slices/appSlice";
import handleRequestError from "../../utils/handleRequestError";

type Option = {
  key: CompanyGlobalAdminOptionsEnum;
  value: boolean;
  label: string;
};

type Props = {
  companyId: number;
};

const CompanyOptions: FC<Props> = ({ companyId }) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const messageApi = useSelector(selectMessageApi);

  useEffect(() => {
    const getOptions = async () => {
      try {
        const { data } = await getCompanyOptionsApi(companyId);

        let options: Option[] = companyGlobalAdminOptions.map(({ key, label }) => ({
          value: false,
          label,
          key,
        }));

        if (data.length) {
          options = companyGlobalAdminOptions.map(({ key, label }) => {
            const savedOption = data.find(({ id }) => id.key === key);
            let value = false;

            try {
              value = savedOption && savedOption.value ? JSON.parse(savedOption.value).checked : false;
            } catch (e) {
              console.error("Can't parse company option data", e);
            }

            return { key, value, label };
          });
        }

        setOptions(options);
      } catch (e: any) {
        const customError = handleRequestError(e);

        messageApi.error(customError.message);
        console.error(customError);
      } finally {
        setIsInitialized(true);
      }
    };

    getOptions();
  }, []);

  if (!isInitialized) {
    return <Skeleton active />;
  }

  const onToggleOption = async ({
    checked,
    key,
  }: {
    checked: boolean;
    key: CompanyGlobalAdminOptionsEnum;
  }) => {
    try {
      setIsFetching(true);

      await saveCompanyOptionApi({
        companyId,
        checked,
        key,
      });

      setOptions((prev) => {
        const updatedOptions = [...prev];

        updatedOptions.forEach((opt) => {
          if (opt.key === key) {
            opt.value = checked;
          }
        });

        return updatedOptions;
      });
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className="flex flex-col gap-[12px]">
      {options.map(({ label, key, value }) => {
        return (
          <div className="flex gap-[12px]" key={key}>
            <span className="font-bold text-[14px] text-[#475569]">
              {label}
            </span>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={value}
              loading={isFetching}
              onChange={(checked) => onToggleOption({ checked, key })}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CompanyOptions;
