import clsx from "clsx";
import React, { FC, useRef } from "react";
import { TFileTreeItem } from "../../../globalTypes";
import useHover from "../../../hooks/useHover";
import { GeneratedDocIcon, Icons, StorageFileIcon } from "../../common/Icons";
import { AssetsFileTreeItem } from "../campaignViewStorage/types";
import StaticStorageItemActions from "./ItemActions";
import { GetMenuActions } from "./hooks/types";

type Props = {
  onSelectItem: () => void;
  isSelected: boolean;
  selectedItem: TFileTreeItem | null;
  getItemMenuActions: GetMenuActions;
  setTreeData: (tree: TFileTreeItem[]) => void;
  item: TFileTreeItem | AssetsFileTreeItem;
};

const StaticStorageFile: FC<Props> = ({
  onSelectItem,
  isSelected,
  selectedItem,
  getItemMenuActions,
  setTreeData,
  item,
}) => {
  const fileRef = useRef<HTMLDivElement | null>(null);
  const isHovering = useHover(fileRef);

  const FileIcon = React.useMemo(() => {
    if ("assetData" in item && item.assetData?.type === "GENERATED") {
      return GeneratedDocIcon;
    }

    return StorageFileIcon;
  }, [item]);

  return (
    <div
      ref={fileRef}
      onClick={(e) => {
        e.stopPropagation();
        onSelectItem();
      }}
      className={clsx(
        "inline-flex items-center ml-[25px] h-[32px] cursor-pointer gap-[28px] rounded-[4px] py-[6px] px-[8px] transition-all",
        {
          "text-[#4F46E5] bg-[#E0E7FF]": isSelected,
          "hover:bg-[#eee]": !isSelected,
        }
      )}
    >
      <div className="flex items-center gap-[8px]">
        <FileIcon className="size-[20px]" />
        <div>{item.title}</div>
      </div>

      {isSelected && selectedItem ? (
        <StaticStorageItemActions
          item={selectedItem}
          getItemMenuActions={getItemMenuActions}
          setTree={setTreeData}
        />
      ) : (
        <Icons.MoreDots
          className={clsx("size-[20px]", {
            "opacity-100": isHovering,
            "opacity-0": !isHovering,
          })}
        />
      )}
    </div>
  );
};

export default StaticStorageFile;
