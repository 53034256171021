import { Progress, Typography } from "antd";
import {
  getComputationStatus,
  getProgress,
  useComputationType,
  useExecutionType,
} from "../../../store/slices/computationMessagesSlice";
import React from "react";

const ComputationProgress = () => {
  const progress = getProgress();
  const computationStatus = getComputationStatus();
  const computationType = useComputationType();
  const executionType = useExecutionType();

  const getStatus = () => {
    if (progress === 100) return "success";
    if (computationStatus === "interrupted") return "normal";
    if (computationStatus === "finished" && progress !== 100)
      return "exception";
    return "active";
  };

  const getStatusClassName = () => {
    const baseClass = "rounded-full px-2.5 py-0.5 font-semibold text-xs";
    switch (computationStatus) {
      case "in_progress":
        return `${baseClass} bg-blue-100 text-blue-900`;
      case "interrupted":
        return `${baseClass} bg-orange-100 text-orange-900`;
      case "finished":
        if (getStatus() === "exception") {
          return `${baseClass} bg-red-100 text-red-900`;
        } else {
          return `${baseClass} bg-green-100 text-green-900`;
        }
      default:
        return `${baseClass} bg-gray-100 text-gray-900`;
    }
  };

  return (
    <div className="flex flex-col items-start">
      <div className="flex gap-[16px] items-center">
        <div className="flex items-center gap-[12px]">
          <span className="font-sans font-bold text-[#475569] text-[14px]">
            Status:
          </span>
          <div className={getStatusClassName()}>
            {computationStatus || "On hold"}
          </div>
        </div>

        {computationType && executionType && (
          <div className="flex items-center gap-[12px]">
            <Typography.Text code>
              {executionType} ({computationType})
            </Typography.Text>
          </div>
        )}
      </div>

      <div className="flex items-center gap-[12px]">
        <span className="font-sans font-bold text-[#475569] text-[14px]">
          Progress:
        </span>
        <Progress
          className="w-[300px] pt-[6px]"
          percent={progress}
          status={getStatus()}
        />
      </div>
    </div>
  );
};

export default ComputationProgress;
