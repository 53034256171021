import { AxiosResponse } from "axios";
import { TLabelLanguage, TLabels } from "../globalTypes";
import axiosInstance from "../utils/axios/axiosInstance";

export const getGlobalLabelsApi = (
  lang: TLabelLanguage,
  companyId: number | undefined
): Promise<AxiosResponse<TLabels>> => {
  return axiosInstance.get(`/api/secure/campaignManager/i18n/${lang}`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      companyId,
    },
  });
};

export const saveGlobalLabelApi = (
  label: string,
  key: string,
  lang: TLabelLanguage,
  action: "create" | "update",
  companyId: number | undefined
): Promise<AxiosResponse<string>> => {
  const method = action === "create" ? "post" : "put";

  return axiosInstance[method](
    `/api/secure/campaignManager/i18n/${lang}/${key}/`,
    label,
    {
      headers: {
        "Content-Type": "text/plain",
      },
      params: {
        companyId,
      },
    }
  );
};

export const bulkDeleteGlobalLabelsApi = (
  keys: Array<string>,
  lang: TLabelLanguage,
  companyId: number | undefined
): Promise<AxiosResponse<string>> => {
  return axiosInstance.delete(
    `/api/secure/campaignManager/i18n/${lang}/bulk/`,
    {
      data: keys,
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};
