import { TreeSelect } from "antd";
import React, { useState } from "react";
import { TFileTreeItem, TStorageFileItem } from "../../../globalTypes";
import { getMessageApi } from "../../../store/slices/appSlice";
import handleRequestError from "../../../utils/handleRequestError";
import processSelectStorageItems from "./utils/processSelectStorageItems";

type Props = {
  getFolderFiles: (path?: string) => Promise<TStorageFileItem[]>;
  selectType: SelectStorageItemType;
  onSelect: (value: string) => void;
  pathPrefix: string;
};

export type SelectStorageItemType = "FILE" | "FOLDER";
export type TreeSelectItem = TFileTreeItem & {
  children?: TreeSelectItem[];
};

const SelectStorageItems: React.FC<Props> = ({
  getFolderFiles,
  onSelect,
  pathPrefix,
  selectType,
}) => {
  const messageApi = getMessageApi();
  const [treeData, setTreeData] = useState<TreeSelectItem[]>(
    processSelectStorageItems({
      data: [
        {
          folder: true,
          fileName: pathPrefix,
          mimeType: "",
          size: 0,
        },
      ],
      selectType,
    })
  );

  const onLoadData = async (item: TFileTreeItem) => {
    if (!getFolderFiles) return;

    try {
      const data = await getFolderFiles(item.fileName);

      setTreeData(
        processSelectStorageItems({
          data,
          parentFileName: item.fileName,
          level: item.level,
          origin: treeData,
          selectType,
        })
      );
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  return (
    <TreeSelect
      treeData={treeData}
      placeholder={`Choose a ${selectType.toLowerCase()}`}
      // @ts-ignore
      loadData={onLoadData}
      onChange={onSelect}
      style={{ width: "100%" }}
    />
  );
};

export default SelectStorageItems;
