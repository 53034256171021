import { AxiosResponse } from "axios";
import { PROXY_PREFIX } from "src/constants";
import { TVEServerRes } from "../globalTypes";
import { TStep } from "../store/slices/stepsSlice";
import axiosInstance from "../utils/axios/axiosInstance";
//TODO: move variable to project constants
const CM_API_PREFIX = "/api/secure/campaignManager";

export const createStepApi = ({
  phaseId,
  companyId,
  ...step
}: {
  phaseId: number;
  name: string;
  seq: number;
  hidden: boolean;
  classes: string[];
  microSiteContextFolder: null | string;
  companyId: number | undefined;
  hiddenDocView: boolean;
}): Promise<AxiosResponse<TStep>> => {
  return axiosInstance.post(
    `${PROXY_PREFIX}/api/secured/${phaseId}/step/`,
    step,
    {
      headers: { "Content-Type": "application/json" },
      params: { companyId },
    }
  );
};

export const updateStepApi = ({
  step,
  companyId,
}: {
  step: TStep;
  companyId: number | undefined;
  republish?: boolean;
}): Promise<AxiosResponse<TStep>> => {
  return axiosInstance.put(
    `${PROXY_PREFIX}/api/secured/${step.phaseId}/step/`,
    step,
    {
      headers: { "Content-Type": "application/json" },
      params: { companyId },
    }
  );
};

export const deleteStepApi = ({
  phaseId,
  stepId,
  removeFiles,
  companyId,
}: {
  phaseId: number;
  stepId: number;
  removeFiles: boolean;
  companyId: number | undefined;
}): Promise<AxiosResponse<TVEServerRes>> => {
  return axiosInstance.delete(
    `${PROXY_PREFIX}/api/secured/${phaseId}/step/${stepId}`,
    {
      headers: { "Content-Type": "application/json" },
      params: { removeFiles, companyId },
    }
  );
};

type TExportCSVStepRes = TVEServerRes & { data: string };

export const exportCSVStepApi = (
  phaseId: number,
  stepId: number,
  companyId: number | undefined
): Promise<AxiosResponse<TExportCSVStepRes>> => {
  return axiosInstance.get(
    `${CM_API_PREFIX}/step/${phaseId}/${stepId}/export-csv`,
    {
      headers: { "Content-Type": "application/json" },
      params: { companyId },
    }
  );
};

export const getStepsApi = ({
  phaseId,
  companyId,
}: {
  phaseId: number;
  companyId: number | undefined;
}): Promise<AxiosResponse<TStep[]>> => {
  return axiosInstance.get(`${PROXY_PREFIX}/api/secured/${phaseId}/step/`, {
    headers: { "Content-Type": "application/json" },
    params: { companyId },
  });
};

type TSwapStepSequenceRes = TVEServerRes & { steps: Array<TStep> };

export const swapStepSequenceApi = (
  phaseId: number,
  step1Seq: number,
  step2Seq: number,
  companyId: number | undefined
): Promise<AxiosResponse<TSwapStepSequenceRes>> => {
  return axiosInstance.put(
    `${CM_API_PREFIX}/step/swap/${phaseId}`,
    { step1Seq, step2Seq },
    {
      headers: { "Content-Type": "application/json" },
      params: { companyId },
    }
  );
};

type TCloneStepRes = TVEServerRes & { step: TStep };
type TCloneStepProps = {
  phaseId: number;
  stepId: number;
  name: string;
  resetResult: boolean;
  resetOvr: boolean;
  companyId: number | undefined;
};

export const cloneStepApi = ({
  phaseId,
  stepId,
  name,
  resetResult,
  resetOvr,
  companyId,
}: TCloneStepProps): Promise<AxiosResponse<TCloneStepRes>> => {
  return axiosInstance.put(
    `${CM_API_PREFIX}/${phaseId}/step/${stepId}/clone`,
    {},
    {
      headers: { "Content-Type": "application/json" },
      params: {
        name,
        resetResult,
        resetOvr,
        companyId,
      },
    }
  );
};

export const getDynFormStepsOptionsApi_proxy = ({
  phaseId,
  stepId,
  companyId,
}: {
  phaseId: number;
  stepId: number;
  companyId: number | undefined;
}): Promise<AxiosResponse<Array<string>>> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/${phaseId}/step/${stepId}/meAndNextSteps`,
    {
      ///api/secured/385/step/518/meAndNextSteps
      headers: { "Content-Type": "application/json" },
      params: { companyId },
    }
  );
};

export const publishStepToMicroSiteApi = (
  phaseId: number,
  stepId: number,
  companyId: number | undefined
): Promise<AxiosResponse<string>> => {
  return axiosInstance.put(
    `${CM_API_PREFIX}/${phaseId}/step/${stepId}/microsite-deploy`,
    {},
    {
      headers: { "Content-Type": "application/json" },
      params: { companyId },
    }
  );
};

export const redeployStepToMicroSiteApi = (
  phaseId: number,
  stepId: number,
  companyId: number | undefined,
): Promise<AxiosResponse<string>> => {
  return axiosInstance.put(
    `${PROXY_PREFIX}/api/secured/${phaseId}/step/${stepId}/microsite-redeploy`,
    {},
    {
      headers: { "Content-Type": "application/json" },
      params: { companyId },
    }
  );
};

export const copyStepToApi = ({
  phaseId,
  targetPhase,
  stepId,
  name,
  newSeq,
  resetResult,
  resetOvr,
  companyId,
}: {
  phaseId: number;
  targetPhase: number;
  stepId: number;
  name: string;
  newSeq: number;
  resetResult: boolean;
  resetOvr: boolean;
  companyId: number | undefined;
}): Promise<AxiosResponse<TStep>> => {
  return axiosInstance.put(
    `${PROXY_PREFIX}/api/secured/${phaseId}/step/${stepId}/copy`,
    {},
    {
      headers: { "Content-Type": "application/json" },
      params: {
        resetResult,
        resetOvr,
        name,
        newSeq,
        targetPhase,
        companyId,
      },
    }
  );
};

export const getStepTemplatePreviewApi = ({
  phaseId,
  stepId,
  template,
  companyId,
}: {
  phaseId: number;
  stepId: number;
  template: number;
  companyId: number | undefined;
}): Promise<AxiosResponse<ArrayBuffer>> => {
  return axiosInstance.get(
    `${CM_API_PREFIX}/template/${phaseId}/step/${stepId}/${template}/preview`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: { companyId },
      responseType: "arraybuffer",
    }
  );
};
