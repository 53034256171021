import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { TUsersListItem } from "./usersSlice";

type CMUsersState = {
  users: TUsersListItem[];
  pageNumber: number;
  pageSize: number;
  total: number;
};

const initialState: CMUsersState = {
  users: [],
  pageNumber: 1,
  pageSize: 50,
  total: 0,
};

const usersListSlice = createSlice({
  name: "usersList",
  initialState,
  reducers: {
    setUsers: (
      state: CMUsersState,
      action: PayloadAction<{ users: TUsersListItem[]; total: number }>
    ) => {
      state.users = action.payload.users;
      state.total = action.payload.total;
    },
    setPageSize: (state: CMUsersState, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNumber: (state: CMUsersState, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    removeUser(state, action: PayloadAction<number>) {
      state.users = state.users.filter((item) => item.id !== action.payload);
    },
  },
});

export const { setUsers, setPageNumber, setPageSize, removeUser } =
  usersListSlice.actions;

export default usersListSlice.reducer;

export const selectUsersListState = (state: RootState) =>
  state.usersList;
