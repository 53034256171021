import { AxiosResponse } from "axios";
import { PROXY_PREFIX } from "src/constants";
import {
  TDynamicFormItemsFunction,
  TDynamicFormItemsImportVars,
  TDynamicFormItemsPrompt,
  TDynamicFormItemsString,
  TEnvVariable,
  TEnvVarsFormValues,
  TImportEnvVarsFormValues,
} from "../store/slices/envVariablesSlice";
import axiosInstance from "../utils/axios/axiosInstance";

export const getPromptTemplateApi = ({
  campaignId,
  stepId,
  key,
  companyId,
}: {
  campaignId: number;
  stepId: number;
  key: string;
  companyId: number | undefined;
}): Promise<AxiosResponse<any>> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/${campaignId}/computation/prompt/${stepId}/${key}/template`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const getEnvVarsApi = (
  campaignId: number,
  companyId: number | undefined
): Promise<AxiosResponse<Array<TEnvVariable>>> => {
  return axiosInstance.get(`/api/secure/campaignManager/env/${campaignId}`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      companyId,
    },
  });
};

export type TGetEnvVarTypesResponse = {
  function: { items: TDynamicFormItemsFunction };
  prompt: { items: TDynamicFormItemsPrompt };
  string: { items: TDynamicFormItemsString };
};

export const getEnvVarTypesApi = (
  companyId: number | undefined
): Promise<AxiosResponse<TGetEnvVarTypesResponse>> => {
  return axiosInstance.get(`/api/secure/campaignManager/env/types/list`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      companyId,
    },
  });
};

export const getImportVarTypesApi = (
  companyId: number | undefined
): Promise<AxiosResponse<TDynamicFormItemsImportVars>> => {
  return axiosInstance.get(`/api/secure/campaignManager/env/import/types`, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      companyId,
    },
  });
};

export const importVarsApi = (
  campaignId: number,
  values: TImportEnvVarsFormValues,
  companyId: number | undefined
): Promise<AxiosResponse<string>> => {
  const { type, value, ...options } = values;
  return axiosInstance.post(
    `/api/secure/campaignManager/env/${campaignId}/import/${type}`,
    {
      rawContent: value,
      options,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const createEnvVarApi = (
  campaignId: number,
  values: TEnvVarsFormValues,
  action: "create" | "update",
  companyId: number | undefined
): Promise<AxiosResponse<number>> => {
  const { type, key, value, ...options } = values;
  const method = action === "create" ? "post" : "put";

  return axiosInstance[method](
    `/api/secure/campaignManager/env/${campaignId}/${type}`,
    {
      id: { key },
      value,
      options,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const bulkDeleteEnvVarApi = (
  campaignId: number,
  keys: Array<string>,
  companyId: number | undefined
): Promise<AxiosResponse<string>> => {
  return axiosInstance.delete(
    `/api/secure/campaignManager/env/delete/${campaignId}`,
    {
      data: keys,
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const executeFunctionVarApi = (
  campaignId: number,
  scriptContent: string,
  companyId: number | undefined
): Promise<AxiosResponse<string>> => {
  return axiosInstance.post(
    `/api/secure/campaignManager/env/execute/${campaignId}/function`,
    scriptContent,
    {
      headers: {
        "Content-Type": "text/plain",
      },
      params: {
        companyId,
      },
    }
  );
};

export const editEnvVarKeyApi = (
  campaignId: number,
  currentKey: string,
  newKey: string,
  companyId: number | undefined
): Promise<AxiosResponse<string>> => {
  return axiosInstance.put(
    `/api/secure/campaignManager/env/editKey/${campaignId}/${currentKey}/${newKey}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const exportEnvVarsToCSVApi = (
  campaignId: number,
  type: string,
  companyId: number | undefined
): Promise<AxiosResponse<Blob>> => {
  return axiosInstance.request({
    method: "get",
    url: `/api/secure/campaignManager/env/${campaignId}/env/${type}/export-csv`,
    responseType: "blob",
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      companyId,
    },
  });
};
