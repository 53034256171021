import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TLabelLanguage, TLabels } from "../../globalTypes";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { handleRequestError, TCustomError } from "../../utils/handleRequestError";
import {
  bulkDeleteCampaignLabelsApi,
  getCampaignLabelsApi,
  saveCampaignLabelApi,
} from "../../api/campaign-labels.api";
import { toggleIsFetching } from "./appSlice";

const initialState = {
  labels: {} as TLabels,
};

type InitialStateType = typeof initialState;

const campaignLabelsSlice = createSlice({
  name: "campaignLabels",
  initialState,
  reducers: {
    setCampaignLabels: (
      state: InitialStateType,
      action: PayloadAction<TLabels>
    ) => {
      state.labels = action.payload;
    },
    addCampaignLabel: (
      state: InitialStateType,
      action: PayloadAction<{ key: string; value: string }>
    ) => {
      const { key, value } = action.payload;

      state.labels[key] = value;
    },
    deleteCampaignLabels: (
      state: InitialStateType,
      action: PayloadAction<Array<string>>
    ) => {
      action.payload.forEach((key) => {
        delete state.labels[key];
      });
    },
  },
});

export const { setCampaignLabels, addCampaignLabel, deleteCampaignLabels } =
  campaignLabelsSlice.actions;

export default campaignLabelsSlice.reducer;

/* eslint-disable*/
export const getCampaignLabels = (): TLabels =>
  useSelector((state: RootState) => state.campaignLabels.labels);

type TSaveLabelProps = {
  label: string;
  key: string;
  lang: TLabelLanguage;
  action: "create" | "update";
};

export const saveCampaignLabelThunk = createAsyncThunk<
  undefined,
  TSaveLabelProps,
  { state: RootState; rejectValue: TCustomError }
>(
  "campaignLabels/saveLabel",
  async (props, { getState, rejectWithValue, dispatch }) => {
    try {
      const { label, lang, key, action } = props;
      const { campaigns, companies } = getState();

      await saveCampaignLabelApi(
        campaigns.current!.id,
        label,
        key,
        lang,
        action,
        companies.current?.id
      );

      dispatch(addCampaignLabel({ key, value: label }));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to save campaign label:`,
        e
      );

      return rejectWithValue(customError);
    }
  }
);

type TDeleteLabelsProps = {
  keys: Array<string>;
  lang: TLabelLanguage;
};

export const deleteCampaignLabelsThunk = createAsyncThunk<
  undefined,
  TDeleteLabelsProps,
  { state: RootState; rejectValue: TCustomError }
>(
  "campaignLabels/deleteLabels",
  async (props, { getState, rejectWithValue, dispatch }) => {
    try {
      const { keys, lang } = props;
      const { campaigns, companies } = getState();

      await bulkDeleteCampaignLabelsApi(campaigns.current!.id, keys, lang, companies.current?.id);

      dispatch(deleteCampaignLabels(keys));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to delete campaign labels:`,
        e
      );

      return rejectWithValue(customError);
    }
  }
);

export const getCampaignLabelsThunk = createAsyncThunk<
  undefined,
  number,
  { state: RootState, rejectValue: TCustomError }
>(
  "campaignLabels/getLabelsAndOpenModal",
  async (campaignId, { getState, rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleIsFetching(true));

      const { companies } = getState();

      //second param - need to get it from state in the future
      const { data } = await getCampaignLabelsApi(campaignId, "en", companies.current?.id);

      dispatch(setCampaignLabels(data));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(
        `An error occurred while trying to get campaign labels:`,
        e
      );

      return rejectWithValue(customError);
    } finally {
      dispatch(toggleIsFetching(false));
    }
  }
);

