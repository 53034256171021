import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCompanyApi } from "src/api/companies.api";
import { setCurrentCompany } from "src/store/slices/companiesSlice";
import { getCampaignDataApi } from "../../../../api/campaigns.api";
import { TNavigationItem } from "../../../../globalTypes";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import { TCampaign } from "../../../../store/slices/campaignsSlice";
import handleRequestError from "../../../../utils/handleRequestError";

const useCampaignStorageData = () => {
  const { companyId, campaignId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const messageApi = useSelector(selectMessageApi);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [campaignData, setCampaignData] = useState<TCampaign | null>(null);
  const dispatch = useDispatch();
  const companyIdValidated =
    companyId && companyId !== "global" ? +companyId : undefined;

  useEffect(() => {
    getCampaignData();
  }, []);

  const onGoBack = () => {
    location.key !== "default"
      ? navigate(-1)
      : navigate(
          `/admin/campaigns/company/${companyId}/campaign/${campaignId}/`
        );
  };

  const navigationItems = [
    {
      path: "public",
      title: "Public",
    },
    {
      path: "private",
      title: "Private",
    },
    {
      path: "assets",
      title: "Assets",
    },
    companyId !== "global" && {
      path: "microsite",
      title: "Microsite",
    },
  ].filter(Boolean) as TNavigationItem[];

  const getCampaignData = async () => {
    if (!campaignId) {
      messageApi.error("Unable to load the campaign view");
      console.error("Unable to get [companyId] or [campaignId]");
      setIsLoading(false);
      return;
    }

    try {
      if (!companyIdValidated) return;

      const [{ data: company }, { data: campaign }] = await Promise.all([
        getCompanyApi(companyIdValidated),
        getCampaignDataApi({
          campaignId: campaignId,
          companyId: companyIdValidated,
        }),
      ]);

      dispatch(setCurrentCompany(company.company));
      setCampaignData(campaign);

      setError(null);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);

      setError(customError.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    campaignData,
    onGoBack,
    navigationItems,
  };
};

export default useCampaignStorageData;
