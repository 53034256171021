import { Tooltip } from "antd";
import clsx from "clsx";
import { FC } from "react";
import { useSelector } from "react-redux";
import { DocIcon, TableIcon } from "src/components/common/Icons";
import { Button } from "src/components/common/ui/button";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { selectDocVisibleSteps } from "src/store/slices/stepsSlice";
import { CampaignViewTypes } from "src/types";

type Props = {
  currentViewType: CampaignViewTypes;
  onViewTypeChange: (viewType: CampaignViewTypes) => void;
};

const items = [
  { viewType: CampaignViewTypes.DOC, icon: DocIcon },
  {
    viewType: CampaignViewTypes.GRID,
    icon: TableIcon,
  },
];

const CampaignViewSelector: FC<Props> = ({
  currentViewType,
  onViewTypeChange,
}) => {
  const docVisibleList = useSelector(selectDocVisibleSteps);
  const { isGlobalAdmin } = useCurrentUser();

  if (!items.length) return null;

  const handleSelect = (viewType: CampaignViewTypes) => {
    onViewTypeChange(viewType);
  };

  return (
    <div className="flex flex-col border-r border-[#E2E8F0]">
      {items.map(({ viewType, icon: Icon }, i) => {
        const isActive = currentViewType === viewType;

        const disabled =
          docVisibleList?.length === 0 &&
          !isGlobalAdmin &&
          viewType === CampaignViewTypes.DOC;

        return (
          <div
            key={viewType}
            className={clsx(
              "bg-slate-50 h-[48px] items-center flex transition-colors text-[#334155] text-sm font-semibold font-sans leading-[14px] border-t-[3px] border-transparent p-[8px]",
              {
                "rounded-tl-[3px]": i === 0,
                "rounded-bl-[3px]": i === items.length - 1,
              }
            )}
          >
            <Tooltip
              title={disabled ? `You don't have visible steps in Doc view` : ""}
            >
              <div>
                <Button
                  className="w-full h-full flex items-center justify-center size-[32px]"
                  size="icon"
                  disabled={disabled}
                  onClick={() => handleSelect(viewType)}
                  variant={isActive ? "primary" : "ghostPrimary2"}
                >
                  <Icon className="h-4 w-4" />
                </Button>
              </div>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

export default CampaignViewSelector;
