import { Checkbox, Form, Input, InputRef, Modal } from "antd";
import { Rule } from "antd/es/form";
import { FC, useEffect, useRef, useState } from "react";
import useSetFocus from "../../../../hooks/useSetFocus";
import useSubmitFormOnEnter from "../../../../hooks/useSubmitFormOnEnter";
import submitFormWithTrim from "../../../../utils/submitFormWithTrim";
import { customizeRequiredMark } from "../../../companies/CompanySite/CustomerSite";

export type TCloneEntityFormValues = {
  name: string;
  resetOvr: boolean;
  resetResult: boolean;
};

type Props = {
  entity: "Phase" | "Step" | "Campaign";
  entityTitle: string;
  nameValidationRule: Rule;
  onSubmitForm: (values: TCloneEntityFormValues) => Promise<void>;
  onCancel: () => void;
  isModalOpen: boolean;
  entityTitleTooltip?: string;
};

const CloneEntityModal: FC<Props> = ({
  entity,
  entityTitle,
  nameValidationRule,
  onSubmitForm,
  isModalOpen,
  onCancel,
  entityTitleTooltip,
}) => {
  const [form] = Form.useForm<TCloneEntityFormValues>();
  const inputRef = useRef<InputRef>(null);
  const [loading, setLoading] = useState(false);

  useSubmitFormOnEnter(() => submitForm());
  useSetFocus(inputRef);

  useEffect(() => {
    form.validateFields(["name"]);
  }, [form]);

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TCloneEntityFormValues) => {
      setLoading(true);
      await onSubmitForm(values);
      setLoading(false);
    },
  });

  return (
    <Modal
      title={`Clone ${entity}`}
      open={isModalOpen}
      onOk={submitForm}
      okText="Clone"
      okButtonProps={{ loading }}
      width={500}
      onCancel={onCancel}
    >
      <Form
        requiredMark={customizeRequiredMark}
        form={form}
        layout="vertical"
        name={`clone_${entity}_form`}
        initialValues={{
          resetOvr: true,
          resetResult: false,
          name: `Clone of ${entityTitle}`,
        }}
      >
        <Form.Item
          name="name"
          label={`${entity} Name`}
          tooltip={entityTitleTooltip}
          rules={[nameValidationRule]}
        >
          <Input className="h-[48px]" placeholder={`Enter ${entity} name`} ref={inputRef} />
        </Form.Item>
        <div className="flex flex-col gap-[6px]">
          <Form.Item name="resetOvr" valuePropName="checked" noStyle>
            <Checkbox>Reset override</Checkbox>
          </Form.Item>
          <Form.Item name="resetResult" valuePropName="checked" noStyle>
            <Checkbox>Reset result</Checkbox>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default CloneEntityModal;
