import { Checkbox, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  CloneIcon,
  CogIcon,
  DeleteIcon,
  ExecuteIcon,
  GetLinkIcon,
  GetReferenceIcon,
  Icons,
  InsertAfterIcon,
  InsertBeforeIcon,
  MoveDownIcon,
  MoveUpIcon,
  SolidEditIcon,
} from "src/components/common/Icons";
import MenuDropdown, {
  MenuDropdownItem,
} from "src/components/common/MenuDropdown";
import { Button } from "src/components/common/ui/button";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import {
  executeCcVarApi,
  validateCcVarApi,
} from "../../../api/cc-variables.api";
import useConfirm from "../../../hooks/useConfirm";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { getCurrentCampaign } from "../../../store/slices/campaignsSlice";
import {
  deleteCcVarThunk,
  swapCcItemSequenceThunk,
  TCcVariable,
  useCcVariables,
} from "../../../store/slices/ccVariablesSlice";
import { resetComputationMessages } from "../../../store/slices/computationMessagesSlice";
import { getCurrentPhase } from "../../../store/slices/phasesSlice";
import { getCurrentStep } from "../../../store/slices/stepsSlice";
import { UserPermissions } from "../../../store/slices/usersSlice";
import { AppDispatch } from "../../../store/store";
import { CC_VAR_SEQUENCE_STEP } from "../../../utils/campaigns.constant";
import { getLastSequence } from "../../../utils/cm.utils";
import handleRequestError from "../../../utils/handleRequestError";
import localStorageProvider from "../../../utils/localStorageProvider";
import sanitizeVariableName from "../../../utils/sanitizeVariableName";
import { TEditModeProps, TitleModal } from "../CampaignGrid";

type PropsType = {
  variable: TCcVariable;
  prevVariable: TCcVariable | undefined;
  nextVariable: TCcVariable | undefined;
  setIsAddRowModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setEditModeProps: React.Dispatch<React.SetStateAction<TEditModeProps | null>>;
  setGridItemSequence: React.Dispatch<React.SetStateAction<number | null>>;
};

const GridItemActions = (props: PropsType) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    variable,
    prevVariable,
    nextVariable,
    setIsAddRowModalOpened,
    setEditModeProps,
    setGridItemSequence,
  } = props;
  const { companyId, campaignId, phaseId, stepId } = useParams();
  const currentCampaign = getCurrentCampaign();
  const currentStep = getCurrentStep();
  const currentPhase = getCurrentPhase();
  const companyIdValidated =
    companyId && companyId !== "global" ? +companyId : undefined;
  const confirm = useConfirm();
  const ccVariables = useCcVariables();
  const navigate = useNavigate();
  const messageApi = useSelector(selectMessageApi);
  const { isGlobalAdmin, hasPermission } = useCurrentUser();

  const hasCcCreatorRole =
    isGlobalAdmin || hasPermission(UserPermissions.ROLE_CAMPAIGN_CC_CREATOR);
  const hasCcModifierRole =
    isGlobalAdmin || hasPermission(UserPermissions.ROLE_CAMPAIGN_CC_MODIFIER);
  const hasExecuteManagerRole =
    isGlobalAdmin ||
    hasPermission(UserPermissions.ROLE_CAMPAIGN_CC_COMPUTATION);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updateDownstreamDependencies, setUpdateDownstreamDependencies] =
    useState(localStorageProvider.getUpdateDownstreamDependencies());

  const onDeleteCcVar = async () => {
    try {
      const res = await dispatch(
        deleteCcVarThunk({
          key: variable.id.key,
          companyId: companyIdValidated,
        })
      );

      if ("error" in res) {
        messageApi.error(res.payload?.message);
      }
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(e);
    }
  };

  const onEdit = () => {
    setEditModeProps({
      variableData: variable,
      actionType: TitleModal.EDIT,
    });
    setGridItemSequence(variable.seq);
    setIsAddRowModalOpened(true);
  };

  const onClone = () => {
    const sequence = nextVariable
      ? variable.seq + Math.floor((nextVariable.seq - variable.seq) / 2)
      : getLastSequence(ccVariables, CC_VAR_SEQUENCE_STEP);

    const varClone = {
      ...variable,
      id: {
        ...variable.id,
        key: `${variable.id.key}_copy`,
      },
    };

    setEditModeProps({
      variableData: varClone,
      actionType: TitleModal.CLONE,
    });
    setGridItemSequence(sequence);
    setIsAddRowModalOpened(true);
  };

  const onInsertBefore = () => {
    const sequence = prevVariable
      ? prevVariable.seq + Math.floor((variable.seq - prevVariable.seq) / 2)
      : variable.seq - CC_VAR_SEQUENCE_STEP;

    setEditModeProps(null);
    setGridItemSequence(sequence);
    setIsAddRowModalOpened(true);
  };

  const onInsertAfter = () => {
    const sequence = nextVariable
      ? variable.seq + Math.floor((nextVariable.seq - variable.seq) / 2)
      : getLastSequence(ccVariables, CC_VAR_SEQUENCE_STEP);

    setEditModeProps(null);
    setGridItemSequence(sequence);
    setIsAddRowModalOpened(true);
  };

  const onLink = (itemKey: string) => {
    const url = `${window.location.href}#${itemKey}`;

    navigator.clipboard.writeText(url).then(
      () => {
        messageApi.success("Link copied to clipboard!");
      },
      (err) => {
        messageApi.error("Failed to copy link");
        console.error("Could not copy text: ", err);
      }
    );
  };

  const onGetReference = (itemKey: string) => {
    if (currentStep && currentPhase) {
      const ref = `${sanitizeVariableName(currentPhase.name)}$${sanitizeVariableName(currentStep.name)}$${itemKey}`;

      navigator.clipboard.writeText(ref).then(
        () => {
          messageApi.success("Reference copied to clipboard!");
        },
        (err) => {
          messageApi.error("Failed to copy reference");
          console.error("Could not copy text: ", err);
        }
      );
    } else {
      messageApi.error("Unable to get [currentStep] or [currentPhase]!");
    }
  };

  const onMove = async (seqToSwap: number | undefined) => {
    if (seqToSwap === undefined) return;

    try {
      const res = await dispatch(
        swapCcItemSequenceThunk({
          currentVar: variable,
          seqToSwap,
        })
      );

      if ("error" in res) {
        messageApi.error(res.payload?.message);
      }
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(e);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    onExecute();
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const onExecute = async () => {
    try {
      const { data: isExecuting } = await validateCcVarApi(
        currentCampaign!.id,
        companyIdValidated
      );

      if (!isExecuting) {
        await executeCcVarApi(
          currentCampaign!.id,
          variable.id.key,
          currentStep!.id,
          companyIdValidated,
          updateDownstreamDependencies
        );

        dispatch(resetComputationMessages());
        messageApi.success("Execution started successfully");
      } else {
        messageApi.error(
          "There are some active computation elements in progress, please wait before execute"
        );
      }
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    }
  };

  const items = [
    hasExecuteManagerRole && {
      key: "1",
      label: "Execute",
      disabled: !variable.executable,
      icon: ExecuteIcon,
      onClick: showModal,
      className: "!text-indigo-600",
    },
    hasCcModifierRole && {
      key: "2",
      label: "Edit",
      onClick: onEdit,
      icon: SolidEditIcon,
    },
    hasCcCreatorRole && {
      key: "3",
      label: "Clone",
      icon: CloneIcon,
      onClick: onClone,
    },
    // isGlobalAdmin &&
    variable.type === "prompt" && {
      key: "4",
      label: "Prompt Engineering",
      icon: CogIcon,
      onClick: () =>
        navigate(
          `/admin/graph/${companyId}/${campaignId}/${phaseId}/${stepId}/${variable.id.key}`
        ),
    },
    hasCcCreatorRole && {
      key: "5",
      label: "Delete",
      danger: true,
      icon: DeleteIcon,
      onClick: () =>
        confirm({
          action: onDeleteCcVar,
          title: "Delete Item",
        }),
      className: "!text-red-600",
    },
  ].filter((v) => !!v) as MenuDropdownItem[];

  const moreItems = [
    hasCcCreatorRole && {
      key: "6",
      label: "Get Link",
      onClick: () => onLink(variable.id.key),
      icon: GetLinkIcon,
    },
    hasCcCreatorRole && {
      key: "7",
      label: "Get Reference",
      onClick: () => onGetReference(variable.id.key),
      icon: GetReferenceIcon,
    },
    hasCcCreatorRole && {
      key: "8",
      label: "Move Up",
      onClick: () => onMove(prevVariable?.seq),
      disabled: prevVariable === undefined,
      icon: MoveUpIcon,
    },
    hasCcCreatorRole && {
      key: "9",
      label: "Move Down",
      onClick: () => onMove(nextVariable?.seq),
      disabled: nextVariable === undefined,
      icon: MoveDownIcon,
    },
    hasCcCreatorRole && {
      key: "10",
      label: "Insert Before",
      onClick: onInsertBefore,
      icon: InsertBeforeIcon,
    },
    hasCcCreatorRole && {
      key: "11",
      label: "Insert After",
      onClick: onInsertAfter,
      icon: InsertAfterIcon,
    },
  ].filter((v) => !!v) as MenuDropdownItem[];

  return (
    <>
      <MenuDropdown items={items} moreItems={moreItems} align="end">
        <Button
          variant={"ghostPrimary"}
          className="p-[6px] size-[40px]"
          onClick={(e) => e.stopPropagation()}
        >
          <Icons.MoreDots className="size-6" />
        </Button>
      </MenuDropdown>
      <Modal
        title="Execute Item"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <p>Are you sure you want to continue?</p>
        <Checkbox
          checked={updateDownstreamDependencies}
          onChange={({ target: { checked } }) => {
            setUpdateDownstreamDependencies(checked);
            localStorageProvider.setUpdateDownstreamDependencies(checked);
          }}
          className="mt-2"
        >
          Update downstream dependencies
        </Checkbox>
      </Modal>
    </>
  );
};

export default GridItemActions;
