import { Editor } from "@monaco-editor/react";
import { Checkbox, CheckboxProps } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icons } from "src/components/common/Icons";
import { Button } from "src/components/common/ui/button";
import { Textarea } from "src/components/common/ui/textarea";
import useSubmitFormOnEnter from "src/hooks/useSubmitFormOnEnter";
import { getCurrentCampaign } from "src/store/slices/campaignsSlice";
import { getCurrentStep } from "src/store/slices/stepsSlice";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { saveCcItemResultOvrThunk } from "../../../store/slices/ccVariablesSlice";
import { getCurrentPhase } from "../../../store/slices/phasesSlice";
import { AppDispatch } from "../../../store/store";
import { editorLanguages } from "../../storage/dynamicStorage/constants";
import { TCcGridItemData, TOverrideProps } from "../CampaignGrid";

type Props = {
  setOverrideProps: React.Dispatch<React.SetStateAction<TOverrideProps | null>>;
  overrideProps: TOverrideProps;
  record: TCcGridItemData;
  companyId: number | undefined;
};

const GridItemOverrideModal: FC<Props> = ({
  setOverrideProps,
  overrideProps,
  record,
  companyId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const messageApi = useSelector(selectMessageApi);
  const overrideRef = React.useRef<HTMLTextAreaElement>(null);
  const [isOverrideFocused, setIsOverrideFocused] = useState(false);
  const defaultOverrideValue =
    (overrideProps.resultOvr as string) || (overrideProps.result as string);
  const [overrideValue, setOverrideValue] =
    useState<string>(defaultOverrideValue);
  const [recomputeDownstream, setRecomputeDownstream] = useState(false);

  const handleRecompute: CheckboxProps["onChange"] = (e) => {
    setRecomputeDownstream(e.target.checked);
  };

  const isPlainText = record.variable.mimeType === "text/plain";

  const currentCampaign = getCurrentCampaign();
  const currentPhase = getCurrentPhase();
  const currentStep = getCurrentStep();

  //  Disable the submit form on enter for the override input because Editor doesn't support focus and blur events
  useSubmitFormOnEnter(
    () => {
      handleSaveOverride();
    },
    { condition: !isOverrideFocused && isPlainText }
  );

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleSaveOverride = async () => {
    try {
      const { varId } = overrideProps;
      const res = await dispatch(
        saveCcItemResultOvrThunk({
          varId,
          value: overrideValue,
          companyId,
          recomputeDownstream,
        })
      );

      if ("error" in res) {
        messageApi.error(res.payload?.message);
      } else {
        setOverrideProps(null);
      }
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(
        `An error occurred while trying to save the result override:`,
        e
      );
    }
  };

  const handleCancelOverride = () => {
    setOverrideProps(null);
  };

  const handleRestoreOriginal = () => {
    setOverrideValue(overrideProps.result as string);
  };

  const lang = editorLanguages[record.variable.mimeType] || "text";

  return (
    <div className="fixed top-0 left-0 z-50 w-screen h-screen bg-white">
      <div className="w-full h-[72px] bg-slate-900">
        <div
          className="justify-start items-center gap-4 inline-flex h-[72px] pl-[48px] cursor-pointer"
          onClick={handleCancelOverride}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.72007 12.53C7.57962 12.3893 7.50073 12.1987 7.50073 12C7.50073 11.8012 7.57962 11.6106 7.72007 11.47L15.2201 3.96997C15.2887 3.89628 15.3715 3.83718 15.4635 3.79619C15.5555 3.7552 15.6548 3.73316 15.7556 3.73138C15.8563 3.7296 15.9563 3.74813 16.0497 3.78585C16.1431 3.82357 16.2279 3.87971 16.2991 3.95093C16.3703 4.02215 16.4265 4.10698 16.4642 4.20037C16.5019 4.29376 16.5204 4.39379 16.5187 4.49449C16.5169 4.5952 16.4948 4.69451 16.4539 4.78651C16.4129 4.87851 16.3538 4.96131 16.2801 5.02997L9.31007 12L16.2801 18.97C16.3538 19.0386 16.4129 19.1214 16.4539 19.2134C16.4948 19.3054 16.5169 19.4047 16.5187 19.5054C16.5204 19.6062 16.5019 19.7062 16.4642 19.7996C16.4265 19.893 16.3703 19.9778 16.2991 20.049C16.2279 20.1202 16.1431 20.1764 16.0497 20.2141C15.9563 20.2518 15.8563 20.2703 15.7556 20.2686C15.6548 20.2668 15.5555 20.2447 15.4635 20.2038C15.3715 20.1628 15.2887 20.1037 15.2201 20.03L7.72007 12.53Z"
              fill="white"
            />
          </svg>
          <div className="text-white text-xl font-semibold font-sans leading-tight">
            Edit Content
          </div>
        </div>
      </div>
      <div className="w-full flex">
        <div className="w-full justify-start items-start gap-2 inline-flex px-[48px] py-[16px] border-b border-b-slate-200">
          <div className="text-slate-600 text-xs font-medium font-sans leading-3 flex gap-2">
            <span>{currentCampaign?.name}</span>
            <span>/</span>
            <span>{currentPhase?.name}</span>
            <span>/</span>
            <span>{currentStep?.name}</span>
            <span>/</span>
            <span>{record.varKey}</span>
          </div>
        </div>
      </div>
      <div className="flex px-[48px] w-full justify-between gap-[24px] pt-[33px] h-[calc(100vh-280px)]">
        <div className="w-full h-full">
          <div className="flex justify-between w-full">
            <div className="flex items-center">
              <h2 className="text-slate-900 text-lg font-semibold  leading-[18px] mr-[16px]">
                Override Version
              </h2>
              <div className=" h-[23px] px-3 py-[7px] rounded-full border border-slate-100 justify-center items-center gap-2.5 inline-flex">
                <div className="text-slate-500 text-xs font-semibold font-sans leading-3 line-clamp-1">
                  Edit Version
                </div>
              </div>
            </div>
          </div>
          {isPlainText && (
            <Textarea
              className="w-full mt-[24px] h-full p-[24px]"
              onChange={(e) => setOverrideValue(e.target.value)}
              value={overrideValue}
              ref={overrideRef}
              onFocus={() => setIsOverrideFocused(true)}
              onBlur={() => setIsOverrideFocused(false)}
            />
          )}
          {!isPlainText && (
            <Editor
              className="w-full h-full border border-slate-200 rounded-md mt-[24px]"
              defaultLanguage={lang || "text"}
              value={overrideValue}
              onChange={(value) => setOverrideValue(value || "")}
            />
          )}
          <div className="flex justify-between gap-3 mt-[58px]">
            <div className="flex items-center gap-2">
              <Checkbox onChange={handleRecompute}>
                <span className="text-sm">Reset downstream dependencies</span>
              </Checkbox>
            </div>
            <div className="flex gap-3">
              <Button
                variant={"outline"}
                className="rounded-full px-[32px]"
                onClick={handleCancelOverride}
              >
                Cancel
              </Button>
              <Button
                className="rounded-full px-[32px]"
                onClick={handleSaveOverride}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="flex items-center justify-between">
            <h2 className="text-slate-900 text-lg font-semibold  leading-[18px]">
              Result Output
            </h2>
            <Button
              className="rounded-full"
              onClick={handleRestoreOriginal}
              icon={Icons.restore}
              size={"sm"}
              disabled={overrideValue === overrideProps.result}
            >
              Restore Original
            </Button>
          </div>
          <Textarea
            className="w-full mt-[10px] h-full p-[24px]"
            defaultValue={overrideProps.result as string}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

export default GridItemOverrideModal;
