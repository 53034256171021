import {
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import { Tooltip } from "antd";
import { Button } from "src/components/common/ui/button";

export const DocViewActions = ({
  onReloadClick,
  onTabPreviewClick,
  disabled,
  isBlob,
}: {
  onReloadClick: () => void;
  onTabPreviewClick: () => void;
  disabled: boolean;
  isBlob: boolean;
}) => {
  return (
    <div>
      <Button
        onClick={onReloadClick}
        variant="ghost"
        icon={ArrowPathIcon}
        size="sm"
        disabled={disabled}
        iconClassName="size-4"
      >
        Reload
      </Button>
      <Tooltip
        title={
          <p className="text-center">
            {isBlob
              ? "Click to preview template in separate tab"
              : "Please, select template to preview template in separate tab"}
          </p>
        }
      >
        <Button
          onClick={onTabPreviewClick}
          variant="ghost"
          icon={ArrowTopRightOnSquareIcon}
          size="sm"
          disabled={disabled}
          iconClassName="size-4"
        >
          Tab Preview
        </Button>
      </Tooltip>
    </div>
  );
};
