import { Spin } from "antd";
import React, { FC, useState } from "react";
import { Button } from "src/components/common/ui/button";
import { getAssetsApi } from "../../../api/assets.api";
import { TAsset } from "../../../globalTypes";
import handleRequestError from "../../../utils/handleRequestError";
import { Icons } from "../../common/Icons";
import AssetModal from "../AssetModal/AssetModal";
import AssetsList from "../AssetsList/AssetsList";
import UploadAssetsModal from "../UploadAssetsModal/UploadAssetsModal";

type PropsType = {
  assets: Array<TAsset>;
  stepId: number;
  campaignId: number;
  companyId: number | undefined;
  setAssets: React.Dispatch<React.SetStateAction<TAsset[]>>;
};

const CampaignAssets: FC<PropsType> = (props) => {
  const { assets, companyId, stepId, campaignId, setAssets } = props;
  const [isUploadAssetsModalOpen, setIsUploadAssetsModalOpen] = useState(false);
  const [isAddAssetModalOpen, setIsAddAssetModalOpen] = useState(false);
  const [editAssetData, setEditAssetData] = useState<TAsset | null>(null);
  const [isFetching, setIsFetching] = useState(false);

  const onAddAsset = () => {
    setIsAddAssetModalOpen(true);
    setEditAssetData(null);
  };

  const onUploadAssets = () => {
    setIsUploadAssetsModalOpen(true);
  };

  const onReloadAssets = async () => {
    try {
      setIsFetching(true);

      const { data } = await getAssetsApi({ campaignId, stepId, companyId });

      setAssets(data);
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className="flex flex-col gap-[8px]">
      <div className="flex gap-[6px]">
        <Button
          onClick={onAddAsset}
          variant="primaryOutline"
          className="rounded-full self-start"
          size="sm"
          icon={Icons.Plus}
        >
          Add Asset
        </Button>
        <Button
          onClick={onUploadAssets}
          variant="primaryOutline"
          className="rounded-full self-start"
          size="sm"
          icon={Icons.UploadIcon}
        >
          Upload Assets
        </Button>
        <Button
          onClick={onReloadAssets}
          className="rounded-full"
          variant="primaryOutline"
          icon={Icons.rerun}
          size="sm"
          iconClassName="size-4"
        >
          Reload Assets
        </Button>
      </div>
      <Spin spinning={isFetching}>
        <AssetsList
          companyId={companyId}
          campaignId={+campaignId}
          assets={assets}
          setAssets={setAssets}
          setIsModalOpen={setIsAddAssetModalOpen}
          setEditAsset={setEditAssetData}
        />
      </Spin>

      {isAddAssetModalOpen && (
        <AssetModal
          editAsset={editAssetData}
          setAssets={setAssets}
          isModalOpen={isAddAssetModalOpen}
          setIsModalOpen={(open) => setIsAddAssetModalOpen(open)}
          campaignId={+campaignId}
          companyId={companyId}
          stepId={+stepId}
        />
      )}

      {isUploadAssetsModalOpen && (
        <UploadAssetsModal
          isModalOpen={isUploadAssetsModalOpen}
          setIsModalOpen={(open) => setIsUploadAssetsModalOpen(open)}
          campaignId={+campaignId}
          stepId={+stepId}
          addAsset={(newAsset) => setAssets((assets) => [...assets, newAsset])}
        />
      )}
    </div>
  );
};

export default CampaignAssets;
