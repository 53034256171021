import { Tooltip } from "antd";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Icons } from "src/components/common/Icons";
import { Button } from "src/components/common/ui/button";
import { getMessageApi } from "src/store/slices/appSlice";
import {
  TCcVariable,
  TCcVariableType,
} from "src/store/slices/ccVariablesSlice";
import styles from "./GridItemResult.module.scss";

type Props = {
  variable: TCcVariable;
  value: string | null;
  onClickEdit?: () => void;
};

const unableToEditTypes: TCcVariableType[] = ["string", "asset"];

const ROW_CONTENT_MAX_LENGTH = 500;

const GridItemResult: React.FC<Props> = ({ value, onClickEdit, variable }) => {
  const { type, resultOvr } = variable;
  const [copied, setCopied] = useState(false);
  const messageApi = getMessageApi();
  const [firstRender, setFirstRender] = useState(true);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (value) {
      if (firstRender) {
        setFirstRender(false);
      } else {
        setAnimate(true);
        const timeout = setTimeout(() => setAnimate(false), 2500); // Длительность вашей анимации
        return () => clearTimeout(timeout);
      }
    }
  }, [value]);

  if (!value) return <></>;

  let content: string | React.ReactElement = value;

  const handleEdit = () => {
    onClickEdit?.();
  };

  if (value && value.length > ROW_CONTENT_MAX_LENGTH) {
    const processedValue = value.slice(0, ROW_CONTENT_MAX_LENGTH) + "...";

    content = (
      <Tooltip
        overlayStyle={{
          maxWidth: "30vW",
          maxHeight: "30vh",
          overflowY: "scroll",
        }}
        title={value}
        overlayInnerStyle={{
          width: "100%",
          height: "100%",
          maxHeight: "30vh",
          overflowY: "scroll",
          whiteSpace: "pre-wrap",
          fontSize: "12px",
        }}
      >
        {processedValue}
      </Tooltip>
    );
  }

  const handleCopy = () => {
    messageApi.success("Copied to clipboard");
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className="flex flex-col p-2">
      {resultOvr && (
        <Tooltip title="Override">
          <div className="absolute top-[-5px] right-[-10px] w-[30px] h-[20px] bg-gradient-to-t from-white to-yellow-500 rotate-45" />
        </Tooltip>
      )}
      <span
        className={clsx(
          `w-full whitespace-pre-line`,
          animate ? styles.updated : ""
        )}
      >
        {content}
      </span>
      <div className="pt-1">
        {!unableToEditTypes.includes(type) && (
          <Button
            onClick={handleEdit}
            icon={Icons.edit}
            size={"sm"}
            variant={"primaryGhost"}
            className="rounded-full"
          >
            Edit
          </Button>
        )}
        <Button
          onClick={handleCopy}
          icon={copied ? Icons.checkmark : Icons.copy}
          size={"sm"}
          variant={"primaryGhost"}
          className="rounded-full"
        >
          <span>{copied ? "Copied" : "Copy"}</span>
        </Button>
      </div>
    </div>
  );
};

export default GridItemResult;
