import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components/common/ui/button";
import MenuDropdown from "src/components/common/MenuDropdown";
import {
  DownloadIcon,
  GlobalStorageIcon,
  Icons,
  LabelsIcon,
  MiniFolderIcon,
} from "../../common/Icons";
import ImportCampaignModal from "../ImportCampaignModal/ImportCampaignModal";

type Props = {
  companyId?: number;
  showCreateButton: boolean;
  onCampaignCreate: () => Promise<void>;
};

const SettingsMenu: FC<Props> = ({
  showCreateButton,
  onCampaignCreate,
  companyId,
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const actionsItems = [
    {
      key: "import_campaign",
      label: "Import Campaign",
      icon: DownloadIcon,
      onClick: () => setIsModalOpen(true),
    },
    {
      key: "global_labels",
      label: "Global Labels",
      icon: LabelsIcon,
      onClick: () => navigate("/campaigns/globalLabels"),
    },
    {
      key: "global_storage",
      label: "Global Storage",
      icon: GlobalStorageIcon,
      onClick: () => navigate("/campaigns/storage/public"),
    },
    {
      key: "company_storage",
      label: "Company Storage",
      icon: MiniFolderIcon,
      disabled: !companyId,
      onClick: () => navigate(`/campaigns/company/${companyId}/storage/public`),
    },
  ];

  return (
    <>
      <div className="flex gap-3">
        <MenuDropdown items={actionsItems} dropdownClassName="w-[190px]">
          <Button variant={"primaryOutline"} className="text-sm font-medium">
            Actions
            <Icons.More className="size-4 ml-2" />
          </Button>
        </MenuDropdown>
        <Button
          className="rounded-full"
          onClick={onCampaignCreate}
          disabled={!showCreateButton}
        >
          <Icons.Plus className="mr-[4px]" />
          Add Draft Template
        </Button>
      </div>
      {isModalOpen && (
        <ImportCampaignModal
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
        />
      )}
    </>
  );
};

export default SettingsMenu;
