import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import { useEffect, useState } from "react";
import { TCompanyShortInfo, TNavigationItem } from "../../../../globalTypes";
import handleRequestError from "../../../../utils/handleRequestError";
import { getCompanyDataApi } from "../../../../api/companies.api";

const useCompanyStorageData = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const messageApi = useSelector(selectMessageApi);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [companyData, setCompanyData] = useState<TCompanyShortInfo | null>(
    null
  );

  useEffect(() => {
    getCampaignData();
  }, []);

  const onGoBack = () => navigate("/admin/campaigns/");

  const navigationItems: TNavigationItem[] = [
    {
      path: "public",
      title: "Public",
    },
    {
      path: "private",
      title: "Private",
    },
    {
      path: "microsite",
      title: "Microsite",
    },
  ];

  const getCampaignData = async () => {
    if (!companyId || companyId === "global") {
      messageApi.error("Unable to load the company view");
      console.error("Unable to get [companyId]");
      setIsLoading(false);
      return;
    }

    try {
      const { data } = await getCompanyDataApi({ companyId });

      setError(null);
      setCompanyData(data);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);

      setError(customError.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    companyName: companyData?.name,
    onGoBack,
    navigationItems,
  };
};

export default useCompanyStorageData;
