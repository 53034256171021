import {
  CloneIcon,
  DeleteIcon,
  DownloadIcon,
  GetLinkIcon,
  Icons,
  SolidEditIcon,
  UploadIcon,
} from "../../../common/Icons";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { GlobeAsiaAustraliaIcon } from "@heroicons/react/16/solid";

const allowedFilesToEdit = [
  "text/html",
  "application/xml",
  "text/plain",
  "text/csv",
  "text/css",
  "application/json",
];

const editorLanguages: { [key: string]: string } = {
  "application/xml": "xml",
  "text/html": "html",
  "text/plain": "text",
  "text/csv": "csv",
  "text/css": "css",
  "application/json": "json",
};

const menuOptions = {
  editFile: {
    key: "editFile",
    label: "Edit File",
    icon: SolidEditIcon,
  },
  createFolder: {
    key: "createFolder",
    label: "Create Folder",
    icon: Icons.Plus,
  },
  copyFile: {
    key: "copyFile",
    label: "Copy File",
    icon: CloneIcon,
  },
  download: {
    key: "download",
    label: "Download",
    icon: DownloadIcon,
  },
  upload: {
    key: "upload",
    label: "Upload",
    icon: UploadIcon,
  },
  delete: {
    key: "delete",
    label: "Delete",
    icon: DeleteIcon,
    className: "!text-red-600",
  },
  getLink: {
    key: "getLink",
    label: "Get Link",
    icon: GetLinkIcon,
  },
  tabPreview: {
    key: "tabPreview",
    label: "Tab Preview",
    icon: ArrowTopRightOnSquareIcon,
  },
  getPublicLink: {
    key: "getPublicLink",
    label: "Get Public AWS Link",
    icon: GlobeAsiaAustraliaIcon,
  },
};

export { allowedFilesToEdit, editorLanguages, menuOptions };
