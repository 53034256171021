import { UploadCloudIcon } from "lucide-react";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuDropdown from "src/components/common/MenuDropdown";
import { Button } from "src/components/common/ui/button";
import { Icons, ListIcon } from "../../common/Icons";
import { UploadTemplatePackageModal } from "../../common/modals/UploadTemplatePackage";
import ClassesListModal from "../../common/modals/ClassesListModal/ClassesListModal";

type Props = {};

const SettingsMenu: FC<Props> = () => {
  const navigate = useNavigate();
  const [uploadOpen, setUploadOpen] = useState(false);
  const [classesOpen, setClassesOpen] = useState(false);

  const actionsItems = [
    {
      key: "0",
      label: "Upload template package",
      icon: UploadCloudIcon,
      onClick: () => setUploadOpen(true),
    },
    {
      key: "1",
      label: "Add XSLT from HTML",
      icon: Icons.Plus,
      onClick: () => navigate("/admin/document-templates/generate-figma"),
    },
    {
      key: "2",
      label: "Classes",
      icon: ListIcon,
      onClick: () => setClassesOpen(true),
    }
  ];

  return (
    <>
      <div className="flex gap-3">
        <MenuDropdown items={actionsItems} dropdownClassName="w-[230px]">
          <Button variant={"primaryOutline"} className="text-sm font-medium">
            Actions
            <Icons.More className="size-4 ml-2" />
          </Button>
        </MenuDropdown>
      </div>
      {uploadOpen && <UploadTemplatePackageModal open={uploadOpen} setOpen={setUploadOpen} />}
      {classesOpen && <ClassesListModal open={classesOpen} setOpen={setClassesOpen}/>}
    </>
  );
};

export default SettingsMenu;
