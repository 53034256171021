export type NodeNavItem = {
  label: ActiveTab;
  active: boolean;
  renderComponent: () => React.ReactElement;
};

export enum ActiveTab {
  Result = "Result",
  Override = "Override",
  Model = "Model",
  Prompt = "Prompt",
}

export type TemplateOptionInfo = {
  title: string;
  addedKeysNumber: number;
  allKeysNumber: number;
  mimeType: string;
};
