import { Button, Spin } from "antd";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { createEnvVarApi } from "../../../api/env-variables.api";
import { ChangeNodeProps } from "../../../pages/graph/hooks/useGetNodes.hook";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { TCcVariable } from "../../../store/slices/ccVariablesSlice";
import handleRequestError from "../../../utils/handleRequestError";
import useGetPrompt from "./useGetPrompt.hook";

type Props = {
  changeNodeProps?: ChangeNodeProps;
  ccItem: TCcVariable;
};

const EditPrompt: React.FC<Props> = ({ ccItem, changeNodeProps }) => {
  const { campaignId, stepId, key } = ccItem.id;
  const editableDivRef = useRef<HTMLDivElement>(null);
  const [isFetching, setIsFetching] = useState(false);
  const messageApi = useSelector(selectMessageApi);
  const { initialized, splitPrompt, error } = useGetPrompt({
    campaignId,
    stepId,
    key,
  });

  if (!initialized) return <Spin />;
  const handleSaveClick = async () => {
    try {
      if (editableDivRef.current && ccItem.options?.promptKey) {
        setIsFetching(true);

        const spans = editableDivRef.current.querySelectorAll("span");
        let updatedText = "";

        spans.forEach((span) => {
          updatedText += span.textContent || "";
        });

        const promptContent = updatedText.replace(/<\|[^|]+\|>/g, "%s");
        const values = {
          key: ccItem.options.promptKey,
          type: ccItem.type,
          value: promptContent,
        };

        await createEnvVarApi(campaignId, values, "update", undefined);

        changeNodeProps?.({
          nodeId: `${key}_${stepId}`,
          props: { touched: true },
        });
        messageApi.success("The prompt has been successfully updated");
      }
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className="flex flex-col">
      {error ? (
        <span className="text-red-600">{error}</span>
      ) : (
        <>
          <div ref={editableDivRef}>
            {splitPrompt.map((element, index) => (
              <React.Fragment key={index}>{element} </React.Fragment>
            ))}
          </div>
          <Button
            size="small"
            type="primary"
            loading={isFetching}
            onClick={handleSaveClick}
            className="self-end"
          >
            Save Prompt
          </Button>
        </>
      )}
    </div>
  );
};

export default EditPrompt;
