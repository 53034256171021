import { Editor } from "@monaco-editor/react";
import { Drawer } from "antd";
import { FC, useState } from "react";
import { Button as CustomButton } from "../../common/ui/button";
import { editorLanguages } from "./constants";

type Props = {
  setIsEditFileOpen: (isEditFileOpen: boolean) => void;
  isEditFileOpen: boolean;
  fileData: string | undefined;
  setFileData: (fileData: string | undefined) => void;
  onSaveFile: (content: string) => Promise<void>;
  mimeType: string;
};

const EditFileDrawer: FC<Props> = ({
  setIsEditFileOpen,
  isEditFileOpen,
  fileData,
  onSaveFile,
  setFileData,
  mimeType,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const lang = editorLanguages[mimeType] || "xml";

  const onSaveFileHandler = async () => {
    if (!fileData) return;

    setIsFetching(true);
    await onSaveFile(fileData);
    setIsFetching(false);
  };

  return (
    <Drawer
      title={<span className="font-sans text-white text-base">Edit File</span>}
      placement="right"
      onClose={() => setIsEditFileOpen(false)}
      open={isEditFileOpen}
      styles={{
        header: { background: "#0D0A37", borderBottom: "0" },
        footer: { borderTop: "1px solid #CBD5E1", paddingLeft: "40px" },
        body: { padding: "40px" },
      }}
      width={"65%"}
      footer={
        <div className="flex justify-start gap-[8px]">
          <CustomButton
            className="rounded-full"
            disabled={isFetching || !fileData}
            loading={isFetching}
            onClick={onSaveFileHandler}
          >
            Save File
          </CustomButton>
          <CustomButton
            className="border border-gray-600 text-gray-600 w-[94px]"
            variant="primaryOutline"
            onClick={() => setIsEditFileOpen(false)}
          >
            Cancel
          </CustomButton>
        </div>
      }
    >
      <Editor
        theme="vs-dark"
        language={lang}
        value={fileData}
        onChange={setFileData}
      />
    </Drawer>
  );
};

export default EditFileDrawer;
