import { usePostHog } from "posthog-js/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert } from "src/components/alerts/Alert";
import { loginThunk } from "../../../store/slices/usersSlice";
import { AppDispatch } from "../../../store/store";
import "./style.scss";
import localStorageProvider from "../../../utils/localStorageProvider";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

type TGoogleServiceAuthRes = {
  clientId: string;
  client_id: string;
  credential: string;
  select_by: string;
};

const GoogleOauth: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const posthog = usePostHog();
  const [error, setError] = useState<any>();
  const signInBtnId = "signInBtn";

  useEffect(() => {
    //@ts-ignore
    google.accounts.id.initialize({
      client_id: CLIENT_ID,
      callback: handleAuthSuccess,
    });
    //@ts-ignore
    google.accounts.id.renderButton(document.getElementById(signInBtnId), {
      theme: "outline",
      size: "large",
    });
  }, []);

  const handleAuthSuccess = async (response: TGoogleServiceAuthRes) => {
    setError(undefined);

    if ("credential" in response) {
      try {
        const { email, role } = await dispatch(
          loginThunk(response.credential)
        ).unwrap();

        const lastUrl = localStorageProvider.getLastUrl();

        const redirectPath = lastUrl
          ? lastUrl
          : role !== "user"
            ? "/admin/companies"
            : "/admin/campaigns";

        posthog?.identify(email);

        posthog?.capture("The user has logged in");

        navigate(redirectPath);
      } catch (err) {
        setError(err);
      }
    }
  };

  return (
    <div className="auth-wrapper">
      <div id={signInBtnId} />
      {error?.errorCode === "USER_NOT_EXISTS" && (
        <Alert title="User not found">
          Sorry, you don't have a registered account with Velocity Engine. Click
          here to{" "}
          <a
            href="mailto:hello@shastaelevate.com?subject=Account Troubleshooting"
            rel="noreferrer nofollower"
            target="_blank"
          >
            send us a note
          </a>
        </Alert>
      )}
    </div>
  );
};

export default GoogleOauth;
