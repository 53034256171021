import { Form } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompaniesApi } from "../../../api/companies.api";
import { getUserDataApi } from "../../../api/user.api";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { AppDispatch } from "../../../store/store";
import handleRequestError from "../../../utils/handleRequestError";
import { TUserForm } from "../UserForm/UserForm";

type Props = {
  visibleDrawer?: boolean;
  userId?: number;
};

const useGetUserData = ({ userId, visibleDrawer }: Props = {}) => {
  const dispatch: AppDispatch = useDispatch();
  const [form] = Form.useForm<TUserForm>();
  const messageApi = useSelector(selectMessageApi);
  const [isFetching, setIsFetching] = useState(false);
  const [companiesOptions, setCompaniesOptions] = useState<
    { id: number; name: string }[]
  >([]);
  const [companiesList, setCompaniesList] = useState<
    { id: number; name: string }[]
  >([]);

  useEffect(() => {
    if (visibleDrawer) {
      form.resetFields();
      userId !== undefined ? getUserData(userId) : getUserCompanies();
    }
  }, [userId, visibleDrawer]);

  const getUserCompanies = async () => {
    try {
      setIsFetching(true);

      const { data } = await getCompaniesApi();

      setCompaniesOptions(data.companies);
      setCompaniesList(data.companies);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const getUserData = async (userId: number) => {
    try {
      setIsFetching(true);

      const { data } = await getUserDataApi(+userId);
      const { user, userCompanies } = data;
      const { companies: companiesWithPermissions, ...userData } = user;
      const { editor, viewer } = companiesWithPermissions;

      form.setFieldsValue({
        ...userData,
        editor: editor?.map((company) => company.name),
        viewer: viewer?.map((company) => company.name),
      });

      const filtered = userCompanies.filter((company) => {
        const isEditIncluded = editor?.some(
          (editorCompany) => editorCompany.id === company.id
        );
        const isViewIncluded = viewer?.some(
          (viewerCompany) => viewerCompany.id === company.id
        );
        return !(isEditIncluded || isViewIncluded);
      });

      setCompaniesOptions(filtered);
      setCompaniesList(userCompanies);
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    companiesOptions,
    companiesList,
    messageApi,
    userId,
    dispatch,
    form,
    isFetching,
    setIsFetching,
  };
};

export default useGetUserData;
