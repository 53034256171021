import { Badge } from "antd";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { Icons } from "src/components/common/Icons";
import { Button } from "src/components/common/ui/button";
import {
  getMessagesCount,
  resetComputationMessages, setComputationStatus, setComputationType, setExecutionType,
  setProgress,
} from "../../../store/slices/computationMessagesSlice";
import { AppDispatch } from "../../../store/store";
import ComputationMessagesModal from "../ComputationMessagesModal/ComputationMessagesModal";

type Props = {
  campaignId: number;
  companyId: number | undefined;
};
const ComputationMessages: FC<Props> = ({ companyId, campaignId }) => {
  const messagesCount = getMessagesCount();
  const [isListOfMessagesOpened, setIsListOfMessagesOpened] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const onCloseAndClear = () => {
    setIsListOfMessagesOpened(false);
    dispatch(resetComputationMessages());
    dispatch(setProgress(0));
    dispatch(setComputationStatus(null));
    dispatch(setComputationType(null));
    dispatch(setExecutionType(null));
  };

  return (
    <>
      <Badge count={messagesCount} overflowCount={9999}>
        <Button
          size="sm"
          onClick={() => setIsListOfMessagesOpened(true)}
          className="rounded-full"
          variant={"ghost"}
          icon={Icons.docs}
        >
          Event Log
        </Button>
      </Badge>
      {isListOfMessagesOpened && (
        <ComputationMessagesModal
          campaignId={campaignId}
          companyId={companyId}
          onCloseAndClear={onCloseAndClear}
          setIsListOfMessagesOpened={setIsListOfMessagesOpened}
          isListOfMessagesOpened={isListOfMessagesOpened}
        />
      )}
    </>
  );
};

export default ComputationMessages;
