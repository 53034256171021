import { getCampaignStructureApi } from "src/api/company-storage.api";
import { TAsset, TFileTreeItem } from "src/globalTypes";
import { CampaignDataAssetsStructure } from "src/store/slices/campaignsSlice";
import { TStep } from "src/store/slices/stepsSlice";
import { AssetsFileTreeItem } from "./types";

export const convertAssetToFileTreeItem = (
  asset: TAsset,
  selectedStep: TStep
): TFileTreeItem => ({
  title: asset.title,
  folder: false,
  fileName: asset.title,
  mimeType: "",
  parentId: selectedStep.naturalId,
  level: 3,
  size: 0,
  // @ts-ignore
  assetData: asset,
});

const convertCampaignDataToFileTree = (
  data: CampaignDataAssetsStructure
): TFileTreeItem[] => {
  const allPhases = data.phases.map((phase) => phase);
  const allSteps = data.phases
    .map((phase) =>
      phase.steps.map((step) => ({ ...step, parentId: phase.naturalId }))
    )
    .flat();

  const allAssets = data.phases
    .map((phase) =>
      phase.steps.map((step) =>
        step.assets.map((asset) => ({ ...asset, parentId: step.naturalId }))
      )
    )
    .flat()
    .flat();

  const phasesFolders: TFileTreeItem[] = allPhases.map((phase) => ({
    title: phase.name,
    folder: true,
    fileName: phase.naturalId,
    mimeType: "",
    parentId: "campaign",
    level: 1,
    size: 0,
    phaseData: phase,
  }));

  const stepsFolders: TFileTreeItem[] = allSteps.map((step) => ({
    title: step.name,
    folder: true,
    fileName: step.naturalId,
    mimeType: "",
    parentId: step.parentId,
    level: 2,
    size: 0,
    stepData: step,
  }));

  const assetsFiles: AssetsFileTreeItem[] = allAssets.map((asset) => ({
    title: asset.title,
    folder: false,
    fileName: asset.title,
    mimeType: "",
    parentId: asset.parentId,
    level: 3,
    size: 0,
    assetData: asset,
  }));

  return [
    {
      title: data.name,
      folder: true,
      fileName: "campaign",
      mimeType: "",
      parentId: null,
      level: 0,
      size: 0,
      isRootDir: true,
      expanded: true,
    },
    ...phasesFolders,
    ...stepsFolders,
    ...assetsFiles,
  ];
};

export const getCampaignViewFileTree = async ({
  companyId,
  campaignId,
}: {
  companyId: string;
  campaignId: string;
}) => {
  const { data } = await getCampaignStructureApi({
    companyId,
    campaignId,
  });

  return convertCampaignDataToFileTree(data);
};
