import { useSelector } from "react-redux";
import {
  UserPermissions,
  selectUserData,
  selectUserPermissions,
} from "src/store/slices/usersSlice";

export const useCurrentUser = () => {
  const data = useSelector(selectUserData);
  const permissions = useSelector(selectUserPermissions);
  const isGlobalAdmin = data.role === "global_admin";

  const hasPermission = (permission: UserPermissions): boolean =>
    permissions.list.includes(permission);

  return {
    data: data,
    isGlobalAdmin,
    isRegularUser: data.role === "user",
    isAdmin: data.role === "admin",
    hasPermission,
    hasPhaseEditRole:
      isGlobalAdmin || hasPermission(UserPermissions.ROLE_PHASE_EDIT),
    hasPhaseDeleteRole:
      isGlobalAdmin || hasPermission(UserPermissions.ROLE_PHASE_DELETE),
    hasPhaseCloneRole:
      isGlobalAdmin || hasPermission(UserPermissions.ROLE_PHASE_CLONE),
    hasPhaseCreateRole:
      isGlobalAdmin || hasPermission(UserPermissions.ROLE_PHASE_CREATE),
    hasPhaseCopyRole:
      isGlobalAdmin || hasPermission(UserPermissions.ROLE_PHASE_COPY),
    hasPhaseDeployRole:
      isGlobalAdmin || hasPermission(UserPermissions.ROLE_PHASE_DEPLOY),
    hasPhaseListRole:
      isGlobalAdmin || hasPermission(UserPermissions.ROLE_PHASE_VIEW),
    hasStepCreateRole:
      isGlobalAdmin || hasPermission(UserPermissions.ROLE_STEP_CREATE),
    hasStepEditRole:
      isGlobalAdmin || hasPermission(UserPermissions.ROLE_STEP_EDIT),
    hasStepCloneRole:
      isGlobalAdmin || hasPermission(UserPermissions.ROLE_STEP_CLONE),
    hasStepDeleteRole:
      isGlobalAdmin || hasPermission(UserPermissions.ROLE_STEP_DELETE),
    hasStepDeployRole:
      isGlobalAdmin || hasPermission(UserPermissions.ROLE_STEP_DEPLOY),
    hasStepCopyRole:
      isGlobalAdmin || hasPermission(UserPermissions.ROLE_STEP_COPY),
    hasStepListRole:
      isGlobalAdmin || hasPermission(UserPermissions.ROLE_STEP_VIEW),
  };
};
