import { useSelector } from "react-redux";
import CampaignsPagination from "src/components/campaignsList/CampaignsPagination";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import {
  getCurrentCompany,
  selectCompanies,
} from "src/store/slices/companiesSlice";
import CampaignListHeader from "../../components/campaignsList/CampaignListHeader";
import CampaignsList from "../../components/campaignsList/CampaignsList";
import { EmptyCampaignPage } from "../../components/campaignsList/EmptyCampaigsList";
import useCampaignsList from "./hooks/useCampaignsList";

const CampaignsPage = () => {
  const {
    requestParams,
    additionalParams,
    requestCampaignsList,
    handleChangePage,
    handleChangePageSize,
    handleChangeCompany,
    handleChangeCampaignType,
  } = useCampaignsList();

  const companies = useSelector(selectCompanies);
  const currentCompany = getCurrentCompany();

  const { isGlobalAdmin } = useCurrentUser();

  if (!isGlobalAdmin && !companies.length) return <EmptyCampaignPage />;

  return (
    <div className="flex flex-col w-full gap-[24px]">
      <CampaignListHeader
        requestParams={requestParams}
        backUrl={additionalParams.backUrl || ""}
        currentCompany={currentCompany}
        campaignType={requestParams.campaignType}
        onCompanyChange={handleChangeCompany}
        onCampaignTypeChange={handleChangeCampaignType}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
      />
      <CampaignsList
        requestParams={requestParams}
        requestCampaignsList={requestCampaignsList}
      />
      <CampaignsPagination
        page={requestParams.page}
        pageSize={requestParams.pageSize}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
      />
    </div>
  );
};

export default CampaignsPage;
