import { AxiosResponse } from "axios";
import { PROXY_PREFIX } from "../constants";
import { TStorageFileItem } from "../globalTypes";
import axiosInstance from "../utils/axios/axiosInstance";

export const getMicroSiteStorageFilesApi = ({
  path,
  companyId,
}: {
  path: string;
  companyId: string;
}): Promise<AxiosResponse<TStorageFileItem[]>> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/company/${companyId}/microsite/fs/list`,
    {
      params: {
        companyId,
        path,
      },
    }
  );
};

export const getMicroSiteStorageFileApi = ({
  path,
  companyId,
}: {
  path: string;
  companyId: string;
}): Promise<AxiosResponse<ArrayBuffer>> => {
  return axiosInstance.get(
    `/api/secure/campaignManager/company/${companyId}/microsite/fs/file`,
    {
      responseType: "arraybuffer",
      params: {
        companyId,
        path,
      },
    }
  );
};

export const deleteMicroSiteStorageFileApi = ({
  path,
  companyId,
}: {
  path: string;
  companyId: string;
}): Promise<AxiosResponse<string>> => {
  return axiosInstance.delete(
    `${PROXY_PREFIX}/api/secured/company/${companyId}/microsite/fs/file`,
    {
      params: {
        companyId,
        path,
      },
    }
  );
};

export const uploadMicroSiteStorageFileApi = ({
  formData,
  companyId,
}: {
  formData: FormData;
  companyId: string;
}): Promise<AxiosResponse<TStorageFileItem>> => {
  return axiosInstance.post(
    `${PROXY_PREFIX}/api/secured/company/${companyId}/microsite/fs/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        companyId,
      },
    }
  );
};

export const copyMicroSiteStorageFileApi = ({
  sourcePath,
  targetPath,
  companyId,
}: {
  sourcePath: string;
  targetPath: string;
  companyId: string;
}): Promise<AxiosResponse<TStorageFileItem>> => {
  return axiosInstance.post(
    `${PROXY_PREFIX}/api/secured/company/${companyId}/microsite/fs/copy`,
    {},
    {
      params: {
        companyId,
        sourcePath,
        targetPath,
      },
    }
  );
};

export const getMicroSiteStorageFileLinkApi = ({
  path,
  companyId,
}: {
  path: string;
  companyId: string;
}): Promise<AxiosResponse<string>> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/company/${companyId}/microsite/fs/link`,
    {
      params: {
        companyId,
        path,
      },
    }
  );
};
