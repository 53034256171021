import { EditOutlined, SettingTwoTone } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import { TEnvVarData, TEnvVariable } from "../../../store/slices/envVariablesSlice";
import React from "react";

type PropsType = {
  variable: TEnvVarData;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setEditModeProps: React.Dispatch<React.SetStateAction<TEnvVariable | null>>;
};

const VariableItemSettings = (props: PropsType) => {
  const { variable, setIsOpened, setEditModeProps } = props;
  const onEditEnvVar = () => {
    setEditModeProps(variable.variable);
    setIsOpened(true);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Edit",
      style: { width: "100px" },
      icon: <EditOutlined />,
      onClick: onEditEnvVar,
    },
  ];

  return (
    <Dropdown menu={{ items }} placement="bottom">
      <SettingTwoTone style={{ cursor: "pointer" }} />
    </Dropdown>
  );
};

export default VariableItemSettings;
