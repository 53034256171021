import { Empty, Spin } from "antd";
import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { useDocTemplatesList } from "src/hooks/useDocTemplatesList";
import {
  DocumentTemplateField,
  SortingDirection,
} from "src/types/docTemplates";
import { Table, TableBody, TableRow } from "../common/ui/table";
import { TableHeader } from "../table/Header";
import DocTemplateListItem from "./ListItem";

type Props = {};

const tableHeaders = [
  {
    title: "Name",
    name: "name",
    className: "font-sans w-1/4 text-black",
    isSortable: true,
  },
  {
    title: "Media Type",
    name: "mediaType",
    className: "font-sans w-1/4 text-black",
    isSortable: false,
  },
  {
    title: "State",
    className: "font-sans w-1/4 text-black",
    name: "state",
    isSortable: false,
  },
  {
    title: "Step Classes",
    className: "font-sans w-1/4 text-black",
    name: "classes",
    isSortable: false,
  },
  null,
];

const DocTemplatesList: FC<Props> = () => {
  const { isGlobalAdmin } = useCurrentUser();
  const {
    list,
    loading,
    load,
    pageNumber,
    pageSize,
    states,
    updateSorting,
    sorting,
  } = useDocTemplatesList();

  useEffect(() => {
    load();
  }, [pageNumber, pageSize, states, sorting.field, sorting.direction]);

  const handleUpdateSorting = (field: string) => {
    updateSorting(
      field as DocumentTemplateField,
      sorting.direction === SortingDirection.ASC
        ? SortingDirection.DESC
        : SortingDirection.ASC
    );
  };

  return (
    <Spin spinning={loading}>
      {!list?.length && (
        <div className="w-full h-full flex justify-center items-center">
          <Empty
            imageStyle={{ height: "30vh" }}
            description={
              <div>
                <span>There are no document templates in your profile.</span>
                {isGlobalAdmin && (
                  <span>
                    <Link to="/admin/document-templates/create"> Create</Link> a
                    new document template.
                  </span>
                )}
              </div>
            }
          />
        </div>
      )}
      {!!list?.length && (
        <div className="rounded-md border w-full mx-auto">
          <Table>
            <TableHeader
              items={tableHeaders}
              sorting={sorting}
              handleUpdateSorting={handleUpdateSorting}
            />
            <TableBody className="font-sans">
              {list.length ? (
                list.map((item) => (
                  <DocTemplateListItem key={item.id} item={item} />
                ))
              ) : (
                <TableRow>No results.</TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      )}
    </Spin>
  );
};

export default DocTemplatesList;
