import { FC } from "react";
import { getCurrentCompany } from "src/store/slices/companiesSlice";
import { TCampaignListOptionType } from "../../store/slices/campaignsListSlice";
import { CustomSelect } from "../custom/Select";

type TChooseCampaignTypeSelectOption = {
  value: number | string;
  label: string;
  disabled?: boolean;
};

type CampaignTypeSelectorProps = {
  campaignType: TCampaignListOptionType;
  onChanged: (campaignType: TCampaignListOptionType) => void;
};

const CampaignTypeSelector: FC<CampaignTypeSelectorProps> = ({
  campaignType,
  onChanged,
}) => {
  const currentCompany = getCurrentCompany();

  const options: Array<TChooseCampaignTypeSelectOption> = [
    { value: "ALL", label: "All Types" },
    { value: "DRAFT_TEMPLATE", label: "Draft Templates" },
    { value: "PUBLISHED_TEMPLATE", label: "Published Templates" },
    {
      value: "CAMPAIGN",
      label: "Campaigns",
      disabled: currentCompany === null,
    },
  ];
  const handleTypeChange = (value: string) => {
    onChanged(value as TCampaignListOptionType);
  };

  return (
    <CustomSelect
      options={options.map((option) => ({
        value: option.value.toString(),
        label: option.label,
      }))}
      value={campaignType?.toString()}
      onChange={handleTypeChange}
      label="Select Campaign Type"
      className="w-[180px]"
    />
  );
};

export default CampaignTypeSelector;
