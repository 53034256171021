import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type PropsType = {
  campaignId: string | undefined;
  companyId: string | undefined;
};
const LabelsHeader: FC<PropsType> = (props) => {
  const { campaignId, companyId } = props;
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Flex vertical gap="middle">
      <div>
        <Button
          size="middle"
          icon={<ArrowLeftOutlined />}
          onClick={() => {
            if (location.key !== "default") {
              navigate(-1);
            } else {
              navigate(
                `/admin/campaigns/company/${companyId}/campaign/${campaignId}`
              );
            }
          }}
          style={{
            fontFamily: "Geist",
            fontWeight: "600",
          }}
        >
          Back To Campaign
        </Button>
      </div>
      <h1
        style={{
          fontFamily: "Geist",
          fontSize: "32px",
          fontWeight: "600",
          margin: 0,
        }}
      >
        Campaign Labels
      </h1>
    </Flex>
  );
};

export default LabelsHeader;
