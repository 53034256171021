import { Form, FormInstance, Select, Spin } from "antd";
import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { TDynamicSelectProps } from "../../../globalTypes";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { camelCaseToWords } from "../../../utils/cm.utils";
import useGetOptionsHook from "./useGetOptions.hook";

type Props = {
  selectProps: TDynamicSelectProps;
  hidden: boolean;
  form: FormInstance<any>;
  restValuesProvider: string;
  companyId: number | undefined;
  campaignId: number;
  stepId: number;
  phaseId: number;
};

const SelectWithOptionsProvider: FC<Props> = ({
  selectProps,
  hidden,
  restValuesProvider,
  companyId,
  form,
  stepId,
  phaseId,
  campaignId,
}) => {
  const { required, dependsOn, name } = selectProps;
  const validatedName = camelCaseToWords(name);
  const { isFetching, selectOptions } = useGetOptionsHook({
    companyId,
    stepId,
    campaignId,
    restValuesProvider,
    dependsOn,
    form,
    name,
    phaseId,
  });
  const { isGlobalAdmin } = useCurrentUser();
  const documentTemplateId = "documentTemplateId";
  const isEmpty = name === documentTemplateId && selectOptions?.length === 0;
  const customValidationMessage = (
    <div className="text-red-500 text-sm mt-1 mb-4">
      There are no templates for this class. Please ask an admin to add a class
      template from the template library{" "}
      {isGlobalAdmin ? (
        <Link to="/admin/document-templates">Velocity Engine</Link>
      ) : (
        <span>Velocity Engine</span>
      )}
    </div>
  );

  useEffect(() => {
    if (isEmpty) {
      form.validateFields([documentTemplateId]);
    }
  }, [isEmpty]);

  return (
    <Spin spinning={isFetching}>
      <Form.Item
        key={name}
        hidden={hidden}
        name={name}
        label={validatedName}
        rules={[
          {
            required: required,
            message: isEmpty ? customValidationMessage : "Required field!",
          },
        ]}
      >
        <Select
          placeholder={
            isEmpty ? "No templates available" : `Choose ${validatedName}`
          }
          options={selectOptions || []}
          disabled={isEmpty}
        />
      </Form.Item>
    </Spin>
  );
};

export default SelectWithOptionsProvider;
