import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { TCompany } from "./companiesSlice";

type CMCompaniesState = {
  list: TCompany[];
  pageNumber: number;
  pageSize: number;
  total: number;
};

const initialState: CMCompaniesState = {
  list: [],
  pageNumber: 1,
  pageSize: 50,
  total: 0,
};

const companiesListSlice = createSlice({
  name: "companiesList",
  initialState,
  reducers: {
    setCompanies: (
      state: CMCompaniesState,
      action: PayloadAction<{ list: TCompany[]; total: number }>
    ) => {
      state.list = action.payload.list;
      state.total = action.payload.total;
    },
    setPageSize: (state: CMCompaniesState, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNumber: (state: CMCompaniesState, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    removeCompany(state, action: PayloadAction<number>) {
      state.list = state.list.filter((item) => item.id !== action.payload);
    },
  },
});

export const { setCompanies, setPageNumber, setPageSize, removeCompany } =
  companiesListSlice.actions;

export default companiesListSlice.reducer;

export const selectCompaniesListState = (state: RootState) =>
  state.companiesList;
