import { Empty } from "antd";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { TCompany } from "../../store/slices/companiesSlice";
import { Table, TableBody, TableRow } from "../common/ui/table";
import { TableHeader } from "../table/Header";
import CompaniesListItem from "./CompaniesListItem";
import { CompanyDrawerProps } from "./CompanyDrawer/CompanyDrawer";

type Props = {
  setCompanyDrawerProps: React.Dispatch<
    React.SetStateAction<CompanyDrawerProps>
  >;
  list: TCompany[];
  updateList: () => Promise<void>;
};

const tableHeaderItems = [
  {
    title: "Company Name",
    className: "font-sans w-1/3 text-black",
  },
  {
    title: "Website",
    className: "font-sans w-1/3 text-black",
  },
  {
    title: "Date Added",
    className: "font-sans w-1/3 text-black",
  },
  null,
];

const CompaniesList: FC<Props> = ({
  setCompanyDrawerProps,
  list,
  updateList,
}) => {
  const { isGlobalAdmin } = useCurrentUser();

  if (!list || !list.length) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Empty
          imageStyle={{ height: "30vh" }}
          description={
            <div>
              <span>There are no companies in your profile.</span>
              {isGlobalAdmin && (
                <span>
                  <Link to="/admin/companies/create"> Create</Link> a new
                  company.
                </span>
              )}
            </div>
          }
        />
      </div>
    );
  }

  return (
    <div className="rounded-md border w-full mx-auto">
      <Table>
        <TableHeader items={tableHeaderItems} />
        <TableBody className="font-sans">
          {list.length ? (
            list.map((item) => (
              <CompaniesListItem
                key={item.id}
                item={item}
                setCompanyDrawerProps={setCompanyDrawerProps}
                updateList={updateList}
              />
            ))
          ) : (
            <TableRow>No results.</TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default CompaniesList;
