import { Outlet } from "react-router-dom";
import { Icons } from "../../../components/common/Icons";
import { Button } from "../../../components/common/ui/button";
import Navigation from "../../../components/common/Navigation";
import useCompanyStorageData from "./hooks/useCompanyStorageData.hook";
import { Skeleton } from "antd";

const CompanyStoragePage = () => {
  const { navigationItems, onGoBack, error, isLoading, companyName } =
    useCompanyStorageData();

  const renderContent = () => {
    if (isLoading) {
      return <Skeleton active />;
    }

    if (error) {
      return <span className="text-red-500">Error: {error}</span>;
    }

    return (
      <>
        <div>
          <div className="flex gap-[12px] items-center">
            <Button
              variant={"ghost"}
              icon={Icons.back}
              onClick={onGoBack}
              className="p-[12px] h-auto"
            />
            <div className="flex items-end gap-[8px]">
              <h1 className="text-slate-900 text-2xl font-semibold font-sans leading-normal capitalize">
                Campaign Storage
                {companyName && (
                  <span className="font-normal">{": "}{companyName}</span>
                )}
              </h1>
            </div>
          </div>

          <Navigation items={navigationItems} />

          <div className="h-[1px] bg-[#E2E8F0]" />
          <Outlet />
        </div>
      </>
    );
  };

  return <div>{renderContent()}</div>;
};

export default CompanyStoragePage;
