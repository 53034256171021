import { CaretRightFilled } from "@ant-design/icons";
import {
  Alert,
  Flex,
  Form,
  FormInstance,
  Select,
  Spin,
  Typography,
} from "antd";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import {
  TPromptTemplate,
  getPromptTemplatesApi,
} from "../../../api/cc-variables.api";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { TCcVariable, TCcVariableType } from "../../../store/slices/ccVariablesSlice";
import { getNumOfVarsInPrompt } from "../../../utils/cm.utils";
import { TEditModeProps } from "../../CampaignGrid/CampaignGrid";
import TemplatePrompt from "./TemplatePrompt";

type TSelectPromptData = {
  value: string;
  label: string;
  envData: TPromptTemplate;
};

type TArgumentsConflict = {
  numberKeysUsed: number;
  numberKeysRequired: number;
  usedKeys: Array<string>;
};

type PropsType = {
  dynElemName: string;
  required: boolean;
  hidden: boolean;
  form: FormInstance<any>;
  gridItemSequence: number;
  ccVarData: TEditModeProps | null;
  companyId: number | undefined;
  campaignId: number;
  stepId: number;
  localKeys: TCcVariable[];
};

const ListKeySearch: React.FC<PropsType> = (props) => {
  const {
    dynElemName,
    required,
    hidden,
    form,
    gridItemSequence,
    ccVarData,
    campaignId,
    companyId,
    stepId,
    localKeys,
  } = props;
  const { isGlobalAdmin } = useCurrentUser();
  const [data, setData] = useState<Array<TSelectPromptData>>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [promptTemplate, setPromptTemplate] = useState("");
  const timeout: MutableRefObject<any> = useRef(null);
  const currentValue: MutableRefObject<string> = useRef("");
  const [varKeys, setVarKeys] = useState<Array<string | null>>([]);
  const [argumentsConflict, setArgumentsConflict] =
    useState<TArgumentsConflict | null>(null);
  const envType: TCcVariableType = form.getFieldValue("type");

  useEffect(() => {
    if (ccVarData && ccVarData.variableData.options && envType === "prompt") {
      const getTemplate = async () => {
        try {
          setIsFetching(true);
          const promptKey = ccVarData.variableData.options?.promptKey as string;
          const { data } = await getPromptTemplatesApi(
            campaignId,
            ccVarData.variableData.type,
            promptKey,
            companyId
          );
          const promptItem = data.find((el) => el.key === promptKey);

          //check if prompt has changed. Add message and hard reset form.arguments with empty values
          if (promptItem) {
            const template = promptItem.template;
            const count = getNumOfVarsInPrompt(template);
            let keys = ccVarData.variableData.options
              ?.arguments as Array<string>;

            if (keys.length !== count) {
              const newKeys = Array(count).fill(null);

              setArgumentsConflict({
                numberKeysUsed: keys.length,
                numberKeysRequired: count,
                usedKeys: keys,
              });

              keys = newKeys;

              form.setFieldsValue({
                arguments: newKeys,
              });
            }

            setVarKeys(keys);
            setPromptTemplate(template);
          }
        } catch (e) {
          console.error("Can't get prompt template", e);
        } finally {
          setIsFetching(false);
        }
      };

      getTemplate();
    }
  }, []);

  const handleSearch = (newValue: string) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }

    currentValue.current = newValue;

    const getSearchItems = async () => {
      try {
        setIsFetching(true);
        const { data } = await getPromptTemplatesApi(
          campaignId,
          envType,
          newValue,
          companyId
        );

        if (currentValue.current === newValue) {
          const optionsData = data.map((item) => ({
            value: item.key,
            label: item.key,
            envData: item,
          }));
          setData(optionsData);
        }
      } catch (e) {
        console.error("Can't get var keys", e);
      } finally {
        setIsFetching(false);
      }
    };

    if (newValue) {
      timeout.current = setTimeout(getSearchItems, 400);
    } else {
      setData([]);
      setIsFetching(false);
    }
  };

  const handleSelect = (value: string, option: TSelectPromptData) => {
    const template = option.envData.template;
    const count = getNumOfVarsInPrompt(template);
    const varKeysArr = Array(count).fill(null);

    form.setFieldsValue({
      promptKey: value,
      arguments: varKeysArr,
    });

    setPromptTemplate(template);
    setVarKeys(varKeysArr);
  };

  return (
    <Spin spinning={isFetching}>
      <Form.Item
        key="promptKey"
        hidden={hidden}
        name="promptKey"
        label="Search Prompt Keys"
        rules={[{ required, message: "Required field!" }]}
      >
        <Select
          disabled={!isGlobalAdmin}
          showSearch
          placeholder="Type to search keys"
          defaultActiveFirstOption={false}
          optionRender={(option) => {
            const optionData = option.data as TSelectPromptData;

            return (
              <div>
                <span style={{ color: "#003784" }}>{optionData.label}</span>
                <CaretRightFilled style={{ color: "#003784" }} />
                <span style={{ opacity: 0.5, fontStyle: "italic" }}>
                  {optionData.envData.template}
                </span>
              </div>
            );
          }}
          suffixIcon={null}
          filterOption={false}
          onSearch={handleSearch}
          onSelect={handleSelect}
          notFoundContent={null}
          options={data}
        />
      </Form.Item>
      <Form.Item
        key={dynElemName}
        name={dynElemName}
        hidden={!promptTemplate}
        rules={[
          {
            validator: (
              _,
              value: Array<string | null | undefined> | undefined
            ) => {
              if (!value) return Promise.resolve();

              const isKeysArrValid = value.every(
                (key) => key !== null && key !== undefined
              );

              if (isKeysArrValid) {
                return Promise.resolve();
              } else {
                return Promise.reject(new Error("Choose all variables!"));
              }
            },
          },
        ]}
      >
        <TemplatePrompt
          promptTemplate={promptTemplate}
          varKeys={varKeys}
          setVarKeys={setVarKeys}
          form={form}
          formPromptArgsKey={dynElemName}
          gridItemSequence={gridItemSequence}
          companyId={companyId}
          campaignId={campaignId}
          stepId={stepId}
          localKeys={localKeys}
        />
      </Form.Item>
      {argumentsConflict && (
        <Alert
          message="The prompt template used has been changed!"
          showIcon
          description={
            <Flex vertical>
              <div>
                The current required number of keys -{" "}
                <Typography.Text type="success" strong>
                  {argumentsConflict.numberKeysRequired}
                </Typography.Text>
              </div>
              <div style={{ marginBottom: "12px" }}>
                The initial number of keys used -{" "}
                <Typography.Text type="danger" strong>
                  {argumentsConflict.numberKeysUsed}
                </Typography.Text>
                {argumentsConflict.usedKeys.map((key, index) => {
                  return (
                    <div
                      style={{ marginLeft: "12px" }}
                      key={`${key}__${index}`}
                    >
                      - <Typography.Text code>{key}</Typography.Text>
                    </div>
                  );
                })}
              </div>
              <Typography.Text strong>
                Please select all variables again according to the changes!
              </Typography.Text>
            </Flex>
          }
          type="error"
          closable
          onClose={() => setArgumentsConflict(null)}
        />
      )}
    </Spin>
  );
};

export default ListKeySearch;
