import { AxiosResponse } from "axios";
import { PROXY_PREFIX } from "src/constants";
import {
  DocumentTemplate,
  DocumentTemplateState,
} from "src/types/docTemplates";
import axiosInstance from "src/utils/axios/axiosInstance";

export const getDocumentTemplatesApi = ({
  wordMatch,
  pageNumber,
  pageSize,
  states = "",
  classes = "",
  companyId,
  sortBy,
  orderBy,
}: {
  wordMatch?: string;
  pageSize: number;
  pageNumber: number;
  classes?: string;
  states?: string;
  companyId?: number;
  sortBy?: string;
  orderBy?: string;
}): Promise<
  AxiosResponse<{ items: DocumentTemplate[]; totalItems: number }>
> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/document-template/list`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        ...(wordMatch && { name: wordMatch }),
        pageNumber,
        pageSize,
        states,
        classes,
        companyId,
        sortBy,
        orderBy,
      },
    }
  );
};

export const generateTemplateApi = ({
  content,
  templateId,
  companyId,
}: {
  content: string;
  templateId: string;
  companyId?: number;
}): Promise<AxiosResponse<string>> => {
  return axiosInstance.post(
    `${PROXY_PREFIX}/api/secured/document-template/figmaHtml2Xsl/${templateId}`,
    content,
    {
      headers: {
        "Content-Type": "text/html",
      },
      params: {
        companyId,
      },
    }
  );
};

export const getTemplatePreviewDataApi = ({
  content,
  stepId,
  phaseId,
  companyId,
}: {
  content: string;
  stepId: number;
  phaseId: number;
  companyId?: number;
}): Promise<AxiosResponse<string>> => {
  return axiosInstance.post(
    `${PROXY_PREFIX}/api/secured/${phaseId}/step/${stepId}/preview`,
    content,
    {
      headers: {
        "Content-Type": "text/html",
      },
      params: {
        companyId,
      },
    }
  );
};

export const docTemplateApi = (templateId: number) => ({
  updateClasses: (classes: string[]) => {
    return axiosInstance.put(
      `${PROXY_PREFIX}/api/secured/document-template/${templateId}/classes`,
      classes
    );
  },
  delete: () => {
    return axiosInstance.delete(
      `${PROXY_PREFIX}/api/secured/document-template/${templateId}`
    );
  },
  updateIncludeCampaignStructure: (
    data: Pick<DocumentTemplate, "includeCampaignStructure">
  ) => {
    return axiosInstance.put(
      `${PROXY_PREFIX}/api/secured/document-template/include-campaign-structure/${templateId}`,
      data
    );
  },
});

export const createTemplateApi = ({
  companyId,
  ...data
}: {
  name: string;
  state: DocumentTemplateState;
  classes: string[];
  content: string;
  companyId?: number;
}): Promise<AxiosResponse<TemplateRes>> => {
  return axiosInstance.post(
    `${PROXY_PREFIX}/api/secured/document-template/`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const updateTemplateApi = ({
  companyId,
  id,
  ...data
}: {
  id: number;
  name: string;
  state: DocumentTemplateState;
  classes: string[];
  content: string;
  companyId?: number;
  includeCampaignStructure: boolean | null;
}): Promise<AxiosResponse<TemplateRes>> => {
  return axiosInstance.put(
    `${PROXY_PREFIX}/api/secured/document-template/${id}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export type TemplateRes = {
  id: number;
  name: string;
  state: DocumentTemplateState;
  classes: string[];
  content: string;
  keys: string[];
  mediaType: string;
  includeCampaignStructure: boolean | null;
};

export const getTemplateByIdApi = ({
  companyId,
  id,
}: {
  id: number;
  companyId?: number;
}): Promise<AxiosResponse<TemplateRes>> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/document-template/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export type GetUITemplatesApiParams = {
  name?: string;
  states: DocumentTemplateState[];
  classes: string[];
  stepId?: number;
  companyId?: number;
};

export const getUiAvailableTemplatesApi = ({
  name,
  states,
  classes,
  stepId,
  companyId,
}: GetUITemplatesApiParams): Promise<AxiosResponse<Array<string>>> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/document-template/ui-available`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        name,
        states: states?.length ? states?.join(",") : "",
        classes: classes?.length ? classes?.join(",") : "",
        stepId,
        companyId,
      },
    }
  );
};

export const uploadPackageTemplateApi = (
  formData: FormData,
  templateId: number
): Promise<AxiosResponse<DocumentTemplate>> => {
  return axiosInstance.post(
    `${PROXY_PREFIX}/api/secured/document-template/import/${templateId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
