import ModelConfigsList from "src/components/modelConfigs/List";
import ModelConfigsPagination from "src/components/modelConfigs/Pagination";
import ModelConfigsHeader from "src/components/modelConfigs/TableHeader";

const ModelConfigsListPage = () => {
  return (
    <div className="w-full flex flex-col">
      <ModelConfigsHeader />
      <div className="py-[24px]">
        <ModelConfigsPagination />
      </div>
      <ModelConfigsList />
      <div className="pt-[24px]">
        <ModelConfigsPagination />
      </div>
    </div>
  );
};

export default ModelConfigsListPage;
