import { Select, Spin } from "antd";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { FileMimeType } from "src/types";
import { getTemplatePreviewDataApi } from "../../../api/document-templates.api";
import { getPhasesApi } from "../../../api/phases.api";
import { getStepsApi } from "../../../api/steps.api";
import { SelectOption } from "../../../globalTypes";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { TCampaignResponseData } from "../../../store/slices/campaignsSlice";
import { fetchCampaignsOptions } from "../../../utils/apiUtils";
import { sortBySequence } from "../../../utils/cm.utils";
import handleRequestError from "../../../utils/handleRequestError";
import SelectWithHighlightSearch, {
  TSelectOptionWithData,
} from "../../common/SelectWithHighlightSearch";
import { Button } from "../../common/ui/button";

enum LoadingState {
  PHASE = "PHASE",
  STEP = "STEP",
  PREVIEW = "PREVIEW",
}

type Props = {
  content: string | undefined;
  className?: string;
  mediaType: string | undefined;
};
const TemplatePreview: FC<Props> = ({ content, className, mediaType }) => {
  const [fetching, setFetching] = useState<LoadingState | undefined>(undefined);
  const [companyId, setCompanyId] = useState<number | undefined>(undefined);
  const [campaignId, setCampaignId] = useState<number | undefined>(undefined);
  const [phaseId, setPhaseId] = useState<number | undefined>(undefined);
  const [stepId, setStepId] = useState<number | undefined>(undefined);
  const [phaseOptions, setPhaseOptions] = useState<SelectOption[]>([]);
  const [stepOptions, setStepOptions] = useState<SelectOption[]>([]);
  const messageApi = useSelector(selectMessageApi);

  const onSelectCampaign = async (
    campaignId: number | undefined,
    option: TSelectOptionWithData<TCampaignResponseData> | undefined
  ) => {
    if (!campaignId) return;

    try {
      setFetching(LoadingState.PHASE);

      //companyId - undefined because of global admin and we don't able to get it
      const { data } = await getPhasesApi(campaignId, undefined);

      if (data.length) {
        const sortedPhases = sortBySequence(data);
        const options = sortedPhases.map(({ id, name }) => ({
          label: name,
          value: id,
        }));
        setPhaseOptions(options);
      }

      setCampaignId(campaignId);
      setCompanyId(
        option && option.data.extCompanyId
          ? option.data.extCompanyId
          : undefined
      );
      setStepOptions([]);
      setPhaseId(undefined);
      setStepId(undefined);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setFetching(undefined);
    }
  };

  const onSelectPhase = async (phaseId: number) => {
    try {
      setFetching(LoadingState.STEP);

      const { data } = await getStepsApi({ phaseId, companyId });
      let options: SelectOption[] = [];

      if (data.length) {
        const sortedSteps = sortBySequence(data);
        options = sortedSteps.map(({ id, name }) => ({
          label: name,
          value: id,
        }));
      }

      setPhaseId(phaseId);
      setStepOptions(options);
      setStepId(undefined);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setFetching(undefined);
    }
  };

  const onSelectStep = (stepId: number) => {
    setStepId(stepId);
  };

  const onPreview = async () => {
    if (!content || !stepId || !phaseId) {
      messageApi.error("Error");
      return;
    }

    const isPdf = mediaType === FileMimeType.PDF;

    try {
      setFetching(LoadingState.PREVIEW);

      const { data } = await getTemplatePreviewDataApi({
        content,
        stepId,
        phaseId,
      });

      if (isPdf) {
        const blob = new Blob([data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      } else {
        const newWindow = window.open("", "_blank") as Window;
        newWindow.document.write(data);
        newWindow.document.close();
      }
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setFetching(undefined);
    }
  };

  return (
    <div
      className={`absolute top-0 left-0 h-full w-full p-[46px] border-2 rounded-tr-lg rounded-br-lg rounded-bl-lg ${className}`}
    >
      <div className="flex flex-col gap-[24px]">
        <SelectorWrap
          title="Campaign"
          selector={
            <SelectWithHighlightSearch
              onSelect={onSelectCampaign}
              fetchOptions={fetchCampaignsOptions}
              optionLabel="extCompanyName"
              className="h-[48px]"
            />
          }
        />

        <SelectorWrap
          title="Phase"
          selector={
            <Spin spinning={fetching === LoadingState.PHASE}>
              <Select
                disabled={campaignId === undefined}
                className="h-[48px] w-full"
                placeholder="Select"
                options={phaseOptions}
                value={phaseId}
                onChange={onSelectPhase}
              />
            </Spin>
          }
        />

        <SelectorWrap
          title="Step"
          selector={
            <Spin spinning={fetching === LoadingState.STEP}>
              <Select
                disabled={phaseId === undefined}
                placeholder="Select"
                className="h-[48px] w-full"
                options={stepOptions}
                value={stepId}
                onChange={onSelectStep}
              />
            </Spin>
          }
        />
        <Button
          variant="primaryOutline"
          className="rounded-full self-start"
          onClick={onPreview}
          disabled={!content || stepId === undefined}
          loading={fetching === LoadingState.PREVIEW}
        >
          Preview
        </Button>
      </div>
    </div>
  );
};

export default TemplatePreview;

const SelectorWrap = ({
  title,
  selector,
}: {
  title: string;
  selector: React.ReactNode;
}) => {
  return (
    <div className="flex flex-col justify-between">
      <div className="text-[#475569] font-sans font-semibold pb-[10px]">
        {title}
      </div>
      {selector}
    </div>
  );
};
