import { Form, Input, InputRef, Modal, Select } from "antd";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { TLabelFormData, TLabelsListData } from "../../../globalTypes";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import { getMessageApi } from "../../../store/slices/appSlice";
import { saveCampaignLabelThunk } from "../../../store/slices/campaignLabelsSlice";
import { AppDispatch } from "../../../store/store";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";

type PropsType = {
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  //null when create mode, object with label data - when edit mode
  labelData: TLabelsListData | null;
  labelsKeysLowerCase: Array<string>;
};

const EditModal = (props: PropsType) => {
  const { isOpened, setIsOpened, labelData, labelsKeysLowerCase } = props;
  const dispatch: AppDispatch = useDispatch();
  const messageApi = getMessageApi();
  const [form] = Form.useForm<TLabelFormData>();
  const initialFormValues = { lang: "en" } as TLabelFormData;
  const inputTagRef = useRef<InputRef>(null);

  useSubmitFormOnEnter(() => submitForm());

  useEffect(() => {
    if (inputTagRef.current) {
      inputTagRef.current.focus({
        cursor: "end",
      });
    }
  }, []);

  if (labelData) {
    initialFormValues.labelKey = labelData.labelKey;
    initialFormValues.value = labelData.value;
  }

  const handleCancel = () => {
    form.resetFields();

    setIsOpened(false);
  };

  const onCreate = async (values: TLabelFormData) => {
    try {
      const { labelKey: key, value: label, lang } = values;
      const action = labelData ? "update" : "create";
      const res = await dispatch(
        saveCampaignLabelThunk({ label, key, lang, action })
      );

      if ("error" in res) {
        messageApi.error(res.payload?.message);
      } else {
        setIsOpened(false);
        form.resetFields();
        messageApi.success("Successfully created!");
      }
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(
        "An error occurred while trying to save campaign label:",
        e
      );
    }
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TLabelFormData) =>
      await onCreate(values),
  });

  return (
    <Modal
      title={`${labelData ? "Edit" : "Add"} Label`}
      open={isOpened}
      okText="Save"
      onOk={submitForm}
      onCancel={handleCancel}
      destroyOnClose={true}
      centered={true}
      width={"30vw"}
    >
      <Form
        form={form}
        layout="vertical"
        name="campaign_lables_edit_form"
        style={{ height: "30vh" }}
        initialValues={initialFormValues}
      >
        <Form.Item
          name="lang"
          label="Language"
          hidden={true}
          rules={[{ required: true, message: "Required field!" }]}
        >
          <Select
            placeholder="Select language"
            style={{ width: 200 }}
            options={[{ label: "English", value: "en" }]}
          />
        </Form.Item>
        <Form.Item
          name="labelKey"
          label="Key"
          tooltip="Please ensure that the label key does not exceed 26 characters"
          rules={[
            {
              required: true,
              message: "Required field!",
              transform: (value) => value?.trim(),
            },
            {
              max: 26,
              message:
                "The label key must contain no more than 26 characters!",
              transform: (value) => value?.trim(),
            },
            {
              transform: (value) => value?.trim(),
              validator: (_, value) => {
                if (!value) {
                  return Promise.resolve()
                }

                const lowerCaseValue = value.toLowerCase();

                if (
                  labelData?.labelKey.toLowerCase() !== lowerCaseValue &&
                  labelsKeysLowerCase.includes(lowerCaseValue)
                ) {
                  return Promise.reject(
                    new Error("A label with that key already exists!")
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            placeholder="Enter the label key"
            ref={inputTagRef}
            disabled={!!labelData}
          />
        </Form.Item>
        <Form.Item
          name="value"
          label="Value"
          tooltip="Please ensure that the label value does not exceed 255 characters"
          rules={[
            {
              required: true,
              message: "Required field!",
              transform: (value) => value?.trim(),
            },
            {
              max: 255,
              message:
                "The label value must contain no more than 255 characters!",
              transform: (value) => value?.trim(),
            },
          ]}
        >
          <Input placeholder="Enter the label definition" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditModal;