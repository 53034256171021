import { CaretRightFilled } from "@ant-design/icons";
import {
  Alert,
  Flex,
  Input,
  Segmented,
  Select,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import React, {
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TVarType, VarTypeEnum } from "src/types";
import {
  getCcTemplateByPath,
  getPromptTemplateByKey,
  getPromptTemplatesApi,
} from "../../../api/cc-variables.api";
import { TCcVariableType } from "../../../store/slices/ccVariablesSlice";
import TreeSelect from "../../common/SelectCCKey";
import { PostProcessingItem } from "./PostProcessingItems";
import TemplatePrompt from "./TemplatePrompt";
import {
  PostProcessingItemProps,
  TArgumentsConflict,
  TSelectPostProcessingData,
  TSelectPromptData,
} from "./types";
import {
  getSegmentedBasedOnType,
  getTypeBasedOnSegmented,
  prepareTemplate,
} from "./utils";

const PostProcessing: React.FC<PostProcessingItemProps> = ({
  index: postProcessingIndex,
  form,
  gridItemSequence,
  onItemEdit,
  defaultItem,
  stepId,
  campaignId,
  companyId,
  localKeys,
}) => {
  const [promptData, setPromptData] = useState<Array<TSelectPromptData>>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [argumentsConflict, setArgumentsConflict] =
    useState<TArgumentsConflict | null>(null);
  const [promptTemplate, setPromptTemplate] = useState<string | undefined>(
    undefined
  );
  const [isPromptLoading, setIsPromptLoading] = useState(false);
  const [envValue, setEnvValue] = useState<string | null>();
  const [ccValue, setCcValue] = useState<string | null>();
  const [textValue, setTextValue] = useState<string | null>();

  const [envKeys, setEnvKeys] = useState<Array<string | null>>([]);
  const [ccKeys, setCcKeys] = useState<Array<string | null>>([]);
  const [textKeys, setTextKeys] = useState<Array<string | null>>([]);

  const [type, setType] = useState<PostProcessingItem["type"]>(
    defaultItem?.type || null
  );

  useEffect(() => {
    if (defaultItem.type === "cc") {
      setType("cc");
      setCcValue(defaultItem.value);
      setCcKeys(defaultItem.keys);
    }

    if (defaultItem.type === "env") {
      setType("env");
      setEnvValue(defaultItem.value);
      setEnvKeys(defaultItem.keys);
    }

    if (defaultItem.type === "text") {
      setType("text");
      setTextValue(defaultItem.value);
      setTextKeys(defaultItem.keys);
    }
  }, []);

  const timeout: MutableRefObject<any> = useRef(null);
  const currentValue: MutableRefObject<string> = useRef("");
  const envType: TCcVariableType = form.getFieldValue("type");

  const handleSearchTemplate = (newValue: string) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }

    currentValue.current = newValue;

    const getSearchItems = async () => {
      try {
        setIsFetching(true);
        const { data } = await getPromptTemplatesApi(
          campaignId,
          envType,
          newValue,
          companyId
        );

        if (currentValue.current === newValue) {
          const optionsData = data.map((item) => ({
            value: item.key,
            label: item.key,
            envData: item,
          }));
          setPromptData(optionsData);
        }
      } catch (e) {
        console.error("Can't get var keys", e);
      } finally {
        setIsFetching(false);
      }
    };

    if (newValue) {
      timeout.current = setTimeout(getSearchItems, 400);
    } else {
      setPromptData([]);
      setIsFetching(false);
    }
  };

  const handleSelectTemplate = (_: string, option: TSelectPromptData) => {
    const { varKeysArr, template } = prepareTemplate(option.envData.template);

    setPromptTemplate(template);
    setVarKeys(varKeysArr);
    setValue(option?.value || null);
  };

  const handleSelectCampaignTemplate = (
    value: string,
    option: TSelectPostProcessingData
  ) => {
    const { varKeysArr, template } = prepareTemplate(option.template);

    setPromptTemplate(template);
    setVarKeys(varKeysArr);
    setValue(value);
  };

  const value = useMemo(() => {
    if (type === "cc") {
      return ccValue;
    }

    if (type === "env") {
      return envValue;
    }

    if (type === "text") {
      return textValue;
    }

    return null;
  }, [ccValue, envValue, textValue, type]);

  useEffect(() => {
    // get prompt template by value
    if (type === "env") {
      setIsPromptLoading(true);
      const fetchTemplateByKey = async () => {
        const temp = await getPromptTemplateByKey(
          campaignId,
          value!,
          companyId
        );

        setPromptTemplate(temp.data.value);
        setIsPromptLoading(false);
      };

      fetchTemplateByKey();
    }

    if (type === "cc") {
      const fetchTemplateByPath = async () => {
        if (!value) return;
        setIsPromptLoading(true);
        try {
          const temp = await getCcTemplateByPath(campaignId, value, companyId);

          setPromptTemplate(temp.data.result);
        } catch (e) {
          console.error("Can't get var keys", e);
        } finally {
          setIsPromptLoading(false);
        }
      };

      fetchTemplateByPath();
    }

    if (type === "text") {
      const { template } = prepareTemplate(value!);
      setPromptTemplate(template);
    }
  }, [type, value]);

  const setValue = (value: string | null) => {
    if (type === "cc") {
      setCcValue(value);
    }

    if (type === "env") {
      setEnvValue(value);
    }

    if (type === "text") {
      setTextValue(value);
    }
  };

  const setVarKeys = (keys: Array<string | null>) => {
    if (type === "cc") {
      setCcKeys(keys);
    }

    if (type === "env") {
      setEnvKeys(keys);
    }

    if (type === "text") {
      setTextKeys(keys);
    }
  };

  const varKeys = useMemo(() => {
    if (type === "cc") {
      return ccKeys;
    }

    if (type === "env") {
      return envKeys;
    }

    if (type === "text") {
      return textKeys;
    }

    return [];
  }, [ccKeys, envKeys, textKeys, type]);

  useEffect(() => {
    onItemEdit({
      id: defaultItem.id,
      value: value!,
      keys: varKeys,
      type: type as PostProcessingItem["type"],
    });
  }, [varKeys, type, value]);

  const renderPromptSelector = () => {
    switch (type) {
      case "env":
        return (
          <Select
            showSearch
            placeholder="Type to search keys"
            defaultActiveFirstOption={false}
            style={{ borderColor: "#d9d9d9" }}
            defaultValue={envValue}
            optionRender={(option) => {
              const optionData = option.data as TSelectPromptData;

              return (
                <div>
                  <span style={{ color: "#003784" }}>{optionData.label}</span>
                  <CaretRightFilled style={{ color: "#003784" }} />
                  <span style={{ opacity: 0.5, fontStyle: "italic" }}>
                    {optionData?.envData?.template}
                  </span>
                </div>
              );
            }}
            suffixIcon={null}
            filterOption={false}
            onSearch={handleSearchTemplate}
            onSelect={handleSelectTemplate}
            notFoundContent={null}
            options={promptData as TSelectPromptData[]}
          />
        );

      case "cc":
        return (
          <div className="flex flex-col w-full">
            <TreeSelect
              gridItemSequence={gridItemSequence}
              campaignId={campaignId}
              companyId={companyId}
              onSelect={handleSelectCampaignTemplate}
            />
            {!!ccValue && (
              <div className="py-[5px] px-[10px] bg-slate-50 rounded-md mt-2">{ccValue}</div>
            )}
          </div>
        );

      case "text":
        return (
          <div>
            <Tooltip
              title={`Enter the text what can be do. If you need a variable to select, use "%s"`}
            >
              <Input
                placeholder="Enter value"
                defaultValue={textValue || ""}
                onChange={(e) => {
                  const { varKeysArr, template } = prepareTemplate(
                    e.target.value
                  );
                  setPromptTemplate(template);
                  setVarKeys(varKeysArr);
                  setValue(template);
                }}
              />
            </Tooltip>
          </div>
        );

      default:
        return null;
    }
  };

  const handleChangeVarType = (value: any) => {
    setType(getTypeBasedOnSegmented(value as TVarType));
    setPromptTemplate(undefined);
  };

  return (
    <Spin spinning={isFetching}>
      <div className="flex flex-col">
        <Segmented
          size="small"
          value={getSegmentedBasedOnType(type!)}
          className="mb-2"
          onChange={handleChangeVarType}
          options={[
            VarTypeEnum.ENVIRONMENT_VAR,
            VarTypeEnum.CAMPAIGN_VAR,
            VarTypeEnum.RAW,
          ]}
        />
        {renderPromptSelector()}
        <Spin spinning={isPromptLoading}>
          <TemplatePrompt
            promptTemplate={promptTemplate}
            varKeys={varKeys}
            setVarKeys={setVarKeys}
            gridItemSequence={gridItemSequence}
            postProcessingIndex={postProcessingIndex}
            companyId={companyId}
            campaignId={campaignId}
            stepId={stepId}
            localKeys={localKeys}
          />
        </Spin>
        {argumentsConflict && (
          <Alert
            message="The prompt template used has been changed!"
            showIcon
            description={
              <Flex vertical>
                <div>
                  The current required number of keys -{" "}
                  <Typography.Text type="success" strong>
                    {argumentsConflict.numberKeysRequired}
                  </Typography.Text>
                </div>
                <div className="mb-[12px]">
                  The initial number of keys used -{" "}
                  <Typography.Text type="danger" strong>
                    {argumentsConflict.numberKeysUsed}
                  </Typography.Text>
                  {argumentsConflict.usedKeys.map((key, index) => {
                    return (
                      <div
                        style={{ marginLeft: "12px" }}
                        key={`${key}__${index}`}
                      >
                        - <Typography.Text code>{key}</Typography.Text>
                      </div>
                    );
                  })}
                </div>
                <Typography.Text strong>
                  Please select all variables again according to the changes!
                </Typography.Text>
              </Flex>
            }
            type="error"
            closable
            onClose={() => setArgumentsConflict(null)}
          />
        )}
      </div>
    </Spin>
  );
};

export default PostProcessing;
