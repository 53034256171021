import { Spin } from "antd";
import clsx from "clsx";
import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { TStepPhaseMetaInfo } from "../../globalTypes";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import {
  ChangeNodeProps,
  NodeColor,
} from "../../pages/graph/hooks/useGetNodes.hook";
import { TCcVariable } from "../../store/slices/ccVariablesSlice";
import { Icons } from "../common/Icons";
import { Button } from "../common/ui/button";
import EditPrompt from "./EditPrompt/EditPrompt";
import { NodeType } from "./EditorPane";
import PaneDynamicForm from "./PaneDynamicForm/PaneDynamicForm";
import PaneExecuteButton from "./PaneExecuteButton";
import PaneInfoSection from "./PaneInfoSection";
import PaneResultOverride from "./PaneResultOverride";

type Props = {
  item: TCcVariable | null;
  nodeType: NodeType;
  stepPhaseMetaInfo: TStepPhaseMetaInfo | null;
  isItemLoading: boolean;
  changeNodeProps?: ChangeNodeProps;
  setItem: Dispatch<SetStateAction<TCcVariable | null>>;
};

type NodeNavItem = {
  label: ActiveTab;
  active: boolean;
  renderComponent: () => React.ReactElement;
};

enum ActiveTab {
  Result = "Result",
  OverRide = "OverRide",
  Model = "Model",
  Prompt = "Prompt",
}

const PaneSection: FC<Props> = ({
  item,
  nodeType,
  stepPhaseMetaInfo,
  isItemLoading,
  changeNodeProps,
  setItem,
}) => {
  const [activeComponent, setActiveComponent] = useState(ActiveTab.Result);
  const { isGlobalAdmin } = useCurrentUser();
  const color = NodeColor[nodeType];

  useEffect(() => {
    setActiveComponent(item?.resultOvr ? ActiveTab.OverRide : ActiveTab.Result);
  }, [item]);

  if (!item) {
    return (
      <PaneSectionWrap color={color} loading={isItemLoading}>
        <span>Please select the node</span>
      </PaneSectionWrap>
    );
  }

  const stepMetaInfo = stepPhaseMetaInfo && stepPhaseMetaInfo[item.id.stepId];

  if (!stepMetaInfo) {
    return (
      <PaneSectionWrap color={color} loading={isItemLoading}>
        <span className="text-red-600">
          Error: Unable to get step by ID {item.id.stepId} from meta info:
        </span>
        <pre className="overflow-y-scroll text-[10px]">
          {JSON.stringify(stepPhaseMetaInfo, null, 2)}
        </pre>
      </PaneSectionWrap>
    );
  }

  const { phaseId, stepName, phaseName } = stepMetaInfo;
  const allowedTypesForModel =
    item.type === "prompt" ||
    item.type === "asset" ||
    item.type === "web_scraper" ||
    item.type === "function";

  const navDataList = [
    {
      label: ActiveTab.OverRide,
      active: activeComponent === ActiveTab.OverRide,
      renderComponent: () => (
        <PaneResultOverride
          ccItem={item}
          changeNodeProps={changeNodeProps}
          setItem={setItem}
        />
      ),
    },
    {
      label: ActiveTab.Result,
      active: activeComponent === ActiveTab.Result,
      renderComponent: () => (
        <div>
          <div className="flex-grow overflow-y-auto">
            {item.result || "There is no value in the item"}
          </div>
          <Button
            onClick={() => setActiveComponent(ActiveTab.OverRide)}
            icon={Icons.edit}
            size={"sm"}
            variant={"primaryGhost"}
            className="rounded-full"
          >
            Edit
          </Button>
        </div>
      ),
    },
    isGlobalAdmin &&
      allowedTypesForModel && {
        label: ActiveTab.Model,
        active: activeComponent === ActiveTab.Model,
        renderComponent: () => (
          <PaneDynamicForm
            nodeType={nodeType}
            ccItem={item}
            ccItemPhaseId={phaseId!}
            changeNodeProps={changeNodeProps}
            setItem={setItem}
          />
        ),
      },
    isGlobalAdmin &&
      item.type === "prompt" && {
        label: ActiveTab.Prompt,
        active: activeComponent === ActiveTab.Prompt,
        renderComponent: () => (
          <EditPrompt ccItem={item} changeNodeProps={changeNodeProps} />
        ),
      },
  ].filter(Boolean) as NodeNavItem[];

  return (
    <PaneSectionWrap color={color} loading={isItemLoading}>
      {item.resultOvr && (
        <div className="absolute top-[-5px] right-[-10px] w-[30px] h-[20px] bg-gradient-to-t from-white to-yellow-500 rotate-45" />
      )}
      <div className="flex items-center justify-between">
        <PaneInfoSection
          stepName={stepName}
          phaseName={phaseName!}
          nodeType={nodeType}
          phaseId={phaseId!}
          type={item.type}
          state={item.state}
          stepId={item.id.stepId}
          ccItemKey={item.id.key}
        />
        <PaneExecuteButton
          campaignId={item.id.campaignId}
          ccItemKey={item.id.key}
          stepId={item.id.stepId}
        />
      </div>
      <div>
        {navDataList.map(({ label, active }) => (
          <div
            key={label}
            className={clsx(
              "cursor-pointer capitalize inline-flex items-center font-sans font-semibold transition-colors text-[#334155] text-[14px] h-[34px] mr-[24px] border-y-transparent hover:text-[#4F46E5] border-y-[3px]",
              {
                "border-b-primary": active,
              }
            )}
            onClick={() => setActiveComponent(label)}
          >
            <span className={active ? "text-primary" : ""}>{label}</span>
          </div>
        ))}
        <div className="h-[1px] bg-[#E2E8F0]" />
      </div>
      {navDataList.map(({ label, active, renderComponent }) => {
        if (active) {
          return (
            <div key={label} className="flex-grow overflow-y-auto">
              {active && renderComponent && renderComponent()}
            </div>
          );
        } else {
          return null;
        }
      })}
    </PaneSectionWrap>
  );
};

const PaneSectionWrap: FC<{
  color: NodeColor;
  children: ReactNode;
  loading: boolean;
}> = ({ color, children, loading }) => {
  return (
    <div
      className="relative w-full h-1/2 p-[8px] flex flex-col gap-[6px] border-2 rounded-sm overflow-hidden"
      style={{
        borderColor: color,
        opacity: loading ? 0.3 : 1,
        pointerEvents: loading ? "none" : "auto",
      }}
    >
      <Spin
        spinning={loading}
        style={{ opacity: 1 }}
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      />
      {children}
    </div>
  );
};

export default PaneSection;
