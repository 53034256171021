import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  getUiAvailableTemplatesApi,
  GetUITemplatesApiParams,
} from "src/api/document-templates.api";
import { ITemplateOption, TemplateOptionInfo } from "src/globalTypes";
import { getMessageApi } from "src/store/slices/appSlice";
import { selectCurrentStep } from "src/store/slices/stepsSlice";
import { FileMimeType } from "src/types";
import handleRequestError from "src/utils/handleRequestError";

export const extractTemplateOptionData = (
  templateOption: string
): TemplateOptionInfo => {
  const matches = templateOption.match(/^(.*)\s\((\d+\/\d+)\)\((.*)\)$/);
  const parts: string[] = [];

  if (matches) {
    parts.push(matches[1], matches[2], matches[3]);
  } else {
    throw new Error("Invalid format");
  }

  // const parts = templateOption.split("(");

  if (parts.length !== 3) {
    throw new Error("Invalid format");
  }

  const title = parts[0].trim();
  const keysPart = parts[1].split(")")[0];
  const [addedKeys, allKeys] = keysPart
    .split("/")
    .map((num) => parseInt(num, 10));
  const mimeType = parts[2].split(")")[0];

  if (isNaN(addedKeys) || isNaN(allKeys)) {
    throw new Error("Invalid key numbers");
  }

  return {
    title,
    addedKeysNumber: addedKeys,
    allKeysNumber: allKeys,
    mimeType: mimeType as FileMimeType,
  };
};

type Params = {
  displayTitle?: boolean;
};

export const useUiTemplatesOptions = ({ displayTitle }: Params) => {
  const messageApi = getMessageApi();
  const currentStep = useSelector(selectCurrentStep);
  const [options, setOptions] = useState<ITemplateOption[]>();
  const [isLoading, setIsLoading] = useState(false);

  const load = async (params: GetUITemplatesApiParams) => {
    setIsLoading(true);
    try {
      const { data } = await getUiAvailableTemplatesApi(params);

      const templateOptions: ITemplateOption[] = data.map((template) => {
        const [value, label] = template.split("~");
        return {
          label: !displayTitle ? label : extractTemplateOptionData(label).title,
          value: Number(value),
          info: extractTemplateOptionData(label),
        };
      });

      setOptions(templateOptions || []);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsLoading(false);
    }
  };

  const selectedOption = useMemo(() => {
    return options?.find((option) => {
      return option.value === Number(currentStep?.documentTemplateId);
    });
  }, [options, currentStep]);

  return { options, load, selectedOption, isLoading };
};
