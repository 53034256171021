import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TCampaignListOptionType } from "src/globalTypes";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { CampaignsListRequestParams } from "src/pages/campaigns/hooks/useCampaignsList";
import { selectMessageApi } from "../../store/slices/appSlice";
import { createCampaignThunk } from "../../store/slices/campaignsSlice";
import { TCompany } from "../../store/slices/companiesSlice";
import { AppDispatch } from "../../store/store";
import { PageHeader } from "../common/PageHeader";
import CampaignTypeSelector from "./CampaignTypeSelector";
import CampaignsPagination from "./CampaignsPagination";
import CompanySelector from "./CompanySelector";
import SettingsMenu from "./SettingsMenu/SettingsMenu";

type Props = {
  currentCompany: TCompany | null;
  backUrl: string;
  campaignType: TCampaignListOptionType;
  requestParams: CampaignsListRequestParams;
  onCompanyChange: (companyId: string) => void;
  onCampaignTypeChange: (campaignType: TCampaignListOptionType) => void;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
};

const CampaignListHeader: FC<Props> = ({
  requestParams,
  backUrl,
  campaignType,
  onCompanyChange,
  onCampaignTypeChange,
  onPageChange,
  onPageSizeChange,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const messageApi = useSelector(selectMessageApi);
  const navigate = useNavigate();
  const { isGlobalAdmin } = useCurrentUser();

  const onCampaignCreate = async () => {
    try {
      const { extCompanyId, id } = await dispatch(
        createCampaignThunk({
          companyId: requestParams.companyId,
        })
      ).unwrap();

      navigate(
        `/admin/campaigns/company/${extCompanyId !== null ? extCompanyId : "global"}/campaign/${id}`
      );
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(e);
    }
  };

  const handleChangeCampaignType = (campaignType: string) => {
    onCampaignTypeChange(campaignType as TCampaignListOptionType);
  };

  const showCreateTemplateButton = ["DRAFT_TEMPLATE", "ALL"].includes(
    campaignType
  );

  return (
    <div className="flex flex-col gap-[24px]">
      <div className="flex justify-between">
        <PageHeader
          title="Campaigns"
          showBackButton={!!backUrl}
          backUrl={backUrl}
        />
        {isGlobalAdmin && (
          <SettingsMenu
            showCreateButton={isGlobalAdmin && showCreateTemplateButton}
            companyId={requestParams.companyId || undefined}
            onCampaignCreate={onCampaignCreate}
          />
        )}
      </div>
      <div className="flex justify-between">
        <div className="flex gap-3">
          <CompanySelector
            companyId={requestParams.companyId || null}
            onChanged={onCompanyChange}
          />
          <CampaignTypeSelector
            campaignType={requestParams.campaignType}
            onChanged={handleChangeCampaignType}
          />
        </div>
        <CampaignsPagination
          page={requestParams.page}
          pageSize={requestParams.pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
        />
      </div>
    </div>
  );
};

export default CampaignListHeader;
