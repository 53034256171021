import DocTemplatesList from "src/components/docTemplates/List";
import DocTemplatesPagination from "src/components/docTemplates/DocTemplatesPagination";
import DocTemplatesHeader from "src/components/docTemplates/TableHeader";

const DocumentTemplatesPage = () => {
  return (
    <div className="w-full flex flex-col">
      <DocTemplatesHeader />
      <div className="py-[24px]">
        <DocTemplatesPagination />
      </div>
      <DocTemplatesList />
      <div className="pt-[24px]">
        <DocTemplatesPagination />
      </div>
    </div>
  );
};

export default DocumentTemplatesPage;
