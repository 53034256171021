import { PROXY_PREFIX } from "src/constants";
import { MicrositeCompanyAssets, TMicroSite } from "src/globalTypes";
import axiosInstance from "src/utils/axios/axiosInstance";

export const micrositeApi = (companyId: number | string) => ({
  create: (subDomain: string, siteContext: string, enableHttps: boolean) =>
    axiosInstance.post<TMicroSite>(
      `${PROXY_PREFIX}/api/secured/company/${companyId}/microsite/`,
      {
        subDomain,
        companyId: Number(companyId),
        siteContext,
        enableHttps,
      },
      {
        params: {
          companyId: Number(companyId),
        },
      }
    ),
  delete: (subDomain: string) =>
    axiosInstance.delete(
      `${PROXY_PREFIX}/api/secured/company/${companyId}/microsite/${subDomain}`,
      {
        params: {
          companyId: Number(companyId),
        },
      }
    ),
  get: () =>
    axiosInstance.get<TMicroSite>(
      `${PROXY_PREFIX}/api/secured/company/${companyId}/microsite/`,
      {
        params: {
          companyId: Number(companyId),
        },
      }
    ),
  getCompanyAssets: () =>
    axiosInstance.get<MicrositeCompanyAssets>(
      `${PROXY_PREFIX}/api/secured/company/${companyId}/microsite/assets`,
      {
        params: {
          companyId: Number(companyId),
        },
      }
    ),
});
