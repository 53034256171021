import clsx from "clsx";
import React, { FC } from "react";
import { ArrowTopRightOnSquareIcon } from "./Icons";

type MenuItem = {
  key?: string;
  label: string;
  active?: boolean;
  onClick: () => void;
  renderComponent?: () => React.ReactElement;
  navigateTo?: string;
};

type Props = {
  menuItems: MenuItem[];
};

const MenuTabs: FC<Props> = ({ menuItems }) => {
  return (
    <div>
      {menuItems.map(({ label, active, onClick, navigateTo }) => (
        <div
          key={label}
          className={clsx(
            "cursor-pointer capitalize inline-flex items-center font-sans font-semibold transition-colors text-[#334155] text-[14px] h-[44px] mr-[48px] border-y-transparent hover:text-[#4F46E5] border-y-[3px] ",
            {
              "border-b-primary": active,
            }
          )}
          onClick={onClick}
        >
          <div className={clsx("inline-flex items-center", { "text-primary": active })}>
            {label}{" "}
            {navigateTo ? (
              <ArrowTopRightOnSquareIcon className="w-[16px] h-[16px] ml-[4px]" />
            ) : (
              ""
            )}
          </div>
        </div>
      ))}
      <div className="h-[1px] bg-[#E2E8F0] mb-[25px]" />
      {menuItems.map(({ label, active, renderComponent }) => (
        <div key={label}>{active && renderComponent && renderComponent()}</div>
      ))}
    </div>
  );
};

export default MenuTabs;
