import { CMCompanyItem, searchCompaniesApi } from "src/api/companies.api";
import { getDocumentTemplatesApi } from "src/api/document-templates.api";
import {
  DocumentTemplate,
  DocumentTemplateState,
} from "src/types/docTemplates";
import { getCampaignsApi_proxy } from "../api/campaigns.api";
import { DictionaryType, dictionaryApi } from "../api/dictionary.api";
import { TSelectOptionWithData } from "../components/common/SelectWithHighlightSearch";
import { SelectOption } from "../globalTypes";
import { TCampaignResponseData } from "../store/slices/campaignsSlice";
import handleRequestError from "./handleRequestError";

export const fetchCompaniesOptions = async (): Promise<
  TSelectOptionWithData<CMCompanyItem>[]
> => {
  const { data } = await searchCompaniesApi({
    name: "",
    pageNumber: 1,
    pageSize: 10000,
  });

  return data.items.map((company) => ({
    label: company.name,
    value: company.id,
    data: company,
  }));
};

export const fetchTemplateClassesOptions = async (
  wordMatch: string
): Promise<SelectOption[]> => {
  try {
    const { data } = await dictionaryApi(DictionaryType.CLASS).find({
      wordMatch: `*${wordMatch}*`,
    });

    return data.map((item) => ({ label: item, value: item }));
  } catch (e: any) {
    const customError = handleRequestError(e);
    console.error(customError);
    return [];
  }
};

export const fetchSystemTemplatesOptions = async (): Promise<
  TSelectOptionWithData<DocumentTemplate>[]
> => {
  const { data } = await getDocumentTemplatesApi({
    wordMatch: `**`,
    pageNumber: 0,
    pageSize: 1000,
    states: DocumentTemplateState.SYSTEM,
    classes: "",
  });

  return data.items.map((template) => ({
    label: template.name,
    value: template.id,
    data: template,
  }));
};

export const fetchCampaignsOptions = async (): Promise<
  TSelectOptionWithData<TCampaignResponseData>[]
> => {
  const { data } = await getCampaignsApi_proxy({
    name: "",
    extCompanyId: undefined, //search all campaigns, since the feature only for global admins
    currentPage: 1,
    pageSize: 10000,
    campaignType: "ALL",
  });

  const sortedData = data.items.sort((a, b) => {
    const nameA = a.extCompanyName || "";
    const nameB = b.extCompanyName || "";

    return nameA.localeCompare(nameB);
  });

  return sortedData.map((campaign) => {
    const { id, extCompanyName, name } = campaign;

    return {
      label: name,
      value: id,
      data: {
        ...campaign,
        extCompanyName: extCompanyName || "Global",
      },
    };
  });
};
