import React, { FC } from "react";
import {
  CogIcon,
  ExecuteIcon,
  Icons,
  LabelsIcon,
  MoveDownIcon,
  SolidEditIcon,
} from "../common/Icons";
import MenuDropdown, { MenuDropdownItem } from "../common/MenuDropdown";
import { Button } from "../common/ui/button";

type Props = {
  setTarget: () => Promise<void>;
  setInput: () => Promise<void>;
  setTargetWithInput: () => Promise<void>;
  goToKeyDefinition: () => Promise<void>;
  reRun: () => Promise<void>;
};
const NodeMenu: FC<Props> = ({
  setTarget,
  setInput,
  setTargetWithInput,
  goToKeyDefinition,
  reRun,
}) => {

  const items = [
    {
      key: "1",
      label: "Set Input",
      disabled: false,
      icon: ExecuteIcon,
      onClick: setInput,
      className: "!text-indigo-600",
    },
    {
      key: "2",
      label: "Set Target",
      disabled: false,
      icon: LabelsIcon,
      onClick: setTarget,
      className: "!text-indigo-600",
    },
    {
      key: "3",
      label: "Set Target With Dep",
      disabled: false,
      icon: SolidEditIcon,
      onClick: setTargetWithInput,
      className: "!text-indigo-600",
    },

    {
      key: "4",
      label: "Go To Key Definition",
      disabled: false,
      icon: MoveDownIcon,
      onClick: goToKeyDefinition,
      className: "!text-indigo-600",
    },
    {
      key: "5",
      label: "Re-Run Dependencies",
      disabled: false,
      icon: CogIcon,
      onClick: reRun,
      className: "!text-indigo-600",
    },
  ].filter((v) => !!v) as MenuDropdownItem[];

  return (
    <MenuDropdown items={items} align="end">
      <Button
        variant={"ghostPrimary"}
        className="p-[1px] size-[25px]"
        onClick={(e) => e.stopPropagation()}
      >
        <Icons.MoreDots className="size-[20px]" />
      </Button>
    </MenuDropdown>
  );
};

export default NodeMenu;
