import { Empty, Flex } from "antd";
import React from "react";
import { TUserData } from "../../../store/slices/usersSlice";
import CompanyUsersListItem from "../CompanyUsersListItem/CompanyUsersListItem";
import { Table, TableBody, TableHead, TableHeader, TableRow } from "../../common/ui/table";

type PropsType = {
  users: Array<Omit<TUserData, "global_admin_api_key">>;
};

const CompanyUsersList: React.FC<PropsType> = (props) => {
  if (!props.users.length) {
    return (
      <Flex
        vertical
        style={{ height: "100%", width: "100%" }}
        align="center"
        justify="center"
      >
        <Empty
          imageStyle={{ height: "30vh" }}
          description={
            <div>
              <span>There are no users in this company.</span>
            </div>
          }
        />
      </Flex>
    );
  }
  const headerClassName = "font-sans font-bold text-[#0F172A] text-[14px]";
  const tableHeaders = [
    {
      title: "User",
      className: headerClassName,
    },
    {
      title: "Email",
      className: headerClassName,
    },
    {
      title: "Role",
      className: headerClassName,
    },
    {
      title: "Department",
      className: headerClassName,
    },
  ];

  return (
    <div className="rounded-md border w-full mx-auto">
      <Table>
        <TableHeader className="rounded-t-2xl">
          <TableRow className="bg-slate-50">
            {tableHeaders.map(({ title, className }) => (
              <TableHead key={title} className={className}>
                {title}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody className="font-sans">
          {props.users.length ? (
            props.users.map((item) => (
              <CompanyUsersListItem key={item.id} user={item} />
            ))
          ) : (
            <TableRow>No results.</TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default CompanyUsersList;
