import { AxiosResponse } from "axios";
import { PROXY_PREFIX } from "../constants";
import { AssetType, TAsset } from "../globalTypes";
import axiosInstance from "../utils/axios/axiosInstance";

const cmManagerApiKey = process.env.REACT_APP_CM_SUPER_USER_API_KEY;
const cmApiUrl = process.env.REACT_APP_CM_API_URL;

type TGetAssetsProps = {
  campaignId: number;
  stepId: number;
  companyId: number | undefined;
};
export const getAssetsApi = ({
  campaignId,
  stepId,
  companyId,
}: TGetAssetsProps): Promise<AxiosResponse<Array<TAsset>>> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/${campaignId}/asset/step/${stepId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};
export const uploadAssetApi = ({
  campaignId,
  stepId,
  formData,
}: {
  formData: FormData;
  campaignId: number;
  stepId: number;
}): Promise<AxiosResponse<TAsset>> => {
  return axiosInstance.post(
    `${cmApiUrl}/api/secured/${campaignId}/asset/step/${stepId}/upload`,
    // `${PROXY_PREFIX}/api/secured/${campaignId}/asset/step/${stepId}/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        "api-key": cmManagerApiKey,
        // companyId,
      },
    }
  );
};

export const getAssetApi = ({
  campaignId,
  assetId,
  companyId,
}: {
  campaignId: number;
  assetId: number;
  companyId: number | undefined;
}): Promise<AxiosResponse<TAsset>> => {
  return axiosInstance.get(
    `${PROXY_PREFIX}/api/secured/${campaignId}/asset/${assetId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

type TCreateAssetProps = {
  campaignId: number;
  stepId: number;
  title: string;
  ref: string;
  microSiteTargetFolder: string;
  type: AssetType;
  companyId: number | undefined;
};
export const createAssetApi = ({
  campaignId,
  stepId,
  title,
  ref,
  microSiteTargetFolder,
  type,
  companyId,
}: TCreateAssetProps): Promise<AxiosResponse<TAsset>> => {
  return axiosInstance.post(
    `${PROXY_PREFIX}/api/secured/${campaignId}/asset/step/${stepId}`,
    { title, ref, microSiteTargetFolder, type },
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

type TUpdateAssetProps = TAsset & { companyId: number | undefined };
export const updateAssetApi = ({
  campaignId,
  stepId,
  companyId,
  ...asset
}: TUpdateAssetProps): Promise<AxiosResponse<TAsset>> => {
  return axiosInstance.put(
    `${PROXY_PREFIX}/api/secured/${campaignId}/asset/step/${stepId}`,
    asset,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

type TDeleteAssetProps = {
  campaignId: number;
  assetId: number;
  companyId: number | undefined;
  removeFiles?: boolean;
};
export const deleteAssetApi = ({
  campaignId,
  assetId,
  companyId,
  removeFiles = false,
}: TDeleteAssetProps): Promise<AxiosResponse<string>> => {
  return axiosInstance.delete(
    `${PROXY_PREFIX}/api/secured/${campaignId}/asset/${assetId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
        removeFiles,
      },
    }
  );
};

type TToggleApproveProps = {
  campaignId: number;
  assetId: number;
  approved: boolean;
  companyId: number | undefined;
};
export const toggleApproveAssetApi = ({
  campaignId,
  assetId,
  approved,
  companyId,
}: TToggleApproveProps): Promise<AxiosResponse<string>> => {
  return axiosInstance.put(
    `${PROXY_PREFIX}/api/secured/${campaignId}/asset/item/${assetId}/${approved}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const toggleApprovePublishApi = ({
  campaignId,
  assetId,
  publishToMicroSite,
  companyId,
}: {
  campaignId: number;
  assetId: number;
  publishToMicroSite: boolean;
  companyId: number | undefined;
}): Promise<AxiosResponse<string>> => {
  return axiosInstance.put(
    `${PROXY_PREFIX}/api/secured/${campaignId}/asset/deploy/${assetId}/${publishToMicroSite}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const publishAssetApi = ({
  campaignId,
  assetId,
  companyId,
}: {
  campaignId: number;
  assetId: number;
  companyId: number | undefined;
}): Promise<AxiosResponse<TAsset>> => {
  return axiosInstance.put(
    `${PROXY_PREFIX}/api/secured/${campaignId}/asset/deploy/${assetId}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};

export const unpublishAssetApi = ({
  campaignId,
  assetId,
  companyId,
}: {
  campaignId: number;
  assetId: number;
  companyId: number | undefined;
}): Promise<AxiosResponse<TAsset>> => {
  return axiosInstance.put(
    `${PROXY_PREFIX}/api/secured/${campaignId}/asset/unpublish/${assetId}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        companyId,
      },
    }
  );
};
