import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModelConfigsListApi } from "src/api/model-configs.api";
import { selectMessageApi } from "src/store/slices/appSlice";
import {
  modelConfigsActions,
  selectModelConfigsState,
} from "src/store/slices/modelConfigsSlice";
import handleRequestError from "src/utils/handleRequestError";

export const useModelConfigsList = () => {
  const dispatch = useDispatch();
  const messageApi = useSelector(selectMessageApi);
  const [loading, setLoading] = useState(false);

  const { list, total, pageNumber, pageSize } = useSelector(
    selectModelConfigsState
  );

  const load = useCallback(
    async (overriedBody?: any) => {
      try {
        setLoading(true);
        const { data } = await getModelConfigsListApi({
          pageNumber: pageNumber,
          pageSize,
        });
        dispatch(
          modelConfigsActions.setList({
            list: data.items,
            total: data.totalItems,
          })
        );
      } catch (e: any) {
        const customError = handleRequestError(e);

        messageApi.error(customError.message);
        console.error(customError);
      } finally {
        setLoading(false);
      }
    },
    [pageNumber, pageSize]
  );

  const updatePage = (pageNumber: number) => {
    dispatch(modelConfigsActions.setPageNumber(pageNumber));
  };

  const updatePageSize = (pageSize: number) => {
    dispatch(modelConfigsActions.setPageNumber(1));
    dispatch(modelConfigsActions.setPageSize(pageSize));
  };

  const updateSystemDefault = (id: number) => {
    dispatch(modelConfigsActions.setSystemDefault(id));
  };

  return {
    list,
    total,
    pageSize,
    pageNumber,
    updatePage,
    updatePageSize,
    loading,
    load,

    updateSystemDefault,
  };
};
