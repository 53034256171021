import { Empty, Flex, Spin } from "antd";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import {
  deleteAssetApi,
  getAssetApi,
  publishAssetApi,
  toggleApproveAssetApi,
  toggleApprovePublishApi,
} from "../../../api/assets.api";
import { TAsset } from "../../../globalTypes";
import { selectMessageApi } from "../../../store/slices/appSlice";
import handleRequestError from "../../../utils/handleRequestError";
import { Table, TableBody } from "../../common/ui/table";
import { TableHeader } from "../../table/Header";
import AssetListItem from "../AssetListItem/AssetListItem";

type Props = {
  assets: Array<TAsset>;
  campaignId: number;
  companyId: number | undefined;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEditAsset: React.Dispatch<React.SetStateAction<TAsset | null>>;
  setAssets: React.Dispatch<React.SetStateAction<TAsset[]>>;
};

const headerClassName = "font-sans font-bold text-[#0F172A]";
const tableHeaderItems = [
  {
    title: "Name",
    className: headerClassName,
  },
  {
    title: "Microsite Context Folder",
    className: headerClassName,
  },
  {
    title: "Asset approved",
    className: headerClassName,
  },
  {
    title: "Publish approved",
    className: headerClassName,
  },
  {
    title: "Publish date",
    className: headerClassName,
  },
  null,
];

const AssetsList: FC<Props> = (props) => {
  const {
    assets,
    campaignId,
    companyId,
    setIsModalOpen,
    setEditAsset,
    setAssets,
  } = props;
  const messageApi = useSelector(selectMessageApi);
  const [isFetching, setIsFetching] = useState(false);

  const onApproveAsset = async (assetId: number, approved: boolean) => {
    try {
      setIsFetching(true);
      await toggleApproveAssetApi({
        campaignId,
        assetId,
        approved,
        companyId,
      });

      setAssets((assets) => {
        return assets.map((asset) =>
          asset.id === assetId ? { ...asset, approved } : asset
        );
      });

      messageApi.success("The status has been changed");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const onDeleteAsset = async (assetId: number, removeFiles?: boolean) => {
    try {
      setIsFetching(true);
      await deleteAssetApi({
        campaignId,
        assetId,
        companyId,
        removeFiles,
      });

      setAssets((assets) => {
        return assets.filter((asset) => asset.id !== assetId);
      });

      messageApi.success("The asset has been deleted");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const onEditAsset = async (asset: TAsset) => {
    try {
      setIsFetching(true);

      const { data } = await getAssetApi({
        campaignId,
        assetId: asset.id,
        companyId,
      });

      setIsModalOpen(true);
      setEditAsset(data);
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const onApprovePublish = async (
    selectedAsset: TAsset,
    publishToMicroSite: boolean
  ) => {
    try {
      setIsFetching(true);
      if (!selectedAsset?.microSiteTargetFolder && publishToMicroSite) {
        messageApi.error(`Publishing path not defined`);
        return;
      }

      await toggleApprovePublishApi({
        campaignId,
        assetId: selectedAsset?.id,
        publishToMicroSite,
        companyId,
      });

      setAssets((assets) => {
        return assets.map((asset) =>
          asset.id === selectedAsset.id
            ? { ...asset, publishToMicroSite }
            : asset
        );
      });

      messageApi.success("The publish status has been changed");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };
  const onPublishAsset = async (assetId: number) => {
    try {
      setIsFetching(true);

      const { data } = await publishAssetApi({
        campaignId,
        assetId,
        companyId,
      });

      setAssets((assets) => {
        return assets.map((asset) =>
          asset.id === assetId ? { ...data } : asset
        );
      });

      messageApi.success("The asset has been published");
    } catch (e: any) {
      const customError = handleRequestError(e);

      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className="rounded-md border w-full mx-auto">
      <Spin spinning={isFetching}>
        {!assets.length ? (
          <Flex
            vertical
            style={{ height: "100%", width: "100%" }}
            align="center"
            justify="center"
            className="my-5"
          >
            <Empty
              imageStyle={{ height: "30vh" }}
              description={
                <div>
                  <span className="font-sans font-medium text-[#0F172A]">
                    There are no assets in this campaign.
                  </span>
                </div>
              }
            />
          </Flex>
        ) : (
          <Table>
            <TableHeader items={tableHeaderItems} />
            <TableBody>
              {assets.map((asset) => (
                <AssetListItem
                  key={asset.id}
                  onApprovePublish={onApprovePublish}
                  onApproveAsset={onApproveAsset}
                  onEditAsset={onEditAsset}
                  onPublishAsset={onPublishAsset}
                  onDeleteAsset={onDeleteAsset}
                  asset={asset}
                />
              ))}
            </TableBody>
          </Table>
        )}
      </Spin>
    </div>
  );
};

export default AssetsList;
