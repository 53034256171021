import React, { FC, useState } from "react";
import {
  copyMicroSiteStorageFileApi,
  deleteMicroSiteStorageFileApi,
  getMicroSiteStorageFileApi,
  getMicroSiteStorageFileLinkApi,
  getMicroSiteStorageFilesApi,
  uploadMicroSiteStorageFileApi,
} from "../../../api/microsite-storage.api";
import { TFileTreeItem, TStorageFileItem } from "../../../globalTypes";
import useConfirm from "../../../hooks/useConfirm";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { UserPermissions } from "../../../store/slices/usersSlice";
import { CopyFileParams } from "../../../types";
import { MenuDropdownItem } from "../../common/MenuDropdown";
import { CopyFileModal } from "../../common/modals/CopyFileModal";
import { CreateFolderModal } from "../../common/modals/CreateFolderModal";
import UploadFileModal from "../../common/modals/UploadFileModal";
import EditFileDrawer from "../dynamicStorage/EditFileDrawer";
import ItemActions from "../dynamicStorage/ItemActions";
import { allowedFilesToEdit, menuOptions } from "../dynamicStorage/constants";
import useCopyFile from "../dynamicStorage/hooks/useCopyFile.hook";
import useCreateFolder from "../dynamicStorage/hooks/useCreateFolder.hook";
import useDelete from "../dynamicStorage/hooks/useDelete.hook";
import useDownload from "../dynamicStorage/hooks/useDownload.hook";
import useEditFile from "../dynamicStorage/hooks/useEditFile.hook";
import useGetLink from "../dynamicStorage/hooks/useGetLink.hook";
import useSaveFile from "../dynamicStorage/hooks/useSaveFile.hook";
import useTabPreview from "../dynamicStorage/hooks/useTabPreview.hook";
import useUpload from "../dynamicStorage/hooks/useUpload.hook";

type Props = {
  treeData: TFileTreeItem[];
  setTreeData: React.Dispatch<React.SetStateAction<TFileTreeItem[]>>;
  contextPath: string;
  companyId: string;
  item: TFileTreeItem;
  isHovering: boolean;
  isSelected: boolean;
};
const MicrositeStorageItemMenu: FC<Props> = ({
  item,
  setTreeData,
  treeData,
  contextPath,
  companyId,
  isSelected,
  isHovering,
}) => {
  const [isOpenCreateFolder, setIsOpenCreateFolder] = useState(false);
  const [isOpenCopyFile, setIsOpenCopyFile] = useState(false);
  const [isOpenUploadFiles, setIsOpenUploadFiles] = useState(false);
  const [isEditFileOpen, setIsEditFileOpen] = useState(false);
  const [fileData, setFileData] = useState<string | undefined>();
  const { isGlobalAdmin, hasPermission } = useCurrentUser();
  const confirm = useConfirm();
  const hasStorageWriteRole =
    isGlobalAdmin || hasPermission(UserPermissions.ROLE_FS_WRITE);
  const hasStorageReadRole =
    isGlobalAdmin || hasPermission(UserPermissions.ROLE_FS_READ);
  const { fileName, folder, isRootDir, mimeType } = item;
  const isEditFileAllowed = allowedFilesToEdit.includes(mimeType);

  const onDownloadFile = useDownload({
    download: async (path: string) => {
      return await getMicroSiteStorageFileApi({
        path,
        companyId,
      });
    },
  });

  const onUploadFile = useUpload({
    upload: async (formData: FormData) => {
      const { data } = await uploadMicroSiteStorageFileApi({
        formData,
        companyId,
      });
      return data;
    },
    setTreeData,
    selectedItem: item,
  });

  const onSaveFile = useSaveFile({
    saveFile: async (formData: FormData) => {
      const { data } = await uploadMicroSiteStorageFileApi({
        formData,
        companyId,
      });
      return data;
    },
    closeModal: () => setIsEditFileOpen(false),
    setTreeData,
    selectedItem: item,
  });

  const onDelete = useDelete({
    deleteFile: async (path: string) => {
      await deleteMicroSiteStorageFileApi({
        path,
        companyId,
      });
    },
    setTreeData,
  });

  const handleCopyFile = useCopyFile({
    copyFile: async (params: CopyFileParams) => {
      const { data } = await copyMicroSiteStorageFileApi({
        ...params,
        companyId,
      });
      return data;
    },
    setTreeData,
    treeData,
  });

  const onEditFile = useEditFile({
    getFileData: async (path: string) => {
      const { data } = await getMicroSiteStorageFileApi({
        path,
        companyId,
      });

      const decoder = new TextDecoder("utf-8");

      return decoder.decode(data);
    },
    setFileData,
    openEditDrawer: () => setIsEditFileOpen(true),
  });

  const onGetLink = useGetLink({
    getFileData: async (path: string) => {
      const { data } = await getMicroSiteStorageFileLinkApi({
        path,
        companyId,
      });

      return data;
    },
  });

  const onGetPublicLink = useGetLink({
    getFileData: async (path: string) => {
      const { data } = await getMicroSiteStorageFileLinkApi({
        path,
        companyId,
      });

      return data.replace(/^.*?:\/\//, "https://s3.us-west-1.amazonaws.com/");
    },
  });

  const onTabPreview = useTabPreview({
    getFileData: async (path: string) => {
      const { data } = await getMicroSiteStorageFileLinkApi({
        path,
        companyId,
      });

      return data;
    },
  });

  const handleCreateFolder = useCreateFolder({
    createFolder: async (formData: FormData) => {
      const { data } = await uploadMicroSiteStorageFileApi({
        formData,
        companyId,
      });
      return data;
    },
    setTreeData,
    selectedItem: item,
  });

  const getFolderFiles = async (path?: string): Promise<TStorageFileItem[]> => {
    const { data } = await getMicroSiteStorageFilesApi({
      path: path || contextPath,
      companyId: companyId as string,
    });

    return data;
  };

  const menuItems = [
    !folder &&
      isEditFileAllowed && {
        ...menuOptions.editFile,
        onClick: () => onEditFile(fileName),
      },
    !folder &&
      hasStorageWriteRole && {
        ...menuOptions.copyFile,
        onClick: () => setIsOpenCopyFile(true),
      },
    hasStorageReadRole &&
      !folder && {
        ...menuOptions.getLink,
        onClick: () => onGetLink(fileName),
      },
    hasStorageReadRole &&
      !folder && {
        ...menuOptions.getPublicLink,
        onClick: () => onGetPublicLink(fileName),
      },
    hasStorageReadRole &&
      !folder && {
        ...menuOptions.tabPreview,
        onClick: () => onTabPreview(fileName),
      },
    hasStorageWriteRole &&
      folder && {
        ...menuOptions.createFolder,
        onClick: () => setIsOpenCreateFolder(true),
      },
    !folder && {
      ...menuOptions.download,
      onClick: () => onDownloadFile(fileName),
    },
    folder &&
      hasStorageWriteRole && {
        ...menuOptions.upload,
        onClick: () => setIsOpenUploadFiles(true),
      },
    hasStorageWriteRole &&
      !isRootDir && {
        ...menuOptions.delete,
        onClick: () =>
          confirm({
            action: () => onDelete(fileName),
            title: `Delete ${folder ? "directory" : "file"}`,
          }),
      },
  ].filter(Boolean) as MenuDropdownItem[];

  return (
    <>
      <ItemActions
        isSelected={isSelected}
        isHovering={isHovering}
        menuItems={menuItems}
      />

      <EditFileDrawer
        fileData={fileData}
        setIsEditFileOpen={setIsEditFileOpen}
        isEditFileOpen={isEditFileOpen}
        onSaveFile={onSaveFile}
        setFileData={setFileData}
        mimeType={mimeType}
      />

      {isOpenCreateFolder && (
        <CreateFolderModal
          open={isOpenCreateFolder}
          setOpen={setIsOpenCreateFolder}
          selectedPath={fileName}
          onCreate={handleCreateFolder}
        />
      )}

      {isOpenCopyFile && (
        <CopyFileModal
          getFolderFiles={getFolderFiles}
          open={isOpenCopyFile}
          setOpen={setIsOpenCopyFile}
          selectedPath={fileName}
          onCopy={handleCopyFile}
          pathPrefix={contextPath}
        />
      )}

      {isOpenUploadFiles && (
        <UploadFileModal
          open={isOpenUploadFiles}
          setOpen={setIsOpenUploadFiles}
          selectedPath={fileName}
          onUpload={onUploadFile}
        />
      )}
    </>
  );
};

export default MicrositeStorageItemMenu;
