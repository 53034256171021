import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getCcVarApi } from "src/api/cc-variables.api";
import { TemplateRes } from "src/api/document-templates.api";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { selectCurrentCampaign } from "src/store/slices/campaignsSlice";
import { TCcVariable } from "src/store/slices/ccVariablesSlice";
import { selectCurrentStep } from "src/store/slices/stepsSlice";
import { KeyPanelMode } from "src/types";
import { getMessageApi } from "../../../store/slices/appSlice";
import AddCCVarForm from "../AddCCVarForm";
import { closestEditableElement, postProcessContent } from "../helpers";
import { VarKeyPanel } from "./KeyPanel";

type DocViewIframeProps = {
  initialContent: string;
  ccVariables: TCcVariable[];
  onUpdated: () => void;
  companyId: number | undefined;
  templateData?: TemplateRes;
  onTemplateLoaded: (doc: Document) => void;
  scrollTop: number;
  setScrollTop: (scrollTop: number) => void;
  onKeyAdded: () => void;
  isPdf?: boolean;
  pdfSrc?: string;
};

const DocViewIframe: FC<DocViewIframeProps> = ({
  initialContent,
  ccVariables,
  onUpdated,
  companyId,
  onTemplateLoaded,
  scrollTop,
  setScrollTop,
  isPdf,
  pdfSrc,
  onKeyAdded,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [selectedVariable, selectVariable] = useState<TCcVariable>();
  const [mode, setMode] = useState<KeyPanelMode | undefined>();
  const currentCampaign = useSelector(selectCurrentCampaign);
  const currentStep = useSelector(selectCurrentStep);
  const [createKey, setCreateKey] = useState<string>();
  const { isGlobalAdmin } = useCurrentUser();
  const messageApi = getMessageApi();

  const handleCloseKeyPanel = () => {
    setMode(undefined);
  };

  useEffect(() => {
    const recentlyAddedVariable = ccVariables.find((variable) => {
      return variable.id.key === createKey;
    });

    if (recentlyAddedVariable && recentlyAddedVariable.state === "computed") {
      setCreateKey(undefined);
      onUpdated();
      onKeyAdded();
    }

    const updatedSelectedVariable = ccVariables.find((variable) => {
      return variable.id.key === selectedVariable?.id.key;
    });

    if (updatedSelectedVariable?.state === "computed") {
      selectVariable(updatedSelectedVariable);
      onUpdated();
    }
  }, [ccVariables]);

  useEffect(() => {
    if (isPdf) {
      return;
    }

    const iframe = iframeRef.current;
    const doc = iframe?.contentDocument || iframe?.contentWindow?.document;

    if (!doc) {
      messageApi.error("Unable to load the template");
      return;
    }

    doc.open();
    doc.write(initialContent);
    doc.close();

    const applyScrollPosition = () => {
      if (scrollTop) {
        const scrollingElement = doc.scrollingElement || doc.documentElement;
        scrollingElement.scrollTop = scrollTop;
      }
    };

    const handleClick = async (event: MouseEvent) => {
      const closestElement = closestEditableElement(event);
      const veContentKey = closestElement
        ? closestElement.getAttribute("ve-content")
        : null;

      if (!veContentKey) {
        console.warn("Unable to find ve-content element");
        return;
      }

      if (!currentCampaign || !currentStep) {
        console.warn("Unable to get [currentCampaign] or [currentStep]");
        return;
      }

      const { data: ccItem } = await getCcVarApi({
        campaignId: currentCampaign.id,
        stepId: currentStep.id,
        key: veContentKey,
        companyId: companyId,
      });

      if (ccItem) {
        setMode(KeyPanelMode.Edit);
        selectVariable(ccItem);
      } else if (!ccItem && veContentKey) {
        if (isGlobalAdmin) {
          setMode(KeyPanelMode.Create);
          setCreateKey(veContentKey);
        } else {
          messageApi.warning("You are not allowed to create CC variables");
        }
      }
    };

    const handleScroll = () => {
      const scrollingElement = doc.scrollingElement || doc.documentElement;
      setScrollTop(scrollingElement.scrollTop);
    };
    const handleLoadedContentLoaded = () => {
      postProcessContent(doc);
      applyScrollPosition();
    };

    const timeoutId = setTimeout(() => {
      handleLoadedContentLoaded();
    }, 0);

    doc.addEventListener("click", handleClick);
    doc.addEventListener("scroll", handleScroll);

    return () => {
      clearTimeout(timeoutId);
      doc.removeEventListener("click", handleClick);
      doc.removeEventListener("scroll", handleScroll);
    };
  }, [initialContent]);

  return (
    <div className="w-full mt-[24px] border border-slate-300 rounded-md h-[600px]">
      <div>
        {isPdf && pdfSrc ? (
          <iframe
            className="w-full h-[600px] rounded-md"
            title="preview"
            src={pdfSrc}
          />
        ) : (
          <iframe
            ref={iframeRef}
            className="w-full h-[600px] rounded-md"
            title="preview"
          />
        )}
      </div>
      {mode === KeyPanelMode.Edit && (
        <VarKeyPanel
          onClose={handleCloseKeyPanel}
          variableData={selectedVariable}
          onSaved={onUpdated}
          ccVariables={ccVariables}
          mode={mode}
          isOpen={!!mode}
        />
      )}
      {mode === KeyPanelMode.Create && (
        <AddCCVarForm
          isOpened={!!mode}
          setIsOpened={(val) => setMode(val ? KeyPanelMode.Create : undefined)}
          setEditModeProps={() => {}}
          ccVarData={null}
          varKeysLowerCase={ccVariables.map((item) =>
            item.id.key.toLowerCase()
          )}
          companyId={companyId}
          createKey={createKey}
          ccVariables={ccVariables}
        />
      )}
    </div>
  );
};

export default DocViewIframe;
