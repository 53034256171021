import { FC } from "react";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { useGoBack } from "src/hooks/useGoBack";
import { TCampaign } from "../../../store/slices/campaignsSlice";
import { UserPermissions } from "../../../store/slices/usersSlice";
import { Icons } from "../../common/Icons";
import { Button } from "../../common/ui/button";
import ComputationMessages from "../ComputationMessages/ComputationMessages";
import EditCampaignName from "../EditCampaignName/EditCampaignName";
import Settings from "../Settings/Settings";

type Props = {
  currentCampaign: TCampaign;
  companyId: number | undefined;
};

const CampaignHeader: FC<Props> = ({ currentCampaign, companyId }) => {
  const goBack = useGoBack("/admin/campaigns");
  const { isGlobalAdmin, hasPermission } = useCurrentUser();

  const hasPermissions =
    isGlobalAdmin || hasPermission(UserPermissions.ROLE_CAMPAIGN_MANAGER);

  const onGoBack = () => {
    goBack();
  };

  return (
    <div className="flex justify-between">
      <div className="gap-[16px] flex items-center">
        <Button
          variant={"ghost"}
          icon={Icons.back}
          onClick={onGoBack}
          className="p-[12px] h-auto"
        />

        <EditCampaignName
          hasPermissions={hasPermissions}
          currentCampaign={currentCampaign}
          companyId={companyId}
        />
      </div>
      <div className="flex items-center justify-between gap-[6px]">
        <ComputationMessages
          campaignId={currentCampaign.id}
          companyId={companyId}
        />
        <Settings currentCampaign={currentCampaign} companyId={companyId} />
      </div>
    </div>
  );
};

export default CampaignHeader;
