import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { TNavigationItem } from "../../globalTypes";
import { Button } from "../../components/common/ui/button";
import { Icons } from "../../components/common/Icons";
import Navigation from "../../components/common/Navigation";

const GlobalStoragePage = () => {
  const navigate = useNavigate();

  const navigationItems: TNavigationItem[] = [
    {
      path: "public",
      title: "Public",
    },
    {
      path: "private",
      title: "Private",
    },
  ];

  return (
    <div>
      <div className="flex gap-[12px] items-center">
        <Button
          variant={"ghost"}
          icon={Icons.back}
          onClick={() => navigate("/admin/campaigns/")}
          className="p-[12px] h-auto"
        />
        <h1 className="text-slate-900 text-2xl font-semibold font-sans leading-normal capitalize">
          Global Storage
        </h1>
      </div>

      <Navigation items={navigationItems} />

      <div className="h-[1px] bg-[#E2E8F0]" />
      <Outlet />
    </div>
  );
};

export default GlobalStoragePage;
