import { Checkbox, Form, FormInstance, Input, InputRef, Modal } from "antd";
import { Rule } from "antd/es/form";
import React, { FC, useRef, useState } from "react";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { dictionaryApi, DictionaryType } from "../../../../api/dictionary.api";
import useSetFocus from "../../../../hooks/useSetFocus";
import useSubmitFormOnEnter from "../../../../hooks/useSubmitFormOnEnter";
import { TStep } from "../../../../store/slices/stepsSlice";
import { TStepFormValues } from "../../../campaignSteps/CampaignSteps/CampaignSteps";
import { customizeRequiredMark } from "../../../companies/CompanySite/CustomerSite";
import SearchableMultiSelect from "../../SearchableMultiSelect";
import { minMax } from "../../../../utils/validations";
import micrositeContextFolderTooltip from "../../../../constants/micrositeContextFolderTooltip";
import { MICROSITE_INPUT_PLACEHOLDER } from "../../../../constants";

type Props = {
  editStep: TStep | null;
  isModalOpen: boolean;
  form: FormInstance<TStepFormValues>;
  onCancel: () => void;
  onSubmitForm: () => void;
  stepNameValidationRule: Rule;
};

const StepModal: FC<Props> = ({
  editStep,
  isModalOpen,
  form,
  onCancel,
  onSubmitForm,
  stepNameValidationRule,
}) => {
  const inputRef = useRef<InputRef>(null);
  const { isGlobalAdmin } = useCurrentUser();
  const [classes, setClasses] = useState<string[]>(editStep?.classes || []);

  useSubmitFormOnEnter(onSubmitForm);
  useSetFocus(inputRef);

  const fetchClasses = async (word: string) => {
    const { data } = await dictionaryApi(DictionaryType.CLASS).find({
      wordMatch: `*${word}*`,
    });
    return data.map((item) => ({ label: item, value: item }));
  };

  const handleSelectClasses = (classes: string[]) => {
    form.setFieldValue("classes", classes);
    setClasses(classes);
  };

  return (
    <Modal
      title={editStep ? "Edit " : "Add Step"}
      open={isModalOpen}
      onOk={onSubmitForm}
      okText={editStep ? "Update" : "Save"}
      width={600}
      onCancel={onCancel}
    >
      <Form
        requiredMark={customizeRequiredMark}
        form={form}
        layout="vertical"
        name="step_form"
        initialValues={
          !editStep
            ? {
                microSiteContextFolder: "${step.name}",
              }
            : undefined
        }
      >
        <Form.Item
          name="name"
          label="Step Name"
          tooltip="Please enter a string up to 255 characters long"
          rules={[
            stepNameValidationRule,
            {
              type: "string",
              required: true,
              whitespace: true,
              message: "Required field!",
            },
            {
              max: 255,
              message: "Step name must contain no more than 255 characters!",
            },
          ]}
        >
          <Input
            className="h-[48px]"
            placeholder="Enter Step name"
            ref={inputRef}
          />
        </Form.Item>
        <Form.Item
          name="microSiteContextFolder"
          label="Microsite Context Folder"
          tooltip={micrositeContextFolderTooltip}
          rules={[minMax({ max: 255, text: "Path" })]}
        >
          <Input
            className="h-[48px]"
            placeholder={MICROSITE_INPUT_PLACEHOLDER}
          />
        </Form.Item>
        {isGlobalAdmin && (
          <>
            <Form.Item name="classes" label="Classes" valuePropName="checked">
              <SearchableMultiSelect
                fetchOptions={fetchClasses}
                onSelect={handleSelectClasses}
                value={classes}
                className="h-[48px]"
              />
            </Form.Item>
            <Form.Item name="hidden" valuePropName="checked">
              <Checkbox>Switch visibility</Checkbox>
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default StepModal;
