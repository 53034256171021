import { Form, Input, InputNumber, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getProviderModelsApi } from "src/api/cc-variables.api";
import {
  createModelConfigApi,
  updateModelConfigApi,
} from "src/api/model-configs.api";
import { serviceProviders } from "src/constants";
import { getMessageApi } from "src/store/slices/appSlice";
import { ModelConfigFormValues } from "src/types/modelConfigs";
import { getDynFormSelectOptions } from "src/utils/cm.utils";
import { minMax, required } from "src/utils/validations";
import DeleteModelConfigModal from "../common/modals/DeleteModelConfigModal/DeleteModelConfigModal";
import { Button } from "../common/ui/button";

type Props = {
  className?: string;
  modelConfigId?: string;
  initialValues?: ModelConfigFormValues;
};

const serviceProviderOptions = Object.entries(serviceProviders).map(
  ([key, value]) => ({
    label: value.label,
    value: key,
  })
);

const ModelConfigForm: FC<Props> = ({
  className,
  modelConfigId,
  initialValues,
}) => {
  const [form] = useForm<ModelConfigFormValues>();
  const [modelNameOptions, setModelNameOptions] = useState<string[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const messageApi = getMessageApi();
  const navigate = useNavigate();

  const getServiceProvidersModels = async (selectedServiceProvider: string) => {
    const options = await getProviderModelsApi({
      provider: selectedServiceProvider,
    });

    setModelNameOptions(options.data);
  };

  const memoizedModelNameOptions = useMemo(() => {
    return getDynFormSelectOptions({ data: modelNameOptions });
  }, [modelNameOptions]);

  useEffect(() => {
    if (initialValues?.serviceProvider) {
      getServiceProvidersModels(initialValues.serviceProvider);
    }
  }, [initialValues?.serviceProvider]);

  const onSubmit = async (values: ModelConfigFormValues) => {
    setLoading(true);
    if (!modelConfigId) {
      try {
        const created = await createModelConfigApi(values);
        messageApi.success("Model config created successfully");
        navigate(`/admin/model-configs/edit/${created?.data.id}`);
      } catch (error) {
        console.error("Error creating model config", error);
      }
    } else {
      try {
        await updateModelConfigApi({
          id: Number(modelConfigId),
          ...initialValues,
          ...values,
        });
        messageApi.success("Model config updated successfully");
      } catch (error) {
        console.error("Error updating model config", error);
      }
    }
    setLoading(false);
  };

  //TODO move to util and reuse
  const customizeRequiredMark = (
    label: React.ReactNode,
    { required }: { required: boolean }
  ) => (
    <span className="text-[#475569] font-sans font-semibold text-[16px] flex">
      {label}
      {required && <span className="text-red-500 ml-1">*</span>}
    </span>
  );

  return (
    <>
      <div className={className}>
        <Form
          name="userForm"
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          requiredMark={customizeRequiredMark}
          initialValues={modelConfigId ? initialValues : undefined}
        >
          <Form.Item
            name="title"
            label="Title"
            tooltip="Please enter a value between 2 and 255 characters."
            rules={[
              required(true),
              minMax({ min: 2, max: 255, text: "Title" }),
            ]}
          >
            <Input placeholder="Enter unique title" className="h-[48px]" />
          </Form.Item>
          <Form.Item
            name="serviceProvider"
            label="Service Provider"
            rules={[required(true)]}
          >
            <Select
              className="h-[48px]"
              options={serviceProviderOptions}
              placeholder="Choose service provider"
              onChange={(value) => {
                if (value !== initialValues?.serviceProvider) {
                  form.setFieldsValue({ modelName: undefined });
                }
                getServiceProvidersModels(value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="modelName"
            label="Model Name"
            rules={[required(true)]}
          >
            <Select
              className="h-[48px]"
              options={memoizedModelNameOptions}
              placeholder="Select service provider to view models"
            />
          </Form.Item>
          <div className="flex justify-between gap-[24px]">
            <Form.Item
              name="readTimoutSec"
              tooltip="Please enter the value beetwen 0 and 5000 in seconds(sec)."
              label="Read Timeout"
              rules={[required(true)]}
              className="flex-1"
            >
              <InputNumber
                placeholder="Enter read timeout"
                className="h-[48px] w-full py-[8px]"
                step={100}
                max={5000}
                min={0}
              />
            </Form.Item>
            <Form.Item
              name="maxTokens"
              tooltip="Please enter the value beetwen 100 and 10000."
              label="Max Tokens"
              rules={[required(true)]}
              className="flex-1"
            >
              <InputNumber
                placeholder="Enter max tokens"
                className="h-[48px] w-full py-[8px]"
                step={100}
                max={10000}
                min={100}
              />
            </Form.Item>
            <Form.Item
              name="topP"
              tooltip="Please enter the value beetwen 0 and 1"
              label="Top P"
              rules={[required(true)]}
              className="flex-1"
            >
              <InputNumber
                placeholder="Enter topP"
                className="h-[48px] w-full py-[8px]"
                step={0.1}
                max={1}
                min={0}
              />
            </Form.Item>
            <Form.Item
              name="temperature"
              tooltip="Please enter the value beetwen 0 and 1"
              label="Temperature"
              rules={[required(true)]}
              className="flex-1"
            >
              <InputNumber
                placeholder="Enter temperature"
                className="h-[48px] w-full py-[8px]"
                step={0.1}
                max={1}
                min={0}
              />
            </Form.Item>
          </div>

          <Form.Item>
            <Button disabled={isLoading} loading={isLoading} type={"submit"}>
              {modelConfigId ? "Update" : "Create"}
            </Button>
            {modelConfigId && (
              <Button
                disabled={initialValues?.systemDefault || isLoading}
                onClick={(e) => {
                  e.preventDefault();
                  setIsDeleteModalOpen(true);
                }}
                className="ml-4"
                variant={"destructive"}
              >
                Delete
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
      {isDeleteModalOpen && (
        <DeleteModelConfigModal
          setIsModalOpen={setIsDeleteModalOpen}
          isModalOpen={isDeleteModalOpen}
          aiModelId={Number(modelConfigId)}
        />
      )}
    </>
  );
};

export default ModelConfigForm;
