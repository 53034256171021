import { Spin, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectMessageApi } from "../../../../store/slices/appSlice";
import {
  editCcItemKeyThunk,
  useCcVariables,
} from "../../../../store/slices/ccVariablesSlice";
import { AppDispatch } from "../../../../store/store";

type TPropsType = {
  itemKey: string;
};

const GridItemKey = (props: TPropsType) => {
  const { itemKey } = props;
  const dispatch: AppDispatch = useDispatch();
  const ccVars = useCcVariables();
  const [value, setValue] = useState(itemKey);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState("");
  const ref = useRef<HTMLDivElement>(null);
  const messageApi = useSelector(selectMessageApi);

  const onBlur = async () => {
    if (isOpened) {
      setValue(itemKey);
      setIsOpened(false);

      return;
    }

    if (value === itemKey) return;

    try {
      setIsLoading(true);
      messageApi.open({
        key: "rename_key",
        type: "loading",
        content: "Renaming the key...",
      });

      await dispatch(
        editCcItemKeyThunk({ currentKey: itemKey, newKey: value })
      ).unwrap();

      messageApi.open({
        key: "rename_key",
        type: "success",
        content: "Successfully renamed!",
      });
    } catch (e: any) {
      console.error(e);
      setValue(itemKey);

      messageApi.open({
        key: "rename_key",
        type: "error",
        content: e?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    const lowerCaseValue = value.toLowerCase();

    if (!value?.length) {
      setValue(value);
      setTooltipTitle(
        "The value should not be empty. The changes will not be saved!"
      );
      setIsOpened(true);

      return;
    }

    if (!/^[a-zA-Z_$][a-zA-Z0-9_$]*$/.test(value)) {
      setValue(value);
      setTooltipTitle(
        "Invalid Javascript variable name. The changes will not be saved!"
      );
      setIsOpened(true);

      return;
    }

    if (
      itemKey.toLowerCase() !== lowerCaseValue &&
      ccVars.map((item) => item.id.key.toLowerCase()).includes(lowerCaseValue)
    ) {
      setValue(value);
      setTooltipTitle(
        "The key already exists in this grid. The changes will not be saved!"
      );
      setIsOpened(true);

      return;
    }

    setValue(value);
    setTooltipTitle("");
    setIsOpened(false);
  };

  return (
    <div ref={ref}>
      <Tooltip title={tooltipTitle} color={"red"} open={isOpened}>
        <TextArea
          autoSize
          value={value.length ? value : undefined}
          placeholder="not defined"
          onBlur={onBlur}
          onChange={onChange}
          disabled={isLoading}
          className="hover:border-indigo-600 border-transparent"
        />
      </Tooltip>
      <Spin
        spinning={isLoading}
        className={
          "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        }
      />
    </div>
  );
};

export default GridItemKey;
