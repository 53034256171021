import {
  CheckCircleIcon,
  CommandLineIcon,
  PencilSquareIcon,
} from "src/components/common/Icons";
import { TCampaignType } from "src/store/slices/campaignsSlice";
import { DocumentTemplateState } from "src/types/docTemplates";
import {
  AdminRoleIcon,
  GlobalRoleIcon,
  UserRoleIcon,
} from "../components/common/Icons";
import {
  TCcVariableType,
  TModelServiceProvider,
} from "../store/slices/ccVariablesSlice";
import { TUserRole } from "../store/slices/usersSlice";

type HeroIcon = React.ForwardRefExoticComponent<
  React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
    title?: string;
    titleId?: string;
  } & React.RefAttributes<SVGSVGElement>
>;

type BadgeSetting = {
  label: string;
  className: string;
  icon?: HeroIcon | any;
};

export const campaignBadgeSettings: Record<TCampaignType, BadgeSetting> = {
  CAMPAIGN: {
    label: "Campaign",
    className: "bg-indigo-100 text-indigo-900",
  },
  DRAFT_TEMPLATE: {
    label: "Draft",
    className: "bg-slate-100 text-slate-500",
  },
  PUBLISHED_TEMPLATE: {
    label: "Published",
    className: "bg-green-100 text-green-950",
  },
};

export const campaignStatusBadgeSettings: Record<
  TCcVariableType,
  { className: string }
> = {
  function: {
    className: "bg-indigo-100 text-indigo-900",
  },
  prompt: {
    className: "bg-slate-200 text-slate-500",
  },
  string: {
    className: "bg-green-100 text-green-950",
  },
  web_scraper: {
    className: "bg-slate-100 text-slate-500",
  },
  asset: {
    className: "bg-slate-100 text-slate-500",
  },
};

export const PROXY_PREFIX = "/proxy/cm";

export enum ComputationType {
  FULL = "FULL",
  NOT_COMPUTED_AND_ASSETS = "NOT_COMPUTED_AND_ASSETS",
  NOT_COMPUTED = "NOT_COMPUTED",
}

export enum ExecutionType {
  EXECUTION_PHASE = "EXECUTION_PHASE",
  EXECUTION_STEP = "EXECUTION_STEP",
  EXECUTION_CC_ITEM = "EXECUTION_CC_ITEM",
  EXECUTION_CAMPAIGN = "EXECUTION_CAMPAIGN",
}

export const recalculateOptions = [
  {
    label: "Re-compute all",
    value: ComputationType.FULL,
  },
  {
    label: "Re-compute assets and not computed",
    value: ComputationType.NOT_COMPUTED_AND_ASSETS,
  },
  {
    label: "Run not computed",
    value: ComputationType.NOT_COMPUTED,
  },
];

export const docTemplateStateBadgeSettings: Record<
  DocumentTemplateState,
  BadgeSetting
> = {
  [DocumentTemplateState.DRAFT]: {
    className: "bg-slate-100 text-slate-500 border-none",
    label: "Draft",
    icon: PencilSquareIcon,
  },
  [DocumentTemplateState.PUBLISHED]: {
    className: "bg-green-100 text-green-950 border-none",
    label: "Published",
    icon: CheckCircleIcon,
  },
  [DocumentTemplateState.SYSTEM]: {
    className: "bg-indigo-100 text-indigo-900 border-none",
    label: "System",
    icon: CommandLineIcon,
  },
};

export const roleSettings: Record<TUserRole, BadgeSetting> = {
  global_admin: {
    label: "Global Admin",
    className: "bg-slate-100 text-slate-500",
    icon: GlobalRoleIcon,
  },
  admin: {
    label: "Admin",
    className: "bg-slate-100 text-slate-500",
    icon: AdminRoleIcon,
  },
  user: {
    label: "User",
    className: "bg-slate-100 text-slate-500",
    icon: UserRoleIcon,
  },
};

export enum CompanyGlobalAdminOptionsEnum {
  ENABLE_ADMIN_RERUN = "ENABLE_ADMIN_RERUN",
}

export const companyGlobalAdminOptions = [
  {
    label: "Allow campaign re-run for admins",
    key: CompanyGlobalAdminOptionsEnum.ENABLE_ADMIN_RERUN,
  },
];

export const DROPDOWN_MENU_COLLAPSE_LENGTH = 6;

export const serviceProviders: Record<
  TModelServiceProvider,
  { label: string }
> = {
  OPENAI: {
    label: "OpenAI",
  },
  VERTEX_AI: {
    label: "Vertex AI",
  },
  BEDROCK: {
    label: "Bedrock",
  },
};

export const MICROSITE_INPUT_PLACEHOLDER = "<Not Used>";
export const MICRO_SITE_POSTFIX = ".velocityengine.co";
export const MICRO_SITE_MAX_LENGTH = 63;
