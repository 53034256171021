import { useModelConfigsList } from "src/hooks/useModelConfigsList";
import Pagination from "../common/Pagination";

const ModelConfigsPagination = () => {
  const { pageNumber, pageSize, total, updatePage, updatePageSize, loading } =
    useModelConfigsList();

  if (loading) {
    return null;
  }

  return (
    <div className="flex justify-end">
      <Pagination
        updatePageSize={updatePageSize}
        updatePage={updatePage}
        total={total}
        pageSize={pageSize}
        pageNumber={pageNumber}
      />
    </div>
  );
};

export default ModelConfigsPagination;
