import { Form, Input, Modal } from "antd";
import { TextAreaRef } from "antd/es/input/TextArea";
import React, { useEffect, useRef } from "react";
import { TImportFormData } from "../../../globalTypes";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import TextAreaWithAltEnter from "../../common/TextAreaWithAltEnter";

type PropsType = {
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

const ImportModal = (props: PropsType) => {
  const { isOpened, setIsOpened } = props;
  const [form] = Form.useForm<TImportFormData>();
  const initialFormValues = { delimiter: "\\t" } as TImportFormData;
  const inputTagRef = useRef<TextAreaRef>(null);

  useSubmitFormOnEnter(() => submitForm());

  useEffect(() => {
    if (inputTagRef.current) {
      inputTagRef.current.focus({
        cursor: "end",
      });
    }
  }, []);

  const handleCancel = () => {
    form.resetFields();
    setIsOpened(false);
  };

  const onImport = async (values: TImportFormData) => {
    console.log(values);
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TImportFormData) => {
      await onImport(values);
    },
    // ignoreFields: ["value"],
  });

  return (
    <Modal
      title="Import Labels"
      open={isOpened}
      okText="Import Labels"
      onOk={submitForm}
      onCancel={handleCancel}
      destroyOnClose={true}
      centered={true}
      width={"50vw"}
    >
      <Form
        form={form}
        layout="vertical"
        name="campaign_lables_import_form"
        style={{ height: "50vh", overflowY: "scroll" }}
        initialValues={initialFormValues}
      >
        <Form.Item
          name="delimiter"
          label="Delimiter"
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if (!value?.length)
                  return Promise.reject(new Error("Required field!"));
                if (value.length > 26)
                  return Promise.reject(
                    new Error(
                      "The delimiter must contain no more than 26 characters!"
                    )
                  );

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="Enter the delimiter" />
        </Form.Item>

        <Form.Item
          name="value"
          label="Value"
          rules={[{ required: true, message: "Required field!" }]}
        >
          <TextAreaWithAltEnter
            placeholder="Enter value"
            currentForm={form}
            fieldName="value"
            rows={10}
            ref={inputTagRef}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ImportModal;
