import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import { useCompaniesList } from "src/store/slices/companiesSlice";
import { stringifyObjectValues } from "src/utils/stringifyObjectValues";
import { getMessageApi } from "../../../store/slices/appSlice";
import {
  TCampaignListOptionType,
  initCampaignsListFilters,
  loadCampaignsList,
} from "../../../store/slices/campaignsListSlice";
import { AppDispatch } from "../../../store/store";

export const ALL_COMPANIES_ID = "ALL";

export type CampaignsListRequestParams = {
  companyId: number | null;
  campaignType: TCampaignListOptionType;
  page: number;
  pageSize: number;
};

export type CampaignsListAdditionalParams = {
  backUrl?: string;
};

// TODO: refactor https://github.com/VelocityEngineAdmin/ElevateApp/pull/579#discussion_r1768241980
const useCampaignsList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const messageApi = getMessageApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const companies = useCompaniesList();
  const { isGlobalAdmin } = useCurrentUser();

  const additionalParams = useMemo(() => {
    const backUrl = searchParams.get("backUrl");
    if (!backUrl) {
      return {};
    }
    return {
      backUrl: decodeURIComponent(backUrl),
    };
  }, [searchParams]);

  const requestParams = useMemo<CampaignsListRequestParams>(() => {
    const page = searchParams.get("page");
    const pageSize = searchParams.get("pageSize");
    const companyId = searchParams.get("companyId");
    const campaignType = searchParams.get("campaignType");

    return {
      companyId: companyId ? Number(companyId) : null,
      campaignType: campaignType as TCampaignListOptionType,
      page: Number(page),
      pageSize: Number(pageSize),
    };
  }, [searchParams]);

  useEffect(() => {
    if (!isGlobalAdmin && companies.length > 0 && !requestParams.companyId) {
      handleChangeCompany(companies[0].id.toString());
    }
  }, [companies, isGlobalAdmin, requestParams.companyId]);

  useEffect(() => {
    dispatch(initCampaignsListFilters());
  }, []);

  useEffect(() => {
    if (
      requestParams.page &&
      requestParams.pageSize &&
      requestParams.campaignType
    ) {
      requestCampaignsList(requestParams);
    } else {
      updateSearchParams({
        ...requestParams,
        campaignType: requestParams.campaignType || ALL_COMPANIES_ID,
        page: requestParams.page || 1,
        pageSize: requestParams.pageSize || 50,
      });
    }
  }, [requestParams]);

  async function requestCampaignsList(
    requestParams: CampaignsListRequestParams
  ) {
    try {
      await dispatch(loadCampaignsList(requestParams)).unwrap();
    } catch (e: any) {
      messageApi.error(e?.message);
      console.error(
        "An error occurred while trying to init campaigns page:",
        e
      );
    }
  }

  const updateSearchParams = (
    requestParams: CampaignsListRequestParams,
    additionalParams?: CampaignsListAdditionalParams
  ) => {
    const stringifiedParams = stringifyObjectValues<
      CampaignsListRequestParams & CampaignsListAdditionalParams
    >({
      ...requestParams,
      ...additionalParams,
    });

    setSearchParams(stringifiedParams);
  };

  const handleChangePage = (page: number) => {
    updateSearchParams(
      {
        ...requestParams,
        page,
      },
      additionalParams
    );
  };

  const handleChangePageSize = (pageSize: number) => {
    updateSearchParams(
      {
        ...requestParams,
        pageSize,
        page: 1,
      },
      additionalParams
    );
  };

  const handleChangeCompany = (companyId: string) => {
    updateSearchParams(
      {
        ...requestParams,
        companyId:
          companyId === ALL_COMPANIES_ID || !companyId
            ? null
            : Number(companyId),
        page: 1,
      },
      additionalParams
    );
  };

  const handleChangeCampaignType = (campaignType: TCampaignListOptionType) => {
    updateSearchParams(
      {
        ...requestParams,
        campaignType,
        page: 1,
      },
      additionalParams
    );
  };

  return {
    requestParams,
    additionalParams,
    requestCampaignsList,
    handleChangePage,
    handleChangePageSize,
    handleChangeCompany,
    handleChangeCampaignType,
  };
};

export default useCampaignsList;
