import { Alert, Flex, Form, Input, InputRef, Modal, Select } from "antd";
import React, { FC, FocusEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextAreaWithAltEnter from "../../../components/common/TextAreaWithAltEnter";
import useCCVarData from "../../../hooks/useCCVarData";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import useSubmitFormOnEnter from "../../../hooks/useSubmitFormOnEnter";
import { selectMessageApi } from "../../../store/slices/appSlice";
import { getCurrentCampaign } from "../../../store/slices/campaignsSlice";
import {
  editCcItemKeyThunk,
  saveCcVarThunk,
  TCcVarsFormValues,
  TSaveCcVarThunkProps,
  useCcVariables,
} from "../../../store/slices/ccVariablesSlice";
import { getCurrentPhase } from "../../../store/slices/phasesSlice";
import { getCurrentStep } from "../../../store/slices/stepsSlice";
import { UserPermissions } from "../../../store/slices/usersSlice";
import { AppDispatch } from "../../../store/store";
import handleRequestError from "../../../utils/handleRequestError";
import saveCCItemWithRepublish from "../../../utils/saveCCItemWithRepublish";
import submitFormWithTrim from "../../../utils/submitFormWithTrim";
import SelectAssetFromPublicCompanyStorage, {
  SelectAssetType,
} from "../../common/SelectAssetFromPublicCompanyStorage";
import { Button } from "../../common/ui/button";
import DynamicCCForm from "../../dynamicForms/DynamicCCForm";
import { TEditModeProps, TitleModal } from "../CampaignGrid";

type Props = {
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setGridItemSequence: React.Dispatch<React.SetStateAction<number | null>>;
  setEditModeProps: React.Dispatch<React.SetStateAction<TEditModeProps | null>>;
  //null when create mode, object with var data - when edit mode
  ccVarData: TEditModeProps | null;
  gridItemSequence: number;
  varKeysLowerCase: Array<string>;
  companyId: number | undefined;
};

const GridItemForm: FC<Props> = ({
  isOpened,
  setIsOpened,
  ccVarData,
  gridItemSequence,
  setGridItemSequence,
  varKeysLowerCase,
  setEditModeProps,
  companyId,
}) => {
  const { ccVarTypes, form, setDynamicData, type, resultType, dynamicItems } =
    useCCVarData(ccVarData);
  const dispatch: AppDispatch = useDispatch();
  const messageApi = useSelector(selectMessageApi);
  const currentCampaign = getCurrentCampaign();
  const currentPhase = getCurrentPhase();
  const currentStep = getCurrentStep();
  const ccVars = useCcVariables();
  const inputTagRef = useRef<InputRef>(null);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { isGlobalAdmin, hasPermission } = useCurrentUser();
  const hasCcCreatorRole =
    isGlobalAdmin || hasPermission(UserPermissions.ROLE_CAMPAIGN_CC_CREATOR);

  useSubmitFormOnEnter(() => submitForm(), { condition: !isFetching });

  useEffect(() => {
    if (inputTagRef.current) {
      inputTagRef.current.focus({
        cursor: "end",
      });
    }
  }, []);

  const handleCancel = () => {
    form.resetFields();

    setDynamicData(null);
    setIsOpened(false);
    setGridItemSequence(null);
  };

  const saveCcVar = async ({
    values,
    republish = false,
  }: {
    republish?: boolean;
    values: TCcVarsFormValues;
  }) => {
    try {
      if (gridItemSequence === null) {
        messageApi.error("Sequence is null");
        return;
      }

      setIsFetching(true);

      const saveVarProps: TSaveCcVarThunkProps = {
        values,
        seq: gridItemSequence,
        action:
          ccVarData && ccVarData.actionType !== TitleModal.CLONE
            ? "update"
            : "create",
        resetOvr: isFormDirty,
      };

      await dispatch(
        saveCcVarThunk({ saveVarProps, republish })
      ).unwrap();

      setIsOpened(false);
      setDynamicData(null);
      form.resetFields();
    } catch (e: any) {
      const customError = handleRequestError(e);
      messageApi.error(customError.message);
      console.error(customError);
    } finally {
      setIsFetching(false);
    }
  };

  const onKeyNameChange = async (e: FocusEvent<HTMLInputElement>) => {
    form
      .validateFields(["key"])
      .then(async () => {
        const newKey = e.target.value;
        const currentKey = ccVarData!.variableData.id.key;

        if (currentKey === newKey) return;
        messageApi.open({
          key: "rename_key",
          type: "loading",
          content: "Renaming the key...",
        });

        try {
          const res = await dispatch(
            editCcItemKeyThunk({ currentKey, newKey })
          );

          if ("error" in res) {
            messageApi.open({
              key: "rename_key",
              type: "error",
              content: "Error, the key could not be renamed.",
            });
          } else {
            const newVarData = { ...ccVarData!.variableData };

            newVarData.id = {
              ...newVarData.id,
              key: newKey,
            };

            setEditModeProps({
              variableData: newVarData,
              actionType: TitleModal.EDIT,
            });

            messageApi.open({
              key: "rename_key",
              type: "success",
              content: "Successfully renamed!",
            });
          }
        } catch (e) {
          console.error("Error in updating item key", e);
          messageApi.open({
            key: "rename_key",
            type: "error",
            content:
              "An error occurred while trying to edit the key of the grid item",
          });
        }
      })
      .catch((e) => {
        console.warn("Can't update invalid item key", e.errorFields);
      });
  };

  const submitForm = submitFormWithTrim({
    form,
    onSuccessValidationCb: async (values: TCcVarsFormValues) => {
      const postProcessingParams = values.postProcessingParams;
      if (postProcessingParams) {
        values.postProcessingParams = JSON.stringify(postProcessingParams);
      }

      await saveCCItemWithRepublish({
        values,
        saveCCItem: saveCcVar,
        ccItem: ccVarData?.variableData,
      });
    },
  });

  const handleFormChange = () => {
    //check if values have been changed only in edit mode
    if (ccVarData && !!ccVarData.variableData.resultOvr) {
      const savedProps: { [key: string]: any } = {
        key: ccVarData.variableData.id.key,
        type: ccVarData.variableData.type,
        result: ccVarData.variableData.result,
        ...ccVarData.variableData.options,
      };
      const newProps = form.getFieldsValue(true);

      //change all the numbers to a string before comparing
      for (const key in savedProps) {
        if (typeof savedProps[key] === "number") {
          savedProps[key] = savedProps[key].toString();
        }
      }

      for (const key in newProps) {
        if (typeof newProps[key] === "number") {
          newProps[key] = newProps[key].toString();
        }
      }

      try {
        const isDirty = JSON.stringify(savedProps) !== JSON.stringify(newProps);
        setIsFormDirty(isDirty);
      } catch (e) {
        console.error("Can't parse props:", e);
      }
    }
  };

  //TODO move to util and reuse
  const customizeRequiredMark = (
    label: React.ReactNode,
    { required }: { required: boolean }
  ) => (
    <span className="font-sans font-bold text-[#475569] text-[14px] flex">
      {label}
      {required && <span className="text-red-500 ml-1">*</span>}
    </span>
  );

  const insertToFormField: SelectAssetType = ({ filePublicUrl }) => {
    form.setFieldValue("result", filePublicUrl);
  };

  return (
    <Modal
      title={ccVarData?.actionType || TitleModal.ADD}
      open={isOpened}
      onCancel={handleCancel}
      destroyOnClose={true}
      centered={true}
      width={"80vw"}
      footer={() => {
        return (
          <div className="flex justify-end items-center gap-[12px]">
            {isFormDirty && !!ccVarData?.variableData.resultOvr && (
              <Alert
                className="px-[10px] py-[4px]"
                message={
                  "Your overwritten content will be reset. Are you sure you want to continue?"
                }
                type="warning"
              />
            )}
            <div className="flex gap-[12px]">
              <Button
                onClick={handleCancel}
                variant="primaryOutline"
                className="rounded-full self-start"
                size="sm"
              >
                Cancel
              </Button>
              <Button
                onClick={submitForm}
                className="rounded-full self-start px-5"
                loading={isFetching}
                disabled={isFetching}
                size="sm"
              >
                Save
              </Button>
            </div>
          </div>
        );
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="cc_variable_form"
        style={{ height: "80vh", overflowY: "scroll" }}
        onFieldsChange={handleFormChange}
        requiredMark={customizeRequiredMark}
      >
        <Flex gap="middle" align="center">
          <Form.Item
            name="type"
            label="Type"
            rules={[{ required: true, message: "Required field!" }]}
          >
            <Select
              placeholder="Select type"
              style={{ width: 140 }}
              options={ccVarTypes.map((type) => ({
                value: type,
                label: type,
              }))}
              disabled={!!ccVarData}
            />
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            hidden={!type}
            name="key"
            label="Key"
            tooltip="Enter a valid JavaScript identifier. It should start with a letter, underscore (_), or dollar sign ($), and can include letters, numbers, underscores, and dollar signs."
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  const trimmed = value ? value.trim() : "";
                  const lowerCaseValue = trimmed.toLowerCase();

                  if (!trimmed.length) {
                    return Promise.reject(new Error("Required field!"));
                  }

                  if (
                    ccVarData?.variableData.id.key.toLowerCase() !==
                      lowerCaseValue &&
                    varKeysLowerCase.includes(lowerCaseValue)
                  ) {
                    return Promise.reject(
                      new Error("The key already exists in this grid!")
                    );
                  }

                  if (!/^[a-zA-Z_$][a-zA-Z0-9_$]*$/.test(trimmed)) {
                    return Promise.reject(
                      new Error("Invalid Javascript variable name!")
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              ref={inputTagRef}
              disabled={!hasCcCreatorRole}
              placeholder="Enter unique key name"
              onChange={(e) => form.setFieldValue("key", e.target.value.trim())}
              onKeyDown={(e) => e.stopPropagation()}
              onBlur={
                ccVarData && ccVarData.actionType !== TitleModal.CLONE
                  ? onKeyNameChange
                  : undefined
              }
            />
          </Form.Item>
        </Flex>
        <Form.Item hidden={!type} name="result" label="Result">
          <TextAreaWithAltEnter
            placeholder="Enter result"
            currentForm={form}
            fieldName="result"
            allowClear
            rows={5}
            disabled={resultType === "read_only"}
          />
        </Form.Item>
        {type === "string" && (
          <SelectAssetFromPublicCompanyStorage onSelect={insertToFormField} />
        )}
        <DynamicCCForm
          hidden={!type}
          form={form}
          items={dynamicItems}
          ccVarData={ccVarData}
          gridItemSequence={gridItemSequence}
          companyId={companyId}
          campaignId={currentCampaign!.id}
          stepId={currentStep!.id}
          phaseId={currentPhase!.id}
          stepCCVars={ccVars}
        />
      </Form>
    </Modal>
  );
};

export default GridItemForm;
