export enum VarTypeEnum {
  CAMPAIGN_VAR = "Campaign Variable",
  LOCAL_VAR = "Local Variable",
  RAW = "Raw text",
  PROMPT_RESULTS_VAR = "Prompt Results",
  ENVIRONMENT_VAR = "Environment Variable",
}

export type TVarType =
  | VarTypeEnum.CAMPAIGN_VAR
  | VarTypeEnum.RAW
  | VarTypeEnum.LOCAL_VAR
  | VarTypeEnum.ENVIRONMENT_VAR
  | VarTypeEnum.PROMPT_RESULTS_VAR;

export enum CampaignViewTypes {
  GRID = "GRID",
  DOC = "DOC",
}

export type CopyFileParams = {
  sourcePath: string;
  targetPath: string;
  companyId?: number | string;
};

export enum KeyPanelMode {
  Create = "create",
  Edit = "edit",
}

export enum FileMimeType {
  HTML = "text/html",
  XML = "application/xml",
  PLAIN = "text/plain",
  CSV = "text/csv",
  CSS = "text/css",
  JSON = "application/json",
  PDF = "application/pdf",
  TEXT = "text/plain",
  XSLT_XML = "application/xslt+xml",
}
