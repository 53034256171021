import { Table } from "antd";
import React, { FC, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useLocation } from "react-router-dom";
import { Icons } from "src/components/common/Icons";
import { Badge } from "src/components/common/ui/badge";
import { Button } from "src/components/common/ui/button";
import { campaignStatusBadgeSettings } from "src/constants";
import { useCurrentUser } from "src/hooks/useCurrentUser";
import CampaignClasses from "src/pages/campaign/components/StepData/CampaignClasses";
import { TLabels } from "../../globalTypes";
import {
  TCcVariable,
  TCcVariableId,
  TCcVariableType,
  TCcVarsFormOptionsValues,
  TComputationState,
} from "../../store/slices/ccVariablesSlice";
import { UserPermissions } from "../../store/slices/usersSlice";
import { CC_VAR_SEQUENCE_STEP } from "../../utils/campaigns.constant";
import { getLastSequence } from "../../utils/cm.utils";
import GridItemActions from "./GridItemActions/GridItemActions";
import GridItemForm from "./GridItemForm/GridItemForm";
import GridItemOverrideModal from "./GridItemOverrideModal/GridItemOverrideModal";
import GridItemKey from "./GridItems/GridItemKey/GridItemKey";
import GridItemParameters from "./GridItems/GridItemParameters/GridItemParameters";
import GridItemResult from "./GridItems/GridItemResult/GridItemResult";
import GridItemState from "./GridItems/GridItemState/GridItemState";
import GridItemTitle from "./GridItems/GridItemTitle/GridItemTitle";

const { Column } = Table;

export type TCcGridItemData = {
  key: React.Key;
  state: TComputationState | undefined;
  type: TCcVariableType;
  title: TGridItemTitle;
  varKey: string;
  content: TCcVarsFormOptionsValues | undefined;
  result: string | null;
  override: string | null;
  action: null;
  variable: TCcVariable;
};

export type TGridItemTitle = {
  scope: "global" | "local";
  value: string;
};

export type TOverrideProps = {
  varId: TCcVariableId;
  result: string | null;
  resultOvr: string | null;
  record: TCcGridItemData;
};

export enum TitleModal {
  EDIT = "Edit Row",
  ADD = "Add Row",
  CLONE = "Clone Row",
}

export type TEditModeProps = {
  variableData: TCcVariable;
  actionType: TitleModal;
};

type Props = {
  ccVariables: Array<TCcVariable>;
  campaignLabels: TLabels;
  globalLabels: TLabels;
  companyId: number | undefined;
};

const CampaignGrid: FC<Props> = ({
  ccVariables,
  campaignLabels,
  globalLabels,
  companyId,
}) => {
  const [isAddRowModalOpened, setIsAddRowModalOpened] = useState(false);
  const [editModeProps, setEditModeProps] = useState<TEditModeProps | null>(
    null
  );
  const [gridItemSequence, setGridItemSequence] = useState<number | null>(null);
  const [overrideProps, setOverrideProps] = useState<TOverrideProps | null>(
    null
  );
  const location = useLocation();
  const { isGlobalAdmin, hasPermission } = useCurrentUser();
  const hasCcCreatorRole =
    isGlobalAdmin || hasPermission(UserPermissions.ROLE_CAMPAIGN_CC_CREATOR);
  const hasLabelsManagerRole =
    isGlobalAdmin || hasPermission(UserPermissions.ROLE_C18N_MANAGER);

  useEffect(() => {
    if (location.hash) {
      const elementId = location.hash.substring(1);
      const element = document.getElementById(elementId);

      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const data: TCcGridItemData[] = useMemo(() => {
    return ccVariables.map((variable) => {
      const { id, type, options, result, resultOvr, state } = variable;
      const key = id.key;
      const campaignLabel = campaignLabels[key];
      const globalLabel = globalLabels[key];

      const title: TGridItemTitle = {
        scope: campaignLabel ? "local" : globalLabel ? "global" : "local",
        value: campaignLabel || globalLabel || "",
      };

      return {
        key: JSON.stringify(id),
        state,
        type,
        title,
        varKey: key,
        content: options,
        result,
        override: resultOvr,
        action: null,
        variable,
      };
    });
  }, [ccVariables, campaignLabels, globalLabels]);

  const onAddRow = () => {
    const sequence = getLastSequence(ccVariables, CC_VAR_SEQUENCE_STEP);

    setGridItemSequence(sequence);
    setEditModeProps(null);
    setIsAddRowModalOpened(true);
  };

  return (
    <div className="flex flex-col gap-[24px]">
      <CampaignClasses />
      <div className="flex flex-col gap-[24px]">
        <div className="redesign">
          <Table
            dataSource={data}
            id="grid"
            bordered={true}
            pagination={false}
            scroll={{ y: "70vh", x: 1200 }}
            // virtual
          >
            <Column
              title="Title"
              dataIndex="title"
              key="title"
              width={150}
              shouldCellUpdate={(
                record: TCcGridItemData,
                prevRecord: TCcGridItemData
              ) =>
                JSON.stringify(record.title) !==
                JSON.stringify(prevRecord.varKey)
              }
              render={(title: TGridItemTitle, record) => {
                return (
                  <>
                    {hasLabelsManagerRole ? (
                      <GridItemTitle
                        key={JSON.stringify(title)}
                        itemKey={record.varKey}
                        title={title}
                      />
                    ) : (
                      title.value
                    )}
                  </>
                );
              }}
            />
            <Column
              title="Key"
              dataIndex="varKey"
              key="varKey"
              width={150}
              shouldCellUpdate={(
                record: TCcGridItemData,
                prevRecord: TCcGridItemData
              ) => record.varKey !== prevRecord.varKey}
              render={(key: string, record) => {
                return (
                  <div id={key} className="flex flex-col gap-[8px]">
                    {hasCcCreatorRole ? (
                      <GridItemKey itemKey={key} />
                    ) : (
                      <div>{key}</div>
                    )}
                    <div>
                      <Badge
                        className={
                          campaignStatusBadgeSettings?.[record.type]?.className || "bg-orange-100 text-orange-500"
                        }
                        variant={"table"}
                      >
                        {record.type}
                      </Badge>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              title="Status"
              dataIndex="state"
              key="state"
              width={80}
              shouldCellUpdate={(
                record: TCcGridItemData,
                prevRecord: TCcGridItemData
              ) => record.state !== prevRecord.state}
              align="center"
              render={(state: TComputationState, record) => (
                <GridItemState
                  state={state}
                  varId={record.variable.id}
                  executable={record.variable.executable}
                  companyId={companyId}
                />
              )}
            />
            {isGlobalAdmin && (
              <Column
                title="Parameters"
                dataIndex="content"
                key="content"
                width={250}
                shouldCellUpdate={(
                  record: TCcGridItemData,
                  prevRecord: TCcGridItemData
                ) =>
                  JSON.stringify(record.content) !==
                  JSON.stringify(prevRecord.content)
                }
                render={(options) => <GridItemParameters options={options} />}
              />
            )}
            <Column
              title="Content"
              dataIndex="result"
              key="result"
              className="last-col"
              shouldCellUpdate={(
                record: TCcGridItemData,
                prevRecord: TCcGridItemData
              ) =>
                record.result !== prevRecord.result ||
                record.override !== prevRecord.override
              }
              onCell={(record: TCcGridItemData) => ({
                className: record.state === "error" ? "text-red-600" : "",
              })}
              render={(value, record) => {
                const visibleValue = record.override ? record.override : value;

                return (
                  <GridItemResult
                    variable={record.variable}
                    value={visibleValue}
                    onClickEdit={() => {
                      setOverrideProps({
                        varId: record.variable.id,
                        result: record.variable.result,
                        resultOvr: record.variable.resultOvr,
                        record,
                      });
                    }}
                  />
                );
              }}
            />
            <Column
              title=""
              key="action"
              width={50}
              fixed="right"
              className="!p-0 !pt-[5px] !pl-[5px]"
              render={(_: any, record: TCcGridItemData, index: number) => (
                <GridItemActions
                  variable={record.variable}
                  prevVariable={data[index - 1]?.variable}
                  nextVariable={data[index + 1]?.variable}
                  setIsAddRowModalOpened={setIsAddRowModalOpened}
                  setEditModeProps={setEditModeProps}
                  setGridItemSequence={setGridItemSequence}
                />
              )}
            />
          </Table>
        </div>
        {hasCcCreatorRole && (
          <Button
            variant="primaryOutline"
            className="rounded-full self-start"
            onClick={onAddRow}
            icon={Icons.Plus}
          >
            Add Row
          </Button>
        )}
      </div>
      {isAddRowModalOpened && gridItemSequence !== null && (
        <GridItemForm
          ccVarData={editModeProps}
          isOpened={isAddRowModalOpened}
          setIsOpened={setIsAddRowModalOpened}
          gridItemSequence={gridItemSequence}
          setGridItemSequence={setGridItemSequence}
          varKeysLowerCase={ccVariables.map((item) =>
            item.id.key.toLowerCase()
          )}
          setEditModeProps={setEditModeProps}
          companyId={companyId}
        />
      )}

      {!!overrideProps && (
        <>
          {createPortal(
            <GridItemOverrideModal
              overrideProps={overrideProps}
              setOverrideProps={setOverrideProps}
              record={overrideProps.record}
              companyId={companyId}
            />,
            document.body
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(CampaignGrid);
