import { Empty, Flex } from "antd";
import { FC } from "react";
import { TableHeader } from "src/components/table/Header";
import { CampaignsListRequestParams } from "src/pages/campaigns/hooks/useCampaignsList";
import { TCampaignListElem } from "../../globalTypes";
import { useCampaigns } from "../../store/slices/campaignsSlice";
import { Table, TableBody } from "../common/ui/table";
import CampaignListItem from "./CampaignListItem";

type PropsType = {
  requestParams: CampaignsListRequestParams;
  requestCampaignsList: (params: CampaignsListRequestParams) => void;
};

export const tableHeaderItems = [
  { title: "Campaign", className: "font-sans w-1/3" },
  { title: "Company", className: "font-sans w-1/3" },
  { title: "Campaign Type", className: "font-sans w-1/3" },
  null,
];

const CampaignsList: FC<PropsType> = ({
  requestParams,
  requestCampaignsList,
}) => {
  const campaigns = useCampaigns();

  const handleReloadCurrentList = () => {
    requestCampaignsList(requestParams);
  };

  if (!campaigns.length) {
    const campaignTypeMessage =
      requestParams?.campaignType === undefined ||
      requestParams.campaignType === "ALL"
        ? ""
        : ` with type ${requestParams.campaignType}`;

    return (
      <Flex
        vertical
        style={{ height: "100%", marginBottom: "15vh" }}
        align="center"
        justify="center"
      >
        <Empty
          imageStyle={{ height: "30vh" }}
          description={`There are no campaigns ${campaignTypeMessage} in this company.`}
        />
      </Flex>
    );
  }

  const campaignsData: TCampaignListElem[] = campaigns.map((campaign) => {
    return {
      id: campaign.id,
      title: campaign.name,
      companyId: campaign.extCompanyId,
      companyName: campaign.extCompanyName,
      campaignType: campaign.type,
    };
  });

  return (
    <div className="rounded-md border w-full mx-auto">
      <Table>
        <TableHeader items={tableHeaderItems} />
        <TableBody className="font-sans">
          {campaignsData.map((item) => (
            <CampaignListItem
              key={item.id}
              item={item}
              onReloadCurrentList={handleReloadCurrentList}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default CampaignsList;
