import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import {
  TCreateOrUpdateUserProps,
  createUserApi,
  deleteUserApi,
  getUsersListApi,
  updateUserApi,
  userLoginApi,
  userLoginWithGoogleApi,
  userLogoutApi,
} from "../../api/user.api";
import handleRequestError, {
  TCustomError,
} from "../../utils/handleRequestError";
import { RootState } from "../store";
import { setUsers } from "./usersListSlice";

export enum UserPermissions {
  ROLE_COMPANY_LIST = "ROLE_COMPANY_LIST",
  ROLE_G_CAMPAIGN_LIST = "ROLE_G_CAMPAIGN_LIST",
  ROLE_G_CAMPAIGN_TYPES = "ROLE_G_CAMPAIGN_TYPES",
  ROLE_G_CAMPAIGN_MANAGER = "ROLE_G_CAMPAIGN_MANAGER",
  ROLE_G_ENV_VAR_LOOKUP = "ROLE_G_ENV_VAR_LOOKUP",
  ROLE_PHASE_VIEW = "ROLE_PHASE_VIEW",
  ROLE_PHASE_CREATE = "ROLE_PHASE_CREATE",
  ROLE_PHASE_EDIT = "ROLE_PHASE_EDIT",
  ROLE_PHASE_DELETE = "ROLE_PHASE_DELETE",
  ROLE_PHASE_CLONE = "ROLE_PHASE_CLONE",
  ROLE_PHASE_DEPLOY = "ROLE_PHASE_DEPLOY",
  ROLE_PHASE_COPY = "ROLE_PHASE_COPY",
  // ROLE_STEP_LIST = "ROLE_STEP_LIST",
  // ROLE_STEP_MANAGER = "ROLE_STEP_MANAGER",
  ROLE_STEP_VIEW = "ROLE_STEP_VIEW",
  ROLE_STEP_CREATE = "ROLE_STEP_CREATE",
  ROLE_STEP_EDIT = "ROLE_STEP_EDIT",
  ROLE_STEP_DELETE = "ROLE_STEP_DELETE",
  ROLE_STEP_CLONE = "ROLE_STEP_CLONE",
  ROLE_STEP_DEPLOY = "ROLE_STEP_DEPLOY",
  ROLE_STEP_COPY = "ROLE_STEP_COPY",
  ROLE_G18N_LIST = "ROLE_G18N_LIST",
  ROLE_G18N_MANAGER = "ROLE_G18N_MANAGER",
  ROLE_C18N_LIST = "ROLE_C18N_LIST",
  ROLE_C18N_MANAGER = "ROLE_C18N_MANAGER",
  ROLE_CAMPAIGN_LIST = "ROLE_CAMPAIGN_LIST",
  ROLE_CAMPAIGN_MANAGER = "ROLE_CAMPAIGN_MANAGER",
  ROLE_CAMPAIGN_CSV_EXPORT = "ROLE_CAMPAIGN_CSV_EXPORT",
  ROLE_CAMPAIGN_CLONE = "ROLE_CAMPAIGN_CLONE",
  ROLE_CAMPAIGN_PUBLISH = "ROLE_CAMPAIGN_PUBLISH",
  ROLE_CAMPAIGN_CC_LIST = "ROLE_CAMPAIGN_CC_LIST",
  ROLE_CAMPAIGN_CC_CREATOR = "ROLE_CAMPAIGN_CC_CREATOR",
  ROLE_CAMPAIGN_CC_MODIFIER = "ROLE_CAMPAIGN_CC_MODIFIER",
  ROLE_CAMPAIGN_CC_OVERRIDE = "ROLE_CAMPAIGN_CC_OVERRIDE",
  ROLE_CAMPAIGN_CC_UPDATE_STATE = "ROLE_CAMPAIGN_CC_UPDATE_STATE",
  ROLE_CAMPAIGN_ENV_COMPUTATION = "ROLE_CAMPAIGN_ENV_COMPUTATION",
  ROLE_CAMPAIGN_CC_COMPUTATION = "ROLE_CAMPAIGN_CC_COMPUTATION",
  ROLE_FS_READ = "ROLE_FS_READ",
  ROLE_FS_WRITE = "ROLE_FS_WRITE",
  ROLE_CAMPAIGN_CHANGE_COMPANY = "ROLE_CAMPAIGN_CHANGE_COMPANY",
  ROLE_DOCUMENT_TEMPLATE_MANAGER = "ROLE_DOCUMENT_TEMPLATE_MANAGER",
  ROLE_DICTIONARY_SEARCH = "ROLE_DICTIONARY_SEARCH",
  ROLE_COMPANY_REGISTRATION = "ROLE_COMPANY_REGISTRATION",
}

export type TUserRole = "global_admin" | "admin" | "user";
export type TUserPermission = "advisor" | "owner";
export type TUserData = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  role: TUserRole;
  global_admin_api_key: string | null;
  position: string;
};

export type TPermissions = {
  list: UserPermissions[];
  cmApiKey: null | string;
};

export type TUsersListItem = Omit<TUserData, "global_admin_api_key"> & {
  companies: Array<{
    name: string;
    role: TUserPermission;
  }>;
};

const initialState = {
  userData: {} as TUserData,
  isAuthenticated: false,
  permissions: {
    list: [],
    cmApiKey: null,
  } as TPermissions,
  usersList: [] as Array<TUsersListItem>,
};

type InitialStateType = typeof initialState;

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUserPermissions: (
      state: InitialStateType,
      action: PayloadAction<TPermissions>
    ) => {
      state.permissions = action.payload;
    },
    setIsAuthenticated: (
      state: InitialStateType,
      action: PayloadAction<boolean>
    ) => {
      state.isAuthenticated = action.payload;
    },
    setUser: (state: InitialStateType, action: PayloadAction<TUserData>) => {
      state.userData = action.payload;
    },
    deleteUser: (state: InitialStateType, action: PayloadAction<number>) => {
      state.usersList = state.usersList.filter(
        (user) => user.id !== action.payload
      );
    },
    setUsersList: (
      state: InitialStateType,
      action: PayloadAction<Array<TUsersListItem>>
    ) => {
      state.usersList = action.payload;
    },
  },
});

export const {
  setUserPermissions,
  setUser,
  setIsAuthenticated,
  setUsersList,
  deleteUser,
} = usersSlice.actions;

export default usersSlice.reducer;

/* eslint-disable*/
export const getIsAuthenticated = (): boolean =>
  useSelector((state: RootState) => state.users.isAuthenticated);
export const getUsersList = (): Array<TUsersListItem> =>
  useSelector((state: RootState) => state.users.usersList);

export const selectUsersState = (state: RootState): RootState["users"] =>
  state.users;
export const selectUserData = createSelector(
  selectUsersState,
  (state) => state.userData
);
export const selectUserPermissions = createSelector(
  selectUsersState,
  (state): TPermissions => state.permissions
);

export const hasUserPermission = (permission: UserPermissions) =>
  createSelector(selectUserPermissions, (permissions) => {
    return permissions.list.includes(permission);
  });

export const loginThunk = createAsyncThunk<
  TUserData,
  string | undefined,
  { rejectValue: TCustomError }
>(
  "users/loginThunk",
  async (googleCredentials, { rejectWithValue, dispatch }) => {
    try {
      let user: TUserData;

      if (googleCredentials) {
        const { data } = await userLoginWithGoogleApi(googleCredentials);
        user = data.user;
      } else {
        const { data } = await userLoginApi();
        user = data.user;
      }

      dispatch(setUser(user));
      dispatch(setIsAuthenticated(true));

      return user;
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to login:`, e);

      return rejectWithValue(customError);
    }
  }
);

export const logoutThunk = createAsyncThunk<
  undefined,
  undefined,
  { rejectValue: TCustomError }
>("users/logoutThunk", async (_, { rejectWithValue, dispatch }) => {
  try {
    await userLogoutApi();

    dispatch(setIsAuthenticated(false));
  } catch (e: any) {
    const customError = handleRequestError(e);
    console.error(`An error occurred while trying to logout:`, e);

    return rejectWithValue(customError);
  }
});

export const deleteUserThunk = createAsyncThunk<
  undefined,
  number,
  { rejectValue: TCustomError }
>("users/deleteUserThunk", async (userId, { rejectWithValue, dispatch }) => {
  try {
    await deleteUserApi(userId);

    dispatch(deleteUser(userId));
  } catch (e: any) {
    const customError = handleRequestError(e);
    console.error(`An error occurred while trying to delete the user:`, e);

    return rejectWithValue(customError);
  }
});

export const createUserThunk = createAsyncThunk<
  undefined,
  TCreateOrUpdateUserProps,
  { state: RootState; rejectValue: TCustomError }
>(
  "users/createUserThunk",
  async (userData, { rejectWithValue, dispatch, getState }) => {
    try {
      await createUserApi(userData);

      const { usersList } = getState();
      const { pageSize, pageNumber } = usersList;
      const { data } = await getUsersListApi({
        pageSize,
        pageNumber,
      });

      const { users, totalUsers } = data.users;

      dispatch(setUsers({ users, total: totalUsers }));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to create the user:`, e);

      return rejectWithValue(customError);
    }
  }
);

type TUpdateUserThunkProps = {
  userProps: TCreateOrUpdateUserProps;
  userId: number;
};
export const updateUserThunk = createAsyncThunk<
  undefined,
  TUpdateUserThunkProps,
  { state: RootState; rejectValue: TCustomError }
>(
  "users/updateUserThunk",
  async ({ userProps, userId }, { rejectWithValue, dispatch, getState }) => {
    try {
      await updateUserApi(userProps, userId);

      const { usersList } = getState();
      const { pageSize, pageNumber } = usersList;
      const { data } = await getUsersListApi({
        pageSize,
        pageNumber,
      });

      const { users, totalUsers } = data.users;

      dispatch(setUsers({ users, total: totalUsers }));
    } catch (e: any) {
      const customError = handleRequestError(e);
      console.error(`An error occurred while trying to update the user:`, e);

      return rejectWithValue(customError);
    }
  }
);
